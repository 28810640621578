import Modal from "../../core/modal";
import { Dispatch, SetStateAction, useState } from "react";
import Button from "../../core/button";
import { notify } from "../../core/toast";
import clsx from "clsx";
import Loader from "../loader";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import ChipInput from "components/core/chipInput";
import { inviteGuestEmail } from "api/videoVisit";
import { useMutation } from "react-query";

export interface IShareModalProps {
  title: string;
  url: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setTitle: Dispatch<SetStateAction<string>>;
  setUrl: Dispatch<SetStateAction<string>>;
  noScroll?: boolean;
  apptId: number | string;
  isJoinGuest?: boolean;
}

const ShareModal = ({
  url,
  title,
  setOpen,
  open,
  isLoading,
  setTitle,
  setUrl,
  setIsLoading,
  noScroll,
  apptId,
  isJoinGuest,
}: IShareModalProps) => {
  const { t } = useTranslation("global");

  function copyToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify.success(t("share_modal.copied"));
      })
      .catch((error) => {
        notify.error(t("share_modal.copy_error"));
      });
  }

  const close = () => {
    setOpen(false);
    setUrl("");
    setTitle("");
    setIsLoading(true);
  };

  const [emailList, setEmailList] = useState<string[]>([]);

  const { mutate, isLoading: queryLoading } = useMutation(inviteGuestEmail);

  return (
    <Modal
      notScroll={noScroll}
      open={open}
      title={title}
      onClose={() => close()}
      backdrop
      draggable={false}
      maxWidthPercent={"70vw"}
    >
      <div className={clsx(classes.shareModal)}>
        <Loader isLoading={isLoading}>
          <div className={classes.shareModal__urlContainer}>
            <div
              className={clsx(classes.shareModal__input)}
              onDoubleClick={(event) => {
                copyToClipboard(window.origin + url);
              }}
            >
              {window.origin + url}
            </div>
            <Button
              onClick={() => copyToClipboard(window.origin + url)}
              className={classes.shareModal__copyBtn}
            >
              {t("copy")}
            </Button>
          </div>
          {isJoinGuest && (
            <div className={classes.shareModal__shareViaEmailContainer}>
              <ChipInput
                className={classes.shareModal__emailInput}
                type="search"
                showAddBtn
                values={emailList}
                onChange={setEmailList}
                btnTitle={t("share_modal.addBtnTitle")}
                placeholder="example@example.com"
              />
              <div className={classes.shareModal__emailInputActions}>
                <Button color={"error"} onClick={() => close()}>
                  {t("cancel")}
                </Button>
                <Button
                  isLoading={queryLoading}
                  onClick={async () => {
                    mutate(
                      { emails: emailList, id: apptId },
                      {
                        onSuccess: (data) => {
                          setUrl("/guest-join/" + data?.data?.data?.token);
                          setEmailList([]);
                          close();
                        },
                      }
                    );
                  }}
                >
                  {t("share_modal.share")}
                </Button>
              </div>
            </div>
          )}
        </Loader>
      </div>
    </Modal>
  );
};

export default ShareModal;

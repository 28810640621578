import { toast, ToastContentProps } from "react-toastify";
import classes from "./styles.module.scss";
import { CanceledLinear, SuccessLinear, WarningLinear } from "components/icon";
import { IResponse } from "api";

const success = (msg: string | undefined) => {
  return toast.success(msg, {
    icon: () => (
      <div className={classes.toast}>
        <SuccessLinear />
      </div>
    ),
  });
};

const error = (msg: string | undefined) => {
  return toast.error(msg, {
    icon: () => (
      <div className={classes.toast}>
        <CanceledLinear />
      </div>
    ),
  });
};

const warning = (msg: string | undefined) => {
  return toast.warning(msg, {
    icon: () => (
      <div className={classes.toast}>
        <WarningLinear />
      </div>
    ),
  });
};

const info = (msg: string | undefined) => {
  return toast.info(msg, {
    icon: false,
  });
};

const promise = (
  promise: Promise<IResponse<any> | any> | (() => Promise<IResponse<any>>),
  pending: string,
  message?: string
) => {
  return toast.promise(promise, {
    success: {
      render(data: ToastContentProps<IResponse<any>>) {
        if (message) {
          return message;
        }
        return data.data?.data.message;
      },
      icon: () => (
        <div className={classes.toast}>
          <SuccessLinear />
        </div>
      ),
      hideProgressBar: false,
      theme: "colored",
      style: { color: "white" },
    },
    pending: {
      render() {
        return pending;
      },
      style: {
        // background: "#328ab3",
        background: "rgba(var(--color-primary-main), 1)",
        color: "rgba(var(--color-light-text), 1)",
        fontWeight: "600",
        border: "1px solid #BCBCBC80",
      },
    },
  });
};
export const notify = { success, error, warning, info, promise };

import {
  AppointmentBold,
  AppointmentDuotone,
  AvailabilityBold,
  AvailabilityDoutone,
  CalendarEditLinear,
  ElementLinear,
  FinancialBold,
  FinancialDuotone,
  HelpBold,
  HelpDuotone,
  HospitalBold,
  MonitorDuotone,
  NoShowCausesDuotone,
  PacsDuotone,
  PrivacyBold,
  PrivacyDuotone,
  ReportBold,
  ReportDuotone,
  UserDoctorLinear,
  WarningBold,
} from "components/icon";
import { ISidebarItem } from "components/layout/sidebar";
import { SummaryDuotone } from "components/icon/summary";
import { SummaryBold } from "components/icon/summaryBold";
import { MonitorBold } from "components/icon/monitorBold";
import { HospitalDuotone } from "components/icon/hospitalDuotone";
import { UsersDuotone } from "components/icon/users";
import { AssignDuotone } from "components/icon/assign";
import { AssignBold } from "components/icon/assignBold";
import { UsersBold } from "components/icon/usersBold";
import { IntakeFormDuotone } from "components/icon/intakeForm";
import { IntakeFormBold } from "components/icon/intakeFormBold";
import { HealthRecordDuotone } from "components/icon/healthRecord";
import { HealthRecordBold } from "components/icon/healthRecordBold";
import { FamilyDuotone } from "components/icon/family";
import { FamilyBold } from "components/icon/familyBold";
import { PastMedicalHistoryDuotone } from "components/icon/history";
import { PastMedicalHistoryBold } from "components/icon/historyBold";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export const useSidebarItems = () => {
  const { t } = useTranslation(["sidebar", "global"]);

  const location = useLocation();
  const splitPathname = location.pathname.toLocaleLowerCase().split("/");

  function getPreferencesItems(panelName: string): ISidebarItem[] {
    return [
      {
        id: "20",
        name: t("sidebar.privacy", { ns: "global" }),
        icon: PrivacyDuotone,
        selectedIcon: PrivacyBold,
        path: `/panel/${panelName}/privacy-policy`,
        child: [],
      },
      {
        id: "30",
        name: t("sidebar.help", { ns: "global" }),
        icon: HelpDuotone,
        selectedIcon: HelpBold,
        path: `/panel/${panelName}/help-center`,
        child: [],
      },
    ];
  }

  const SuperAdminItems: ISidebarItem[] = [
    {
      id: "0",
      name: t("super.summary"),
      icon: SummaryDuotone,
      selectedIcon: SummaryBold,
      path: "/panel/super/dashboard",
      child: [],
    },
    {
      id: "1",
      name: t("super.hf_management"),
      icon: HospitalDuotone,
      selectedIcon: HospitalBold,
      path: "/panel/super/healthcare-facility",
      child: [],
    },
    {
      id: "2",
      name: t("super.robot_management"),
      icon: MonitorDuotone,
      selectedIcon: MonitorBold,
      child: [
        {
          id: "1",
          name: t("super.robot_registration"),
          path: "/panel/super/robot",
        },
        {
          id: "2",
          name: t("super.medical_device_registration"),
          path: "/panel/super/medical-device",
        },
        {
          id: "3",
          name: t("super.create_robot"),
          path: "/panel/super/create-robot",
        },
      ],
    },
    {
      id: "3",
      name: t("super.assigning_robot"),
      icon: AssignDuotone,
      selectedIcon: AssignBold,
      child: [
        {
          id: "1",
          name: t("super.assign_hf"),
          path: "/panel/super/assign-robot-to-healthcare-facility",
        },
        {
          id: "2",
          name: t("super.assign_doctor"),
          path: "/panel/super/assign-robot-to-doctor",
        },
      ],
    },
    {
      id: "4",
      name: t("super.user_management"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      child: [
        {
          id: "1",
          name: t("super.user_management"),
          path: "/panel/super/user-management",
        },
        {
          id: "2",
          name: t("super.local_management"),
          path: "/panel/super/local-management",
        },
        {
          id: "3",
          name: t("super.cmo_management"),
          path: "/panel/super/cmo-management",
        },
        {
          id: "4",
          name: t("super.doctor_management"),
          path: "/panel/super/doctor-management",
        },
        {
          id: "5",
          name: t("super.staff_management"),
          path: "/panel/super/staff-management",
        },
        {
          id: "6",
          name: t("super.patient_management"),
          path: "/panel/super/patient-management",
        },
      ],
    },
    {
      id: "5",
      name: t("super.financial_management"),
      icon: FinancialDuotone,
      selectedIcon: FinancialBold,
      child: [
        {
          id: "1",
          name: t("super.financial_rule"),
          path: "/panel/super/financial-rule",
        },
        {
          id: "2",
          name: t("super.payment_method"),
          path: "/panel/super/payment-method",
        },
        {
          id: "3",
          name: t("super.accounting_report"),
          path: "/panel/super/accounting-reports",
        },
        {
          id: "4",
          name: t("super.patient_wallet"),
          path: "/panel/super/patient-wallet",
        },
        {
          id: "5",
          name: t("super.create_promotion"),
          path: "/panel/super/create-promotion",
        },
      ],
    },
    {
      id: "6",
      name: t("super.appointment_management"),
      icon: AppointmentDuotone,
      selectedIcon: AppointmentBold,
      child: [
        {
          id: "1",
          name: t("super.appointment_management"),
          path: "/panel/super/appointment-management",
        },
        {
          id: "2",
          name: t("super.visit_summary"),
          path: "/panel/super/visit-summary",
        },
      ],
    },
    {
      id: "7",
      name: t("super.report_generation"),
      icon: ReportDuotone,
      selectedIcon: ReportBold,
      child: [
        {
          id: "0",
          name: t("super.user"),
          path: "/panel/super/user-report-generation",
        },
        {
          id: "1",
          name: t("super.robot_usage"),
          path: "/panel/super/robot-usage-report-generation",
        },
        {
          id: "2",
          name: t("super.appointments"),
          path: "/panel/super/appointments-report-generation",
        },
        {
          id: "3",
          name: t("super.financial"),
          path: "/panel/super/financial-report-generation",
        },
        {
          id: "4",
          name: t("super.clinical"),
          path: "/panel/super/clinical-report-generation",
        },
        {
          id: "5",
          name: t("super.device_usage"),
          path: "/panel/super/device-report-generation",
        },
      ],
    },
    {
      id: "8",
      name: t("super.pacs"),
      icon: PacsDuotone,
      selectedIcon: PacsDuotone,
      child: [
        {
          name: t("super.pacs_viewer"),
          path: "/panel/super/pacs/viewer",
          id: "1",
        },
        {
          name: t("super.upload_study"),
          path: "/panel/super/pacs/upload-study",
          id: "2",
        },
        {
          name: t("super.pacs_admin"),
          path: "/panel/super/pacs/pacs-admin",
          id: "3",
        },
      ],
    },
  ];

  const LocalAdminItems: ISidebarItem[] = [
    {
      id: "0",
      name: t("local.summary"),
      icon: SummaryDuotone,
      selectedIcon: SummaryBold,
      path: "/panel/local-admin/dashboard",
      child: [],
    },
    {
      id: "1",
      name: t("local.staff_management"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      child: [
        {
          id: "0",
          name: t("local.staff_management"),
          path: "/panel/local-admin/staff-management",
        },
        {
          id: "1",
          name: t("local.staff_approval"),
          path: "/panel/local-admin/staff-approval",
        },
      ],
    },
    {
      id: "2",
      name: t("local.patient_management"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      path: "/panel/local-admin/patient-management",
      child: [],
    },
    {
      id: "3",
      name: t("local.assigning"),
      icon: AssignDuotone,
      selectedIcon: AssignBold,
      child: [
        {
          id: "0",
          name: t("local.assign_staff_doctor"),
          path: "/panel/local-admin/assign-staff-to-doctor",
        },
        {
          id: "1",
          name: t("local.assign_robot_patient"),
          path: "/panel/local-admin/assign-robot-to-patient",
        },
      ],
    },
    {
      id: "4",
      name: t("local.financial_management"),
      icon: FinancialDuotone,
      selectedIcon: FinancialBold,
      child: [
        {
          id: "0",
          name: t("local.accounting_report"),
          path: "/panel/local-admin/accounting-report",
        },
        {
          id: "1",
          name: t("local.patient_wallet"),
          path: "/panel/local-admin/patient-wallet",
        },
      ],
    },
    {
      id: "5",
      name: t("local.appointment_management"),
      icon: AppointmentDuotone,
      selectedIcon: AppointmentBold,
      child: [
        {
          id: "0",
          name: t("local.appointment_list"),
          path: "/panel/local-admin/appointments",
        },
        {
          id: "1",
          name: t("local.pvs"),
          path: "/panel/local-admin/summary-notes",
        },
      ],
    },
    {
      id: "6",
      name: t("local.report_generation"),
      icon: ReportDuotone,
      selectedIcon: ReportBold,
      child: [
        {
          id: "0",
          name: t("local.user"),
          path: "/panel/local-admin/user-report-generation",
        },
        {
          id: "1",
          name: t("local.robot_usage"),
          path: "/panel/local-admin/robot-usage-report-generation",
        },
        {
          id: "2",
          name: t("local.appointments"),
          path: "/panel/local-admin/appointments-report-generation",
        },
        {
          id: "3",
          name: t("local.financial"),
          path: "/panel/local-admin/financial-report-generation",
        },
      ],
    },
  ];

  const DoctorItems: ISidebarItem[] = [
    {
      id: "0",
      name: t("doctor.summary"),
      icon: SummaryDuotone,
      selectedIcon: SummaryBold,
      path: "/panel/doctor/dashboard",
      child: [],
    },
    {
      id: "1",
      name: t("doctor.availability_time"),
      icon: AvailabilityDoutone,
      selectedIcon: AvailabilityBold,
      child: [
        {
          name: t("doctor.availability_time_list"),
          id: "1",
          path: "/panel/doctor/availability-time",
        },
        {
          name: t("doctor.assigned_robots"),
          id: "2",
          path: "/panel/doctor/assigned-robots",
        },
      ],
    },
    {
      id: "2",
      name: t("doctor.my_appointments"),
      icon: AppointmentDuotone,
      selectedIcon: AppointmentBold,
      child: [
        {
          id: "0",
          name: t("doctor.scheduled_appointments"),
          path: "/panel/doctor/appointments/scheduled",
        },
        {
          id: "1",
          name: t("doctor.on_demand_appointments"),
          path: "/panel/doctor/appointments/on-demand",
        },
      ],
    } /*
  {
    id: "12",
    name: "Intake Form Management",
    icon: IntakeFormDuotone,
    selectedIcon: IntakeFormBold,
    path: "/panel/doctor/intake-form-management",
    child: [],
  },*/,
    {
      id: "3",
      name: t("doctor.my_patients"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      child: [
        {
          id: "0",
          name: t("doctor.view_patient_list"),
          path: "/panel/doctor/patients/list",
        },
        {
          id: "1",
          name: t("doctor.patient_stored_result"),
          path: "/panel/doctor/patients/stored-results",
        },
        {
          id: "2",
          name: t("doctor.pvs"),
          path: "/panel/doctor/patients/past-visit-summary",
        },
        {
          id: "3",
          name: t("doctor.summary_management"),
          path: "/panel/doctor/patients/summary-note-management",
        },
      ],
    },
    {
      id: "4",
      name: t("doctor.my_staff"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      path: "/panel/doctor/staffs",
      child: [],
    },
    {
      id: "5",
      name: t("doctor.report_generation"),
      icon: ReportDuotone,
      selectedIcon: ReportBold,
      child: [
        {
          id: "2",
          name: t("doctor.appointments"),
          path: "/panel/doctor/appointments-report-generation",
        },
        {
          id: "3",
          name: t("doctor.financial"),
          path: "/panel/doctor/financial-report-generation",
        },
      ],
    },
    {
      id: "6",
      name: t("doctor.pacs"),
      icon: PacsDuotone,
      selectedIcon: PacsDuotone,
      child: [
        {
          name: t("doctor.pacs_viewer"),
          path: "/panel/doctor/pacs/viewer",
          id: "1",
        },
        {
          name: t("doctor.upload_study"),
          path: "/panel/doctor/pacs/upload-study",
          id: "2",
        },
        /*{
          name: t("sidebar.pacs_admin", ),
          id: "3",
          path: "/panel/doctor/pacs/admin",
        },*/
      ],
    },
    // {
    //   id: "7",
    //   name: t("doctor.drive_robot"),
    //   icon: MonitorDuotone,
    //   selectedIcon: MonitorBold,
    //   path: "/panel/doctor/robot-drive",
    //   child: [],
    // },
  ];

  const DNAItems: ISidebarItem[] = [
    {
      id: "0",
      name: "Dashboard",
      icon: ElementLinear,
      path: "/panel/",
      child: [],
    },
    {
      id: "1",
      name: "Doctor Registration",
      icon: UserDoctorLinear,
      child: [],
      path: "/panel/dna/doctor-management",
    },
    {
      id: "2",
      name: "Doctor Availability Time",
      icon: CalendarEditLinear,
      child: [],
      path: "/panel/dna/doctor-availability-time",
    },
  ];

  const StaffItems: ISidebarItem[] = [
    {
      id: "0",
      name: t("staff.summary"),
      icon: SummaryDuotone,
      selectedIcon: SummaryBold,
      path: "/panel/staff/dashboard",
      child: [],
    },
    {
      id: "123",
      name: t("appointment.make", { ns: "global" }),
      icon: AppointmentDuotone,
      selectedIcon: AppointmentBold,
      child: [
        {
          id: "1",
          name: t("staff.create_scheduled"),
          path: "/panel/staff/scheduled-appointment/create",
        },
        {
          id: "2",
          name: t("staff.create_on_demand"),
          path: "/panel/staff/on-demand-appointment/create",
        },
      ],
    },
    {
      id: "1",
      name: t("staff.patient_management"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      child: [
        {
          id: "0",
          name: t("staff.patient_registration"),
          path: "/panel/staff/patient-registration",
        },
        {
          id: "1",
          name: t("staff.pvs"),
          path: "/panel/staff/visit-summary",
        },
        {
          id: "2",
          name: t("staff.patient_followup"),
          path: "/panel/staff/patient-followup",
        },
      ],
    },
    {
      id: "2",
      name: t("staff.intake_management"),
      icon: IntakeFormDuotone,
      selectedIcon: IntakeFormBold,
      path: "/panel/staff/intake-form-management",
      child: [],
    },
    {
      id: "322",
      name: t("staff.all_appointments"),
      icon: AppointmentDuotone,
      selectedIcon: AppointmentBold,
      child: [
        {
          id: "12",
          name: t("staff.scheduled_appointments"),
          path: "/panel/staff/appointments/scheduled",
        },
        {
          id: "14",
          name: t("staff.on_demand_appointments"),
          path: "/panel/staff/appointments/on-demand",
        },
      ],
    },
    {
      id: "4",
      name: t("staff.no_show"),
      icon: NoShowCausesDuotone,
      selectedIcon: WarningBold,
      path: "/panel/staff/no-show-causes",
      child: [],
    },
    {
      id: "5",
      name: t("staff.financial_management"),
      icon: FinancialDuotone,
      selectedIcon: FinancialBold,
      path: "/panel/staff/patient-wallet",
      child: [],
    },
    {
      id: "6",
      name: t("staff.doctor_list"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      path: "/panel/staff/doctors",
      child: [],
    },
    {
      id: "7",
      name: t("staff.assign_robot_patient"),
      icon: AssignDuotone,
      selectedIcon: AssignBold,
      path: "/panel/staff/assign-robot-to-patient",
      child: [],
    },
    {
      id: "8",
      name: t("staff.report_generation"),
      icon: ReportDuotone,
      selectedIcon: ReportBold,
      child: [
        {
          id: "0",
          name: t("staff.user"),
          path: "/panel/staff/user-report-generation",
        },
        {
          id: "1",
          name: t("staff.robot_usage"),
          path: "/panel/staff/robot-usage-report-generation",
        },
        {
          id: "2",
          name: t("staff.appointments"),
          path: "/panel/staff/appointments-report-generation",
        },
        {
          id: "4",
          name: t("staff.followup_referral"),
          path: "/panel/staff/followup-report-generation",
        },
        {
          id: "3",
          name: t("staff.financial"),
          path: "/panel/staff/financial-report-generation",
        },
      ],
    },
    {
      id: "9",
      name: t("staff.pacs"),
      icon: PacsDuotone,
      selectedIcon: PacsDuotone,
      child: [
        {
          name: t("staff.pacs_viewer"),
          path: "/panel/staff/pacs/viewer",
          id: "1",
        },
        {
          name: t("staff.upload_study"),
          path: "/panel/staff/pacs/upload-study",
          id: "2",
        },
      ],
    },
  ];

  const PatientItems: ISidebarItem[] = [
    {
      id: "0",
      name: t("patient.summary"),
      icon: SummaryDuotone,
      selectedIcon: SummaryBold,
      path: "/panel/patient/dashboard",
      child: [],
    },
    {
      id: "1",
      name: t("patient.intake_management"),
      icon: IntakeFormDuotone,
      selectedIcon: IntakeFormBold,
      path: "/panel/patient/intake-form-management",
      child: [],
    },
    {
      id: "6",
      name: t("patient.past_medical"),
      icon: PastMedicalHistoryDuotone,
      selectedIcon: PastMedicalHistoryBold,
      path: "/panel/patient/past-medical-history",
      child: [],
    },
    {
      id: "2",
      name: t("patient.appointment_management"),
      icon: AppointmentDuotone,
      selectedIcon: AppointmentBold,
      child: [
        {
          id: "0",
          name: t("patient.scheduled_appointments"),
          path: "/panel/patient/appointments/scheduled",
        },
        {
          id: "1",
          name: t("patient.on_demand_appointments"),
          path: "/panel/patient/appointments/on-demand",
        },
      ],
    },
    {
      id: "3",
      name: t("patient.health_record"),
      icon: HealthRecordDuotone,
      selectedIcon: HealthRecordBold,
      child: [
        {
          id: "0",
          name: t("patient.visit_summary"),
          path: "/panel/patient/visit-summary",
        },
        {
          id: "1",
          name: t("patient.self_report"),
          path: "/panel/patient/new-self-report",
        },
        {
          id: "2",
          name: t("patient.stored_results"),
          path: "/panel/patient/view-stored-result",
        },
        {
          name: t("patient.forp"),
          id: "3",
          path: "/panel/patient/follow-up",
        },
      ],
    },
    {
      id: "4",
      name: t("patient.financial_management"),
      icon: FinancialDuotone,
      selectedIcon: FinancialBold,
      child: [
        {
          id: "0",
          name: t("patient.my_payments"),
          path: "/panel/patient/financial-records",
        },
        {
          id: "1",
          name: t("patient.my_wallet"),
          path: "/panel/patient/wallet-balance",
        },
      ],
    },
    {
      id: "5",
      name: t("patient.my_family_list"),
      icon: FamilyDuotone,
      selectedIcon: FamilyBold,
      path: "/panel/patient/family-members",
      child: [],
    },
    {
      id: "7",
      name: t("patient.reports"),
      icon: ReportDuotone,
      selectedIcon: ReportBold,
      path: "/panel/patient/appointments-report-generation",
      child: [],
    },
  ];

  const CMOItems: ISidebarItem[] = [
    {
      id: "0",
      name: t("cmo.summary"),
      icon: SummaryDuotone,
      selectedIcon: SummaryBold,
      path: "/panel/cmo/dashboard",
      child: [],
    },
    {
      id: "1",
      name: t("cmo.doctor_management"),
      icon: UsersDuotone,
      selectedIcon: UsersBold,
      child: [
        {
          id: "0",
          name: t("cmo.doctor_registration"),
          path: "/panel/cmo/doctor-registration",
        },
        {
          id: "1",
          name: t("cmo.doctor_approval"),
          path: "/panel/cmo/doctor-approval",
        },
      ],
    },
    {
      id: "2",
      name: t("cmo.doctor_availability"),
      icon: AvailabilityDoutone,
      selectedIcon: AvailabilityBold,
      path: "/panel/cmo/doctor-availability",
      child: [],
    },
    {
      id: "3",
      name: t("cmo.financial_management"),
      icon: FinancialDuotone,
      selectedIcon: FinancialBold,
      path: "/panel/cmo/financial/accounting-reports",
      child: [],
    },
    {
      id: "4",
      name: t("cmo.summary_notes"),
      icon: AppointmentDuotone,
      selectedIcon: AppointmentBold,
      path: "/panel/cmo/summary-notes",
      child: [],
    },
    {
      id: "5",
      name: t("cmo.report_generation"),
      icon: ReportDuotone,
      selectedIcon: ReportBold,
      child: [
        {
          id: "0",
          name: t("cmo.user"),
          path: "/panel/cmo/user-report-generation",
        },
        {
          id: "1",
          name: t("cmo.robot_usage"),
          path: "/panel/cmo/robot-usage-report-generation",
        },
        {
          id: "2",
          name: t("cmo.appointments"),
          path: "/panel/cmo/appointments-report-generation",
        },
      ],
    },
  ];

  const itemObject = {
    super: SuperAdminItems,
    "local-admin": LocalAdminItems,
    doctor: DoctorItems,
    staff: StaffItems,
    dna: DNAItems,
    patient: PatientItems,
    cmo: CMOItems,
  };

  type keyType = keyof typeof itemObject;

  const Items = itemObject[splitPathname[2] as keyType];

  const PrefrencesItems = getPreferencesItems(splitPathname[2]);

  return {
    PrefrencesItems,
    Items,
  };
};

import React, { useState } from "react";
import { showSummaryNote } from "../../../api/doctor";
import { jsonToHumanUrl } from "../../core/filter";
import { notify } from "../../core/toast";
import {
  showPatientFollowUp,
  showPatientOrders,
  showPatientPrescription,
  showPatientReferral,
} from "../../../api/healthRecord";
import { showAppointment } from "../../../api/appointment";
import ShareModal from "../shareModal";
import { linkShorter } from "../../../api/share";
import { useTranslation } from "react-i18next";

export interface IShareLinkGeneratorProps {
  type: "note" | "followup" | "order" | "prescription" | "referral";
  id: string | number;
  className?: string;
  appt_id?: string | number;
}

const ShareLinkGenerator: React.FC<IShareLinkGeneratorProps> = ({
  children,
  type,
  id,
  className,
  appt_id,
}) => {
  const { t } = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <div
        className={className}
        onClick={async () => {
          setOpen(true);
          notify.info(t("share_link.generate"));
          if (type === "note") {
            const data = await showSummaryNote(id);
            const d = encodeURIComponent(
              jsonToHumanUrl(JSON.stringify(data?.data?.data))
            );
            const shortLink = await linkShorter(`data=${d}`);
            setTitle(t("share_link.past_summary"));
            setUrl(`/past-visit-summary/${shortLink?.data?.data?.token}`);
            setIsLoading(false);
          }
          if (type === "followup") {
            const data = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showPatientFollowUp(Number(id)))?.data?.data
                )
              )
            );
            const appt = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showAppointment(Number(appt_id)))?.data?.data
                )
              )
            );
            const shortLink = await linkShorter(`data=${data}&appt=${appt}`);
            setTitle(t("share_link.followup"));
            setUrl(`/followup/${shortLink?.data?.data?.token}`);
            setIsLoading(false);
          }
          if (type === "order") {
            const data = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showPatientOrders(Number(id)))?.data?.data
                )
              )
            );
            const appt = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showAppointment(Number(appt_id)))?.data?.data
                )
              )
            );
            const shortLink = await linkShorter(`data=${data}&appt=${appt}`);
            setTitle(t("share_link.order"));
            setUrl(`/orders/${shortLink?.data?.data?.token}`);
            setIsLoading(false);
          }
          if (type === "prescription") {
            const data = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showPatientPrescription(Number(id)))?.data?.data
                )
              )
            );
            const appt = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showAppointment(Number(appt_id)))?.data?.data
                )
              )
            );
            const shortLink = await linkShorter(`data=${data}&appt=${appt}`);
            setTitle(t("share_link.prescription"));
            setUrl(`/prescription/${shortLink?.data?.data?.token}`);
            setIsLoading(false);
          }
          if (type === "referral") {
            const data = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showPatientReferral(Number(id)))?.data?.data
                )
              )
            );
            const appt = encodeURIComponent(
              jsonToHumanUrl(
                JSON.stringify(
                  (await showAppointment(Number(appt_id)))?.data?.data
                )
              )
            );
            const shortLink = await linkShorter(`data=${data}&appt=${appt}`);
            setTitle(t("share_link.referral"));
            setUrl(`/referral/${shortLink?.data?.data?.token}`);
            setIsLoading(false);
          }
        }}
      >
        <>{children}</>
      </div>
      <ShareModal
        title={title}
        url={url}
        open={open}
        setOpen={setOpen}
        isLoading={isLoading}
        setUrl={setUrl}
        setTitle={setTitle}
        setIsLoading={setIsLoading}
        apptId={appt_id || ""}
      />
    </>
  );
};

export default ShareLinkGenerator;

/**
 * get the file size in bytes in convert to KB, MB, GB
 * @param size file size
 */
export function humanizedFileSize(size: number) {
  if (size === 0) return "0 Bytes";
  const k = 1000,
    dm = 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export interface IReasonForVisit {
  reason: string;
  reason1: string;
  reason2: string;
  reason3: string;
  reason4: string;
  reason5: string;
  reason6: string;
  reason7: string;
  reason8: string;
}

export function convertReason(
  reason: string
): IReasonForVisit | string | number {
  try {
    return JSON.parse(reason);
  } catch (e) {
    return reason;
  }
}

export function getReason(
  reason?: string,
  q?: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8"
): string {
  if (!reason) return "- - -";
  const r = convertReason(reason);
  if (!q) {
    if (typeof r === "string" || typeof r === "number") {
      return r?.toString();
    }
    return r?.reason || "- - -";
  }

  if (typeof r === "string" || typeof r === "number") {
    return "Didn't answer";
  }

  if (q === "1") return r?.reason1;
  if (q === "2") return r?.reason2;
  if (q === "3") return r?.reason3;
  if (q === "4") return r?.reason4;
  if (q === "5") return r?.reason5;
  if (q === "6") return r?.reason6;
  if (q === "7") return r?.reason7;
  if (q === "8") return r?.reason8;
  return "- - -";
}

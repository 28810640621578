import SideBartTop from "../sideBartTop";
// import ImageCard, { IImageCardProps } from "./ImageCard";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { IImagesItemProps } from "./ImagesItem";
import ImageItems from "./imageItems";
import { IDoctorAppointment } from "../../../../../api/appointment";
import { humanizedDate } from "../../../../../utils/date";
import { useTranslation } from "react-i18next";

export interface IImagesProps {
  items: IImagesItemProps[];
  onClose: () => void;
  appointment?: IDoctorAppointment;
}

// Images List component for video visit
const Images = ({ items, onClose, appointment }: IImagesProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  // render compnent
  return (
    <div className={clsx(classes.images)}>
      <SideBartTop
        title={t("test_files")}
        text={
          appointment?.patient?.name +
          ` ,${t("dob")}: ` +
          humanizedDate(appointment?.patient?.birthday || "")
        }
        onClick={() => onClose()}
      />
      <ImageItems items={items} />
    </div>
  );
};

export default Images;

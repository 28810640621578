import generateKey, { QGET, QGET_ALL } from "./index";
import {
  E_APPOINTMENT,
  E_CITY,
  E_COUNTRY,
  E_DEPARTMENT,
  E_DEVICE,
  E_DOCTOR,
  E_FACILITY,
  E_FILE,
  E_FINANCIAL,
  E_INTAKE,
  E_MEDICAL_HISTORY,
  E_MESSAGE,
  E_NOTIFICATION,
  E_PATIENT,
  E_PAYMENT,
  E_PHONE,
  E_PROFILE,
  E_RESULT,
  E_ROBOT,
  E_STAFF,
  E_STATE,
  E_SUMMARY,
  E_SUPER,
  E_TIMEZONE,
  E_USER,
} from "../entities";
import {
  ACCOUNTING__TYPE,
  ASSIGNED_TYPE,
  BUILD_TYPE,
  CREATED_TYPE,
  DASHBOARD_TYPE,
  DROPDOWN_TYPE,
  STATISTICS_TYPE,
  LOCATION_TYPE,
  MEDICAL_TYPE,
  NOT_ASSIGNED_TYPE,
  PROMOTION_TYPE,
  REPORT_TYPE,
  SPECIALITY_TYPE,
  STATUS_TYPE,
  SUB_TYPE,
  TEMP_TYPE,
  TYPE,
  VISIT_TYPE,
  WALLET_TYPE,
  DETAIL_TYPE,
  DEVICE_TYPE,
} from "./keyTypes";

// dashboard related keys
//---------------------------------------
export const superDashboardKey = generateKey({
  name: E_SUPER,
  method: QGET,
  type: [STATISTICS_TYPE, DASHBOARD_TYPE],
});

// notification related keys
//---------------------------------------
export const notificationsKey = generateKey({
  name: E_NOTIFICATION,
  method: QGET_ALL,
});
export const getNotificationKey = generateKey({
  name: E_NOTIFICATION,
  method: QGET,
});

// message related keys
//---------------------------------------
export const messagesKey = generateKey({ name: E_MESSAGE, method: QGET_ALL });

// country related keys
//----------------------------------------
export const countriesKey = generateKey({
  name: E_COUNTRY,
  method: QGET,
  type: [DROPDOWN_TYPE],
});

// state related keys
//----------------------------------------
export const statesKey = generateKey({
  name: E_STATE,
  method: QGET,
  type: [DROPDOWN_TYPE],
});

// city related keys
//----------------------------------------
export const citiesKey = generateKey({
  name: E_CITY,
  method: QGET,
  type: [DROPDOWN_TYPE],
});

// robot related keys
//----------------------------------------
export const createdRobotStatisticsKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [CREATED_TYPE, STATISTICS_TYPE],
});

export const createdRobotsKey = generateKey({
  name: E_ROBOT,
  method: QGET_ALL,
  type: [CREATED_TYPE],
});

export const getBuildedRobotKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [BUILD_TYPE],
});

export const robotFacilityStatisticsKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [STATISTICS_TYPE, E_FACILITY],
});

export const robotsFacilityKey = generateKey({
  name: E_ROBOT,
  method: QGET_ALL,
  type: [E_FACILITY],
});

export const notAssignedRobotsFacilityKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [E_FACILITY, NOT_ASSIGNED_TYPE],
});

export const assignedRobotsFacilityKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [E_FACILITY, ASSIGNED_TYPE],
});

export const assignedRobotsDepartmentKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [E_DEPARTMENT, ASSIGNED_TYPE],
});

export const getRobotKey = generateKey({
  name: E_ROBOT,
  method: QGET,
});

export const robotDoctorStatisticsKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [STATISTICS_TYPE, E_DOCTOR],
});

export const robotsDoctorKey = generateKey({
  name: E_ROBOT,
  method: QGET_ALL,
  type: [E_DOCTOR],
});

export const assignedRobotsDoctorKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [E_DOCTOR, ASSIGNED_TYPE],
});

export const robotLocationsKey = generateKey({
  name: E_ROBOT,
  method: QGET,
  type: [E_DOCTOR, LOCATION_TYPE],
});

// device related keys
//----------------------------------------
export const deviceTypeDevicesKey = generateKey({
  name: E_DEVICE,
  method: QGET_ALL,
  type: [DEVICE_TYPE],
});

export const deviceTypeDropdownKey = generateKey({
  name: E_DEVICE,
  method: QGET_ALL,
  type: [DEVICE_TYPE, DROPDOWN_TYPE],
});

export const getDeviceKey = generateKey({
  name: E_DEVICE,
  method: QGET,
});

export const deviceStatisticsKey = generateKey({
  name: E_DEVICE,
  method: QGET,
  type: [STATISTICS_TYPE],
});

export const devicesKey = generateKey({
  name: E_DEVICE,
  method: QGET_ALL,
});

// profile related keys
//----------------------------------------
export const profileKey = generateKey({
  name: E_PROFILE,
  method: QGET,
  type: [DASHBOARD_TYPE],
});

// file related keys
//----------------------------------------
export const fileKey = generateKey({
  name: E_FILE,
  method: QGET,
});

// phone related keys
//----------------------------------------
export const countryPhoneKey = generateKey({
  name: E_PHONE,
  method: QGET,
  type: [DROPDOWN_TYPE, E_COUNTRY],
});

// phone related keys
//----------------------------------------
export const countryTimezoneKey = generateKey({
  name: E_TIMEZONE,
  method: QGET,
  type: [DROPDOWN_TYPE, E_COUNTRY],
});

// doctor related keys
//----------------------------------------
export const cityDoctorBySpecialityKey = generateKey({
  name: E_DOCTOR,
  method: QGET,
  type: [DROPDOWN_TYPE, SPECIALITY_TYPE, E_CITY],
});

export const doctorSpecialitiesKey = generateKey({
  name: E_DOCTOR,
  method: QGET,
  type: [DROPDOWN_TYPE, SPECIALITY_TYPE],
});

export const subSpecialitiesKey = generateKey({
  name: E_DOCTOR,
  method: QGET,
  type: [DROPDOWN_TYPE, SPECIALITY_TYPE, SUB_TYPE],
});

export const doctorTypesKey = generateKey({
  name: E_DOCTOR,
  method: QGET,
  type: [DROPDOWN_TYPE, TYPE],
});

export const doctorTempsKey = generateKey({
  name: E_DOCTOR,
  method: QGET_ALL,
  type: [TEMP_TYPE],
});

export const getDoctorTempKey = generateKey({
  name: E_DOCTOR,
  method: QGET,
  type: [TEMP_TYPE],
});

export const doctorPatientsKey = generateKey({
  name: E_DOCTOR,
  method: QGET_ALL,
  type: [E_PATIENT],
});

export const doctorStaffsKey = generateKey({
  name: E_DOCTOR,
  method: QGET_ALL,
  type: [E_STAFF],
});

// user related keys
//----------------------------------------
export const userStatisticByStatusKey = generateKey({
  name: E_USER,
  method: QGET,
  type: [STATISTICS_TYPE, STATUS_TYPE],
});

export const usersKey = generateKey({
  name: E_USER,
  method: QGET_ALL,
});

export const getUserKey = generateKey({
  name: E_USER,
  method: QGET,
});

// financial related keys
//----------------------------------------
export const financialRulesKey = generateKey({
  name: E_FINANCIAL,
  method: QGET_ALL,
});

export const accountingReportsKey = generateKey({
  name: E_FINANCIAL,
  method: QGET_ALL,
  type: [ACCOUNTING__TYPE, REPORT_TYPE],
});

export const hfReportsKey = generateKey({
  name: E_FINANCIAL,
  method: QGET_ALL,
  type: [E_FACILITY, REPORT_TYPE],
});

export const walletsKey = generateKey({
  name: E_FINANCIAL,
  method: QGET_ALL,
  type: [WALLET_TYPE],
});

export const getWalletKey = generateKey({
  name: E_FINANCIAL,
  method: QGET,
  type: [WALLET_TYPE],
});

export const promotionsKey = generateKey({
  name: E_FINANCIAL,
  method: QGET_ALL,
  type: [PROMOTION_TYPE],
});

export const promotionTypesKey = generateKey({
  name: E_FINANCIAL,
  method: QGET,
  type: [PROMOTION_TYPE, TYPE, DROPDOWN_TYPE],
});

export const getPromotionKey = generateKey({
  name: E_FINANCIAL,
  method: QGET,
  type: [PROMOTION_TYPE],
});

// appointment related keys
//----------------------------------------
export const visitTypeKey = generateKey({
  name: E_APPOINTMENT,
  method: QGET,
  type: [DROPDOWN_TYPE, VISIT_TYPE],
});

export const superAppointmentStatisticsKey = generateKey({
  name: E_APPOINTMENT,
  method: QGET,
  type: [STATISTICS_TYPE, E_SUPER],
});

export const superAppointmentsKey = generateKey({
  name: E_APPOINTMENT,
  method: QGET_ALL,
  type: [E_SUPER],
});

export const doctorAppointmentsKey = generateKey({
  name: E_APPOINTMENT,
  method: QGET_ALL,
  type: [E_DOCTOR],
});

export const doctorAppointmentStatisticsKey = generateKey({
  name: E_APPOINTMENT,
  method: QGET,
  type: [STATISTICS_TYPE, E_DOCTOR],
});

// summary note related keys
//----------------------------------------
export const superSummariesKey = generateKey({
  name: E_SUMMARY,
  method: QGET_ALL,
  type: [E_SUPER],
});
export const getSummaryKey = generateKey({
  name: E_SUMMARY,
  method: QGET,
});

// payment related keys
//----------------------------------------
export const paymentMethodsKey = generateKey({
  name: E_PAYMENT,
  method: QGET_ALL,
});

// patient related keys
//----------------------------------------
export const patientsByFacilityAndCityKey = generateKey({
  name: E_PATIENT,
  method: QGET,
  type: [DROPDOWN_TYPE, E_FACILITY, E_CITY],
});

// intake related keys
//----------------------------------------
export const intakeAndMedicalKey = generateKey({
  name: E_INTAKE,
  method: QGET,
  type: [MEDICAL_TYPE],
});

// medical related keys
//----------------------------------------
export const getMedicalHistoryKey = generateKey({
  name: E_MEDICAL_HISTORY,
  method: QGET,
});

// storing result related keys
//----------------------------------------
export const appointmentResultsKey = generateKey({
  name: E_RESULT,
  method: QGET_ALL,
  type: [E_APPOINTMENT],
});

export const storedResultDetailKey = generateKey({
  name: E_RESULT,
  method: QGET,
  type: [DETAIL_TYPE],
});

import DetailCard from "../detailCard";
import { CalendarBold, MaleBold, UserBulk } from "../../icon";
import clsx from "clsx";
import classes from "../../../pages/dashboard/doctor/writeSummaryNote/styles.module.scss";
import { humanizedDate } from "../../../utils/date";
import { useTranslation } from "react-i18next";

export interface IPatientDetailCardProps {
  name: string;
  id: string;
  gender: string;
  birth: string;
}
const PatientDetailCard = ({
  id,
  name,
  gender,
  birth,
}: IPatientDetailCardProps) => {
  const { t } = useTranslation("global");
  return (
    <DetailCard
      icon={<UserBulk />}
      title={t("patient_card.title", { ns: "global" })}
      subTitle={
        <>
          <span className={clsx(classes.summary__bold)}>
            {t("patient_card.name", { ns: "global" })}:{" "}
          </span>
          {name}
        </>
      }
      text={<span className={clsx(classes.summary__cp)}>{id}</span>}
      chips={[
        {
          icon: <MaleBold />,
          texts: [
            <>
              <span className={clsx(classes.summary__bold)}>
                {t("patient_card.gender", { ns: "global" })}:{" "}
              </span>
              {t(`genders.${gender}`, {
                ns: "global",
              })}
            </>,
          ],
          color: "#8EDCE6",
        },
        {
          icon: <CalendarBold />,
          texts: [
            <>
              <span className={clsx(classes.summary__bold)}>
                {t("patient_card.birth", { ns: "global" })}:{" "}
              </span>
              {humanizedDate(birth)}
            </>,
          ],
          color: "#377771",
        },
      ]}
    />
  );
};

export default PatientDetailCard;

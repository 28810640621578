import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IFilter } from "../../../../../components/core/filter";
import { useQuery } from "react-query";
import {
  getPatientPrescriptions,
  IPatientPrescription,
  showPatientPrescription,
} from "../../../../../api/healthRecord";
import { humanizedDate, humanizedTime } from "../../../../../utils/date";
import Table, { IFetchedData } from "../../../../../components/core/table";
import clsx from "clsx";
import classes from "../styles.module.scss";
import Button from "../../../../../components/core/button";
import HoverToolbox from "../../../../../components/common/hoverToolbox";
import { DownloadBold, PrinterBold } from "../../../../../components/icon";
import { ShareBold } from "../../../../../components/icon/share";
import DataCard from "../../../../../components/common/dataCard";
import Loader from "../../../../../components/common/loader";
import { ListInfo } from "../../../../../components/icon/listInfo";
import InformationCard from "../../../../../components/common/informationCard";
import Modal from "components/core/modal";
import CardContainer from "../../../../../components/common/cardContainer";
import BasicTable from "../../../../../components/core/basicTable";
import {
  useAllRelationshipFilterOption,
  useAppointmentTypesOption,
  useTimespanFilterOption,
} from "../../../../../utils/options";
import { Link } from "react-router-dom";
import { useBaseUrl } from "../../../../../utils/route";
import { getFilterParamsByValue, getTimespanParams } from "utils/filterParams";
import ShareLinkGenerator from "../../../../../components/common/shareLinkGenerator";
import useTableParams from "hooks/useTableParams";
import { useTranslation } from "react-i18next";

export const usePrescriptionColumns = () => {
  const { t } = useTranslation("table");

  return [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: t("columns.medicine"),
      accessor: "medicine_name",
    },
    {
      Header: t("columns.dosage"),
      accessor: "dosage",
    },
    {
      Header: t("columns.formulation"),
      accessor: "formulation",
    },
    {
      Header: t("columns.frequency"),
      accessor: "frequency",
    },
    {
      Header: t("columns.quantity"),
      accessor: "quantity",
    },
    {
      Header: t("columns.refills"),
      accessor: "refills",
    },
  ];
};

const INDEX = 3;
const Prescriptions: React.FC<{
  setCount: Dispatch<SetStateAction<number>>;
}> = ({ setCount }) => {
  const { t } = useTranslation("global");

  const PRESCRIPTION_COLUMNS = usePrescriptionColumns();

  const appointmentTypes = useAppointmentTypesOption();
  const relationships = useAllRelationshipFilterOption();
  const span = useTimespanFilterOption();

  const filterOptions: IFilter[] = [appointmentTypes, relationships, span];

  const { searchParam, filterParams, paginationParams } = useTableParams(INDEX);
  const { debouncedSearch, searchValue, setSearch } = searchParam;
  const { debouncedFilters, filtersValue, setFilters } = filterParams;
  const { pagination, ...restPagination } = paginationParams;

  const apptType_filter = getFilterParamsByValue(debouncedFilters, 0, "key");
  const relation_filter = getFilterParamsByValue(debouncedFilters, 1, "key");
  const from_filter = getTimespanParams(debouncedFilters, 2, "from");
  const to_filter = getTimespanParams(debouncedFilters, 2, "to");

  const { data, isFetching, isLoading, isError } = useQuery(
    [
      getPatientPrescriptions.name,
      debouncedFilters,
      pagination.resultsPerPage,
      pagination.currentPage,
      debouncedSearch,
    ],
    () =>
      getPatientPrescriptions({
        filter: debouncedSearch,
        appointment_types: apptType_filter,
        page: pagination.currentPage,
        per_page: pagination.resultsPerPage,
        from: from_filter,
        to: to_filter,
        relationship: relation_filter,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setCount(data?.data?.data?.total || 0);
  }, [data?.data?.data?.total, setCount]);

  useEffect(() => {
    let maxPage =
      Math.floor(
        (Number(data?.data.data?.total) - 1) / Number(data?.data.data?.pageSize)
      ) + 1;

    if (maxPage) {
      restPagination.updateMaxPage(maxPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let fixedData: IFetchedData = {
    data: !!data
      ? (data?.data?.data?.list as unknown as IPatientPrescription[])
      : [],
    ...pagination,
  };

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<IPatientPrescription>();

  const { data: prescription, isLoading: isPrescriptionLoading } = useQuery(
    [showPatientPrescription.name, modalData],
    () => showPatientPrescription(modalData?.id || 0),
    { enabled: !!modalData?.id }
  );

  const base = useBaseUrl();

  const RenderActions = ({ data }: { data: IPatientPrescription }) => {
    return (
      <div className={clsx(classes.healthRecord__actions)}>
        <Button
          onClick={() => {
            setModalData(data);
            setOpen(true);
          }}
        >
          {t("health_record.prescription.view")}
        </Button>
        <div className={clsx(classes.healthRecord__sep)}></div>
        <Link
          to={`${base}patient-prescription/${data?.appointment?.id}/${data?.id}/print`}
        >
          <HoverToolbox text={t("print")}>
            <div className={clsx(classes.healthRecord__print)}>
              <PrinterBold />
            </div>
          </HoverToolbox>
        </Link>
        <Link
          to={`${base}patient-prescription/${data?.appointment?.id}/${data?.id}/download`}
        >
          <HoverToolbox text={t("download")}>
            <div className={clsx(classes.healthRecord__print)}>
              <DownloadBold />
            </div>
          </HoverToolbox>
        </Link>
        <HoverToolbox text={t("share")}>
          <ShareLinkGenerator
            className={clsx(classes.healthRecord__print)}
            type={"prescription"}
            id={data?.id}
            appt_id={data?.appointment?.id}
          >
            <ShareBold />
          </ShareLinkGenerator>
        </HoverToolbox>
      </div>
    );
  };
  return (
    <Loader isLoading={isLoading} isError={isError}>
      <div>
        <Table
          index={INDEX}
          onRowSelect={(results) => console.log({ results })}
          onNextPage={restPagination.handleNextPage}
          onPreviousPage={restPagination.handlePreviousPage}
          onResultsPerPageChange={restPagination.handleResultsPerPageChange}
          resultsPerPage={pagination.resultsPerPage}
          onGoToPage={restPagination.handleGotoPage}
          fetchedData={fixedData}
          columns={[]}
          type="custom"
          isFetching={isFetching}
          title={t("health_record.prescription.table.title")}
          filterProps={{
            options: filterOptions,
            value: filtersValue,
            onFilterSelect: setFilters,
          }}
          searchProps={{
            placeholder: t(
              "health_record.prescription.table.search_placeholder"
            ),
            onChange: setSearch,
            value: searchValue,
          }}
        >
          <div className={clsx(classes.healthRecord__items)}>
            {data?.data.data?.list?.map((prescription, index) => {
              return (
                <DataCard
                  key={index}
                  variant={"patientPrescription"}
                  avatar={prescription.doctor.avatar}
                  cardActions={<RenderActions data={prescription} />}
                  tableData={{
                    appt_id: prescription?.appointment?.pid || "- - -",
                    appt_type: prescription?.appointment?.type || "- - -",
                    visit_date:
                      humanizedDate(prescription?.appointment?.date) || "- - -",
                    visit_time:
                      humanizedTime(prescription?.appointment?.date) || "- - -",
                    timezone: prescription?.appointment?.timezone || "- - -",
                    patient_name: prescription?.patient?.name || "- - -",
                    birth:
                      humanizedDate(prescription?.patient?.birthday) || "- - -",
                    hf_name: prescription?.hf_name || "- - -",
                  }}
                  id={prescription?.doctor?.pid}
                  name={prescription?.doctor?.name}
                  specialty={prescription?.doctor?.speciality}
                />
              );
            })}
          </div>
        </Table>
      </div>
      <Modal open={open} backdrop={true} onClose={() => setOpen(false)}>
        <div className={clsx(classes.modal)}>
          <DataCard
            variant={"patientPrescription"}
            avatar={modalData?.doctor?.avatar}
            tableData={{
              appt_id: modalData?.appointment?.id || "- - -",
              appt_type: modalData?.appointment?.type || "- - -",
              visit_date:
                humanizedDate(modalData?.appointment?.date || "") || "- - -",
              visit_time:
                humanizedTime(modalData?.appointment?.date || "") || "- - -",
              timezone: modalData?.appointment?.timezone || "- - -",
              patient_name: modalData?.patient?.name || "- - -",
              birth:
                humanizedDate(modalData?.patient?.birthday || "") || "- - -",
              hf_name: modalData?.hf_name || "- - -",
            }}
            id={modalData?.doctor?.pid || ""}
            name={modalData?.doctor?.name || ""}
            specialty={modalData?.doctor?.speciality || ""}
          />
          <Loader isLoading={isPrescriptionLoading}>
            <InformationCard
              icon={<ListInfo />}
              title={t("health_record.prescription.title")}
              parentClassName={clsx(classes.modal__back)}
            >
              <div className={clsx(classes.healthRecord__column, "mt-2")}>
                <div>
                  <CardContainer type={"basic"}>
                    <BasicTable
                      columns={PRESCRIPTION_COLUMNS}
                      data={prescription?.data?.data?.medicines?.map((p, i) => {
                        return { ...p, id: i + 1 };
                      })}
                    />
                  </CardContainer>
                </div>
                <div>
                  <span
                    className={clsx(
                      classes.modal__bigText,
                      classes.modal__bold
                    )}
                  >
                    {t("doctor")}:&nbsp;&nbsp;
                  </span>
                  <span
                    className={clsx(classes.modal__bigText)}
                  >{`${modalData?.doctor?.name} (${modalData?.doctor?.speciality})`}</span>
                </div>
                <div className={clsx(classes.modal__smallPrimary)}>
                  {humanizedDate(modalData?.appointment?.date || "") +
                    " " +
                    humanizedTime(modalData?.appointment?.date || "")}
                </div>
              </div>
            </InformationCard>
          </Loader>
        </div>
      </Modal>
    </Loader>
  );
};

export default Prescriptions;

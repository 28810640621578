import { HTMLAttributes, ReactNode } from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { FilterLinear } from "components/icon";
import useOutsideDetect from "hooks/useOutsideDetect";
import Select from "./select";
import { IDefaultProps, IMenuOption } from "../autoComplete";
import { useTranslation } from "react-i18next";

type omittedProps = "value" | "onChange" | "name";
type IDropdownProps = Omit<IDefaultProps, omittedProps>;

export interface IFilterOption {
  key: string;
  value: string;
  variant?: "basic" | "timespan" | "cost" | "dropdown";
  child?: ReactNode;
  dropdownProps?: IDropdownProps;
  dropdownLabel?: string;
}

export interface IFilter {
  title: string;
  filters: IFilterOption[];
}

export interface IFilterValue {
  title: string;
  filters: IFilterValueOption[];
}

export interface IFilterValueOption {
  key: string;
  value: string;
  to?: Date;
  from?: Date;
  costFrom?: string;
  costTo?: string;
  dropdown?: IMenuOption;
}

export interface IFilterDefaultProps extends HTMLAttributes<any> {
  options: IFilter[];
  value: IFilterValue[];
  color?: "transparent" | "white";
  className?: string;
  onFilterSelect?: (filters: IFilter[]) => void;
  /**
   * give a key or index for each input in a page. If you have more than a table in one page give each of them a key or index to prevent conflicting with each other.
   */
  index?: string | number;
  setToUrl?: boolean;
}

// filter button component
const Filter = ({
  options,
  value,
  color,
  className,
  onFilterSelect,
  index,
  ...rest
}: IFilterDefaultProps) => {
  const { t } = useTranslation("global");

  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideDetect(false);
  // function for handling opening and closing toggle menu
  const handleToggleMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };
  // function for closing toggle menu
  // const handleClose = () => {
  //   setIsComponentVisible(false);
  // };
  // function for clearing filter
  const handleClearFilter = () => {
    if (!!onFilterSelect) {
      let cloneValues = [...value];

      cloneValues.forEach((x) => {
        x.filters.length = 0;
      });
      onFilterSelect(cloneValues);
    }
  };
  // render filter options
  const getFilterOptions = () => {
    const filterSelectHandle = (f: IFilter[]) => {
      onFilterSelect?.(f);
    };
    return (
      <>
        {options.map((x, number) => {
          return (
            <Select
              key={`${x.title}-${number}`}
              content={x}
              value={
                value.length !== 0
                  ? value
                  : options.map((item) => {
                      return { title: item.title, filters: [] };
                    })
              }
              onFilterSelect={filterSelectHandle}
              {...{ number, options }}
            />
          );
        })}
      </>
    );
  };
  // render component
  return (
    <div
      data-testid="test-filter"
      ref={ref}
      className={clsx([classes.container, className])}
      {...rest}
    >
      <div
        data-testid="test-button"
        data-color={color}
        className={classes.filterBtn}
        onClick={handleToggleMenu}
      >
        <FilterLinear className={classes.icon} />
        {t("filter")}
      </div>

      <div
        data-testid="test-filter-menu"
        className={classes.menuContainer}
        data-open={isComponentVisible}
      >
        <div className={clsx(classes.inside)}>
          <div className={clsx([classes.menuTitle])}>
            <span className="title">{t("filters")}</span>

            <div className={classes.tools}>
              <span onClick={handleClearFilter}>{t("clear_all")}</span>
              {/* <span className="mx-1">-</span> */}
              {/* <span onClick={handleClose}>save view</span> */}
            </div>
          </div>
          {/* <div className={classes.divider} /> */}
          <div className={classes.optionsContainer}>{getFilterOptions()}</div>
        </div>
      </div>
    </div>
  );
};

Filter.defaultProps = {
  color: "transparent",
  onFilterSelect: () => {},
  variant: "basic",
};

export const jsonToHumanUrl = (json: string) => {
  return json;
};

export default Filter;

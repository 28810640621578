import clsx from "clsx";
import IconButton from "components/core/iconButton";
import { AddSquareLinear, EditLinear, TrashLinear } from "components/icon";
import { PropsWithChildren, ReactNode } from "react";
import ItemNumberLabel from "./itemNumberLabel";
import classes from "./styles.module.scss";
import { BeatLoader } from "react-spinners";

function addColon(text: string) {
  if (text.includes(":")) {
    return text;
  }
  return text + ":";
}

/**
 * Interface for a column of ListItem shown in "label: value" format
 */
interface IListItemColumn {
  label: string;
  value: string;
}

/**
 * Interface for contents (columns) shown in the ListItem
 */
export interface IListItemContent {
  /** Title of item (first column) */
  title?: string | ReactNode;
  /** The detail of the title shown below it */
  titleDetail?: string;
  /** Contents as columns after title  */
  columns: IListItemColumn[];
}

/**
 * ListItem component properties
 */
interface IListItemProps extends PropsWithChildren<any> {
  /** Row number of item  */
  numberLabel?: number;
  /** Data to be shown in item  */
  data: IListItemContent;
  /** Is item deletable? */
  deletable?: boolean;
  /** Function to be called on deletion of item  */
  onDeleteItem?: () => void;
  /** Icon if there was no number indexing */
  icon?: ReactNode;
  noAlign?: boolean;
  noScroll?: boolean;
  update?: boolean;
  onUpdate?: () => void;
  add?: boolean;
  onAdd?: () => void;
  /** Data loading state */
  isLoading?: boolean;
  /** className of card container div */
  className?: string;
  /** ClassName of root container */
  rootClassName?: string;
  children?: any;
}

function ListItem({
  numberLabel,
  data,
  deletable = false,
  onDeleteItem,
  icon,
  update,
  onUpdate,
  add,
  onAdd,
  isLoading,
  className,
  rootClassName,
  children,
}: IListItemProps) {
  return (
    <div className={clsx([classes.container, rootClassName])}>
      <div className={clsx([classes.card, className])}>
        <div className={classes.head}>
          {icon ? (
            <span className="PE-3">{icon}</span>
          ) : (
            <ItemNumberLabel numberLabel={numberLabel || 0} />
          )}
          {data.title && (
            <div className={classes.titleContainer}>
              <div className={clsx(classes.cardTitle)}>{data.title}</div>
              {data.titleDetail && (
                <div className={classes.titleDetail}>{data.titleDetail}</div>
              )}
            </div>
          )}
        </div>
        <div className={classes.content}>
          {!!isLoading ? (
            <span className={classes.loadingSpan}>
              <BeatLoader color="#29A9E182" size={10} margin={2} />
            </span>
          ) : (
            <>
              {data.columns.map((column, idx) => (
                <div key={idx}>
                  <span className={classes.columnLabel}>
                    {addColon(column.label)}
                  </span>
                  <span className={classes.columnValue}> {column.value} </span>
                </div>
              ))}
            </>
          )}
        </div>
        {!isLoading && (
          <>
            <div className={classes.action}>
              {add && (
                <div className="col-auto d-flex justify-content-end ">
                  <IconButton
                    className={classes.addIcon}
                    onClick={onAdd}
                    variant="text"
                    icon={AddSquareLinear}
                  />
                </div>
              )}
              {update && (
                <div className="col-auto d-flex justify-content-end ">
                  <IconButton
                    className={classes.updateIcon}
                    onClick={onUpdate}
                    variant="text"
                    icon={EditLinear}
                  />
                </div>
              )}
              {deletable && (
                <div className="col-auto d-flex justify-content-end ">
                  <IconButton
                    className={classes.deleteIcon}
                    onClick={onDeleteItem}
                    variant="text"
                    icon={TrashLinear}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {children}
    </div>
  );
}

export default ListItem;

import { getDoctorsSpecialities } from "api/dropdown";
import { useDropdownsOptions } from "../useOptions";
import { useQuery } from "react-query";
import { doctorSpecialitiesKey } from "../../utils/keyGenerator/keys";

export function useDoctorsSpecialitiesOptions() {
  const { data, ...rest } = useQuery(
    doctorSpecialitiesKey,
    getDoctorsSpecialities
  );

  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
}

import { LanguageBold, MaleBold, SpecialityBold, UserBulk } from "../../icon";
import clsx from "clsx";
import classes from "../../../pages/dashboard/doctor/writeSummaryNote/styles.module.scss";
import DetailCard from "../detailCard";
import { useTranslation } from "react-i18next";
import React from "react";

export interface IDoctorDetailCardProps {
  name: string;
  id: string;
  gender: string;
  speciality: string;
  international?: boolean;
  language?: string;
  custom_title?: string;
  gray_back?: boolean;
}

const DoctorDetailCard = ({
  id,
  name,
  speciality,
  gender,
  international,
  language,
  custom_title,
  gray_back,
}: IDoctorDetailCardProps) => {
  const { t } = useTranslation("global");
  if (!name || name === "-" || !gender || !speciality) {
    return <></>;
  }

  const chips = language
    ? [
        {
          icon: <MaleBold />,
          texts: [
            <>
              <span className={clsx(classes.summary__bold)}>
                {t("appointment_card.gender", { ns: "global" })}:{" "}
              </span>
              {t(`genders.${gender}`, {
                ns: "global",
              })}
            </>,
          ],
          color: "#8EDCE6",
        },
        {
          icon: <SpecialityBold />,
          texts: [
            <>
              <span className={clsx(classes.summary__bold)}>
                {t("appointment_card.speciality", { ns: "global" })}:{" "}
              </span>
              {speciality}
            </>,
          ],
          color: "#4CE0B3",
        },
        {
          icon: <LanguageBold />,
          color: "#607196",
          texts: [
            <>
              <span className={clsx(classes.modal__bold)}>
                {" "}
                {t("appointment_card.language", { ns: "global" })}:{" "}
              </span>
              <span>{language || "- - -"}</span>
            </>,
          ],
        },
      ]
    : [
        {
          icon: <MaleBold />,
          texts: [
            <>
              <span className={clsx(classes.summary__bold)}>
                {t("appointment_card.gender", { ns: "global" })}:{" "}
              </span>
              {t(`genders.${gender}`, {
                ns: "global",
              })}
            </>,
          ],
          color: "#8EDCE6",
        },
        {
          icon: <SpecialityBold />,
          texts: [
            <>
              <span className={clsx(classes.summary__bold)}>
                {t("appointment_card.speciality", { ns: "global" })}:{" "}
              </span>
              {speciality}
            </>,
          ],
          color: "#4CE0B3",
        },
      ];

  return (
    <DetailCard
      icon={<UserBulk />}
      title={
        custom_title ||
        t(
          `appointment_card.${
            international ? "inter_doctor" : "local_doctor"
          }.title`,
          { ns: "global" }
        )
      }
      parentClassName={clsx(gray_back && classes.summary__grayBack)}
      subTitle={
        <>
          <span className={clsx(classes.summary__bold)}>
            {t("appointment_card.doctor_name", { ns: "global" })}:{" "}
          </span>
          {name}
        </>
      }
      text={<span className={clsx(classes.summary__cp)}>{id}</span>}
      chips={chips}
    />
  );
};

export default DoctorDetailCard;

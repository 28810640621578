import clsx from "clsx";
import Button from "components/core/button";
import FormInput from "components/form/formInput";
import { DirectboxReceiveBold } from "components/icon";
import { FormikErrors, useFormik } from "formik";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { getFormikFieldProps } from "utils/form";
import LoginLayout from "./loginLayout";
import classes from "./styles.module.scss";
import { PasswordRecoveryContext } from "../passwordRecovery";
import { useMutation } from "react-query";
import { submitForgetPassword } from "api/auth";
import { notify } from "components/core/toast";
import BeatLoader from "react-spinners/BeatLoader";
import yup from "utils/yupExtended";
import { useTranslation } from "react-i18next";

interface IOnFieldForm {
  email: string;
}

export default function ForgetPasswordPage() {
  const { t } = useTranslation(["global", "form"]);
  const { setStep, email, setEmail } = useContext(PasswordRecoveryContext);
  // eslint-disable-next-line
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const mobileFormat = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  const INITIAL_ONE_FIELD_FORM: IOnFieldForm = {
    email: email,
  };

  let forgetPasswordMutation = useMutation(submitForgetPassword, {
    onSuccess: (_res, values) => {
      setEmail(values.email);
      notify.success(t("verification_sent", { ns: "form" }));
      setStep(2);
    },
  });

  const formik = useFormik<IOnFieldForm>({
    initialValues: INITIAL_ONE_FIELD_FORM,
    validateOnChange: false,
    onSubmit: (values, actions) => {
      // console.log({ values, actions });
      forgetPasswordMutation.mutate(values);
    },
    validate: (values) => {
      const errors: FormikErrors<IOnFieldForm> = {};

      if (!values.email) {
        errors.email = t("enter_email", { ns: "form" });
      } else {
        if (!isNaN(Number(values.email))) {
          //check if the format of phone number is correct
          if (!values.email.match(mobileFormat)) {
            errors.email = t("mobile_not_valid", { ns: "form" });
          }
        } else {
          if (!yup.string().email().isValidSync(values.email)) {
            errors.email = t("email_not_valid", { ns: "form" });
          }
        }
      }
      return errors;
    },
  });
  return (
    <LoginLayout
      form_title={t("forget_password.title")}
      form_description={t("forget_password.description1")}
      step={1}
    >
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormInput
          rootProps={{
            icon: DirectboxReceiveBold,
            placeholder: t("email", { ns: "form" }),
          }}
          className="p-0"
          {...getFormikFieldProps("email", t("email", { ns: "form" }), formik)}
        />
        <Button
          className="mt-4 w-100"
          type="submit"
          disabled={forgetPasswordMutation.isLoading}
        >
          {forgetPasswordMutation.isLoading ? (
            <div className="d-flex mt-2">
              <BeatLoader color="#fff" size={10} margin={2} />
            </div>
          ) : (
            t("send", { ns: "form" })
          )}
        </Button>
        <div className="d-flex mt-3">
          <span>
            <span className={clsx(["ME-1", classes.text])}>
              {t("forget_password.back")}
            </span>
            <Link className={classes.link} to={"/login"}>
              {t("click_here")}
            </Link>
          </span>
        </div>
      </form>
    </LoginLayout>
  );
}

import { submitRegister } from "api/auth";
import NotificationAlert from "components/core/notificationAlert";
import { notify } from "components/core/toast";
import { useFormik } from "formik";
import LoginLayout from "pages/login/components/loginLayout";
import { useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { convertBirthday } from "utils/date";
import { encodePhone } from "utils/form";
import yup, {
  emailObj,
  parseDateString,
  phoneObj,
  requiredObj,
} from "utils/yupExtended";
import { StepNumber } from "../../context";
import RegistrationFirstPage from "./firstPage";
import RegistrationFourthPage from "./fourthPage";
import RegistrationSecondPage from "./secondPage";
import RegistrationThirdPage from "./thirdPage";
import { IRegisterForm, REGISTER_INITIAL_VALUES } from "../../IForm";
import Button from "components/core/button";
import { getStepNumByFieldName } from "../../stepFieldNames";
import { useTranslation } from "react-i18next";

const RegistraitonPage = () => {
  const { t } = useTranslation("form");
  const getStepSchema = (step: StepNumber) => {
    switch (step) {
      case 1:
        return yup.object().shape({
          firstName: yup.string().required(requiredObj("first_name")),
          lastName: yup.string().required(requiredObj("last_name")),
          gender: yup
            .object()
            .dropdown({ errorMessage: requiredObj("gender") })
            .nullable(),
          birthday: yup
            .date()
            .minAge({
              min: 18,
              errorMessage: requiredObj("birthday"),
            })
            .transform(parseDateString),
          nationalId: yup.string().optional(),
          mrnNumber: yup.string().optional(),
          mobile: yup.object().phoneNumber({ errorMessage: phoneObj() }),
          alternativePhoneNumber: yup
            .object()
            .phoneNumber({ errorMessage: phoneObj() }),
          emergencyPhoneNumber: yup
            .object()
            .phoneNumber({ errorMessage: phoneObj() }),
          email: yup.string().email(emailObj()).required(requiredObj("email")),
        });
      case 2:
        return yup.object().shape({
          hfCountry: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_country") }),
          hfState: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_state") }),
          hfCity: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_city") }),
          hfType: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_type") }),
          hfName: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_name") }),
        });
      case 3:
        return yup.object().shape({
          languages: yup
            .array()
            .of(
              yup.object().shape({
                language: yup.string(),
                language_fluency: yup.string(),
              })
            )
            .required(requiredObj("language"))
            .min(1),
        });
      case 4:
        return yup.object().shape({
          country: yup
            .object()
            .dropdown({ errorMessage: requiredObj("country") }),
          state: yup.object().dropdown({ errorMessage: requiredObj("state") }),
          city: yup.object().dropdown({ errorMessage: requiredObj("city") }),
          timezone: yup
            .object()
            .dropdown({ errorMessage: requiredObj("timezone") }),
          zipCode: yup.string().required(requiredObj("zipcode")),
          address: yup.string().required(requiredObj("address")),
        });
    }
  };

  const history = useHistory();
  const { mutate, isLoading } = useMutation(submitRegister);
  const [step, setStep] = useState<StepNumber>(1);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const formik = useFormik<IRegisterForm>({
    initialValues: REGISTER_INITIAL_VALUES,
    validationSchema: getStepSchema(step),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      // setData(values);
      console.log("number");

      console.log(encodePhone(values.emergencyPhoneNumber));

      if (step !== 4) {
        setStep((step + 1) as StepNumber);
      } else {
        mutate(
          {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            mobile: encodePhone(values.mobile),
            birthday: convertBirthday(values.birthday), // with format of yyyy-mm-dd
            gender: values.gender.key as "male" | "female" | "others",
            city_id: values.city.key, // select from list
            timezone: values.timezone.key,
            role_name: "patient",
            facility_id: values.hfName.key,
            languages: values.languages,
            address: values.address,
            user_name: `test-${Math.random() * 10000}`,
            phone: encodePhone(values.alternativePhoneNumber),
            emergency_phone_number: encodePhone(values.emergencyPhoneNumber),
            national_id: values.nationalId,
            mrn_number: values.mrnNumber,
            zip_code: values.zipCode,
          },
          {
            onSuccess: ({ data }) => {
              notify.success(data?.message);
              history.replace("/login");
            },
            onError: (err: any) => {
              const errStepNum = getStepNumByFieldName(
                Object.keys(err.response.data.errors),
                "patient"
              );
              errStepNum > 0 && setStep(errStepNum as StepNumber);
            },
          }
        );
      }
    },
  });

  const BackButton = (
    <Button
      className="ME-auto"
      color="secondary"
      variant="outlined"
      onClick={() => setStep((prevStep) => (prevStep - 1) as StepNumber)}
    >
      {t("back")}
    </Button>
  );

  return (
    <LoginLayout
      form_title={t("patient_registration")}
      form_description={
        step === 2 ? t("complete_hf_info") : t("complete_personal_info")
      }
      stepsCount={4}
      step={step}
      onSetStep={setStep}
    >
      <div>
        {step === 1 && (
          <RegistrationFirstPage onSetAlert={setIsAlertOpen} formik={formik} />
        )}
        {step === 2 && (
          <RegistrationSecondPage
            onSetAlert={setIsAlertOpen}
            formik={formik}
            backButton={BackButton}
          />
        )}
        {step === 3 && (
          <RegistrationThirdPage
            onSetAlert={setIsAlertOpen}
            formik={formik}
            backButton={BackButton}
          />
        )}
        {step === 4 && (
          <RegistrationFourthPage
            onSetAlert={setIsAlertOpen}
            formik={formik}
            isMutationLoading={isLoading}
            backButton={BackButton}
          />
        )}
      </div>
      <NotificationAlert
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onApprove={() => {
          history.push("/login");
        }}
        title={t("register_cancel")}
        contnet={t("register_cancel_text")}
      />
    </LoginLayout>
  );
};

export default RegistraitonPage;

// import { ReactComponent as BackIcon } from "assets/icons/bold/arrow-circle-left.svg";
import { useHistory } from "react-router";
import classes from "../styles.module.scss";
import { EditBold } from "components/icon";
import { useTranslation } from "react-i18next";
import HoverToolbox from "../../../common/hoverToolbox";

interface IBackGroupProps {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editPath?: string;
  editPathState?: any;
}

function EditGroup({ editPath, setIsEditing, editPathState }: IBackGroupProps) {
  const { t } = useTranslation("global");
  const history = useHistory();

  return (
    <HoverToolbox text={t("dropdown.edit")}>
      <button
        className={classes.mainIconContainer}
        onClick={() => {
          if (editPath) history.replace(editPath, editPathState);
          setIsEditing(true);
        }}
      >
        <EditBold className={classes.mainIcon} />
      </button>
    </HoverToolbox>
  );
}

export default EditGroup;

import { getRobotTypes } from "api/dropdown";
import { useDropdownsOptions } from "./useOptions";
import { useQuery } from "react-query";
import { robotTypesKey } from "features/registeredRobots";
//TODO: remove this hook
// hook that fetch and save robot types
export function useRobotTypesOptions(noHome?: boolean) {
  const { data, ...rest } = useQuery(robotTypesKey, getRobotTypes);

  const OPTIONS = useDropdownsOptions({ data: data });
  return {
    OPTIONS: noHome ? OPTIONS?.filter((i) => i.key !== "home-robot") : OPTIONS,
    ...rest,
  };
}

import IMultiSelect from "components/formikFields/IMultiSelect";
import {FastMultiSelect} from "components/formikFields";
import {DepartmentBold} from "components/icon";
import {useAllDepartmentDropdown} from "features/healthcareFacility";
import {useTranslation} from "react-i18next";

interface IProps<T> extends IMultiSelect<T> {}

export function AllDepartments<T>(props: IProps<T>) {
  const { t } = useTranslation("form");

  const { data, isError, isLoading } = useAllDepartmentDropdown();

  return (
    <FastMultiSelect
      label={`${t("departments")} (${t("optional")})`}
      data={data}
      isError={isError}
      isLoading={isLoading}
      icon={DepartmentBold}
      {...props}
    />
  );
}

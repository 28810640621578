import SideBartTop from "../sideBartTop";
import VitalItem, { IPastData } from "./vitalItem";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { IDoctorAppointment } from "../../../../../api/appointment";
import { useHistory } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { humanizedDate } from "../../../../../utils/date";
import { useTranslation } from "react-i18next";
import { useBaseUrl } from "../../../../../utils/route";

export interface IVitalData {
  data: IPastData[];
}

export interface IVitalSign {
  bloodPressureData: IVitalData;
  thermometerData: IVitalData;
  glucometerData: IVitalData;
  weightScaleData: IVitalData;
  pulseOximeterData: IVitalData;
  onClose: () => void;
  isLoading: boolean;
  appointment?: IDoctorAppointment;
  setMinimized: Dispatch<SetStateAction<boolean>>;
}

// vital sign component for video visit
const VitalSignData = ({
  bloodPressureData,
  thermometerData,
  glucometerData,
  weightScaleData,
  onClose,
  isLoading,
  appointment,
  pulseOximeterData,
  setMinimized,
}: IVitalSign) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  const history = useHistory();
  const base = useBaseUrl();

  // render component
  return (
    <div className={clsx(classes.vitalSign)}>
      <SideBartTop
        title={t("vital_sign")}
        text={
          appointment?.patient?.name +
          ` ,${t("dob")}: ` +
          humanizedDate(appointment?.patient?.birthday || "")
        }
        onClick={() => onClose()}
      />
      <div className={clsx(classes.vitalSign__place)}>
        <VitalItem
          isLoading={isLoading}
          title={t("blood_pressure")}
          data={bloodPressureData.data}
          onViewMoreClick={() => {
            setMinimized(true);
            history.push({
              pathname: `${base}stored-results/${appointment?.patient.id}/${appointment?.visit_for}/${appointment?.id}`,
            });
          }}
        />
        <VitalItem
          isLoading={isLoading}
          title={t("thermometer")}
          data={thermometerData.data}
          onViewMoreClick={() => {
            setMinimized(true);
            history.push({
              pathname: `${base}stored-results/${appointment?.patient.id}/${appointment?.visit_for}/${appointment?.id}`,
            });
          }}
        />
        <VitalItem
          isLoading={isLoading}
          title={t("glucometer")}
          data={glucometerData.data}
          onViewMoreClick={() => {
            setMinimized(true);
            history.push({
              pathname: `${base}stored-results/${appointment?.patient.id}/${appointment?.visit_for}/${appointment?.id}`,
            });
          }}
        />
        <VitalItem
          isLoading={isLoading}
          title={t("weight_scale")}
          data={weightScaleData.data}
          onViewMoreClick={() => {
            setMinimized(true);
            history.push({
              pathname: `${base}stored-results/${appointment?.patient.id}/${appointment?.visit_for}/${appointment?.id}`,
            });
          }}
        />
        <VitalItem
          isLoading={isLoading}
          title={t("pulse_oximeter")}
          data={pulseOximeterData.data}
          onViewMoreClick={() => {
            setMinimized(true);
            history.push({
              pathname: `${base}stored-results/${appointment?.patient.id}/${appointment?.visit_for}/${appointment?.id}`,
            });
          }}
        />
      </div>
    </div>
  );
};

export default VitalSignData;

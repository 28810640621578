import React, { useState, memo, useRef, useEffect } from "react";
import NotificationAlert from "../notificationAlert";
import { IAlertify, alertDefaultProps } from "./types";
import emitter, { notificationAlertEmitterKey } from "./emitter";

export const NotificationAlertContainer = memo(() => {
  // const [open, setOpen] = useState(false);
  const open = useRef(false);
  const [alertProps, setAlertProps] = useState<IAlertify>(alertDefaultProps);

  const closeAlert = () => {
    open.current = false;
  };
  const openAlert = () => {
    open.current = true;
  };

  const resetAlertProps = React.useCallback(() => {
    setAlertProps(alertDefaultProps);
    closeAlert();
  }, []);

  useEffect(() => {
    emitter.on(notificationAlertEmitterKey, (alertProps: IAlertify) => {
      setAlertProps({
        ...alertProps,
        onApprove() {
          if (alertProps.onApprove) alertProps.onApprove();
          resetAlertProps();
        },
        onClose() {
          if (alertProps.onClose) alertProps.onClose();
          resetAlertProps();
        },
        onCancel() {
          if (alertProps.onCancel) alertProps.onCancel();
          resetAlertProps();
        },
      });
      openAlert();
    });

    return () => {
      emitter.off(notificationAlertEmitterKey, () => {
        console.log("EMITTER HAS REMOVED.");
      });
    };
  }, [resetAlertProps]);

  return <NotificationAlert {...alertProps} open={open.current} />;
});

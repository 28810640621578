import { useQuery } from "react-query";
import { getStates } from "api/dropdown";
import { GPSBold } from "components/icon";
import { getDropdownValue } from "utils/dropdowns";
import { FastDropdown } from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import { useTranslation } from "react-i18next";
import { statesKey } from "../../utils/keyGenerator/keys";

interface IStateProps<T> extends IDropdown<T> {
  /**
   * The field name of the country that this state is dependent on it.
   *
   * without knowing the country we can not fetch the states of that country, so the state depends on the country.
   */
  countryFieldName: keyof T & string;
}

export function State<T>({
  countryFieldName,
  formik,
  disabled,
  ...props
}: IStateProps<T>) {
  const { t } = useTranslation("form");

  // get the country value from formik values and extract its key
  const { key: countryId } = getDropdownValue(formik, countryFieldName);

  // fetch data
  const { data, isLoading, isError } = useQuery(
    [statesKey, countryId],
    () => getStates(Number(countryId)),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!countryId,
    }
  );

  return (
    <FastDropdown
      label={t("state")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!countryId || disabled}
      icon={GPSBold}
      {...props}
    />
  );
}

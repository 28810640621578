import { ReactComponent as UnavailableSVG } from "assets/images/503.svg";
import classes from "./styles.module.scss";

function UnavailableIcon(props: { [key: string]: any }) {
  return (
    <UnavailableSVG
      data-variant="bulk"
      {...props}
      className={classes.unAvailable}
    />
  );
}

export { UnavailableIcon };

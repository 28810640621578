import { Gender, DoctorType } from "components/dropdown";
import { CalendarBold, UserBold } from "components/icon";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { IRegistrationStepProps } from "./registrationLayout";
import Grid from "components/core/Grid";
import { useTranslation } from "react-i18next";
import { FastDatePicker, FastInput } from "components/formikFields";
import { formSubmission } from "utils/form";

const RegistrationFirstPage = ({
  formik,
  onSetAlert,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");

  return (
    <form autoComplete="off" onSubmit={(e) => formSubmission(e, formik)}>
      <Grid>
        <FastInput
          formik={formik}
          fieldName={"firstName"}
          icon={UserBold}
          label={t("first_name")}
        />
        <FastInput
          formik={formik}
          fieldName={"lastName"}
          icon={UserBold}
          label={t("last_name")}
        />
        <Gender
          formik={formik}
          fieldName="gender"
          id={formik.values.gender.key}
        />
        <FastDatePicker
          formik={formik}
          fieldName={"birthday"}
          label={t("birth")}
          icon={CalendarBold}
        />
        <DoctorType
          formik={formik}
          fieldName="docType"
          id={formik.values.docType.key}
        />

        {/* <FormInput
  noPadding
  rootProps={{
    icon: UserBold,
    placeholder: "CMO (Optional)",
    type: "number",
  }}
  {...getFormikFieldProps("cmoNumber", "CMO (Optional)", formik)}
/> */}

        <div className={classes.buttonContainer}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => onSetAlert?.(true)}
          >
            {t("cancel")}
          </Button>
          <Button type="submit">{t("continue")}</Button>
        </div>
      </Grid>
    </form>
  );
};

export default RegistrationFirstPage;

import { useState, useCallback, useEffect } from "react";

export default function useCountdown(seconds: number) {
  const [timer, setTimer] = useState(0);

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = () => {
    setTimer(seconds);
  };

  return { timer, resetTimer };
}

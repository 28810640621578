import { IFilter, IFilterValue } from "components/core/filter";
import { convertToAPIDateFormat } from "utils/date";
import { convertToString } from "utils/form";

function getFilterParamsByKey(filters: IFilter[], index: number) {
  if (filters[index]) {
    return filters[index].filters.map((item) => Number(item.key));
  }
  return [];
}
function getFilterParamsByValue(
  filters: IFilterValue[],
  index: number,
  accessor: "value" | "key" = "value"
) {
  if (filters[index]) {
    return filters[index].filters.map((item) => item[accessor]);
  }
  return [];
}

function getMyDoctorsFilterValue(
  filters: IFilterValue[],
  index: number,
  accessor: "value" | "key" = "key"
) {
  if (filters[index]) {
    const values = filters[index].filters.map((item) => item[accessor]);
    if (values?.length === 1) {
      return values[0] === "my_doctors";
    }
    return false;
  }
  return false;
}

function getGenderParams(
  genderFilterParams: ["male" | "female" | "others"]
): "male" | "female" | "others" | undefined {
  if (genderFilterParams.length === 1) {
    return genderFilterParams[0];
  }
  return undefined;
}
function getDropdownParams(filters: IFilterValue[], index: number) {
  if (filters[index]) {
    return filters[index].filters[0]?.["dropdown"];
  }
  return undefined;
}
function serializeParams(paramsArray: any[], accessor: string) {
  return paramsArray.map((n, index) => `${accessor}[${index}]=${n}`).join("&");
}

function getTimespanParams(
  filters: IFilterValue[],
  index: number,
  accessor: "from" | "to" = "from"
) {
  if (filters[index]) {
    return convertToAPIDateFormat(filters[index].filters[0]?.[accessor] || "");
  }
  return undefined;
}

function getCostParams(filters: IFilterValue[], index: number) {
  if (filters[index] && filters[index].filters[0]) {
    const cost = filters[index].filters[0];
    return {
      from: convertToString(cost.costFrom),
      to: convertToString(cost.costTo),
    };
  }
  return { from: "", to: "" };
}

function getUserStatus(approved: boolean) {
  if (approved) {
    return ["active"];
  } else {
    return ["denied", "pending"];
  }
}

function getParamName(paramName: string, index?: string | number) {
  return paramName + convertToString(index);
}

function getTabParamName(index?: string | number) {
  return getParamName("tab", index);
}
function getSearchParamName(index?: string | number) {
  return getParamName("keyword", index);
}
function getFilterParamName(index?: string | number) {
  return getParamName("filters", index);
}
function getCurrentPageParamName(index?: string | number) {
  return getParamName("currentPage", index);
}
function getResultsPerPageParamName(index?: string | number) {
  return getParamName("resultsPerPage", index);
}

export {
  getFilterParamsByKey,
  getFilterParamsByValue,
  getGenderParams,
  serializeParams,
  getTimespanParams,
  getCostParams,
  getDropdownParams,
  getUserStatus,
  getSearchParamName,
  getFilterParamName,
  getCurrentPageParamName,
  getResultsPerPageParamName,
  getTabParamName as getTabParamNAme,
  getMyDoctorsFilterValue,
};

import IDropdown from "../formikFields/IDropdown";
import { FastDropdown } from "../formikFields";
import { MessagesBold } from "components/icon";
import { useTranslation } from "react-i18next";
import { useOptions } from "../../utils/options";

interface IProps<T> extends IDropdown<T> {}

export function LanguageFluency<T>(props: IProps<T>) {
  const { t } = useTranslation("form");
  const { LANGUAGE_FLUENCY_OPTIONS } = useOptions();

  return (
    <FastDropdown
      label={t("language_fluency")}
      options={LANGUAGE_FLUENCY_OPTIONS}
      isError={false}
      isLoading={false}
      icon={MessagesBold}
      {...props}
    />
  );
}

import apiCaller, { IResponse } from "api";
import {
  INotBuiltRobotsDropdownParams,
  IRobotData,
  IRobotResponse,
  IRobotsListResponse,
  IRobotsListParams,
} from "./type";
import { IStatistics } from "api/types/statisticsTypes";
import { IPaginationTableList } from "api/types/paginationType";
import { IDropdownResponse } from "api/dropdown";

export function createRobot(data: IRobotData): Promise<IResponse<null>> {
  return apiCaller.post("robots", data);
}

export function updateRobot(
  data: IRobotData,
  robot_id: number
): Promise<IResponse<null>> {
  return apiCaller.put(`robots/${robot_id}`, data);
}

export function deleteRobot(robot_id: number): Promise<IResponse<null>> {
  return apiCaller.delete(`robots/${robot_id}`);
}

export function getRobotDetail(
  robot_id: number
): Promise<IResponse<IRobotResponse>> {
  return apiCaller.get(`robots/${robot_id}`);
}

export function getRobotsList(
  params: IRobotsListParams
): Promise<IResponse<IPaginationTableList<IRobotsListResponse>>> {
  return apiCaller.get(`robots`, { params: params });
}

export function getRobotsStatistics(): Promise<IResponse<IStatistics>> {
  return apiCaller.get(`robots/statistics`);
}

export function getRobotLevelsDropdown(): Promise<
  IResponse<IDropdownResponse[]>
> {
  return apiCaller.get(`dropdown/robots/levels`);
}

export function getRobotTypesDropdown(): Promise<
  IResponse<IDropdownResponse[]>
> {
  return apiCaller.get(`dropdown/robots/types`);
}

export function getNotBuiltRobotsDropdown(
  params: INotBuiltRobotsDropdownParams
): Promise<IResponse<IDropdownResponse[]>> {
  return apiCaller.get(`dropdown/not-builded-robots`, { params: params });
}

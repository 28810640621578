import SideBartTop from "../sideBartTop";
import Button from "../../../../core/button";
import { SendBold } from "../../../../icon/sendBold";
import Message from "./Message";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { VideoVisitContext } from "../..";
import Textarea from "../../../../core/textarea";
import { useTranslation } from "react-i18next";

export interface IMessagesProps {
  // messages: IMessage[];
  // userCount: number;
  onMessageInputChange?: (message: string) => any;
  onSendClick?: (message: string) => any;
  // session: OT.Session;
  onClose: () => void;
  isGuest?: boolean;
}

// Messages component for video visit
const Messages = ({
  // messages,
  // userCount,
  onMessageInputChange,
  onClose,
  isGuest,
}: IMessagesProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  // input value state
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { session, messages, setHasNewMessage } = useContext(VideoVisitContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const onSendHandler = () => {
    if (!message || message === "") {
      return;
    }

    session?.signal(
      {
        type: "msg",
        data: message?.replace("\n", "</br>"),
      },
      (error) => {
        if (error) {
          toast.error(
            t("sending_error", { name: error?.name, message: error?.message })
          );
        } else {
          inputRef.current!.value = "";
        }
      }
    );
  };

  const [rows, setRows] = useState(1);

  useEffect(() => {
    setRows(() =>
      Math.min(5, Math.floor(message.length / 24) + message.split("\n").length)
    );
  }, [message]);

  useEffect(() => {
    let containerRefCurrent = containerRef?.current;
    if (containerRefCurrent) {
      containerRefCurrent?.scrollTo({
        behavior: "smooth",
        top:
          containerRefCurrent?.scrollHeight + containerRefCurrent?.clientHeight,
      });
    }
  }, [messages]);

  const { streamArr } = useContext(VideoVisitContext);

  // render component
  return (
    <div className={clsx(classes.container, isGuest && classes.guestContainer)}>
      <SideBartTop
        onClick={() => onClose()}
        title={"Messaging"}
        text={t("user_in", { count: streamArr?.length })}
      />
      <div
        className={clsx(
          classes.messageComponent,
          isGuest && classes.messageComponentGuest
        )}
      >
        <div
          className={clsx(
            classes.messageComponent__place
            /*  rows === 1 && classes.h1,
            rows === 2 && classes.h2,
            rows === 3 && classes.h3,
            rows === 4 && classes.h4,
            rows === 5 && classes.h5,
            rows === 1 && isGuest && classes.h1,
            rows === 2 && isGuest && classes.h2,
            rows === 3 && isGuest && classes.h3,
            rows === 4 && isGuest && classes.h4,
            rows === 5 && isGuest && classes.h5*/
          )}
        >
          <div
            className={clsx(classes.messageComponent__messagesPlace)}
            ref={containerRef}
          >
            {messages.map((m, i) => {
              setHasNewMessage(false);
              return (
                <Message
                  from={m.from}
                  text={m.text}
                  timeText={m.timeText}
                  isMyMessage={m.isMyMessage}
                  key={i}
                />
              );
            })}
          </div>
        </div>
        <div
          className={clsx(
            classes.messageComponent__inputPlace,
            rows === 1 && classes.height1,
            rows === 2 && classes.height2,
            rows === 3 && classes.height3,
            rows === 4 && classes.height4,
            rows === 5 && classes.height5
          )}
        >
          <div className={clsx(classes.messageComponent__inputGroup)}>
            <Textarea
              ref={inputRef}
              className={clsx(classes.messageComponent__input)}
              placeholder={t("type")}
              value={message}
              htmlProps={{
                cols: 24,
                size: 24,
                rows: rows,
                onKeyDown: (event) => {
                  if (event.key === "Enter" && event.shiftKey) {
                    return;
                  }
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onSendHandler();
                    setMessage("");
                  }
                },
              }}
              onChange={(e) => {
                setMessage(e.target.value);
                if (onMessageInputChange) {
                  onMessageInputChange(e.target.value);
                }
              }}
            />
            <Button
              className={clsx(classes.messageComponent__send)}
              onClick={() => {
                // if (onSendClick) onSendClick(message);
                onSendHandler();
                setMessage("");
              }}
            >
              <SendBold />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;

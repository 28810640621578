import { IUserLanguage } from "../hooks/useLang";

const LANGUAGES: IUserLanguage[] = [
  {
    dir: "ltr",
    langCode: "en",
    langName: "English",
  },
  {
    dir: "ltr",
    langCode: "tr",
    langName: "Turkey",
  },
];

export default LANGUAGES;

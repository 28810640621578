import { useQuery } from "react-query";
import { getCountryTimezones } from "api/dropdown";
import { ClockBold } from "components/icon";
import { getDropdownValue } from "utils/dropdowns";
import { FastDropdown } from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import { useTranslation } from "react-i18next";
import { countryTimezoneKey } from "../../utils/keyGenerator/keys";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the country that this state is dependent on it.
   *
   * without knowing the country we can not fetch the states of that country, so the state depends on the country.
   */
  countryFieldName: keyof T & string;
  user_id: string | number | undefined;
}

export function Timezone<T>({
  countryFieldName,
  formik,
  disabled,
  user_id,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the country value from formik values and extract its key
  const { key: countryId } = getDropdownValue(formik, countryFieldName);

  // fetch data
  const { data, isLoading, isError } = useQuery(
    [countryTimezoneKey, countryId, user_id],
    () => getCountryTimezones(Number(countryId), user_id),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!countryId,
    }
  );

  return (
    <FastDropdown
      label={t("timezone")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!countryId || disabled}
      icon={ClockBold}
      {...props}
    />
  );
}

export const alertModalNaxWidth = 600;

export interface INotificationAlertProps {
  open: boolean;
  /** Title for the modal header (optional) */
  title?: string;
  onClose?: () => void;
  /**
   * by default cancel button behave like onClose function, but if you pass this props
   * they (cancel and close buttons) will behave severalty.
   * @returns
   */
  onCancel?: () => void;
  contnet?: string;
  onApprove?: () => void;
  /**
   * modal max-width in px
   */
  modalMaxWidth?: number;
  approveTitle?: string;
  noCancel?: boolean;
}

export type IAlertify = Omit<
  INotificationAlertProps,
  "open" | "approveAfterClosing"
>;

export const alertDefaultProps: IAlertify = {
  contnet: "",
  title: "",
  /** this props will set to `Approve` in notificationAlert component */
  approveTitle: "",
  modalMaxWidth: alertModalNaxWidth,
  noCancel: false,
  onApprove() {},
  onCancel() {},
  onClose() {},
};

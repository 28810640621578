import classes from "./header.styles.module.scss";
import { ReactComponent as Polygon } from "assets/images/polygon.svg";
import { ReactComponent as Logo } from "assets/images/brandLogo/logo.svg";
import { ReactComponent as SettingIcon } from "assets/icons/bold/setting.svg";
import { SidebarContext } from "..";
import { FunctionComponent, useContext, useState } from "react";
import { SideMenuButton } from "./sideMenuButton";
import Avatar from "components/core/avatar";
import clsx from "clsx";
import Button from "components/core/button";
import { Dropdown } from "components/core/dropdown";
import DropdownItem from "components/core/dropdownItem";
import { DropdownMenu } from "components/core/dropdownMenu";
import {
  ArrowRightLinear,
  LanguageBold,
  LogoutLinear,
  NotificationBold,
  PasswordCheckLinear,
} from "components/icon";
import { SwitchLinear } from "components/icon/switchLinear";
import { EditProfileLinear } from "components/icon/editProfileLinear";
import { useAuth } from "hooks/useAuth";
import { useHistory } from "react-router";
import { useProfile } from "hooks/useProfile";
import Alert from "../../../common/alert";
import { MEDIUM } from "constants/breakpoints";
import { closeSidebar, reloadPage } from "./helperFunctions";
import { VideVisitMinimizeContext } from "App";
import { notify } from "../../../core/toast";
import { useTranslation } from "react-i18next";
import languages from "../../../../constants/languages";
import { useLanguage } from "../../../../hooks/useLang";
import { useBaseUrl } from "../../../../utils/route";

interface IAvatarDropdownItems {
  label: string;
  icon: FunctionComponent;
  nestedItems?: JSX.Element[];
  onClick?: () => void;
}

/** Sidebar Header Component */
export function SidebarHeader() {
  const { t } = useTranslation("global", { keyPrefix: "sidebar_menu" });

  const { sideIn, setSideIn } = useContext(SidebarContext);
  const { roles, role, changeRole, logOut } = useAuth();
  const profile = useProfile();
  const profileName = `${profile.data?.data.data?.first_name} ${profile.data?.data.data?.last_name}`;
  const history = useHistory();
  const [logOutOpen, setLogoutOpen] = useState(false);
  const base = useBaseUrl();

  const { setVideoVisitMinimize, videoVisitMinimize, videoVisitIsOpen } =
    useContext(VideVisitMinimizeContext);
  const { changeLang, language } = useLanguage();

  /** Avatar settings dropdown items */
  const AVATAR_DROPDOWN_ITEMS: IAvatarDropdownItems[] = [
    {
      label: t("view_profile"),
      icon: EditProfileLinear,
      onClick: () => {
        history.push(`/panel/${role}/profile-management/view-profile`);
        closeSidebar(
          setSideIn,
          videoVisitMinimize,
          setVideoVisitMinimize,
          videoVisitIsOpen
        );
      },
    },
    {
      label: t("change_password"),
      icon: PasswordCheckLinear,
      onClick: () => {
        history.push(`/panel/${role}/change-password`);
        closeSidebar(
          setSideIn,
          videoVisitMinimize,
          setVideoVisitMinimize,
          videoVisitIsOpen
        );
      },
    },
    {
      label: t("switch_account"),
      icon: SwitchLinear,
      nestedItems: roles?.map((item) => (
        <DropdownItem
          key={item.roleName + item.facilityId}
          className={clsx([
            classes.accountMenuItem,
            classes.accountMenuPaddingRight,
          ])}
          onClick={() => {
            if (videoVisitIsOpen) {
              notify.warning(t("exit_video_visit"));
              return;
            }
            changeRole(item.role, item.facilityId);
            document.body.style.overflowY = "auto";
          }}
        >
          <div className="d-flex">
            <Avatar
              name={item.roleName}
              src={profile?.data?.data?.data?.avatar}
              contentClassName={clsx([classes.avatar])}
              size="normal"
            />
            <div className="MS-3">
              <div className={classes.profileName}>{item.roleName}</div>
              <div className={classes.profileRole}>{item.facility}</div>
            </div>
          </div>
        </DropdownItem>
      )),
    },
    {
      label: t("change_language"),
      icon: LanguageBold,
      nestedItems: languages?.map((item, index) => (
        <DropdownItem
          key={index}
          className={clsx([
            classes.accountMenuItem,
            classes.accountMenuPaddingRight,
          ])}
          onClick={() => {
            history.push(`${base}dashboard`);
            reloadPage();
            // TODO UNCOMMENT
            changeLang(item);
            document.body.style.overflowY = "auto";
          }}
        >
          <div
            className={clsx(
              "d-flex",
              classes.languagePlace,
              language.langCode === item.langCode && classes.selectedLang
            )}
          >
            <div className="d-flex justify-content-between w-100">
              <div className={clsx(classes.profileName, classes.lang)}>
                {item.langName}
              </div>
              <div className={classes.profileRole}>{item.langCode}</div>
            </div>
          </div>
        </DropdownItem>
      )),
    },
    {
      label: t("notification_setting"),
      icon: NotificationBold,
      onClick: () => {
        history.push(`/panel/${role}/notification-settings`);
        closeSidebar(
          setSideIn,
          videoVisitMinimize,
          setVideoVisitMinimize,
          videoVisitIsOpen
        );
      },
    },
    {
      label: t("logout"),
      icon: LogoutLinear,
      onClick: () => setLogoutOpen(true),
    },
  ];

  return (
    <>
      <div className="d-flex flex-column">
        <div
          className={
            sideIn ? classes.sidebar_header__min : classes.sidebar_header
          }
        >
          <div className={classes.header__top}>
            <div
              className={clsx(
                classes.logoContainer,
                sideIn && classes.hideText
              )}
            >
              <Logo />
              <span className={classes.logoLabel}>LEO360™</span>
            </div>

            <div>
              <SideMenuButton />
            </div>
          </div>

          <div
            className={sideIn ? classes.avatar__min : classes.avatar__container}
          >
            <Avatar
              name={profileName}
              size={sideIn ? "big" : "large"}
              src={profile?.data?.data?.data?.avatar}
            />
            <Dropdown anchor="bottom">
              <Button
                rootAttributes={{
                  id: "avatar-setting",
                }}
                variant="text"
                size={sideIn ? "small" : "normal"}
                className={clsx(
                  classes.avatar_setting__icon,
                  sideIn && classes.avatar_setting_icon__min
                )}
              >
                <SettingIcon />
              </Button>

              <DropdownMenu
                anchor={
                  document.body.clientWidth <= MEDIUM
                    ? "bottom-end"
                    : "bottom-start"
                }
                toggleId="avatar-setting"
                nestedToggleId="avater-nested-container"
                className={classes.avatarSettingDropdownMenu}
              >
                {AVATAR_DROPDOWN_ITEMS.map(
                  ({ label, icon: Icon, nestedItems, onClick }, index) =>
                    nestedItems ? (
                      <Dropdown
                        anchor="right"
                        id={"avater-nested-container"}
                        key={index}
                      >
                        <DropdownItem
                          id={"switch-nested" + index}
                          className={classes.avatar__dropdownItem}
                        >
                          <div className={classes.content}>
                            {Icon && <Icon />}
                            <span className="MS-2"> {label}</span>
                          </div>
                          <div className={classes.avatar__dropdownNestedIcon}>
                            <ArrowRightLinear />
                          </div>
                        </DropdownItem>
                        <DropdownMenu
                          menuAttributes={{
                            style: {
                              marginLeft: "6px",
                              marginBottom: "-61px",
                              zIndex: "999",
                              maxHeight: "300px",
                              overflowY: "auto",
                              paddingRight: "8px",
                            },
                          }}
                          anchor="right-bottom"
                          toggleId={"switch-nested" + index}
                        >
                          {nestedItems}
                        </DropdownMenu>
                      </Dropdown>
                    ) : (
                      <DropdownItem
                        onClick={onClick}
                        className={classes.avatar__dropdownItem}
                        key={index}
                      >
                        <div className={classes.content}>
                          {Icon && <Icon />}
                          <span className="MS-2"> {label}</span>
                        </div>
                      </DropdownItem>
                    )
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className={classes.polygon}>
            <Polygon />
          </div>
        </div>
        {!sideIn && (
          <div className={classes.info}>
            <span className={classes.info__name}>
              {`${profile.data?.data.data?.first_name} ${profile.data?.data.data?.last_name}`}
            </span>
            <span className={classes.info__role}>
              {roles?.find((item) => item.role === role)?.roleName}
            </span>
          </div>
        )}
        <Alert
          open={logOutOpen}
          setOpen={setLogoutOpen}
          title={t("logout_alert.title")}
          text={t("logout_alert.text")}
          approveText={t("logout")}
          dismissible={true}
          onApprove={() => logOut()}
          modalMaxWidth={725}
        />
      </div>
    </>
  );
}

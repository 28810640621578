import {
  ActionMenuBoldDark,
  AudioMuteBold,
  PauseBold,
  PlayBold,
  VoiceBold,
} from "../../../../icon";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import classes from "./styles.module.scss";

function getTime(t: number) {
  return `${parseInt((t / 60 / 10).toString(), 10)}${parseInt(
    ((t / 60) % 10).toString(),
    10
  )}:${parseInt(((t % 60) / 10).toString(), 10)}${parseInt(
    ((t % 60) % 10).toString(),
    10
  )}`;
}

const AudioPlayer = ({ src, gray }: { src: string; gray?: boolean }) => {
  const playerRef = useRef<HTMLButtonElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const timeLineRef = useRef<HTMLInputElement>(null);
  const soundRef = useRef<HTMLButtonElement>(null);

  const [mute, setMute] = useState(false);
  const [time, setTime] = useState("00:00");
  const [end, setEnd] = useState(false);
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState("00:00");

  useEffect(() => {
    if (audioRef.current && audioRef.current.duration > 0) {
      const t = audioRef.current.duration;
      setDuration(getTime(t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef.current]);

  return (
    <div className={clsx(classes.audioContainer, gray && "w-100")}>
      <div className={clsx(classes.audioPlayer, gray && classes.gray)}>
        <audio
          onEnded={() => {
            console.log("ENDD");
            setEnd(true);
          }}
          onTimeUpdate={(e) => {
            if (audioRef.current && timeLineRef.current) {
              const percentagePosition =
                (100 * audioRef.current.currentTime) /
                audioRef.current.duration;
              timeLineRef.current.style.backgroundSize = `${percentagePosition}% 100%`;
              timeLineRef.current.value = percentagePosition.toString();
              const t = audioRef.current.currentTime;
              setTime(getTime(t));
            }
          }}
          ref={audioRef}
          src={src}
        ></audio>
        <div className={clsx(classes.audioPlayer__controls)}>
          <button
            className={clsx(classes.audioPlayer__btn)}
            ref={playerRef}
            onClick={() => {
              if (end) {
                setEnd(false);
              }
              if (audioRef.current && playerRef.current) {
                if (audioRef.current.paused) {
                  audioRef.current.play();
                  setPlay(true);
                } else {
                  audioRef.current.pause();
                  setPlay(false);
                }
              }
            }}
          >
            {play && !end ? <PauseBold /> : <PlayBold />}
          </button>
          <div
            className={clsx(
              classes.audioPlayer__texts,
              classes.audioPlayer__shrink
            )}
          >
            {time} / {duration ? duration : "0"}
          </div>
          <input
            type="range"
            className={clsx(classes.audioPlayer__timeline)}
            max="100"
            value="0"
            ref={timeLineRef}
            onChange={() => {
              if (timeLineRef.current && audioRef.current) {
                audioRef.current.currentTime =
                  (Number(timeLineRef.current.value) *
                    audioRef.current.duration) /
                  100;
              }
            }}
          />
          <button
            className={clsx(
              classes.audioPlayer__btn,
              classes.audioPlayer__shrink
            )}
            ref={soundRef}
            onClick={() => {
              if (audioRef.current) {
                audioRef.current.muted = !audioRef.current.muted;
              }
              setMute((m) => !m);
            }}
          >
            {mute ? <AudioMuteBold /> : <VoiceBold />}
          </button>
          <div className={clsx(classes.audioPlayer__shrink)}>
            {" "}
            <ActionMenuBoldDark />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;

import IDropdown from "../formikFields/IDropdown";
import { FastDropdown } from "../formikFields";
import { EmojiHappyBold } from "components/icon";
import { useRobotLevelDropdown } from "features/registeredRobots/queries";
import { useTranslation } from "react-i18next";

interface IProps<T> extends IDropdown<T> {}

export function RobotLevel<T>(props: IProps<T>) {
  const { t } = useTranslation("form");

  // get the device types from server
  const { data, isError, isLoading } = useRobotLevelDropdown();

  return (
    <FastDropdown
      label={t("robot_level")}
      data={data}
      isError={isError}
      isLoading={isLoading}
      icon={EmojiHappyBold}
      {...props}
    />
  );
}

import { E_CITY, E_DEPARTMENT, E_FACILITY } from "utils/entities";
import generateKey, { QGET, QGET_ALL } from "utils/keyGenerator";
import {
  DASHBOARD_TYPE,
  DETAIL_TYPE,
  DROPDOWN_TYPE,
  PARENT_TYPE,
  STATISTICS_TYPE,
  TYPE,
} from "utils/keyGenerator/keyTypes";

export const facilityStatisticsKey = generateKey({
  name: E_FACILITY,
  method: QGET,
  type: [DASHBOARD_TYPE, STATISTICS_TYPE],
});
export const facilitiesKey = generateKey({
  name: E_FACILITY,
  method: QGET_ALL,
});
export const facilityByTypeKey = generateKey({
  name: E_FACILITY,
  method: QGET,
  type: [DROPDOWN_TYPE, TYPE],
});

export const facilityTypes = generateKey({
  name: E_FACILITY,
  method: QGET,
  type: [DROPDOWN_TYPE],
});

export const cityFacilityByTypeKey = generateKey({
  name: E_FACILITY,
  method: QGET,
  type: [DROPDOWN_TYPE, E_CITY, TYPE],
});

export const facilityDetailKey = generateKey({
  name: E_FACILITY,
  method: QGET,
  type: [DETAIL_TYPE],
});

export const facilityDepartmentsKey = generateKey({
  name: E_DEPARTMENT,
  method: QGET,
  type: [E_FACILITY, DROPDOWN_TYPE],
});

export const facilityTypesKey = generateKey({
  name: E_FACILITY,
  method: QGET,
  type: [TYPE, DROPDOWN_TYPE],
});

export const facilityParentsKey = generateKey({
  name: E_FACILITY,
  method: QGET,
  type: [PARENT_TYPE, DROPDOWN_TYPE],
});

export const allDepartmentsKey = generateKey({
  name: E_DEPARTMENT,
  method: QGET,
  type: [DROPDOWN_TYPE],
});

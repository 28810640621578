import { ReactComponent as BadRequestSVG } from "assets/images/400.svg";
import classes from "./styles.module.scss";

function BadRequestIcon(props: { [key: string]: any }) {
  return (
    <BadRequestSVG
      data-variant="bulk"
      {...props}
      className={classes.serverError}
    />
  );
}

export { BadRequestIcon };

import classes from "./styles.module.scss";
import { memo, useState } from "react";
import ImageModal from "./ImageModal";
import Image from "../../../image";
import { useQuery } from "react-query";
import { showFile } from "../../../../../api/files";
import { fileKey } from "../../../../../utils/keyGenerator/keys";
import { humanizedDate } from "../../../../../utils/date";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Button from "../../../../core/button";
import { Document, Page } from "react-pdf";
import { useHistory } from "react-router";

export interface IImageCardProps {
  imgUrl?: string;
  openModal?: boolean;
  noZoom?: boolean;
  video?: boolean;
  date?: string;
}

// image card for video visit component
const ImageCard = ({
  imgUrl,
  openModal,
  noZoom,
  video,
  date,
}: IImageCardProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  // state for check open or close of card
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);

  const history = useHistory();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const isUrl =
    !!imgUrl &&
    imgUrl !== "" &&
    !imgUrl.includes("/") &&
    !imgUrl.includes("base64");

  const { data, isLoading, isError } = useQuery(
    [fileKey, imgUrl],
    () => showFile(imgUrl || ""),
    {
      enabled: isUrl,
    }
  );

  const src = !isUrl ? imgUrl : data;
  const isPdf = String(src)?.includes(".pdf");
  const pdfSrc = String(src)?.replace(".pdf", "");

  if (!imgUrl || imgUrl === "" || isError) {
    return <></>;
  }

  if (isPdf) {
    return (
      <div className={classes.iframeParent}>
        <Document
          className={clsx(classes.pdfRenderer)}
          file={pdfSrc} // Replace with the path to your PDF file
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className={classes.pdfRenderer__page}
              width={292}
            />
          ))}
        </Document>
        <div className={classes.iframeParent__link}>
          <Button
            size={"small"}
            onClick={() => {
              history.push({ pathname: "/pdf-viewer", state: pdfSrc });
            }}
          >
            {t("open_pdf")}
          </Button>
        </div>
      </div>
    );
  }

  if (date) {
    return (
      <div className={"d-flex flex-column gap-2"}>
        <div
          className={classes.imageCard}
          onClick={() => {
            if (openModal && !noZoom) setOpen(true);
          }}
        >
          <Image
            video={video}
            src={!isUrl ? imgUrl : data}
            isLoading={isLoading}
            alt={"medical"}
            className={classes.imageCard__img}
          />
          {open ? (
            <ImageModal
              imageUrl={!isUrl ? imgUrl : data}
              onClose={setOpen}
              video={video}
            />
          ) : (
            ""
          )}
        </div>
        {date && (
          <div className={classes.imageCard__date}>
            {t("measured_at")}: {humanizedDate(date, true)}
          </div>
        )}
      </div>
    );
  }
  // render component
  return (
    <div
      className={classes.imageCard}
      onClick={() => {
        if (openModal && !noZoom) setOpen(true);
      }}
    >
      <Image
        video={video}
        src={!isUrl ? imgUrl : data}
        isLoading={isLoading}
        alt={"medical"}
        className={classes.imageCard__img}
      />
      {open ? (
        <ImageModal
          imageUrl={!isUrl ? imgUrl : data}
          onClose={setOpen}
          video={video}
        />
      ) : (
        ""
      )}
    </div>
  );
};

ImageCard.defaultProps = {
  openModal: true,
};

export default memo(ImageCard);

import clsx from "clsx";
import Button from "components/core/button";
import FormInput from "components/form/formInput";
import FormPassword from "components/form/password";
import { FrameBold } from "components/icon";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { getFormikFieldProps } from "utils/form";
import LoginLayout from "./components/loginLayout";
import classes from "./components/styles.module.scss";
import { useAuth } from "hooks/useAuth";
import { UserLoginType } from "api/types/userTypes";
import {
  ClipboardEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { Dropdown } from "components/core/dropdown";
import { DropdownMenu } from "components/core/dropdownMenu";
import DropdownItem from "components/core/dropdownItem";
import yup, { phoneObj } from "utils/yupExtended";
import { useMutation } from "react-query";
import { loginFirstStep } from "api/auth";
import { notify } from "components/core/toast";
import { PhoneInputDropdown } from "components/common/dropdownField";
import { IPhoneNumber } from "interfaces";
import useCountdown from "hooks/useCountdown";
import { padWithZero } from "utils/date";
import { useTranslation } from "react-i18next";

interface IOnFieldForm {
  username: string;
  password: string;
  remember: boolean;
  mobile: IPhoneNumber;
}

interface IVerifyCode {
  firstDigit: string;
  secondDigit: string;
  thirdDigit: string;
  fourthDigit: string;
  fifthDigit: string;
}

const INITIAL_VERIFY_CODE: IVerifyCode = {
  firstDigit: "",
  secondDigit: "",
  thirdDigit: "",
  fourthDigit: "",
  fifthDigit: "",
};

const INITIAL_ONE_FIELD_FORM: IOnFieldForm = {
  username: "",
  password: "",
  remember: false,
  mobile: { code: "98", number: "" },
};

const VerifyValidationSchema = yup.object().shape({
  firstDigit: yup.string().required(""),
  secondDigit: yup.string().required(""),
  thirdDigit: yup.string().required(""),
  fourthDigit: yup.string().required(""),
  fifthDigit: yup.string().required(""),
});

export default function LoginPage() {
  const { t } = useTranslation(["global", "form"]);

  const auth = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loginType, setLoginType] = useState(UserLoginType.Email);
  const [step, setStep] = useState<1 | 2>(1);
  const { timer, resetTimer } = useCountdown(59);

  // const LoginValidationSchema =

  const getSchema = () => {
    return yup.object().shape({
      ...(loginType === UserLoginType.Email && {
        username: yup
          .string()
          .min(4)
          .required(t("required", { ns: "form" })),
      }),
      ...(loginType === UserLoginType.Mobile && {
        mobile: yup.object().phoneNumber({ errorMessage: phoneObj() }),
      }),
      password: yup
        .string()
        .min(6)
        .required(t("required", { ns: "form" })),
    });
  };

  const getLoginUsername = (type: UserLoginType, values: IOnFieldForm) => {
    return type === UserLoginType.Email
      ? values.username
      : Object.values(values.mobile).join("-");
  };

  const loginFirstMutation = useMutation(loginFirstStep, {
    onSuccess: () => {
      notify.success(t("verification_sent", { ns: "form" }));
    },
  });

  useEffect(() => {
    if (auth.isLoggedIn) {
      toast.success(t("login_successful"));
      history.replace(`/panel/${auth.role}/`);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [auth.isLoggedIn, auth.role, history]);

  const submitVerificationCode = async (values: IVerifyCode) => {
    try {
      setIsLoading(true);
      await auth.logIn({
        type: loginType,
        verification_code: Object.values(values).join(""),
        user_name: getLoginUsername(loginType, formik.values),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line
  // const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // const mobileFormat = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  const formik = useFormik<IOnFieldForm>({
    initialValues: INITIAL_ONE_FIELD_FORM,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      console.log({ values, actions });
      loginFirstMutation.mutate(
        {
          user_name: getLoginUsername(loginType, values),
          password: values.password,
          type: loginType,
        },
        {
          onSuccess: () => {
            setStep(2);
            resetTimer();
          },
        }
      );
    },
    validationSchema: getSchema(),
  });

  const verifyFormik = useFormik<IVerifyCode>({
    initialValues: INITIAL_VERIFY_CODE,

    onSubmit: async (values, actions) => {
      console.log({ values, actions });
      submitVerificationCode(values);
    },
    validationSchema: VerifyValidationSchema,
  });

  const firstDigitRef = useRef<HTMLFormElement>(null);

  const handleInputChange = async (
    e: any,
    nextInputIndex: number,
    fieldName: string
  ) => {
    if (isNaN(e) && e.length === 1) {
      return;
    }
    verifyFormik.setFieldValue(fieldName, e);

    if (e?.trim() && nextInputIndex !== 5) {
      const input = firstDigitRef.current?.querySelectorAll("input");
      input && input[nextInputIndex]?.focus();
    }
  };

  useEffect(() => {
    if (Object.values(verifyFormik.values).join("").length === 5) {
      verifyFormik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyFormik.values.fifthDigit]);

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginType]);

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement>,
    prevInputIndex: number
  ) => {
    const inputs = firstDigitRef.current?.querySelectorAll("input");

    if (e.key === "Backspace" && !inputs?.[prevInputIndex + 1].value) {
      inputs?.[prevInputIndex].focus();
    }
  };

  const handlePasteText = (e: ClipboardEvent<HTMLInputElement>): void => {
    const code = e.clipboardData.getData("text").trim();
    if (code.length <= 5 && !isNaN(+code)) {
      const splittedCode = code.split("");
      verifyFormik.setFieldValue("firstDigit", splittedCode[0]);
      verifyFormik.setFieldValue("secondDigit", splittedCode[1]);
      verifyFormik.setFieldValue("thirdDigit", splittedCode[2]);
      verifyFormik.setFieldValue("fourthDigit", splittedCode[3]);
      verifyFormik.setFieldValue("fifthDigit", splittedCode[4]);
    }
  };

  return (
    <LoginLayout
      hasFooter
      form_title={t("login.title")}
      form_description={
        step === 1 ? t("login.description1") : t("login.description2")
      }
      stepsCount={2}
      step={step}
    >
      {step === 1 && (
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          {loginType === UserLoginType.Email ? (
            <FormInput
              rootProps={{
                icon: FrameBold,
                placeholder: t("username_email", { ns: "form" }),
                disabled: isLoading,
              }}
              className="p-0"
              {...getFormikFieldProps(
                "username",
                t("username", { ns: "form" }),
                formik
              )}
            />
          ) : (
            <PhoneInputDropdown
              noPadding
              formik={formik}
              label={t("mobile", { ns: "form" })}
              placeholder={t("mobile", { ns: "form" })}
              phoneFieldName="mobile"
            />
          )}

          <div className="d-flex mt-3">
            <span>
              <span className={clsx(["ME-1", classes.text])}>
                {loginType === UserLoginType.Email
                  ? t("login.mobile")
                  : t("login.email")}
              </span>
              <span
                className={classes.link}
                onClick={() =>
                  setLoginType(
                    loginType === UserLoginType.Mobile
                      ? UserLoginType.Email
                      : UserLoginType.Mobile
                  )
                }
              >
                {t("click_here")}
              </span>
            </span>
          </div>

          <FormPassword
            rootProps={{
              placeholder: t("password", { ns: "form" }),
              disabled: isLoading,
            }}
            className="p-0 mt-4"
            {...getFormikFieldProps(
              "password",
              t("password", { ns: "form" }),
              formik
            )}
          />
          {/*
            <Switch
              checked={formik.values.remember}
              onChange={(value) => formik.setFieldValue("remember", value)}
            />
            <label htmlFor="remember" className={clsx([classes.label, "MS-2"])}>
              Remember me
            </label>
            */}
          <div className="d-flex mt-4 pt-2">
            <Link
              className={clsx([classes.link, "MS-auto"])}
              to="/forget-password"
            >
              {t("forgot_password", { ns: "form" })}
            </Link>
          </div>
          <Button
            disabled={loginFirstMutation.isLoading}
            className="mt-3 w-100"
            type="submit"
          >
            {loginFirstMutation.isLoading ? (
              <div className="d-flex mt-2">
                <BeatLoader color="#fff" size={10} margin={2} />
              </div>
            ) : (
              t("sign_in", { ns: "form" })
            )}
          </Button>
          <div className="d-flex mt-3">
            <span>
              <span className={clsx(["ME-1", classes.text])}>
                {t("login.account_text")}
              </span>
              <Dropdown anchor="right">
                <span
                  className={clsx([classes.link, "PE-2"])}
                  id="login-sign-up-toggle-btn"
                >
                  {t("sign_up", { ns: "form" })}
                </span>
                <DropdownMenu
                  anchor="right-bottom"
                  toggleId="login-sign-up-toggle-btn"
                >
                  <DropdownItem
                    onClick={() => history.replace(`doctor-registration`)}
                  >
                    {t("doctor", { ns: "form" })}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => history.replace(`staff-registration`)}
                  >
                    {t("staff", { ns: "form" })}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => history.replace(`patient-registration`)}
                  >
                    {t("patient", { ns: "form" })}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </span>
          </div>
        </form>
      )}
      {step === 2 && (
        <form
          autoComplete="off"
          onSubmit={verifyFormik.handleSubmit}
          ref={firstDigitRef}
        >
          <div
            className="d-flex justify-content-between gap-2"
            style={{ direction: "ltr" }}
          >
            <FormInput
              className={clsx(["p-0", classes.verifyCodeInput])}
              rootProps={{
                htmlProps: { onPaste: handlePasteText, maxLength: 1 },
              }}
              {...getFormikFieldProps("firstDigit", "", verifyFormik)}
              onChange={(value) => {
                handleInputChange(value, 1, "firstDigit");
              }}
            />
            <FormInput
              rootProps={{
                onKeyDown: (e) => handleKeyDown(e, 0),
                htmlProps: { maxLength: 1 },
              }}
              className={clsx(["p-0", classes.verifyCodeInput])}
              {...getFormikFieldProps("secondDigit", "", verifyFormik)}
              onChange={(value) => {
                handleInputChange(value, 2, "secondDigit");
              }}
            />
            <FormInput
              rootProps={{
                onKeyDown: (e) => handleKeyDown(e, 1),
                htmlProps: { maxLength: 1 },
              }}
              className={clsx(["p-0", classes.verifyCodeInput])}
              {...getFormikFieldProps("thirdDigit", "", verifyFormik)}
              onChange={(value) => {
                handleInputChange(value, 3, "thirdDigit");
              }}
            />
            <FormInput
              rootProps={{
                onKeyDown: (e) => handleKeyDown(e, 2),
                htmlProps: { maxLength: 1 },
              }}
              className={clsx(["p-0", classes.verifyCodeInput])}
              {...getFormikFieldProps("fourthDigit", "", verifyFormik)}
              onChange={(value) => {
                handleInputChange(value, 4, "fourthDigit");
              }}
            />
            <FormInput
              rootProps={{
                onKeyDown: (e) => handleKeyDown(e, 3),
                htmlProps: { maxLength: 1 },
              }}
              className={clsx(["p-0", classes.verifyCodeInput])}
              {...getFormikFieldProps("fifthDigit", "", verifyFormik)}
              onChange={(value) => {
                handleInputChange(value, 5, "fifthDigit");
              }}
            />
          </div>

          <Button
            onClick={() => {
              setIsLoading(true);
              verifyFormik.resetForm();
              resetTimer();
              const user_name =
                loginType === UserLoginType.Mobile
                  ? `${formik.values.mobile.code}-${formik.values.mobile.number}`
                  : formik.values.username;
              loginFirstMutation.mutate(
                {
                  user_name: user_name,
                  password: formik.values.password,
                  type: loginType,
                },
                {
                  onSettled: () => {
                    setIsLoading(false);
                  },
                }
              );
            }}
            size="small"
            variant="text"
            className="px-0 mt-4"
            disabled={!!timer && timer > 0}
          >
            {!!timer && timer > 0
              ? t("login.resend_code", { time: padWithZero(timer, 2) })
              : t("login.resend")}
          </Button>

          <Button disabled={isLoading} className="mt-4 w-100" type="submit">
            {isLoading ? (
              <div className="d-flex mt-2">
                <BeatLoader color="#fff" size={10} margin={2} />
              </div>
            ) : (
              t("verify", { ns: "form" })
            )}
          </Button>

          <div className="d-flex mt-3">
            <span>
              <span className={clsx(["ME-1", classes.text])}>
                {t("back_to_login")}
              </span>
              <span
                className={classes.link}
                onClick={() => {
                  verifyFormik.resetForm();
                  setStep(1);
                }}
              >
                {t("click_here")}
              </span>
            </span>
          </div>
        </form>
      )}
    </LoginLayout>
  );
}

// function LoginFirstStepForm() {
//   return <div></div>;
// }

import { LARGE } from "constants/breakpoints";

export function closeSidebar(
  setSideIn: (value: boolean) => void,
  videoVisitMinimize: boolean,
  setVideVisitMinimize: React.Dispatch<React.SetStateAction<boolean>>,
  videoVisitIsOpen: boolean
) {
  if (document.body.clientWidth <= LARGE) {
    //we are in mobile mode
    setSideIn(true);
    document.body.style.overflowY = "auto";
  }
  if (!videoVisitMinimize && videoVisitIsOpen) {
    setVideVisitMinimize(true);
  }
}
export async function reloadPage() {
  window.location.reload();
}

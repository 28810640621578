import clsx from "clsx";
import img from "../../../assets/images/ruler.svg";
import classes from "./styles.module.scss";

// Ruler props type interface
export interface IRulerProps {
  color: "light" | "blue" | string;
  type: "Height" | "Weight";
  number?: number;
}

// component for create card with background of ruler
const Ruler = ({ color, type, number }: IRulerProps) => {
  const convertData = () => {
    if (number) {
      if (type === "Height") {
        return `${number} Cm / ${convertCmToInch(number)} In"`;
      } else {
        return `${number} Kg / ${kgToP(number)} lbs`;
      }
    }
    return "- - -";
  };
  function kgToP(kilograms?: number): number {
    if (!kilograms) return 0;
    return round(kilograms * 2.20462262185);
  }
  function convertCmToInch(cm: number): number {
    const inchesPerCm = 1 / 2.54;
    return round(cm * inchesPerCm);
  }
  function round(number: number): number {
    const roundedNumber = Math.round(number * 100) / 100;
    if (roundedNumber - number >= 0.5) {
      return roundedNumber + 1;
    } else {
      return roundedNumber;
    }
  }
  // render component
  return (
    <div
      className={clsx(classes.ruler)}
      style={{
        background:
          color === "light" ? "#FFEFDB" : color === "blue" ? "#47CBFF" : color,
      }}
    >
      <div className={clsx(classes.ruler__title)}>{type}</div>
      <div className={clsx(classes.ruler__numberPlace)}>
        <img src={img} alt="ruler" />
        <div>{`${convertData()}`}</div>
      </div>
    </div>
  );
};

export default Ruler;

import { useHistory } from "react-router-dom";
import classes from "./styles.module.scss";
import { NoConnectionIcon } from "../../components/icon/noConnection";
import { useEffect } from "react";

const NO_CONNECTION = `No Internet Connection You Can Continue When Connection Is Back.`;

interface INotFoundProps {
  isInPanel?: boolean;
}

function NoInternet({ isInPanel }: INotFoundProps) {
  const history = useHistory();
  useEffect(() => {
    if (window.navigator.onLine) history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.navigator.onLine]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.pageContent}>
        <NoConnectionIcon />
        <span className={classes.pageText}>{NO_CONNECTION}</span>
      </div>
    </div>
  );
}

export default NoInternet;

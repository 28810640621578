import clsx from "clsx";
import Chip from "components/core/chip";
import { ROLES } from "constants/autocompleteOptions";
import { TChipColor } from "interfaces";
import { getFullRoleLabel } from "utils/mappers";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export interface IRoleTypeCellProps {
  value?: any;
  role_name: string;
  className?: string;
  style?: React.CSSProperties | undefined;
}
function getColor(role_name: string): TChipColor {
  for (let index = 0; index < ROLES.length; index++) {
    const element = ROLES[index];
    if (role_name === element.name) return element.color;
  }
  return "gray-dark";
}

//? a cell in tables that defines the user type by specific color

export function RoleTypeCell({
  value,
  role_name,
  className,
  style,
}: IRoleTypeCellProps) {
  const { t } = useTranslation("global");

  const color = getColor(role_name);
  return (
    <Chip
      style={style}
      color={(value as TChipColor) || color}
      text={t(getFullRoleLabel(value))}
      className={clsx([classes.tableChip, className])}
    />
  );
}

import AutoComplete, {
  IDefaultProps as IAutoCompleteProps,
  IMenuOption,
} from "components/core/autoComplete";
import FormBaseElement, { IBaseProps, IFormikFieldProps } from "./base";

export interface IFormAutoCompleteProps {
  options: IMenuOption[];
  rootProps?: Partial<IAutoCompleteProps>;
  onInputFieldChange?: (value: string) => void;
}

// form component for wrapping auto complete input component
function FormAutoComplete({
  onChange,
  value,
  options,
  rootProps,
  onInputFieldChange,
  nameId,
  ...props
}: IFormAutoCompleteProps & IBaseProps & IFormikFieldProps) {
  // render component
  return (
    <FormBaseElement nameId={nameId} {...props}>
      <AutoComplete
        options={options}
        value={value}
        onChange={(v) => onChange(v)}
        onInputChange={onInputFieldChange}
        name={nameId}
        {...rootProps}
      />
    </FormBaseElement>
  );
}

export { type IMenuOption };
export default FormAutoComplete;

import { useQuery } from "react-query";
import { getProfile } from "api/auth";
import { profileKey } from "../utils/keyGenerator/keys";
// import { useAuth } from "hooks/useAuth";

// hook that fetch profile
export function useProfile(enabled = true) {
  return useQuery(profileKey, getProfile, {
    enabled,
  });
}

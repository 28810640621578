import Loader from "../loader";
import { ImgHTMLAttributes } from "react";

export interface IImageProps extends ImgHTMLAttributes<any> {
  className?: string;
  src: string;
  alt?: string;
  isLoading?: boolean;
  video?: boolean;
}

const Image = ({
  alt,
  src,
  className,
  isLoading,
  video,
  ...rest
}: IImageProps) => {
  if (video) {
    return (
      <Loader isLoading={isLoading || false}>
        <video
          autoPlay
          muted
          src={src}
          className={className}
          onClick={(e) => {
            e.preventDefault();
          }}
          {...rest}
        />
      </Loader>
    );
  }
  return (
    <Loader isLoading={isLoading || false}>
      <img
        src={src}
        alt={alt}
        className={className}
        loading={"eager"}
        {...rest}
      />
    </Loader>
  );
};

export default Image;

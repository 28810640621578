import classes from "./base.styles.module.scss";
import clsx from "clsx";
import { useContext } from "react";
import { SidebarHeader } from "./header";
import { SidebarMenuItem } from "./sidebarMenuItem";
import { SidebarFooter } from "./footer";
import { SidebarContext } from "..";
import { useSidebarItems } from "../../../../constants/sidebarItems";
import { useTranslation } from "react-i18next";

export function SidebarBase() {
  const { t } = useTranslation("sidebar");
  const { PrefrencesItems, Items } = useSidebarItems();

  // const [filteredItems, setFilteredItems] = useState<ISidebarItem[]>([]);
  const { sideIn } = useContext(SidebarContext);
  // const [searchParameter, setSearchParameter] = useState("");

  return (
    <div className="position-fixed" style={{ zIndex: "102" }}>
      <div
        className={clsx([
          classes.sidebarContainer,
          sideIn ? classes.sideIn : null,
        ])}
      >
        <SidebarHeader />
        <div className={clsx([classes.menuItemContainer, sideIn && "pt-2"])}>
          {Items.map((item, index) => (
            <SidebarMenuItem key={index} item={item} />
          ))}
          <div
            className={clsx(
              classes.sidebar_separator__text,
              sideIn && classes.center
            )}
          >
            <span>{t("preferences")}</span>
          </div>
          {PrefrencesItems.map((item, index) => (
            <SidebarMenuItem key={index + 10} item={item} />
          ))}
        </div>
        <SidebarFooter />
      </div>
    </div>
  );
}

import apiCaller, { IResponse } from "./index";

export function linkShorter(
  data: string
): Promise<IResponse<{ token: string }>> {
  return apiCaller.post("link-shorter", { data });
}

export function getLinkData(
  token: string
): Promise<IResponse<{ data: string }>> {
  return apiCaller.get("get-link", { params: { token } });
}

export function getLastArrayItem<T>(array: T[] | undefined): T | undefined {
  if (!array) {
    return undefined;
  }
  if (array.length === 0) {
    return undefined; // Return undefined if the array is empty
  }
  return array[array.length - 1]; // Return the last item in the array
}

export function doesKeyExistInArray(
  keyToCheck: number,
  arrayToSearch: { key: number; value: string }[]
): boolean {
  return !!arrayToSearch.filter((i) => i?.key === keyToCheck)[0];
}

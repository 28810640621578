import {
  RedirectToDashboard,
  RedirectToDashboardAbs,
} from "components/common/redirectToDashboard";
import { lazy } from "react";
import { addInnerRoutes, TRoute } from "config/router";
import {
  DASHBOARD_GLOBAL_ROUTES,
  NEW_NOTIFICATION_ROUTE,
} from "./globalDashboard";
import { UserRoleName } from "api/types/userTypes";
import ServerError from "../../pages/500";
import Forbidden from "../../pages/403";
import BadRequest from "../../pages/400";
import Unavailable from "../../pages/503";
import NoInternet from "../../pages/noInternet";
import { lazyRetry } from "utils/route";

const ResetPassword = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/dashboard/superAdmin/localAdmin/staffManagement/resetPassword"
      )
  )
);

const CMO_ROUTES: TRoute = {
  path: "cmo",
  exact: true,
  component: RedirectToDashboard,
  inners: [
    {
      path: "500",
      exact: true,
      component: ServerError,
    },
    {
      path: "403",
      exact: true,
      component: Forbidden,
    },
    {
      path: "400",
      exact: true,
      component: BadRequest,
    },
    {
      path: "503",
      exact: true,
      component: Unavailable,
    },
    {
      path: "no-connection",
      exact: true,
      component: NoInternet,
    },
    {
      path: "dashboard",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/cmo/home"))
      ),
      exact: true,
    },
    {
      path: "reset-password/:id",
      component: () => <ResetPassword roleName={UserRoleName.Doctor} />,
      exact: true,
    },
    {
      path: "doctor-availability",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/cmo/availabilityTime"))
      ),
      exact: true,
      inners: [
        {
          path: ":id",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/doctor/availabilityTime"))
          ),
          exact: true,
        },
        {
          path: ":id/add",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/doctor/availabilityTime/components/addAvailabilityTime"
                )
            )
          ),
          exact: true,
        },
        {
          path: ":docId/edit-time/:schId",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/doctor/availabilityTime/components/updateAvailabilityTime"
                )
            )
          ),
          exact: true,
        },
        {
          path: "view-appointment-detail/:id",
          component: lazy(() =>
            lazyRetry(
              () => import("pages/dashboard/superAdmin/appointmentDetail")
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "doctor-registration",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/cmo/doctorRegistration"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
          exact: true,
        },
        {
          exact: true,
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/viewDoctorProfilePage"
                )
            )
          ),
          exact: true,
        },
        {
          path: "reset-password/:id",
          component: () => <ResetPassword roleName={UserRoleName.Doctor} />,
          exact: true,
        },
      ],
    },
    {
      path: "doctor-approval",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/cmo/doctorApproval"))
      ),
      exact: true,
      inners: [
        {
          path: "reset-password/:id",
          component: () => <ResetPassword />,
          exact: true,
        },
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/viewDoctorProfilePage"
                )
            )
          ),
          exact: true,
        },
        {
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
      ],
    },
    {
      path: "summary-notes",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/localAdmin/summaryNotes"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/download",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "profile-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/cmo/profileManagement"))
      ),
      // exact: true,
    },
    {
      path: "doctor-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/doctorManagement"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/viewDoctorProfilePage"
                )
            )
          ),
          exact: true,
        },
        {
          path: "view-changes/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import("pages/dashboard/cmo/doctorApproval/viewDoctorChanges")
            )
          ),
          exact: true,
        },
        {
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
      ],
    },
    {
      path: "financial",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/cmo/profileManagement"))
      ),
      exact: true,
      inners: [
        {
          path: "accounting-reports",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/cmo/financialManagment/accountingReports"
                )
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "user-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/userReports")
        )
      ),
    },
    {
      path: "robot-usage-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/robotReports")
        )
      ),
    },
    {
      path: "appointments-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/appointmentReports")
        )
      ),
    },
    {
      path: "financial-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/financialReports")
        )
      ),
    },
    {
      path: "robot-appointments/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/robotAppointments"))
      ),
    },
    { component: RedirectToDashboardAbs, exact: true, path: "help-center" },
    ...addInnerRoutes(DASHBOARD_GLOBAL_ROUTES, {
      routePath: "notifications",
      routes: [NEW_NOTIFICATION_ROUTE],
    }),
  ],
};

export default CMO_ROUTES;

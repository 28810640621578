import { ReactComponent as ServerErrorSVG } from "assets/images/500.svg";
import classes from "./styles.module.scss";

function ServerErrorIcon(props: { [key: string]: any }) {
  return (
    <ServerErrorSVG
      data-variant="bulk"
      {...props}
      className={classes.serverError}
    />
  );
}

export { ServerErrorIcon };

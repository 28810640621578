import { useQuery, UseQueryOptions } from "react-query";
import { IPagination } from "./usePagination";
import { IPaginationTableList } from "api/types/paginationType";
import { IResponse } from "api";
import { getMaxPage } from "utils/pagination";
import { getTablesData } from "utils/mappers";

/**
 * This hook will help u to fetch the data and convert it to `fixedData` type and update the maxPageSize.
 * @param queryFn query function to fetch the data
 * @param queryFnName query key: the name of the query function
 * @param params query function's parameters
 * @param pagination pagination object
 * @param maxPageUpdater `updateMaxPage` function to update the max page when the data is fetched successfully
 * @param options useQuery's options
 * @returns return query result of useQuery, fixedData and total number of data.
 */
export default function useTableQuery<T, U extends object>(
  queryFn: (params: U) => Promise<IResponse<IPaginationTableList<T>>>,
  queryFnName: string,
  params: U,
  pagination: IPagination,
  maxPageUpdater: (maxPage: number) => void,
  options?: Omit<
    UseQueryOptions<
      IResponse<IPaginationTableList<T>>,
      unknown,
      IResponse<IPaginationTableList<T>>,
      (string | U)[]
    >,
    "queryKey" | "queryFn"
  >
) {
  const { data, ...queryResult } = useQuery(
    [queryFnName, { ...params }],
    () => queryFn(params),
    {
      ...options,
      onSuccess(data) {
        const result = data?.data.data;
        maxPageUpdater(getMaxPage(result?.total, pagination.resultsPerPage));
        options?.onSuccess?.(data);
      },
      keepPreviousData: true,
    }
  );
  const fixedData = getTablesData(data, pagination);
  return { ...fixedData, ...queryResult };
}

import React from "react";
import { IField } from "./IField";
import FastField from "./field";
import Textarea, { ITextAreaDefaultProps } from "components/core/textarea";

type inputProps = Omit<ITextAreaDefaultProps, "onChange" | "value" | "name">;

interface IProps<T> extends IField<T, string>, inputProps {}
export function FastTextarea<T>({
  formik,
  onChange,
  onChangeAlongside,
  ...inputProps
}: IProps<T>) {
  return (
    <FastField
      onChangeValueMapper={(value: React.ChangeEvent<HTMLTextAreaElement>) =>
        value.target.value
      }
      {...{ formik, onChange, onChangeAlongside, ...inputProps }}
    >
      {(props) => (
        <Textarea
          {...inputProps}
          readOnly={inputProps.readOnly}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.validateOnBlur}
          placeholder={inputProps.placeholder || inputProps.label || ""}
          name={inputProps.fieldName}
        />
      )}
    </FastField>
  );
}

import { Redirect, useLocation } from "react-router";
import { useBaseUrl } from "../../../utils/route";

//? Redirect to dashboard
export function RedirectToDashboard() {
  const location = useLocation();

  return <Redirect to={`${location.pathname}dashboard`} />;
}

export function RedirectToDashboardAbs() {
  const base = useBaseUrl();

  return <Redirect to={`${base}dashboard`} />;
}

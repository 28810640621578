import clsx from "clsx";
import classes from "../../../dashboard/staff/healthRecords/styles.module.scss";
import { useHistory } from "react-router";
import { IShowAppointment } from "../../../../api/appointment";
import React, { useEffect, useState } from "react";
import useDownloadPdf from "../../../../hooks/useDownloadPdf";
import { notify } from "../../../../components/core/toast";
import Page from "../../../../components/layout/page";
import PrintNotif from "../../../../components/common/printNotif";
import DataCard from "../../../../components/common/dataCard";
import { humanizedDate, humanizedTime } from "../../../../utils/date";
import { IShowPatientFollowUp } from "../../../../api/healthRecord";
import ImageCard from "../../../../components/common/videoVisit/components/images/ImageCard";
import { CalendarBold, TickConsentIcon } from "../../../../components/icon";
import InformationCard from "../../../../components/common/informationCard";
import Switch from "../../../../components/core/switch";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getLinkData } from "../../../../api/share";
import Loader from "../../../../components/common/loader";
import { useTranslation } from "react-i18next";
import DoctorDetailCard from "../../../../components/common/doctorDetailCard";

const ShareShowFollowup = () => {
  const { t } = useTranslation("global");
  const history = useHistory();

  const id = useParams<{ id: string }>()?.id;
  if (!id) {
    history.push("/404");
  }
  const { data, isLoading, isError } = useQuery([getLinkData.name, id], () =>
    getLinkData(id)
  );
  const search = new URLSearchParams(data?.data?.data?.data);
  const encodedData = search.get("data");
  let d: undefined | IShowPatientFollowUp = undefined;
  try {
    d = JSON.parse(encodedData || "");
  } catch (e) {}
  const encodedAppt = search.get("appt");
  if (!encodedAppt) {
  }
  let appt: IShowAppointment | undefined = undefined;

  try {
    appt = JSON.parse(encodedAppt || "");
  } catch (e) {}

  const [printOpen, setPrintOpen] = useState(false);

  const printWebpage = useDownloadPdf();

  useEffect(() => {
    if (d && !isLoading) {
      notify.info(t("pdf_wait"));
      printWebpage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, isLoading]);

  return (
    <Page
      title={t("health_record.followup.name")}
      type={"inner"}
      backTo={"pop"}
      noButtons={true}
    >
      <Loader isLoading={isLoading} isError={isError}>
        <div className={clsx(classes.modal)}>
          <DataCard
            variant={"patientFollowUp"}
            avatar={appt?.local_doctor?.avatar}
            tableData={{
              appt_id: appt?.appt_id || "- - -",
              appt_type: appt?.appt_type || "- - -",
              visit_date: humanizedDate(appt?.start_date || "") || "- - -",
              visit_time: humanizedTime(appt?.start_date || "") || "- - -",
              timezone: appt?.local_doctor?.timezone?.name || "- - -",
              patient_name:
                appt?.patient?.first_name + " " + appt?.patient?.last_name ||
                "- - -",
              birth: humanizedDate(appt?.patient?.birthday || "") || "- - -",
              hf_name: appt?.facility?.name || "- - -",
              status: {
                label: d?.followup?.status
                  ? t("health_record.performed")
                  : t("health_record.un_performed"),
                value: d?.followup?.status,
              },
            }}
            id={appt?.local_doctor?.user_id || ""}
            name={
              appt?.local_doctor?.first_name +
              " " +
              appt?.local_doctor?.last_name
            }
            specialty={appt?.local_doctor?.speciality || ""}
          />
          <DoctorDetailCard
            custom_title={t("health_record.followup.modal.title")}
            gray_back
            language={d?.doctor?.language[0]?.language}
            name={
              appt?.local_doctor?.first_name +
              " " +
              appt?.local_doctor?.last_name
            }
            id={appt?.local_doctor?.user_id || ""}
            gender={d?.doctor?.gender || ""}
            speciality={appt?.local_doctor?.speciality || ""}
          />
          <InformationCard
            icon={<CalendarBold />}
            title={t("health_record.followup.modal.date")}
            parentClassName={clsx(classes.modal__back)}
          >
            <div className={clsx(classes.modal__text)}>
              {d?.followup?.interval || ""}
            </div>
          </InformationCard>
          <InformationCard
            icon={<TickConsentIcon />}
            title={t("health_record.followup.status")}
            parentClassName={clsx(classes.modal__back)}
          >
            <div className={clsx(classes.healthRecord__row)}>
              <Switch checked={d?.followup?.status || false} />
              <span className={clsx(classes.modal__text)}>
                {d?.followup?.status
                  ? t("health_record.performed")
                  : t("health_record.un_performed")}
              </span>
            </div>
          </InformationCard>
          <div className={classes.modal__sig}>
            <ImageCard imgUrl={d?.doctor?.signature || ""} noZoom />
          </div>
        </div>
      </Loader>
      <PrintNotif
        open={printOpen}
        setOpen={setPrintOpen}
        name={t("health_record.followup.name")}
      />
    </Page>
  );
};

export default ShareShowFollowup;

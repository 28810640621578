const DOCTOR_FIELDS: { [key: string]: string[] } = {
  "1": [
    "first_name",
    "last_name",
    "gender",
    "birthday",
    "cmoNumber",
    "zip_code",
    "doctor_type",
  ],
  "2": [
    "mobile",
    "phone",
    "email",
    "website",
    "speciality",
    "licenses",
    "title",
  ],
  "3": ["facility_id", "departments"],
  "4": ["languages"],
  "5": ["city_id", "address"],
};

const STAFF_FIELDS: { [key: string]: string[] } = {
  "1": [
    "first_name",
    "last_name",
    "gender",
    "birthday",
    "employee_number",
    "zip_code",
    "mobile",
    "phone",
    "email",
  ],
  "2": ["facility_id", "departments"],
  "3": ["city_id", "address"],
};

const PATIENT_FIELDS: { [key: string]: string[] } = {
  "1": [
    "first_name",
    "last_name",
    "gender",
    "birthday",
    "national_id",
    "mrn_number",
    "mobile",
    "phone",
    "email",
  ],
  "2": ["facility_id", "departments"],
  "3": ["languages"],
  "4": ["city_id", "address"],
};

const STEP_FIELDS: { [key: string]: { [key: string]: string[] } } = {
  doctor: DOCTOR_FIELDS,
  staff: STAFF_FIELDS,
  patient: PATIENT_FIELDS,
};

export const getStepNumByFieldName = (fieldName: string[], role: string) => {
  let stepNum = 0;
  const fields = STEP_FIELDS[role];
  for (let key in fields) {
    const isFieldExist = fields[key].some((field) => fieldName.includes(field));
    if (isFieldExist) {
      stepNum = Number(key);
    }
  }
  return stepNum;
};

import { MaximizeIcon, MuteIcon, UnmuteIcon } from "../../../../icon";
import clsx from "clsx";
import classes from "./styles.module.scss";
import React, {
  LegacyRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IVonageStream } from "interfaces/vonageStream";
import { VideoVisitContext } from "../..";
import { toast } from "react-toastify";
import MinimizedVideoVisit from "../minimizedVideoVisit";
import { TRoleName } from "../../../../../api/user";

export interface IVideoComponent {
  title: string;
  full: string;
  onFullscreen?: () => any;
  onDefaultScreen?: () => any;
  className?: string;
  videoObj: HTMLVideoElement | HTMLObjectElement;
  screenShareStream: IVonageStream | undefined;
  streamKey: string;
  role?: "Doctor" | "Staff" | "Patient";
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onBreak?: () => void;
  stream: OT.Stream | undefined;
  reference: LegacyRef<HTMLDivElement> | undefined;
  onUpClicked?: () => void;
  onDownClicked?: () => void;
  onLeftClicked?: () => void;
  onRightClicked?: () => void;
  streamArr: IVonageStream[];
  enteredRole: TRoleName | "guest" | undefined;
  isRobot?: boolean;
}

// video conference component for video visit
const VideoComponent = ({
  title,
  full,
  onFullscreen,
  onDefaultScreen,
  className,
  videoObj,
  screenShareStream,
  streamKey,
  role,
  onZoomOut,
  onZoomIn,
  onBreak,
  stream,
  reference,
  onUpClicked,
  onDownClicked,
  onLeftClicked,
  onRightClicked,
  streamArr,
  enteredRole,
  isRobot,
}: IVideoComponent) => {
  // TODO ADD MULTI LANGUAGE

  const defaultStreamIndex = useMemo(() => {
    if (role === "Staff" && enteredRole === "doctor") {
      const index = streamArr?.findIndex(
        (s) => s?.role?.toLowerCase() === "staff" && s?.id !== streamKey
      );

      return index > -1 ? index : undefined;
    }

    return undefined;
  }, [enteredRole, role, streamArr, streamKey]);

  const ptzStreamKey = useMemo(() => {
    return streamArr?.find((stream) => {
      return stream?.type !== "screen" && stream?.is_robot;
    });
  }, [streamArr])?.id;

  // mute or unmute state
  // const [mute, setMute] = useState(false);
  // fullscreen state
  const [fullScreen, setFullScreen] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [hide, setHide] = useState(true);
  const ctx = useContext(VideoVisitContext);

  useEffect(() => {
    videoObj.style.height = "100%";
    videoObj.style.width = "100%";
    videoObj.style.position = "absolute";
    videoObj.style.left = "0";
    videoObj.style.right = "0";
    videoObj.style.top = "0";
    videoObj.style.margin = "auto";
    console.log(videoObj);

    document.getElementById(streamKey)!.appendChild(videoObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (screenShareStream) {
      screenShareStream.videoObj.style.height = "100%";
      screenShareStream.videoObj.style.position = "absolute";
      screenShareStream.videoObj.style.left = "0";
      screenShareStream.videoObj.style.right = "0";
      screenShareStream.videoObj.style.margin = "auto";

      document
        .getElementById("screen")
        ?.appendChild(screenShareStream.videoObj);
    }
  }, [screenShareStream]);

  // trigger onMute or UnMute function based on mute state update
  // useEffect(() => {
  //   ctx.onMicMute(mute);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mute]);

  // trigger onFullscreen or onDefaultScreen based on fullscreen state update
  useEffect(() => {
    if (fullScreen) {
      if (onFullscreen) {
        onFullscreen();
        setMinimized(true);
      }
    } else if (minimized) {
      if (onDefaultScreen) {
        onDefaultScreen();
        setMinimized(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreen, onDefaultScreen, onFullscreen]);
  const zoomPos = (pos: number) => {
    return {
      pos: Math.abs(pos),
      dir: pos > 0 ? "pos" : "neg",
    };
  };

  let timer: NodeJS.Timeout | null = null;

  const { session, infoMessage, staffState } = useContext(VideoVisitContext);

  // mute or unmute state for participant
  const [mute, setMute] = useState(stream ? !stream.hasAudio : false);

  useEffect(() => {
    if (stream) {
      if (infoMessage?.from.connectionId === stream.connection.connectionId) {
        if (infoMessage?.data.split(":")[1] === "camera") {
        } else {
          setMute(infoMessage?.data.split(":")[2] === "close");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoMessage]);

  // render component
  return (
    <div
      className={clsx(
        classes.parent,
        !fullScreen && className,
        fullScreen && classes.fullH,
        full !== "" && full !== streamKey && classes.minimize
      )}
      onClick={() => {
        if (!hide) {
          if (timer) {
            clearTimeout(timer);
            timer = setTimeout(() => setHide(true), 20000);
          }
        }
      }}
      onDoubleClick={(e) => {
        if (!fullScreen) return;

        const currentUserRole = window.localStorage.getItem("role");
        // Only doctor should be able to remotely control PTZ.
        // We do not permit other roles.
        if (currentUserRole !== "doctor") {
          // WARN: even though we're handling this in the frontend, still there
          // should be some kind of permission on the other side of the socket
          // connection.
          return;
        }

        if (!(streamKey === ptzStreamKey && staffState === "ptz-open")) {
          return;
        }

        // NOTE: I have no idea what this part is doing and why but since
        // currently the requirements says that only doctors can control PTZ,
        // then this part is redundant but I'm going to keep it commented out
        // in case something happens or we need it again. feel free to delete
        // this chunk after system is stable.
        // if (role === "Staff") {
        //   setHide(false);
        //   timer = setTimeout(() => setHide(true), 20000);
        // }

        const wGain = () => {
          if (e.currentTarget.offsetWidth < 450) return 0.8;
          if (
            e.currentTarget.offsetWidth > 450 &&
            e.currentTarget.offsetWidth < 550
          )
            return 0.6;
          if (
            e.currentTarget.offsetWidth > 550 &&
            e.currentTarget.offsetWidth < 650
          )
            return 0.5;
          if (
            e.currentTarget.offsetWidth > 650 &&
            e.currentTarget.offsetWidth < 800
          )
            return 0.4;

          if (
            e.currentTarget.offsetWidth > 1400 &&
            e.currentTarget.offsetWidth < 1500
          )
            return 0.5;
          if (
            e.currentTarget.offsetWidth > 1400 &&
            e.currentTarget.offsetWidth < 1800
          )
            return 0.4;
          if (
            e.currentTarget.offsetWidth > 1800 &&
            e.currentTarget.offsetWidth < 2000
          )
            return 0.4;
          if (e.currentTarget.offsetWidth > 2000) return 0.3;
          return 0.5;
        };
        const wDiff = (
          ((e.clientX -
            e.currentTarget.offsetWidth / 2 -
            e.currentTarget.getBoundingClientRect().x) /
            1000) *
          38 *
          wGain()
        ).toFixed(0);
        const hDiff = (
          ((e.currentTarget.getBoundingClientRect().y +
            e.currentTarget.offsetHeight / 2 -
            e.clientY) /
            e.currentTarget.offsetHeight) *
          14
        ).toFixed(0);
        ctx.session?.signal(
          {
            type: "dc-movement",
            data: JSON.stringify([
              zoomPos(parseInt(hDiff, 10)),
              zoomPos(parseInt(wDiff, 10)),
            ]),
          },
          () => {}
        );
      }}
      // style={{ backgroundImage: `url(${img})` }}
    >
      <div
        // style={{ backgroundImage: `url(${img})` }}
        className={clsx(
          classes.videoContainer,
          role === "Staff" && fullScreen && classes.cover
        )}
        id={streamKey}
        ref={role === "Staff" ? reference : undefined}
      >
        {title === "You" && (
          <div id="screen" className={classes.screenShare}></div>
        )}
        <div className={clsx(classes.parent__topBar)}>
          <div className={clsx(classes.texts)}>
            {role && (
              <div
                className={clsx(classes.title)}
                style={
                  role
                    ? {
                        backgroundColor:
                          role === "Doctor"
                            ? "#31E981"
                            : role === "Staff"
                            ? "#98DFEA"
                            : "#21c864",
                      }
                    : {}
                }
              >
                {role === "Staff" && isRobot ? "Robot" : role}
              </div>
            )}
            {role !== "Staff" && (
              <div className={clsx(classes.title)}>{title}</div>
            )}
          </div>
          <div className={clsx(classes.parent__sideTop)}>
            {title === "You" && (
              <div
                onClick={() => ctx.onMicMute(!ctx.micMute)}
                className={clsx(
                  classes.iconPlace,
                  ctx.micMute && classes.danger
                )}
              >
                {ctx.micMute ? (
                  <MuteIcon className={clsx(classes.icons)} />
                ) : (
                  <UnmuteIcon className={clsx(classes.icons)} />
                )}
              </div>
            )}
            {title !== "You" &&
              session!.capabilities.forceDisconnect === 1 &&
              stream?.videoType !== "screen" && (
                <div
                  onClick={() => {
                    session!.signal(
                      {
                        to: stream!.connection,
                        data: `mic:${mute ? "open" : "close"}`,
                      },
                      (e) => {
                        if (!e)
                          toast.success(
                            "user's microphone modified successfully"
                          );
                      }
                    );
                    setMute(!mute);
                  }}
                  className={clsx(classes.iconPlace, mute && classes.danger)}
                >
                  {mute ? (
                    <MuteIcon className={clsx(classes.icons)} />
                  ) : (
                    <UnmuteIcon className={clsx(classes.icons)} />
                  )}
                </div>
              )}
            <div className={clsx(classes.column)}>
              <div
                className={clsx(
                  classes.iconPlace,
                  fullScreen && classes.primary
                )}
                onClick={() => setFullScreen((prevState) => !prevState)}
              >
                <MaximizeIcon className={clsx(classes.icons)} />
              </div>
            </div>
          </div>
        </div>
        {/*  <div
          className={clsx(
            classes.parent__sideBottm,
            !fullScreen && classes.no,
            role !== "Staff" && classes.no,
            ctx.staffState === "ptz-close" && classes.no
          )}
        >
          <div className={clsx(classes.parent__ic, classes.parent__centrIc)}>
            <div onMouseDown={onLeftClicked} onMouseUp={onBreak}>
              <ArrowLeftBold />
            </div>
            <div onMouseDown={onRightClicked} onMouseUp={onBreak}>
              <ArrowRightBold />
            </div>
            <div onMouseDown={onUpClicked} onMouseUp={onBreak}>
              <ArrowUpBold />
            </div>
            <div onMouseDown={onDownClicked} onMouseUp={onBreak}>
              <ArrowDownBold />
            </div>
          </div>
          <div className={clsx(classes.parent__zoom)}>
            <div onMouseDown={onZoomIn} onMouseUp={onBreak}>
              <ZoomInBold />
            </div>
            <div onMouseDown={onZoomOut} onMouseUp={onBreak}>
              <ZoomOutBold />
            </div>
          </div>
        </div>*/}
      </div>
      {fullScreen && role === "Staff" && enteredRole === "doctor" && (
        <MinimizedVideoVisit
          streams={streamArr}
          doctor
          streamId={streamKey}
          defaultStreamIndex={defaultStreamIndex}
        />
      )}
    </div>
  );
};

export default VideoComponent;

import { Dispatch, SetStateAction, useState } from "react";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { ArrowRightLinear, RefreshLinear } from "../../../../icon";
import SideBartTop from "../sideBartTop";
import AudioPlayer from "./AudioPlayer";
import { useQueryClient } from "react-query";
import { getVideoVisitStoredResult } from "../../../../../api/videoVisit";
import { humanizedDate } from "../../../../../utils/date";
import { IDoctorAppointment } from "../../../../../api/appointment";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Loader from "../../../loader";
import ImagesItem, { IImagesItemProps } from "../images/ImagesItem";
import { useTranslation } from "react-i18next";
import Button from "../../../../core/button";
import { useHistory } from "react-router";

export interface IMedicalItem {
  title: string;
  items: string[];
  data: { date: string[] };
  onRequest: (stethoscopeMode: "bell" | "diaphragm") => void;
}

export interface IMedicalExaminationProps {
  exams: IMedicalItem[];
  onClose: () => void;
  appointment?: IDoctorAppointment;
  isLoading?: boolean;
  ecgData: IImagesItemProps[];
  ultraSoundData: IImagesItemProps[];
  spirometerData: IImagesItemProps[];
  setMinimized: Dispatch<SetStateAction<boolean>>;
}

const MedicalExamination = ({
  exams,
  onClose,
  appointment,
  isLoading,
  ecgData,
  ultraSoundData,
  spirometerData,
  setMinimized,
}: IMedicalExaminationProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  const history = useHistory();

  return (
    <div className={clsx(classes.medical)}>
      <SideBartTop
        onClick={() => onClose()}
        title={t("medical_examination")}
        text={
          appointment?.patient?.name +
          ` ,${t("dob")}: ` +
          humanizedDate(appointment?.patient?.birthday || "")
        }
      />
      <div className={clsx(classes.medical__inside)}>
        {exams?.map((exam, index) => {
          return (
            <Item
              title={exam.title}
              items={exam.items}
              data={exam.data}
              key={index}
              isLoading={isLoading}
              onRequest={exam.onRequest}
            />
          );
        })}
        {ecgData?.map((item, index) => {
          return (
            <ImagesItem
              {...item}
              key={index}
              child={
                <Button
                  onClick={() => {
                    setMinimized(true);
                    history.push({
                      pathname: `/panel/doctor/stored-results/${appointment?.patient.id}/${appointment?.visit_for}`,
                    });
                  }}
                >
                  {t("view_more")}
                </Button>
              }
            />
          );
        })}
        {ultraSoundData?.map((item, index) => {
          return (
            <ImagesItem
              {...item}
              key={index}
              video
              child={
                <Button
                  onClick={() => {
                    setMinimized(true);
                    history.push({
                      pathname: `/panel/doctor/stored-results/${appointment?.patient.id}/${appointment?.visit_for}`,
                    });
                  }}
                >
                  {t("view_more")}
                </Button>
              }
            />
          );
        })}
        {spirometerData?.map((item, index) => {
          return (
            <ImagesItem
              {...item}
              key={index}
              child={
                <Button
                  onClick={() => {
                    setMinimized(true);
                    history.push({
                      pathname: `/panel/doctor/stored-results/${appointment?.patient.id}/${appointment?.visit_for}`,
                    });
                  }}
                >
                  {t("view_more")}
                </Button>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

const Item = ({
  items,
  title,
  data,
  isLoading,
  onRequest,
}: IMedicalItem & { isLoading?: boolean }) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  // data open or close state
  const [open, setOpen] = useState(false);

  const client = useQueryClient();

  // render component
  return (
    <Accordion
      className={clsx(classes.item)}
      expanded={open}
      onChange={(event, expanded) => {
        setOpen(expanded);
      }}
    >
      <AccordionSummary>
        <div className={clsx(classes.item__head)}>
          <div className={clsx(classes.item__title)}>{title}</div>
          <div className={clsx(classes.item__icon, open && classes.rotate)}>
            {<ArrowRightLinear />}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={clsx(classes.item__content)}>
        <div
          className={clsx(classes.item__contentInside)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={clsx(classes.item__place)}>
            <div className={clsx(classes.medical__result)}>
              <div className={clsx(classes.medical__result)}>
                <div className={clsx(classes.medical__top)}>
                  <div className={clsx(classes.medical__title)}>
                    {t("last_result")}:
                  </div>
                  <div
                    className={clsx(
                      classes.medical__resultText,
                      classes.item__refresh
                    )}
                    onClick={() =>
                      client.invalidateQueries([getVideoVisitStoredResult.name])
                    }
                  >
                    <RefreshLinear />
                    <div>{t("refresh")}</div>
                  </div>
                </div>
              </div>
              <Loader isLoading={isLoading || false}>
                {items?.map((item, index) => {
                  return (
                    <>
                      <div
                        className={clsx(
                          classes.medical__pastData,
                          classes.medical__top
                        )}
                      >
                        <div>{data.date[index]}</div>
                        <div className={clsx(classes.medical__resultText)}>
                          {""}
                        </div>
                      </div>

                      <AudioPlayer src={item} />
                    </>
                  );
                })}
              </Loader>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default MedicalExamination;

import PhoneInput, {
  ICountryItem,
  IDefaultProps,
  IPhoneNumber,
} from "components/core/phoneInput";
import { IField } from "./IField";
import FastField from "./field";
import countries from "constants/countries.json";
import { PhoneBold } from "components/icon";
import React, { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { getCountryPhoneCodes } from "api/dropdown";
import { getDropdownValue } from "utils/dropdowns";
import { countryPhoneKey } from "../../utils/keyGenerator/keys";

type inputProps = Omit<
  IDefaultProps,
  "onChange" | "value" | "countries" | "isLoading"
>;
interface IProps<T> extends IField<T, IPhoneNumber>, inputProps {
  /**
   * The field name of the country that this field is dependent on it.
   *
   * without knowing the country we can not fetch the code of that country, so the this field depends on the country.
   * if you don't pass the countryFieldName, by changing the country value, the code will not synchronize with that country.
   */
  countryFieldName?: keyof T & string;
}

export function FastPhoneInput<T>({
  formik,
  fieldName,
  label,
  onChange,
  onChangeAlongside,
  countryFieldName,
  disabled,
  placeholder,
  ...inputProps
}: IProps<T>) {
  // get the country value from formik values and extract its key
  const { option: country, key: countryId } = getDropdownValue(
    formik,
    countryFieldName
  );

  const value = formik.values[fieldName] as IPhoneNumber | undefined;
  const isFlagCanChange = !!value && value.number === "";

  const { data, isLoading, isError } = useQuery(
    [countryPhoneKey, countryId],
    () => getCountryPhoneCodes(Number(countryId)),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!countryId && !!countryFieldName,
    }
  );
  const COUNTRIES: ICountryItem[] | undefined = useMemo(
    () =>
      data?.data.data?.map((item) => ({
        flag: undefined,
        code: item.name ? item.name : "",
        name: "",
      })),
    [data]
  );

  useEffect(() => {
    const number = value?.number || "";
    if (COUNTRIES && COUNTRIES.length > 0 && number === "") {
      formik.setFieldValue(fieldName, {
        code: COUNTRIES[0].code,
        number: number,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [COUNTRIES, country]);

  const memo_countries = React.useMemo(
    () =>
      countries.map((record) => ({
        code: record.dialCode,
        flag: record.flag,
        name: record.name,
      })),
    []
  );
  return (
    <FastField
      onChangeValueMapper={(country: ICountryItem, number: string) => {
        const code = country ? country.code : "";
        return { code: code, number };
      }}
      {...{
        formik,
        fieldName,
        label,
        onChange,
        onChangeAlongside,
        ...inputProps,
      }}
    >
      {(props) => (
        <PhoneInput
          icon={PhoneBold}
          {...inputProps}
          value={props.value as IPhoneNumber}
          onChange={props.onChange}
          disabled={disabled || (isFlagCanChange && (isLoading || isError))}
          placeholder={placeholder ? placeholder : label || ""}
          name={fieldName}
          countries={memo_countries}
          isLoading={isFlagCanChange && isLoading}
          isError={isError}
          onBlur={props.validateOnBlur}
        />
      )}
    </FastField>
  );
}

import { ReactComponent as BackIcon } from "assets/icons/bold/arrow-circle-left.svg";
import { useHistory } from "react-router";
import classes from "../styles.module.scss";
import { useTranslation } from "react-i18next";
import HoverToolbox from "../../../common/hoverToolbox";

interface IBackGroupProps {
  backTo: "pop" | string;
}

function BackGroup({ backTo }: IBackGroupProps) {
  const history = useHistory();
  const { t } = useTranslation("global");

  const handleClick = () => {
    if (backTo === "pop") {
      // pop the browser history
      history.goBack();
    } else {
      history.push(backTo);
    }
  };
  return (
    <HoverToolbox text={t("back")}>
      <button className={classes.mainIconContainer} onClick={handleClick}>
        <BackIcon className={classes.mainIcon} />
      </button>
    </HoverToolbox>
  );
}

export default BackGroup;

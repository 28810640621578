import { useFormik } from "formik";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { useEffect, useState } from "react";
import List from "components/common/list";
import ListItem from "components/common/list/components/listItem";
import yup, { requiredObj } from "utils/yupExtended";
import { IRegistrationStepProps } from "./registrationLayout";
import Grid from "components/core/Grid";
import SecondaryButton from "../../../../../components/core/secondaryButton";
import { notify } from "components/core/toast";
import { Language, LanguageFluency } from "components/dropdown";
import { useTranslation } from "react-i18next";
import { formSubmission } from "utils/form";

interface ILanguage {
  language: { key: string; value: string };
  language_fluency: { key: string; value: string };
}

const ThirdStepValidation = yup.object().shape({
  language: yup.object().dropdown({ errorMessage: requiredObj("language") }),
  language_fluency: yup
    .object()
    .dropdown({ errorMessage: requiredObj("language_fluency") }),
});

const RegistrationFourthPage = ({
  formik,
  onSetAlert,
  backButton,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");

  const getDefaultLangs = () => {
    return (
      formik.values?.languages.map((lang) => ({
        language: { key: lang.language, value: lang.language },
        language_fluency: {
          key: lang.language_fluency,
          value: lang.language_fluency,
        },
      })) || []
    );
  };
  const [languages, setLanguages] = useState<ILanguage[]>(() =>
    getDefaultLangs()
  );

  useEffect(() => {
    const languagesFormatted = languages.map((lang) => ({
      language: lang.language.value,
      language_fluency: lang.language_fluency.key,
    }));

    // console.log(languages);

    formik.setFieldValue("languages", languagesFormatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages]);

  const langFormik = useFormik<ILanguage>({
    initialValues: {
      language: { key: "English", value: "English" }, //TODO ADD MULTI LANGUAGE
      language_fluency: { key: "", value: "" },
    },
    validationSchema: ThirdStepValidation,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    // enableReinitialize: true,
    onSubmit: () => {
      const duplicateIndex = languages.findIndex(
        (lang) => lang.language.value === langFormik.values.language.value
      );

      duplicateIndex < 0
        ? setLanguages((prevLangs) => [
            ...prevLangs,
            {
              language: langFormik.values.language,
              language_fluency: langFormik.values.language_fluency,
            },
          ])
        : notify.error(t("duplicate_language", { index: duplicateIndex + 1 }));

      langFormik.resetForm();
      langFormik.setValues({
        language: { key: "", value: "" },
        language_fluency: { key: "", value: "" },
      });
    },
  });

  // const handleSubmit = () => {
  //   const languagesFormatted = languages.map((lang) => ({
  //     language: lang.language.key,
  //     language_fluency: lang.language_fluency.key,
  //   }));
  //   if (languages.length > 0) {
  //     setData((prevData: any) => ({
  //       ...prevData,
  //       languages: languagesFormatted,
  //     }));
  //     setStep(5);
  //   }
  // };

  const addHandler = () => {
    langFormik.validateForm().then((errors) => {
      if (!Object.keys(errors).length) {
        const duplicateIndex = languages.findIndex(
          (lang) => lang.language.value === langFormik.values.language.value
        );

        duplicateIndex < 0
          ? setLanguages((prevLangs) => [
              ...prevLangs,
              {
                language: langFormik.values.language,
                language_fluency: langFormik.values.language_fluency,
              },
            ])
          : notify.error(
              t("duplicate_language", { index: duplicateIndex + 1 })
            );

        langFormik.resetForm();
        langFormik.setFieldValue("language", { key: "", value: "" });
      }
    });
  };

  return (
    <form autoComplete="off" onSubmit={(e) => formSubmission(e, formik)}>
      <Grid>
        <Language
          formik={langFormik}
          fieldName="language"
          name={langFormik.values.language.value}
        />

        <LanguageFluency formik={langFormik} fieldName="language_fluency" />

        <Grid.Button>
          <SecondaryButton onClick={addHandler}>{t("add")}</SecondaryButton>
        </Grid.Button>
      </Grid>
      <List className="mt-4">
        {languages.map((language, idx) => (
          <ListItem
            className={"w-100 overflow-auto"}
            key={idx}
            numberLabel={idx + 1}
            data={{
              title: language.language.value,
              columns: [
                {
                  label: t("language_fluency") + ":",
                  value: language.language_fluency.value,
                },
              ],
            }}
            deletable
            onDeleteItem={() => {
              setLanguages(
                languages.filter((lang) => lang.language !== language.language)
              );
            }}
          />
        ))}
      </List>

      <div className={classes.buttonContainer}>
        {backButton}
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => onSetAlert(true)}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            formik.submitForm();
            if (!formik.values.languages.length) {
              notify.error(t("must_add_language"));
            }
          }}
        >
          {t("continue")}
        </Button>
      </div>
    </form>
  );
};

export default RegistrationFourthPage;

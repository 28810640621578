import IDropdown from "../formikFields/IDropdown";
import { FastDropdown } from "../formikFields";
import { MessagesBold } from "components/icon";
import { useTranslation } from "react-i18next";
import { useLanguagesOption } from "../../utils/options";

interface IProps<T> extends IDropdown<T> {}

export function Language<T>(props: IProps<T>) {
  const { t } = useTranslation("form");
  const { OPTIONS } = useLanguagesOption();
  // TODO CHECK

  //   const { data, isError, isLoading } = useQuery(getLanguage.name, getLanguage, {
  //     cacheTime: Infinity,
  //     staleTime: Infinity,
  //   });

  //   const options = useGenericDropdownOptions(data?.data.data, (item) => ({
  //     key: item.id.code,
  //     value: item.name.name,
  //   }));

  return (
    <FastDropdown
      label={t("language")}
      options={OPTIONS}
      isError={false}
      isLoading={false}
      icon={MessagesBold}
      {...props}
    />
  );
}

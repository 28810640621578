import { useQuery } from "react-query";
import { getDeviceTypeDevices } from "api/dropdown";
import { CpuBold } from "components/icon";
import { getDropdownKey } from "utils/dropdowns";
import { FastDropdown } from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import { useTranslation } from "react-i18next";
import { deviceTypeDevicesKey } from "features/medicalDevice";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the `Device Type` that this `Device Name` is dependent on it.
   *
   * without knowing the `Device Type` we can not fetch the`Device Name`.
   */
  deviceTypeFieldName: keyof T & string;
}

export function DeviceID<T>({
  formik,
  deviceTypeFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the country value from formik values and extract its key
  const deviceTypeKey = getDropdownKey(formik, deviceTypeFieldName);

  // fetch data
  const { data, isLoading, isError } = useQuery(
    [deviceTypeDevicesKey, deviceTypeKey],
    () => getDeviceTypeDevices(Number(deviceTypeKey)),
    {
      enabled: !!deviceTypeKey,
    }
  );

  return (
    <FastDropdown
      label={t("medical_device_id")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!deviceTypeKey || disabled}
      icon={CpuBold}
      {...props}
    />
  );
}

import clsx from "clsx";
import classes from "./styles.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { IShowSummaryNote } from "../../../api/doctor";
import { useEffect, useState } from "react";
import { notify } from "../../../components/core/toast";
import Page from "../../../components/layout/page";
import ShowSummaryNote from "../../dashboard/doctor/showSummaryNote";
import { humanizedDate } from "../../../utils/date";
import {
  getBloodPressureCondition,
  getBloodSugarCondition,
  getHeartRateCondition,
  getOximeterCondition,
  getTemperatureCondition,
} from "../../../utils/ranges/conditions";
import { getReason } from "../../../utils/convert";
import ImageCard from "../../../components/common/videoVisit/components/images/ImageCard";
import PrintNotif from "../../../components/common/printNotif";
import useDownloadPdf from "../../../hooks/useDownloadPdf";
import { useQuery } from "react-query";
import { getLinkData } from "../../../api/share";
import Loader from "../../../components/common/loader";
import { getLastArrayItem } from "../../../utils/array";
import { useTranslation } from "react-i18next";

const SharePastVisitSummaryNote = () => {
  const { t } = useTranslation("global");

  const printWebpage = useDownloadPdf();
  const history = useHistory();

  const id = useParams<{ id: string }>()?.id;
  if (!id) {
    history.push("/404");
  }
  const { data, isLoading, isError } = useQuery([getLinkData.name, id], () =>
    getLinkData(id)
  );
  const encodedData = new URLSearchParams(data?.data?.data?.data).get("data");
  // console.log("encodedData", encodedData);
  let d: undefined | IShowSummaryNote = undefined;

  try {
    console.log(encodedData || "");
    d = JSON.parse(encodedData || "") as IShowSummaryNote;
  } catch (e) {}

  const [printOpen, setPrintOpen] = useState(false);

  useEffect(() => {
    if (encodedData && !isLoading) {
      notify.info(t("pdf_wait"));
      printWebpage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodedData, isLoading]);

  return (
    <Page title={t("pvs.title")} type={"inner"} backTo={"pop"} noButtons={true}>
      <Loader isLoading={isLoading} isError={isError}>
        <ShowSummaryNote
          constitution={d?.summary_note?.constitution || []}
          comment={
            d?.summary_note?.past_medical_history[0]?.value?.comment || "- - -"
          }
          reason={d?.vital_sign?.reason || ""}
          appt_type={d?.summary_note?.appointment?.type || ""}
          appt_id={
            d?.summary_note?.appointment?.appointment_id?.toString() || "-"
          }
          appt_date={d?.summary_note?.appointment?.date || ""}
          appt_time={d?.summary_note?.appointment?.date || ""}
          local_doctor_name={d?.summary_note?.local_doctor?.name || "-"}
          local_doctor_id={d?.summary_note?.local_doctor?.pid || "-"}
          local_doctor_gender={d?.summary_note?.local_doctor?.gender || ""}
          local_doctor_speciality={
            d?.summary_note?.local_doctor?.speciality || "-"
          }
          international_doctor_name={
            d?.summary_note?.international_doctor?.name || "-"
          }
          international_doctor_id={
            d?.summary_note?.international_doctor?.pid || "-"
          }
          international_doctor_gender={
            d?.summary_note?.international_doctor?.gender || ""
          }
          international_doctor_speciality={
            d?.summary_note?.international_doctor?.speciality || "-"
          }
          robot_name={d?.summary_note?.robot?.name || "- - -"}
          robot_id={d?.summary_note?.robot?.robot_id?.toString() || "-"}
          robot_type={d?.summary_note?.robot?.type || "-"}
          assigned_devices={d?.summary_note?.robot?.devices || []}
          patient_name={d?.summary_note?.patient?.name || "-"}
          patient_id={d?.summary_note?.patient?.pid?.toString() || "-"}
          patient_gender={d?.summary_note?.patient.gender || ""}
          patient_birth={humanizedDate(
            d?.summary_note?.patient?.birthday || "-"
          )}
          visit_for={d?.summary_note?.visit_for || ""}
          name_consent={true}
          birth_consent={true}
          language_consent={true}
          visit_consent={true}
          language={d?.vital_sign?.lang || "-"}
          tele_health={""}
          medications={
            d?.summary_note?.past_medical_history[1]?.value?.medicines || []
          }
          allergies={
            d?.summary_note?.past_medical_history[1]?.value?.allergies || []
          }
          allergic_reactions={
            d?.summary_note?.past_medical_history[1]?.value
              ?.allergic_reactions || []
          }
          food_allergies={
            d?.summary_note?.past_medical_history[1]?.value?.food_allergic || []
          }
          other_allergies={
            d?.summary_note?.past_medical_history[1]?.value?.other_allergic ||
            []
          }
          illnesses={
            d?.summary_note?.past_medical_history[0]?.value?.illnesses || []
          }
          hospitalized={
            d?.summary_note?.past_medical_history[0]?.value?.hospitalized ||
            false
          }
          hospitalized_reasons={
            d?.summary_note?.past_medical_history[0]?.value
              ?.hospitalized_reasons || []
          }
          had_surgery={
            d?.summary_note?.past_medical_history[0]?.value?.past_surgery ||
            false
          }
          surgeries={
            d?.summary_note?.past_medical_history[0]?.value
              ?.past_surgery_types || []
          }
          blood_sugar={{
            data:
              d?.vital_sign?.blood_sugar?.map((i) => i?.value?.glucose) || [],
            dates: d?.vital_sign?.blood_sugar?.map((i) => i?.date) || [],
            number:
              getLastArrayItem(d?.vital_sign?.blood_sugar)?.value?.glucose || 0,
            condition: getBloodSugarCondition(
              getLastArrayItem(d?.vital_sign?.blood_sugar)?.value?.glucose
            ),
          }}
          heart_rate={{
            data:
              d?.vital_sign?.blood_pressure?.map((i) => i?.value?.heart_rate) ||
              [],
            dates: d?.vital_sign?.blood_pressure?.map((i) => i?.date) || [],
            number:
              getLastArrayItem(d?.vital_sign?.blood_pressure)?.value
                ?.heart_rate || 0,
            condition: getHeartRateCondition(
              getLastArrayItem(d?.vital_sign?.blood_pressure)?.value?.heart_rate
            ),
          }}
          blood_pressure={{
            data:
              d?.vital_sign?.blood_pressure?.map((i) => i?.value?.diastolic) ||
              [],
            dates: d?.vital_sign?.blood_pressure?.map((i) => i?.date) || [],
            number: getLastArrayItem(d?.vital_sign?.blood_pressure)?.value
              ? getLastArrayItem(d?.vital_sign?.blood_pressure)?.value
                  ?.systolic +
                "/ " +
                getLastArrayItem(d?.vital_sign?.blood_pressure)?.value
                  ?.diastolic
              : 0,
            condition: getBloodPressureCondition(
              getLastArrayItem(d?.vital_sign?.blood_pressure)?.value.diastolic
            ),
          }}
          temperature={{
            data:
              d?.vital_sign?.temperature?.map((i) => i?.value?.temperature) ||
              [],
            dates: d?.vital_sign?.temperature?.map((i) => i?.date) || [],
            number:
              getLastArrayItem(d?.vital_sign?.temperature)?.value
                ?.temperature || 0,
            condition: getTemperatureCondition(
              getLastArrayItem(d?.vital_sign?.temperature)?.value?.temperature
            ),
          }}
          pulse_oximeter={{
            data:
              d?.vital_sign?.pulse_oximeter?.map((i) => {
                return i?.value?.spO2 || 0;
              }) || [],
            dates:
              d?.vital_sign?.pulse_oximeter?.map((i) => {
                return i?.date;
              }) || [],
            number: getLastArrayItem(d?.vital_sign?.pulse_oximeter)?.value
              ? getLastArrayItem(d?.vital_sign?.pulse_oximeter)?.value?.spO2 +
                " / " +
                getLastArrayItem(d?.vital_sign?.pulse_oximeter)?.value
                  ?.pulse_rate
              : 0,
            condition: getOximeterCondition(
              getLastArrayItem(d?.vital_sign?.pulse_oximeter)?.value?.spO2 ||
                100
            ),
          }}
          height={d?.vital_sign?.height}
          weight={d?.vital_sign?.weight}
          reason_for_visit={getReason(d?.vital_sign?.reason || "") || "- - -"}
          history_of_present_illness={
            d?.summary_note?.history_of_present_illness || "- - -"
          }
          review_of_system={d?.summary_note?.review_of_systems || "- - -"}
          physical_exam={d?.summary_note?.physical_exam || "- - -"}
          impression={d?.summary_note?.impressions || "- - -"}
          prescription={d?.summary_note?.prescription?.medicines || []}
          orders={{
            tests: d?.summary_note?.orders?.tests || [],
            plan: d?.summary_note?.orders?.plans || [],
            imaging: d?.summary_note?.orders?.imagings || [],
            labs: d?.summary_note?.orders?.labs || [],
          }}
          instruction={d?.summary_note?.instructions || "- - -"}
          note={d?.summary_note?.note || "- - -"}
          education={d?.summary_note?.education && d?.summary_note?.education}
          followUp_date={d?.summary_note?.followup_interval}
          referral={d?.summary_note?.referral}
        >
          <div className={clsx(classes.writeAddendum__addendums)}>
            {d?.summary_note?.addendum?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={clsx(classes.writeAddendum__addendums, classes.pg)}
                >
                  <div>
                    <div className={clsx(classes.writeAddendum__secondary)}>
                      {t("addendum.title")}:
                    </div>
                    <div className={clsx(classes.writeAddendum__gray)}>
                      {item?.content || "- - -"}
                    </div>
                  </div>
                  <div className={clsx(classes.writeAddendum__row)}>
                    <div>{t("addendum.datetime")}:</div>
                    <div className={clsx(classes.writeAddendum__gray)}>
                      {item?.updated_at
                        ? humanizedDate(item?.updated_at || "")
                        : "- - -"}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={clsx(classes.writeAddendum__sign, classes.pg)}>
            <div className={clsx(classes.writeAddendum__signImage)}>
              {d?.summary_note?.creator_doctor?.signature && (
                <ImageCard
                  noZoom
                  imgUrl={d?.summary_note?.creator_doctor?.signature || ""}
                />
              )}
            </div>
            <div className={clsx(classes.writeAddendum__row, classes.pg)}>
              <div>{t("addendum.doctor_sign")}:</div>
              <div className={clsx(classes.writeAddendum__gray)}>
                {d?.summary_note?.signed_at
                  ? humanizedDate(d?.summary_note?.signed_at || "")
                  : "- - -"}
              </div>
            </div>
          </div>
        </ShowSummaryNote>
      </Loader>
      <PrintNotif
        open={printOpen}
        setOpen={setPrintOpen}
        name={t("pvs.title")}
      />
    </Page>
  );
};

export default SharePastVisitSummaryNote;

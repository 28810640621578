import IDropdown from "../formikFields/IDropdown";
import { FastDropdown } from "../formikFields";
import { ManBold } from "components/icon";
import { useTranslation } from "react-i18next";
import { useOptions } from "../../utils/options";

interface IProps<T> extends IDropdown<T> {}

export function Gender<T>(props: IProps<T>) {
  const { t } = useTranslation("form");
  const { GENDER } = useOptions();

  return (
    <FastDropdown
      label={t("gender")}
      options={GENDER}
      isError={false}
      isLoading={false}
      icon={ManBold}
      {...props}
    />
  );
}

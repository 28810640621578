import clsx from "clsx";
import { PropsWithChildren } from "react";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { IValidationObj } from "utils/yupExtended";

export interface IFormikFieldProps {
  nameId: string;
  label: string;
  value: any;
  onChange: (value: any) => void;
  error?: any;
}

export interface IFormikInnerElementProps {
  onChange: (value: any) => void;
  className: string;
  value: any;
}

export interface IBaseProps {
  label: string;
  error?: string | IValidationObj;
  nameId: string;
  className?: string;
  noPadding?: boolean;
}

// form component for wrapping input and adding title label and error label
function FormBaseElement({
  label,
  error,
  nameId,
  children,
  className,
  noPadding,
}: PropsWithChildren<IBaseProps>) {
  // render component
  const { t } = useTranslation("validation");

  let errorMsg = error?.toString();
  if (typeof error === "object") {
    const fieldName = t(error.fieldName);
    errorMsg = t(error.key, { fieldName: fieldName });
  }
  return (
    <div
      className={clsx([
        // classes.formElementBaseContainer,
        noPadding && classes.no,
        className,
      ])}
    >
      {label && (
        <label htmlFor={nameId} className={classes.formElementLabel}>
          {label}
        </label>
      )}
      {children}
      {error && (
        <label htmlFor={nameId} className={classes.formElementError}>
          {errorMsg}
        </label>
      )}
    </div>
  );
}

FormBaseElement.defaultProps = {
  className: "",
};

export default FormBaseElement;

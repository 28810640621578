import { E_DEVICE } from "utils/entities";
import generateKey, { QGET, QGET_ALL } from "utils/keyGenerator";
import {
  DEVICE_TYPE,
  DROPDOWN_TYPE,
  STATISTICS_TYPE,
} from "utils/keyGenerator/keyTypes";

export const deviceTypeDevicesKey = generateKey({
  name: E_DEVICE,
  method: QGET_ALL,
  type: [DEVICE_TYPE],
});

export const deviceTypeDropdownKey = generateKey({
  name: E_DEVICE,
  method: QGET_ALL,
  type: [DEVICE_TYPE, DROPDOWN_TYPE],
});

export const getDeviceKey = generateKey({
  name: E_DEVICE,
  method: QGET,
});

export const deviceStatisticsKey = generateKey({
  name: E_DEVICE,
  method: QGET,
  type: [STATISTICS_TYPE],
});

export const devicesKey = generateKey({
  name: E_DEVICE,
  method: QGET_ALL,
});

import IDropdown from "../formikFields/IDropdown";
import {FastDropdown} from "../formikFields";
import {HospitalBold} from "components/icon";
import {useFacilityTypesDropdown} from "features/healthcareFacility";
import {useTranslation} from "react-i18next";

interface IHFTypeProps<T> extends IDropdown<T> {}

export function HFType<T>(props: IHFTypeProps<T>) {
  const { t } = useTranslation("form");

  // get the device types from server
  const { data, isError, isLoading } = useFacilityTypesDropdown();

  return (
    <FastDropdown
      label={t("hf_type")}
      data={data}
      isError={isError}
      isLoading={isLoading}
      icon={HospitalBold}
      {...props}
    />
  );
}

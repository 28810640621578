import DropdownItem from "components/core/dropdownItem";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

type Props = {
  rowId: number;
  fullName: string;
  roles: string[];
};

const RemoveRoleMenuItem = ({ roles, rowId, fullName }: Props) => {
  const { t } = useTranslation("table");
  const history = useHistory();
  if (roles.length === 0) {
    return null;
  }

  return (
    <DropdownItem
      onClick={() => {
        console.log("rowId: " + rowId, fullName, roles);
        history.push({
          pathname: `${history.location.pathname}/remove-role/${rowId}`,
          state: { roles: roles, name: fullName },
        });
      }}
    >
      <span>{t("actions.removeRole")}</span>
    </DropdownItem>
  );
};

export default RemoveRoleMenuItem;

import { useEffect, useRef, useState } from "react";

/* eslint-disable react-hooks/exhaustive-deps */

/** Cordinates Interface for draggable element */
export interface Position {
  x: number | string;
  y: number | string;
}

/** useDraggable hook for handling draggable element movements  */
export default function useDraggable<T extends HTMLElement>(
  initialPosition: Position = { x: "50%", y: "50%" },
  touch?: boolean
) {
  const ref = useRef<T>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<Position>(initialPosition);

  /** When mouse is moving */
  const handleMouseMove = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isDragging) return;
    if (!ref.current) return;

    setPosition({
      // x: event.movementX + ref.current.offsetLeft,
      // y: event.movementY + ref.current.offsetTop,
      x: Math.max(
        ref.current.offsetWidth / 2,
        event.movementX + ref.current.offsetLeft
      ),
      y: Math.max(
        ref.current.offsetHeight / 2,
        event.movementY + ref.current.offsetTop
      ),
    });
  };

  const touchPos = useRef<{ x: number; y: number }>({ x: -999999, y: -999999 });
  const handleTouchMove = (event: TouchEvent) => {
    console.log("TOUCH_MOVE");
    event.stopPropagation();
    event.preventDefault();

    if (!isDragging) return;
    if (!ref.current) return;

    const clientX = event.touches[0]?.clientX;
    const clientY = event.touches[0]?.clientY;

    const movementX =
      touchPos?.current?.x > -999999 ? clientX - touchPos?.current?.x : 0;
    const movementY =
      touchPos?.current?.y > -999999 ? clientY - touchPos?.current?.y : 0;

    touchPos.current = { x: clientX, y: clientY };

    setPosition({
      // x: event.movementX + ref.current.offsetLeft,
      // y: event.movementY + ref.current.offsetTop,
      x: Math.max(
        ref.current.offsetWidth / 2,
        movementX + ref.current.offsetLeft
      ),
      y: Math.max(
        ref.current.offsetHeight / 2,
        movementY + ref.current.offsetTop
      ),
    });
  };

  /** When mouse button is released  */
  function handleMouseUp(event: MouseEvent | TouchEvent) {
    if (!touch) {
      event.preventDefault();
      event.stopPropagation();
    }

    setIsDragging(false);
  }

  /** When mouse button is pressed */
  function handleMouseDown(event: MouseEvent | TouchEvent) {
    if (!touch) {
      event.preventDefault();
      event.stopPropagation();
    }

    setIsDragging(true);

    if (!ref.current) return;

    // setPosition({
    //   x: event.x - ref.current.offsetWidth,
    //   y: event.y - ref.current.offsetHeight,
    // });
  }

  useEffect(() => {
    ref.current?.addEventListener("mousedown", handleMouseDown);
    if (touch) {
      ref?.current?.addEventListener("touchstart", handleMouseDown);
    }

    return () => {
      ref.current?.removeEventListener("mousedown", handleMouseDown);
      ref?.current?.removeEventListener("touchstart", handleMouseDown);
    };
  }, [ref.current]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
      if (touch) {
        document.addEventListener("touchend", handleMouseUp);
        document.addEventListener("touchmove", handleTouchMove);
      }
    } else {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
      if (touch) {
        document.removeEventListener("touchend", handleMouseUp);
        document.removeEventListener("touchmove", handleTouchMove);
      }
    }
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isDragging]);

  return [ref, position, isDragging] as const;
}

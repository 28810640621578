import React, { createContext, PropsWithChildren, useState } from "react";
import { IRegisterForm, REGISTER_INITIAL_VALUES } from "../IForm";

export type StepNumber = 1 | 2 | 3 | 4 | 5;

interface IRegistrationContext {
  step: StepNumber;
  setStep: (step: StepNumber) => void;
  data: IRegisterForm;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

export const RegistrationContext = createContext<IRegistrationContext>({
  step: 1,
  setStep: () => null,
  data: REGISTER_INITIAL_VALUES,
  setData: () => null,
});

export const RegistrationProvider = ({ children }: PropsWithChildren<any>) => {
  const [data, setData] = useState<any>(REGISTER_INITIAL_VALUES);
  const [step, setStep] = useState<StepNumber>(1);
  return (
    <RegistrationContext.Provider value={{ data, setData, step, setStep }}>
      {children}
    </RegistrationContext.Provider>
  );
};

import { TRoute } from "config/router";
import PasswordRecoveryPage from "pages/login/passwordRecovery";
import LoginPage from "pages/login/login";
import PatientRegistration from "pages/globalPages/registration/patient";
import DoctorRegistration from "pages/globalPages/registration/doctor";
import StaffRegistration from "pages/globalPages/registration/staff";
import GuestJoinPage from "../../components/common/videoVisit/components/guestJoinPage";
import SharePastVisitSummaryNote from "../../pages/globalPages/sharePastVisitSummaryNote";
import ShareShowFollowup from "../../pages/globalPages/healthRecords/followUp/showFollowup";
import ShareShowReferral from "../../pages/globalPages/healthRecords/referral/showReferral";
import ShareShowPrescription from "../../pages/globalPages/healthRecords/prescription/showPrescription";
import ShareShowOrder from "../../pages/globalPages/healthRecords/orders/showOrder";
import { lazy } from "react";
import { lazyRetry } from "utils/route";

// please do not change this import to lazy.
// we do it for ux reasons.
const GLOBAL_ROUTES: TRoute[] = [
  {
    path: "guest-join/:token",
    exact: true,
    component: GuestJoinPage,
  },
  {
    path: "forget-password",
    component: PasswordRecoveryPage,
    exact: true,
  },
  {
    path: "login",
    component: LoginPage,
    exact: true,
  },
  {
    path: "doctor-registration",
    component: DoctorRegistration,
    exact: true,
  },
  {
    path: "staff-registration",
    component: StaffRegistration,
    exact: true,
  },
  {
    path: "patient-registration",
    component: PatientRegistration,
    exact: true,
  },
  {
    path: "past-visit-summary/:id",
    component: SharePastVisitSummaryNote,
    exact: true,
  },
  {
    path: "followup/:id",
    component: ShareShowFollowup,
    exact: true,
  },
  {
    path: "orders/:id",
    component: ShareShowOrder,
    exact: true,
  },
  {
    path: "prescription/:id",
    component: ShareShowPrescription,
    exact: true,
  },
  {
    path: "referral/:id",
    component: ShareShowReferral,
    exact: true,
  },
  {
    path: "500",
    exact: true,
    component: lazy(() => lazyRetry(() => import("pages/500"))),
  },
  {
    path: "403",
    exact: true,
    component: lazy(() => lazyRetry(() => import("pages/403"))),
  },
  {
    path: "400",
    exact: true,
    component: lazy(() => lazyRetry(() => import("pages/400"))),
  },
  {
    path: "503",
    exact: true,
    component: lazy(() => lazyRetry(() => import("pages/503"))),
  },
  {
    path: "pdf-viewer",
    exact: true,
    component: lazy(() =>
      lazyRetry(() => import("pages/globalPages/pdfViewer"))
    ),
  },
];

export default GLOBAL_ROUTES;

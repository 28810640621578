import { ArrowRightLinear, RefreshLinear } from "../../../../icon";
import clsx from "clsx";
import classes from "./styles.module.scss";
import Button from "../../../../core/button";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { getVideoVisitStoredResult } from "../../../../../api/videoVisit";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Loader from "../../../loader";
import { useTranslation } from "react-i18next";

export interface IPastData {
  result: string;
  date: string;
}

export interface IVitalItemProps {
  title: string;
  data: IPastData[];
  onViewMoreClick?: () => any;
  isLoading: boolean;
}

// vital item for vital sign data
const VitalItem = ({
  title,
  data,
  onViewMoreClick,
  isLoading,
}: IVitalItemProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  // data open or close state
  const [open, setOpen] = useState(false);
  const client = useQueryClient();

  // render component
  return (
    <Accordion
      className={clsx(classes.vitalItem)}
      expanded={open}
      onChange={(event, expanded) => {
        setOpen(expanded);
      }}
    >
      <AccordionSummary>
        <div className={clsx(classes.vitalItem__head)}>
          <div className={clsx(classes.vitalItem__title)}>{title}</div>
          <div
            className={clsx(classes.vitalItem__icon, open && classes.rotate)}
          >
            {<ArrowRightLinear />}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={clsx(
          classes.vitalItem__content,
          open && classes.vitalItem__contentOpen
        )}
      >
        <div
          className={clsx(classes.vitalItem__contentInside)}
          onClick={(e) => e.stopPropagation()}
        >
          <Loader isLoading={isLoading}>
            <div className={clsx(classes.vitalItem__result)}>
              <div className={clsx(classes.vitalItem__top)}>
                <div className={clsx(classes.vitalItem__title)}>
                  {t("last_result")}:
                </div>
                <div
                  className={clsx(
                    classes.vitalItem__resultText,
                    classes.vitalSign__cursor,
                    classes.vitalItem__refresh
                  )}
                  onClick={() => {
                    client.invalidateQueries([getVideoVisitStoredResult.name]);
                  }}
                >
                  <RefreshLinear />
                  <div>{t("refresh")}</div>
                </div>
              </div>
              <div
                className={clsx(
                  classes.vitalItem__pastData,
                  classes.vitalItem__top
                )}
              >
                <div>{data[0]?.date}</div>
                <div className={clsx(classes.vitalItem__resultText)}>
                  {data[0]?.result || t("no_data")}
                </div>
              </div>
            </div>
            {data?.length > 1 && (
              <div className={clsx(classes.vitalItem__title)}>
                {t("stored_result")}
              </div>
            )}
            <div className={clsx(classes.vitalItem__rows)}>
              {data.map((data, index) => {
                if (index === 0) return "";
                return (
                  <div
                    key={index}
                    className={clsx(classes.vitalItem__pastData)}
                  >
                    <div>{data?.date}</div>
                    <div>{data?.result || "- - -"}</div>
                  </div>
                );
              })}
            </div>
          </Loader>

          <Button
            variant={"contained"}
            onClick={() => {
              if (onViewMoreClick) {
                onViewMoreClick();
              }
            }}
          >
            {t("view_more")}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default VitalItem;

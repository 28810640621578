import OT, { OTError } from "@opentok/client";
import { IInitializedDevices } from "components/common/videoVisit";
import { IVonageMetaData, IVonageStream } from "interfaces/vonageStream";
import { notify } from "../../components/core/toast";

/*const reloadPage = () => {
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};*/

const useSession = () => {
  let session: OT.Session | undefined = undefined;
  let publisher: OT.Publisher | undefined = undefined;

  function initializeSession(
    apiKey: string,
    sessionId: string,
    token: string,
    onSubscriberJoin: (newStream: IVonageStream) => void,
    onSubscriberDis: (id: string) => void,
    initializedDevices: IInitializedDevices
  ) {
    session = OT.initSession(apiKey, sessionId);
    // Subscribe to a newly created stream
    session.on("streamCreated", function (event) {
      // onSubscriberJoin(event.stream.streamId);
      // setTimeout(() => {
      let sub = session?.subscribe(
        event.stream,
        undefined,
        {
          insertDefaultUI: false,
          style: { buttonDisplayMode: "off" },
          width: "100%",
          height: "100%",
        },
        handleError
      );
      sub?.on("videoElementCreated", function (event2) {
        const metaData = event2.target.stream!.connection.data;
        console.log("Subscriber Meta Data", metaData);
        const userMetaData: IVonageMetaData = JSON.parse(metaData);

        const newStream: IVonageStream = {
          id: event2.target.stream!.streamId,
          type: "camera",
          videoObj: event2.element,
          stream: event2.target.stream!,
          ...userMetaData,
        };
        sub?.on("videoDisabled", () => {
          (event2.element as HTMLVideoElement).style.display = "none";
        });
        sub?.on("videoEnabled", () => {
          (event2.element as HTMLVideoElement).style.display = "block";
        });
        onSubscriberJoin(newStream);
      });
      // }, 2000);
    });
    session.on("streamDestroyed", function (event) {
      const metaData = event?.stream?.connection?.data;
      if (metaData) {
        const userMetaData: IVonageMetaData = JSON.parse(metaData);
        if (userMetaData?.name) {
          /*notify.error(
            `User ${userMetaData?.name} disconnected, (${event.reason})`
          );*/
        }
      }
      onSubscriberDis(event.stream.streamId);
    });

    // Create a publisher
    publisher = OT.initPublisher(
      undefined,
      {
        insertDefaultUI: false,
        style: { buttonDisplayMode: "off" },
        width: "100%",
        height: "100%",
        audioSource: initializedDevices.mic || undefined,
        videoSource: initializedDevices.camera || undefined,
        audioFallbackEnabled: false,
      },
      handleError
    );
    OT.setAudioOutputDevice(initializedDevices.speaker);

    publisher.on("videoElementCreated", function (event) {
      const newStream: IVonageStream = {
        id: "You-camera",
        name: "You",
        type: "camera",
        videoObj: event.element,
        stream: event.target.stream,
        avatar: "",
        role: "",
      };
      onSubscriberJoin(newStream);
    });

    // Connect to the session
    session.connect(token, function (error) {
      // If the connection is successful, publish to the session
      if (error) {
        handleError(error);
      } else {
        session?.publish(publisher!, handleError);
      }
    });

    return { session, publisher };
  }
  return { initializeSession };
};

function handleError(error: OTError | undefined) {
  if (error) {
    notify.error(`Vonage Error: ${error.message}`);
    // reloadPage();
  }
}

export default useSession;

import "./styles/global.scss";
import "./styles/main.css";
import "react-toastify/dist/ReactToastify.css";

import { Route, Router, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { createContext, Suspense, useEffect, useState } from "react";
import { AuthProvider } from "components/provider/auth";
import { ThemeModeProvider } from "components/provider/themeMode";
import { ToastContainer } from "react-toastify";
import { TOAST_PROPS } from "config/toast";
import { APIConfigurator } from "components/other/APIConfigurator";
import { PanelPages } from "pages/dashboard";
import {
  buildRoute,
  getAllRoutes,
  getGlobalRoutes,
  getSampleRoutes,
} from "config/router";
import NotFound from "pages/404";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { RouteConfig } from "@sentry/react/types/reactrouter";
import { matchPath, Redirect, useLocation } from "react-router";
import VisitContext from "./components/provider/visitContext";
import VideoVisit from "./components/common/videoVisit";
import { TRoleName } from "./api/user";
import ConnectionChecker from "./components/common/ConnectionChecker";
import { LanguageProvider } from "./hooks/useLang";
import { AlertContainer } from "components/common/alert";
import { NotificationAlertContainer } from "components/core/notificationAlert";
import Alert from "./components/common/alert";
import Button from "./components/core/button";
import { ReactQueryDevtools } from "react-query/devtools";
import { useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";
import { notify } from "./components/core/toast";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import clsx from "clsx";
import classes from "./components/common/loader/styles.module.scss";
import { VideoVisitLogo } from "./components/icon";

// Initialize the pdf.js worker for rendering PDFs
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/*const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("react-query/devtools/development").then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);*/
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

type VVMinimize = {
  videoVisitIsOpen: boolean;
  videoVisitMinimize: boolean;
  setVideoVisitMinimize: React.Dispatch<React.SetStateAction<boolean>>;
};

export const VideVisitMinimizeContext = createContext<VVMinimize>({
  videoVisitIsOpen: false,
  videoVisitMinimize: false,
  setVideoVisitMinimize: () => {},
});

const history = createBrowserHistory();
const allRoutes = getAllRoutes();
const routes = [...allRoutes.globalRoutes, ...allRoutes.authRoutes].map(
  (item): RouteConfig => ({
    path: item.path!.toString(),
    exact: item.exact,
  })
);

Sentry.init({
  dsn: "https://e0201598ecf147acb9e0d102b1e412b7@sentry.leo360.us/3",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history,
        routes,
        matchPath
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: Number(process.env["REACT_APP_SENTRY_SAMPLE_RATE"] ?? 1),
});

function App() {
  const { i18n } = useTranslation();
  document.documentElement.dir = i18n.dir();
  document.documentElement.lang = i18n.language;

  const { t } = useTranslation("global");

  useEffect(() => {
    const reload = () => {
      setTimeout(() => window.location.reload(), 100);
    };
    window.addEventListener("storage", reload);

    return () => window.removeEventListener("storage", reload);
  }, []);

  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState<TRoleName>();
  /* const [showDevtools, setShowDevtools] = React.useState(true);
  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);*/
  /**
   * video visit minimized state
   */
  const [minimize, setMinimize] = useState<boolean>(false);

  const memoSetMinimize = React.useCallback(
    (value: ((param: boolean) => boolean) | boolean) => {
      if (typeof value === "function") {
        setMinimize((p) => value(p));
      } else setMinimize(value);
    },
    []
  );

  // const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [updateOpen, setUpdateOpen] = useState(false);

  // decides when to show the toast
  /*  useEffect(() => {
    console.log("showReload: " + showReload);
    console.log("waitingWorker: ");
    console.log(waitingWorker);
    if (showReload && waitingWorker) {
      setUpdateOpen(true);
    } else setUpdateOpen(false);
  }, [waitingWorker, showReload, reloadPage]);*/

  return (
    <ThemeModeProvider>
      <LanguageProvider>
        <QueryClientProvider client={queryClient}>
          <VisitContext.Provider
            value={{
              open: open,
              onJoin: (id, role) => {
                if (open) {
                  notify.error(t("video_visit_open"));
                  return;
                }
                setOpen(true);
                setId(id);
                setRole(role);
              },
            }}
          >
            <Router history={history}>
              <RestoreScroll>
                <AuthProvider>
                  <ConnectionChecker>
                    <React.StrictMode>
                      <Suspense
                        fallback={
                          <div
                            className={clsx(
                              "d-flex justify-content-center py-5 align-items-center",
                              classes.loader
                            )}
                            style={{ height: "100vh" }}
                          >
                            <VideoVisitLogo
                              style={{ width: "200px", height: "200px" }}
                            />
                          </div>
                        }
                      >
                        <VideVisitMinimizeContext.Provider
                          value={{
                            setVideoVisitMinimize: memoSetMinimize,
                            videoVisitMinimize: minimize,
                            videoVisitIsOpen: open,
                          }}
                        >
                          <Switch>
                            <Route
                              path={"/"}
                              component={() => <Redirect to={"/login"} />}
                              exact
                            />
                            {getGlobalRoutes().map(buildRoute)}
                            {getSampleRoutes().map(buildRoute)}
                            <Route path={"/panel"} component={PanelPages} />
                            <Route component={() => <NotFound />} />
                          </Switch>
                        </VideVisitMinimizeContext.Provider>
                      </Suspense>
                    </React.StrictMode>
                    <APIConfigurator />
                  </ConnectionChecker>
                </AuthProvider>
                {open && (
                  <VideoVisit
                    role={role}
                    date={new Date()}
                    appt_id={id}
                    open={open}
                    setOpen={setOpen}
                    minimize={minimize}
                    setMinimize={memoSetMinimize}
                  />
                )}
                <Alert
                  open={updateOpen}
                  setOpen={setUpdateOpen}
                  title={"New Version Available"}
                  text={
                    'Newer version of the app available. Please click "Update" to explore new features. Thank you.'
                  }
                  dismissible
                  customButtons={
                    <Button onClick={() => /*reloadPage()*/ {}}>Update</Button>
                  }
                />
              </RestoreScroll>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
            {/*{showDevtools ? (
              <React.Suspense fallback={null}>
                <ReactQueryDevtoolsProduction />
              </React.Suspense>
            ) : null}*/}
            {/* {process.env.NODE_ENV === "development" && (
            )} */}
            <ToastContainer {...TOAST_PROPS} rtl={i18n.dir() === "rtl"} />
            <AlertContainer />
            <NotificationAlertContainer />
          </VisitContext.Provider>
        </QueryClientProvider>
      </LanguageProvider>
    </ThemeModeProvider>
  );
}
const RestoreScroll: React.FC = ({ children }) => {
  const loc = useLocation();
  useEffect(() => {
    window.scroll({ left: 0, top: 0, behavior: "smooth" });
  }, [loc.pathname]);
  return <>{children}</>;
};

export default App;
// export default Sentry.withErrorBoundary(App, {
//   fallback: () => <div>Something went wrong</div>,
// });

import DropdownItem from "components/core/dropdownItem";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export interface IUpdateMenuItemProps {
  parentId: number;
}

export function AddBranchMenuItem({ parentId }: IUpdateMenuItemProps) {
  const { t } = useTranslation("global");
  const history = useHistory();

  if (parentId === null || parentId === undefined) return null;

  return (
    <DropdownItem
      onClick={() =>
        history.push(`${history.location.pathname}/create?parentId=${parentId}`)
      }
    >
      <span>{t("add_branch")}</span>
    </DropdownItem>
  );
}

import { useQuery } from "react-query";
import { getCountries } from "api/dropdown";
import { GPSBold } from "components/icon";
import IDropdown from "components/formikFields/IDropdown";
import { FastDropdown } from "components/formikFields";
import { useTranslation } from "react-i18next";
import { countriesKey } from "../../utils/keyGenerator/keys";

interface ICountryProps<T> extends IDropdown<T> {}

export function Country<T>(props: ICountryProps<T>) {
  const { t } = useTranslation("form");

  //? Caches The Data received from server
  const { data, isLoading, isError } = useQuery(countriesKey, getCountries, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return (
    <FastDropdown
      label={t("country")}
      data={data}
      isError={isError}
      isLoading={isLoading}
      icon={GPSBold}
      {...props}
    />
  );
}

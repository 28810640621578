import TimeInput, { ITimeInputProps } from "components/core/timeInput";
import { IField } from "./IField";
import FastField from "./field";
import { ITime } from "interfaces";

type inputProps = Omit<ITimeInputProps, "onChange" | "defaultTime" | "name">;
interface IProps<T> extends IField<T, ITime>, inputProps {}

export function FastTimeInput<T>({
  formik,
  onChange,
  onChangeAlongside,
  ...inputProps
}: IProps<T>) {
  return (
    <FastField
      onChangeValueMapper={(value: ITime) => value}
      {...{ formik, onChange, onChangeAlongside, ...inputProps }}
    >
      {(props) => (
        <TimeInput
          {...inputProps}
          defaultTime={props.value}
          onChange={props.onChange}
          name={inputProps.fieldName}
        />
      )}
    </FastField>
  );
}

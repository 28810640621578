import {
  RedirectToDashboard,
  RedirectToDashboardAbs,
} from "components/common/redirectToDashboard";
import { lazy } from "react";
import { TRoute } from "config/router";
import { DASHBOARD_GLOBAL_ROUTES } from "./globalDashboard";
import { UserRoleName } from "../../api/types/userTypes";
import NoInternet from "../../pages/noInternet";
import Unavailable from "../../pages/503";
import BadRequest from "../../pages/400";
import Forbidden from "../../pages/403";
import ServerError from "../../pages/500";
import { lazyRetry } from "utils/route";

const ResetPassword = lazy(
  () =>
    import(
      "pages/dashboard/superAdmin/localAdmin/staffManagement/resetPassword"
    )
);

const STAFF_ROUTES: TRoute = {
  path: "staff",
  exact: true,
  component: RedirectToDashboard,
  inners: [
    {
      path: "500",
      exact: true,
      component: ServerError,
    },
    {
      path: "403",
      exact: true,
      component: Forbidden,
    },
    {
      path: "400",
      exact: true,
      component: BadRequest,
    },
    {
      path: "503",
      exact: true,
      component: Unavailable,
    },
    {
      path: "no-connection",
      exact: true,
      component: NoInternet,
    },
    {
      path: "stripe/success",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/successPayment"))
      ),
      exact: true,
    },
    {
      path: "stripe/cancel",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/failurePayment"))
      ),
      exact: true,
    },
    {
      path: "paypal/success",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/successPayment"))
      ),
      exact: true,
    },
    {
      path: "paypal/cancel",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/failurePayment"))
      ),
      exact: true,
    },
    {
      path: "dashboard",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/home"))
      ),
      exact: true,
    },
    {
      path: "reset-password/:id",
      component: () => <ResetPassword roleName={UserRoleName.Patient} />,
      exact: true,
    },
    {
      path: "intake-form-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/intakeForms"))
      ),
      exact: true,
      inners: [
        {
          path: ":intake_id",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
          ),
          exact: true,
        },
        {
          path: ":intake_id/edit",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
          ),
          exact: true,
        },
      ],
    },
    {
      path: "edit-intake-form/:intake_id/edit",
      component: lazy(() =>
        lazyRetry(() =>
          lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
        )
      ),
      exact: true,
    },
    {
      path: "edit-intake-form/:intake_id",
      component: lazy(() =>
        lazyRetry(() =>
          lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
        )
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member/:appointment_id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "assign-robot-to-patient",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/assignRobot"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () => import("pages/dashboard/staff/assignRobotWithoutHF/form")
            )
          ),
          exact: true,
        },
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () => import("pages/dashboard/staff/assignRobotWithoutHF/form")
            )
          ),
          exact: true,
        },
        {
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () => import("pages/dashboard/staff/assignRobotWithoutHF/form")
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "scheduled-appointment/create",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/appointments/form"))
      ),
      exact: true,
    },
    {
      path: "on-demand-appointment/create",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/appointments/form"))
      ),
      exact: true,
    },
    {
      path: "appointments/scheduled",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/appointments"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/patient/appointments/form"))
          ),
          exact: true,
        },
        {
          path: "reschedule",
          component: lazy(() =>
            lazyRetry(
              () => import("pages/dashboard/patient/appointments/reschedule")
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "reschedule",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/patient/appointments/innerPages/reScheduled"
            )
        )
      ),
      exact: true,
    },
    {
      path: "reschedule-appointment/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/appointmentManagement/innerPages/reScheduled"
            )
        )
      ),
      exact: true,
    },
    {
      path: "appointments/on-demand",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/appointments"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/patient/appointments/form"))
          ),
          exact: true,
        },
      ],
    },
    {
      path: "medical-history/:pid/:patient_type",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/medicalHistory"))
      ),
      exact: true,
    },
    {
      path: "patient-registration",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/patientList"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/patientManagement/patientRegistration"
                )
            )
          ),
        },
        {
          exact: true,
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/patientManagement/viewPatientProfile"
                )
            )
          ),
        },
        {
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/patientManagement/patientRegistration"
                )
            )
          ),
        },
      ],
    },
    {
      path: "patient-followup",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/healthRecords"))
      ),
      exact: true,
    },
    {
      path: "patient-followup/:appt_id/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import("pages/dashboard/staff/healthRecords/followUp/showFollowup")
        )
      ),
    },
    {
      path: "visit-summary",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/summaryNotes"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/download",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "patients/past-visit-summary/:patient_id/:patient_type",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/summaryNotes"))
      ),
      exact: true,
    },
    {
      path: "no-show-causes",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/noShowCauses"))
      ),
      exact: true,
    },
    {
      path: "video-visit/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/general/onlineVisit"))
      ),
    },
    {
      path: "profile-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/profileManagement"))
      ),
    },
    {
      path: "doctors",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/doctorList"))
      ),
      exact: true,
      inners: [
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () => import("pages/dashboard/staff/doctorList/viewDoctorProfile")
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "view-doctor-profile/:id",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/staff/doctorList/viewDoctorProfile")
        )
      ),
      exact: true,
    },
    {
      path: "patient-wallet",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/financialManagement"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/staff/financialManagement/components/createNewWalletWithoutHF"
                )
            )
          ),
          exact: true,
        },
        {
          path: "increase/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/staff/financialManagement/components/addCredit"
                )
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "view-appointment-detail/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/appointmentDetail"))
      ),
      exact: true,
    },
    {
      path: "view-appointment-detail/:id/detail",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/appointmentDetail"))
      ),
      exact: true,
    },
    {
      path: "doctor-available-time",
      component: lazy(() =>
        lazyRetry(
          () =>
            import("pages/dashboard/patient/appointments/doctorAvailableTime")
        )
      ),
      exact: true,
    },
    {
      path: "patient-referral/:appt_id/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/patient/healthRecords/referral/showReferral"
            )
        )
      ),
    },
    {
      path: "PACS",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/pacs/pacs"))
      ),
    },
    {
      path: "user-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/userReports")
        )
      ),
    },
    {
      path: "robot-usage-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/robotReports")
        )
      ),
    },
    {
      path: "appointments-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/appointmentReports")
        )
      ),
    },
    {
      path: "followup-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/followUpReports")
        )
      ),
    },
    {
      path: "financial-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/financialReports")
        )
      ),
    },
    {
      path: "robot-appointments/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/robotAppointments"))
      ),
    },
    { component: RedirectToDashboardAbs, exact: true, path: "help-center" },
    ...DASHBOARD_GLOBAL_ROUTES,
  ],
};

export default STAFF_ROUTES;

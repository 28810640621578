import ImagesItem, { IImagesItemProps } from "./ImagesItem";
import clsx from "clsx";
import classes from "./styles.module.scss";

export interface IImagesProps {
  items: IImagesItemProps[];
}

const ImageItems = ({ items }: IImagesProps) => {
  return (
    <div className={clsx(classes.images__container)}>
      {items.map((item, index) => {
        return <ImagesItem {...item} key={index} />;
      })}
    </div>
  );
};

export default ImageItems;

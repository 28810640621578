import {
  Country,
  Degree,
  Speciality,
  SubSpeciality,
} from "components/dropdown";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { GlobalBold, MailBold, VerifyBold } from "components/icon";
import { IRegistrationStepProps } from "./registrationLayout";
import Grid from "components/core/Grid";
import { useTranslation } from "react-i18next";
import { FastInput, FastPhoneInput } from "components/formikFields";
import { formSubmission } from "utils/form";

const RegistrationSecondPage = ({
  formik,
  onSetAlert,
  backButton,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");
  // const { data } = useContext(RegistrationContext);

  return (
    <form onSubmit={(e) => formSubmission(e, formik)}>
      <Grid>
        <FastPhoneInput
          formik={formik}
          fieldName={"mobile"}
          label={t("mobile")}
        />
        <FastPhoneInput
          formik={formik}
          fieldName={"alternativePhoneNumber"}
          label={t("phone")}
        />
        <FastInput
          formik={formik}
          fieldName={"email"}
          icon={MailBold}
          label={t("email")}
        />
        <FastInput
          formik={formik}
          fieldName={"website"}
          icon={GlobalBold}
          label={t("website")}
        />
        <Speciality
          formik={formik}
          fieldName="speciality"
          id={formik.values.speciality.key}
          dependencies={["subSpecialty"]}
        />
        <SubSpeciality
          formik={formik}
          fieldName={"subSpecialty"}
          specialityFieldName={"speciality"}
          id={formik.values.subSpecialty.key}
        />
        <Country
          fieldName="countryOfLicense"
          formik={formik}
          label={t("country_license")}
          id={formik.values.countryOfLicense.key}
        />
        <FastInput
          formik={formik}
          fieldName={"licenseNo"}
          icon={VerifyBold}
          label={t("license_number")}
          type="number"
        />
        <Degree
          formik={formik}
          fieldName="title"
          id={formik.values.title.key}
        />

        <div className={classes.buttonContainer}>
          {backButton}
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => onSetAlert(true)}
          >
            {t("cancel")}
          </Button>
          <Button type="submit">{t("continue")}</Button>
        </div>
      </Grid>
    </form>
  );
};

export default RegistrationSecondPage;

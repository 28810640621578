import React from "react";
import { IField } from "./IField";
import FastField from "./field";
import Input from "components/core/input";
import { IInputDefaultProps } from "components/core/input";

type inputProps = Omit<IInputDefaultProps, "onChange" | "value" | "name">;

interface IProps<T> extends IField<T, string>, inputProps {}
export function FastInput<T>({
  formik,
  onChange,
  onChangeAlongside,
  ...inputProps
}: IProps<T>) {
  return (
    <FastField
      {...{
        formik,
        onChange,
        onChangeAlongside,
        ...inputProps,
      }}
      onChangeValueMapper={(value: React.ChangeEvent<HTMLInputElement>) =>
        value.target.value
      }
    >
      {(props) => (
        <Input
          {...inputProps}
          placeholder={inputProps.placeholder || inputProps.label || ""}
          name={inputProps.fieldName}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.validateOnBlur}
        />
      )}
    </FastField>
  );
}

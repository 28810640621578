import MultiSelect from "components/core/multiSelect";
import { IMenuOption } from "components/core/autoComplete";
import FastField from "./field";
import { IResponse } from "api";
import { IDropdownResponse } from "api/dropdown";
import { useDropdownsOptions } from "hooks/useOptions";
import { useMultiSelectDefaultValue } from "hooks/useMultiSelecetDefaultValue";
import IMultiSelect from "./IMultiSelect";
import React from "react";

interface IProps<T> extends IMultiSelect<T> {
  /**
   * The data that u fetched. pass the data or options
   */
  data?: IResponse<IDropdownResponse[]>;
  isLoading: boolean;
  isError: boolean;
  /**
   * pass the data or options
   */
  options?: IMenuOption[];
}

export function FastMultiSelect<T>({
  formik,
  onChange,
  onChangeAlongside,
  data,
  options,
  isError,
  isLoading,
  fieldName,
  ids,
  names,
  placeholder,
  label,
  disabled,
  ...rest
}: IProps<T>) {
  // map fetched data to IMenuOption type
  const dataOptions = useDropdownsOptions({ data: data });

  const OPTIONS = !!options ? options : dataOptions;

  useMultiSelectDefaultValue({
    OPTIONS: OPTIONS,
    fieldName: fieldName,
    formik: formik,
    ids: ids,
    names: names,
  });

  const memorized_OPTIONS = React.useMemo(() => OPTIONS || [], [OPTIONS]);

  return (
    <FastField
      {...{
        formik,
        onChange,
        onChangeAlongside,
        fieldName,
        label,
        ...rest,
      }}
      onChangeValueMapper={(value: IMenuOption[]) => value}
    >
      {(props) => (
        <MultiSelect
          size="big"
          {...rest}
          placeholder={placeholder ? placeholder : label}
          disabled={isLoading || disabled}
          options={memorized_OPTIONS}
          isError={isError}
          isLoading={isLoading}
          name={fieldName}
          value={props.value}
          onBlur={props.validateOnBlur}
          onItemSelect={props.onChange}
        />
      )}
    </FastField>
  );
}

import Sidebar from "components/layout/sidebar";
import { buildRoute, getAuthRoutes } from "config/router";
import { useAuth, WithAuth } from "components/provider/auth";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { Prompt, Route, Switch, useHistory } from "react-router";
import NotFound from "pages/404";
import Alert from "../../components/common/alert";
import VisitContext from "../../components/provider/visitContext";
import { useProfile } from "../../hooks/useProfile";
import Button from "../../components/core/button";
import { useBaseUrl } from "../../utils/route";
import { useTranslation } from "react-i18next";

export interface INavigationContext {
  block: () => void;
  unblock: () => void;
}

export const NavigationContext = React.createContext<INavigationContext>({
  block: () => {},
  unblock: () => {},
});

export function PanelPages() {
  // console.log(getAuthRoutes());
  const { role } = useAuth();

  const [block, setBlock] = useState(false);
  const ctx = useContext(VisitContext);

  useEffect(() => {
    const unblock = () => {
      setBlock(false);
    };
    window.addEventListener("storage", unblock);

    return () => window.removeEventListener("storage", unblock);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (block || ctx.open) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [block, ctx.open]);

  const { isLoggedIn } = useAuth();

  const profile = useProfile(isLoggedIn);
  const base = useBaseUrl();
  const [resetPassOpen, setResetPassOpen] = useState(false);
  const [uploadOpen, setUpload] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      role !== "super" &&
      profile?.data?.data?.data?.need_to_change_password === 1
    ) {
      setResetPassOpen(true);
    } else if (
      role === "doctor" &&
      profile?.data?.data?.data?.more_info?.need_to_upload_avatar
    ) {
      setUpload(true);
    }
  }, [
    role,
    profile?.data?.data?.data?.need_to_change_password,
    profile?.data?.data?.data?.more_info?.need_to_upload_avatar,
  ]);
  const { t } = useTranslation("form");

  return (
    <WithAuth>
      <NavigationContext.Provider
        value={{ block: () => setBlock(true), unblock: () => setBlock(false) }}
      >
        <Sidebar>
          <Suspense fallback={<div />}>
            <Switch>
              {getAuthRoutes().map(buildRoute)}
              <Route component={() => <NotFound />} />
            </Switch>
          </Suspense>
          {block && <Prompt message={t("form_leave")} when={block} />}
          <Alert
            open={resetPassOpen}
            setOpen={setResetPassOpen}
            title={"Reset Password"}
            text={"You need to reset your password before using your account!"}
            dismissible
            onClose={() => {
              setResetPassOpen(false);
              setTimeout(() => setResetPassOpen(true), 300);
            }}
            customButtons={
              <Button
                onClick={() => {
                  setResetPassOpen(false);
                  history.push(base + "change-password");
                }}
              >
                Reset Password
              </Button>
            }
          />
          <Alert
            open={uploadOpen}
            setOpen={setUpload}
            title={"Upload Avatar"}
            text={"You need to upload your avatar before using your account!"}
            dismissible
            onClose={() => {
              setUpload(false);
              setTimeout(() => setUpload(true), 300);
            }}
            customButtons={
              <Button
                onClick={() => {
                  setUpload(false);
                  history.push(base + "profile-management/view-profile");
                }}
              >
                Upload Avatar
              </Button>
            }
          />
        </Sidebar>
      </NavigationContext.Provider>
    </WithAuth>
  );
}

import React from "react";

const ConnectionChecker: React.FC = ({ children }) => {
  /*const { role } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!window.navigator.onLine) {
      if (role) {
        history.push(`/panel/${role}/no-connection`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.navigator.onLine]);*/
  return <>{children}</>;
};

export default ConnectionChecker;

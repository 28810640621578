import { getDeviceTypes } from "api/dropdown";
import { IMenuOption } from "components/core/autoComplete";
import { deviceTypeDropdownKey } from "features/medicalDevice";
import { useMemo } from "react";
import { useQuery } from "react-query";
//TODO: remove this hook
// hook that fetch and save device types
export function useDeviceTypesOptions() {
  const { data, ...rest } = useQuery(deviceTypeDropdownKey, getDeviceTypes);

  const OPTIONS: IMenuOption[] | undefined = useMemo(() => {
    if (!data?.data?.data) {
      return [];
    }
    return data?.data?.data?.map((item) => {
      return { key: item.id.toString(), value: String(item.name) };
    });
  }, [data]);
  return { OPTIONS: OPTIONS, ...rest };
}

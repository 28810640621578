import IDropdown from "../formikFields/IDropdown";
import {FastDropdown} from "../formikFields";
import {GPSBold} from "components/icon";
import {useTranslation} from "react-i18next";
import {useDeviceTypesOptions} from "features/medicalDevice";

interface IProps<T> extends IDropdown<T> {
  /**
   * There is an item with key:0 and value:"Linktop 6 in 1", if you want to delete it from options pass below property with true
   */
  remove_Linktop_Item?: boolean;
}

export function DeviceType<T>({ remove_Linktop_Item, ...props }: IProps<T>) {
  const { t } = useTranslation("form");

  // get the device types from server
  const { OPTIONS, isError, isLoading } = useDeviceTypesOptions();
  const filteredOptions =
    remove_Linktop_Item && OPTIONS
      ? OPTIONS.filter((item) => item.key !== "0")
      : OPTIONS;
  return (
    <FastDropdown
      label={t("device_type")}
      options={filteredOptions}
      isError={isError}
      isLoading={isLoading}
      icon={GPSBold}
      {...props}
    />
  );
}

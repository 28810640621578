import { FormikProps } from "formik";
import FormPhoneInput from "components/form/formPhoneInput";
import { CallBold } from "components/icon";
import { getFormikFieldProps } from "utils/form";
import {
  ICountryItem,
  IDefaultProps as IPhoneInputProps,
} from "components/core/phoneInput";
import { useQuery } from "react-query";
import { getCountryPhoneCodes } from "api/dropdown";
import { useEffect, useMemo } from "react";
import { countryPhoneKey } from "../../../utils/keyGenerator/keys";
import countries from "../../../constants/countries.json";
import { useTranslation } from "react-i18next";

export interface IPhoneInputDropdownProps<T> {
  formik: FormikProps<T>;
  countryFieldName?: string;
  phoneFieldName: string;
  icon?: any;
  label?: string;
  rootProps?: Partial<IPhoneInputProps>;
  placeholder?: string;
  defaultNumber?: string;
  noPadding?: boolean;
}

//? customized phone input that shows the flag of country with pre code

export function PhoneInputDropdown<T extends { [key: string]: any }>({
  countryFieldName = "",
  formik,
  phoneFieldName,
  label,
  placeholder,
  icon = CallBold,
  rootProps,
  defaultNumber,
  noPadding,
}: IPhoneInputDropdownProps<T>) {
  const { t } = useTranslation("form");

  // const [flag, setFlag] = useState(false);
  const { data, isLoading, isError } = useQuery(
    [countryPhoneKey, formik.values[countryFieldName]?.key],
    () => getCountryPhoneCodes(Number(formik.values[countryFieldName]?.key)),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!formik.values[countryFieldName]?.key && !!countryFieldName,
    }
  );

  const COUNTRIES: ICountryItem[] | undefined = useMemo(
    () =>
      data?.data.data?.map((item) => ({
        flag: undefined,
        code: item.name ? item.name : "",
        name: "",
      })),
    [data]
  );
  const selectedCountry = formik.values[countryFieldName]?.key;
  useEffect(() => {
    const number = !!formik.values[phoneFieldName]
      ? formik.values[phoneFieldName].number
      : "";
    if (COUNTRIES && COUNTRIES.length > 0 && number === "") {
      formik.setFieldValue(phoneFieldName, {
        code: COUNTRIES[0].code,
        number: !!defaultNumber ? defaultNumber : number,
      });
    }

    // else if (selectedCountry === (undefined || "")) {
    //   formik.setFieldValue(phoneFieldName, {
    //     code: "",
    //     number: !!defaultNumber ? defaultNumber : number,
    //   });
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [COUNTRIES, selectedCountry, defaultNumber]);

  return (
    <FormPhoneInput
      noPadding={noPadding}
      /*countries={COUNTRIES ? COUNTRIES : []}*/
      countries={getCountryCodes()}
      rootProps={{
        disabled:
          // !formik.values[countryFieldName] ||
          // !formik.values[countryFieldName].key ||
          isError || isLoading,
        icon: icon,
        placeholder: placeholder || t("phone"),
        isError,
        isLoading,
        ...rootProps,
      }}
      {...getFormikFieldProps(phoneFieldName, label || t("phone"), formik)}
    />
  );
}
const getCountryCodes = () => {
  return countries.map((record) => ({
    code: record.dialCode,
    flag: record.flag,
    name: record.name,
  }));
};

import DropdownItem from "components/core/dropdownItem";
import { DropdownMenu } from "components/core/dropdownMenu";
import LANGUAGES from "../../../constants/languages";
import { Dropdown } from "components/core/dropdown";
import classes from "./main.styles.module.scss";
import clsx from "clsx";
import { useLanguage } from "hooks/useLang";
import { GlobeLinear } from "components/icon";
import IconButton from "components/core/iconButton";

export function LanguageDropdown() {
  const { changeLang, language } = useLanguage();
  return (
    <div className={classes.languageDropdown}>
      <Dropdown anchor="bottom">
        {/* <Button
          type="button"
          rootAttributes={{
            id: "language-setting",
          }}
          variant="outlined"
          size={"normal"}
          //   color=""
          className={
            clsx()
            // classes.avatar_setting__icon
            // sideIn && classes.avatar_setting_icon__min
          }
          icon={GlobeLinear}
        ></Button> */}
        <div id="language-setting">
          <IconButton
            className={classes.langIcon}
            //   onClick={onAdd}
            variant="text"
            type="button"
            icon={GlobeLinear}
          />
        </div>
        <DropdownMenu
          anchor={"bottom-start"}
          toggleId="language-setting"
          nestedToggleId="language-nested-container"
          className={classes.avatarSettingDropdownMenu}
        >
          {LANGUAGES?.map((item, index) => (
            <DropdownItem
              onClick={() => {
                // history.push(`${base}dashboard`);
                // // TODO UNCOMMENT
                // document.body.style.overflowY = "afuto";
                // reloadPage();
                changeLang(item);
              }}
            >
              <div
                className={clsx(
                  "d-flex",
                  classes.languagePlace,
                  language.langCode === item.langCode && classes.selectedLang
                )}
              >
                <div className="d-flex justify-content-between w-100">
                  <div className={clsx(classes.profileName, classes.lang)}>
                    {item.langName}
                  </div>
                  <div className={classes.profileRole}>{item.langCode}</div>
                </div>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

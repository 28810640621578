import IDropdown from "../formikFields/IDropdown";
import { FastDropdown } from "../formikFields";
import { AwardBold } from "components/icon";
import { useDoctorsSpecialitiesOptions } from "hooks/dropdownOptions/useDoctorsSpecialitiesOptions";
import { useTranslation } from "react-i18next";

interface IProps<T> extends IDropdown<T> {}

export function Speciality<T>(props: IProps<T>) {
  const { t } = useTranslation("form");

  // get the doctor speciality from server
  const { OPTIONS, isError, isLoading } = useDoctorsSpecialitiesOptions();

  return (
    <FastDropdown
      label={t("speciality")}
      options={OPTIONS}
      isError={isError}
      isLoading={isLoading}
      icon={AwardBold}
      {...props}
    />
  );
}

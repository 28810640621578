import Alert from "../alert";
import {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";

export interface IPrintNotifProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
}

const PrintNotif = ({ open, setOpen, name }: IPrintNotifProps) => {
  const { t } = useTranslation("global", { keyPrefix: "print_notif" });

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      title={t("title", { name: name })}
      text={t("text")}
      dismissible
      approveText={t("print")}
      onApprove={() => {
        setOpen(false);
        setTimeout(() => {
          window.print();
        }, 1000);
      }}
    />
  );
};

export default PrintNotif;

import apiCaller, { IResponse } from "api";
import { TDevices } from "interfaces/devices";
import {
  IPaginationParams,
  IPaginationTableList,
} from "./types/paginationType";
import { ISelectedItem } from "../components/core/checkboxList";

const APPOINTMENTS = "appointments";
const DOCTOR_APPOINTMENTS = "appointments/doctor-appointments";
const DOCTOR_STATISTICS = "appointments/doctor-appointments-statistics";
const PATIENT_APPOINTMENTS = "appointments/patient-appointments";
const STAFF_APPOINTMENTS = "appointments/staff-appointments";
const PATIENT_STATISTICS = "appointments/patient-appointments-statistics";
const STAFF_STATISTICS = "appointments/staff-appointments-statistics";

interface ICountry {
  name: string;
  flag: string;
}

export type TNoShowCauses =
  | "doctor-no-show"
  | "patient-no-show"
  | "system-caused-no-show"
  | "";

export interface ICreateAppointment {
  start_date?: string | Date;
  // robot_id: number;
  doctor_id: number;
  visit_type: string;
  reason: string;
  lang: string; // key of dropdown e.g. en
  robot_location: string;
  patient_id: number | null;
  tests?: { type: string; file: string }[];
  international_doctor_id?: number;
  patient_type?: `App\\User` | `App\\Models\\FamilyMember` | null;
  payment_type?: string; //wallet, stripe or paypal
  discount_code?: string;
  height: number | "";
  weight: number | "";
  // time_from: string;
  // time_to: string;
  // robot_id: number;
  // user_id: number;
  // date: string;
}

export interface IAppointment {
  robot_id: string;
  robot_level: string;
  country: string;
  facility: string;
  department: string;
  data: string;
  time: string;
  status: boolean;
  id: number;
}

export interface IDoctorAppointment {
  appt_id: string;
  appt_type: string;
  doctor: {
    id: number;
    pid: string;
    name: string;
    email: string;
    avatar: string;
    gender: "Male" | "Female";
    speciality: string;
  };
  international_summary_note_id: string;
  international_summary_note_signed_at: string;
  international_doctor: {
    id: number;
    pid: string;
    name: string;
    email: string;
    avatar: string;
    gender: "Male" | "Female";
    speciality: string;
  };
  patient: {
    id: number;
    user_id: string;
    name: string;
    gender: "Male" | "Female";
    birthday: string;
  };
  visit_for: string;
  visit_for_key: string;
  language: string;
  intake_form_id: number;
  robot: {
    id: number;
    level: string;
    name: string;
    robot_id: string;
    autoxing_id: string;
    type: string;
  };
  facility: string;
  department: string;
  date: string;
  status: {
    canceled: boolean;
    checking_in: boolean;
    completed: boolean;
    in_session: boolean;
    now_show: boolean;
    patient_is_ready: boolean;
    pending: boolean;
    pending_summary_note: boolean;
    can_join: boolean;
    can_cancel: boolean;
  };
  id: number;
  devices: string[];
  summary_note_id: number;
  summary_note_signed_at: string;
  addendum: {
    id: number;
    appointment_id: number;
    content: string;
    created_at: string;
    updated_at: string;
  }[];
  can_drive: boolean;
  drivable: boolean;
}
export interface IDoctorStatistics {
  status: string;
  count: number;
}
interface IDoctor {
  id: number | null; //38;
  name: string | null; //"Ali Najafi";
  city_id: number | null; // 180;
  state_id: number | null; //59;
  country_id: number | null; //3;
  speciality: string | null; //"Heart";
  gender: string | null; //"female";
}
interface IPatientAppointment {
  id: number; // 90;
  appt_id: string; // "loc-0000090";
  end_date: null | string;
  start_date: null | string;
  visit_type: string; // "first visit";
  robot_city_id: number; // 127775;
  robot_state_id: number; //4737;
  robot_country_id: number; //231;
  robot_hf_type: string; // "hospital";
  robot_hf_name: string; //"lyons";
  robot_hf_id: number; //44;
  robot_type: string; //"hospital-robot";
  robot_level: string; //"robo-cam";
  robot_name: string; //"1234567-husan";
  robot_id: number; //26;
  local_doctor: IDoctor;
  international_doctor: IDoctor;
  patient: {
    id: number; //17;
    gender: string; //"male";
    name: string; //"Elta Raynor";
    birthday: string; //"2012-11-06T00:00:00.000000Z";
    reference_id: number; // 37;
  };
  intake_form: {
    id: number; //54;
    visit_for: string; //"my_self";
    patient_id: number; //37;
    reason: string; //"bbbbbbbbbbb";
    tests: {
      type: string; //"labs";
      file: string; //"fb20dcc8-8692-4975-b045-d935555d90e9";
    }[];
    lang: string; //"la";
    robot_location: string; // "home_without_robot";
    created_at: string; //"2023-03-07T13:21:49.000000Z";
    updated_at: string; //"2023-03-12T09:57:41.000000Z";
  };
}
export interface IAppointmentDetail {
  date: string;
  doctor_city_id: number;
  doctor_country_id: number;
  doctor_dna_id: number;
  doctor_dna_name: string;
  doctor_id: number;
  doctor_speciality: string;
  doctor_state_id: number;
  doctor_name: string;
  id: number;
  robot_city_id: number;
  robot_country_id: number;
  robot_hf_id: number;
  robot_hf_name: string;
  robot_hf_type: string;
  robot_id: number;
  robot_level: string;
  robot_name: string;
  robot_state_id: number;
  robot_type: string;
  time_from: string;
  time_to: string;
}

export interface INoshowReport {
  doctor_name: string;
  doctor_email: string;
  robot_id: string;
  robot_level: string;
  robot_name: string;
  country: ICountry;
  facility: string;
  department: string;
  data: string;
  time: string;
  no_show_cause: TNoShowCauses;
  id: number;
  devices: string[];
}

interface INoShowCausesParams {
  page: number;
  per_page: number;
  robot_types?: string[];
  robot_levels?: string[];
  device_types?: string[];
  appointment_types?: string[];
  causes?: string[];
  filter?: string;
  my_doctors?: boolean;
}

export interface INoShowCausesResponse {
  doctor_name: string;
  doctor_id: string;
  doctor_email: string;
  doctor_speciality: string;
  doctor_avatar: string;
  robot: {
    id: number;
    level: string;
    name: string;
    robot_id: string;
    type: string;
  };
  country: {
    name: string;
    flag: string;
  };
  facility: string;
  department: string;
  date: string;
  no_show_cause: TNoShowCauses;
  id: number;
  appt_id: string;
  appt_type: string;
  devices: any[];
  patient_name: string;
}

interface INoShowCausesStatsResponse {
  "doctor-no-show": number;
  "patient-no-show": number;
  "system-caused-no-show": number;
}

export interface ISuperAdminAppointments {
  appt_type: "local" | "international";
  appt_id: string;
  doctor_id: number;
  doctor: {
    id: number;
    pid: string;
    name: string;
    email: string;
    avatar: string;
    gender: "Female" | "Male";
    speciality: string;
    country_flag: string;
  };
  international_doctor: {
    id: number;
    pid: string;
    name: string;
    email: string;
    avatar: string;
    gender: "Female" | "Male";
    speciality: string;
    country_flag: string;
  };
  patient: {
    id: number;
    user_id: string;
    name: string;
    gender: "Female" | "Male";
    birthday: string;
    avatar: string;
  };
  visit_for: string;
  language: string;
  intake_form_id: number;
  robot: {
    id: number;
    level: string;
    name: string;
    robot_id: string;
    type: string;
  };
  facility: string;
  department: string;
  date: string;
  status: {
    canceled: boolean;
    checking_in: boolean;
    completed: boolean;
    in_session: boolean;
    now_show: boolean;
    patient_is_ready: boolean;
    pending: boolean;
    pending_summary_note: boolean;
    can_join: boolean;
    can_cancel: boolean;
  };
  id: number;
  visit_type: string;
  devices: string[];
  summary_note_id: number;
  summary_note_signed_at: string;
  addendum: {
    id: number;
    appointment_id: number;
    content: string;
    created_at: string;
    updated_at: string;
  }[];
  visit_cost: number;
}

export function getSuperAdminAppointments({
  page,
  per_page,
  status,
  appointment_types,
  filter,
  scheduled,
  robot_location,
}: {
  page: number;
  per_page: number;
  filter: string;
  scheduled?: boolean;
  appointment_types?: string[];
  status: string[];
  robot_location?: string[];
}): Promise<IResponse<IPaginationTableList<ISuperAdminAppointments>>> {
  return apiCaller.get("/appointments/super-admin-appointments", {
    params: {
      page,
      per_page,
      status,
      appointment_types,
      filter,
      scheduled,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      robot_location,
    },
  });
}

export function getAllAppointmentsForStaff(
  page: number,
  resultsPerPage: number,
  filter?: string,
  robot_types?: string[],
  robot_levels?: string[],
  device_types?: number[],
  doctor_status?: number
): Promise<IResponse<IPaginationTableList<IAppointment>>> {
  return apiCaller.get(APPOINTMENTS, {
    params: {
      page,
      per_page: resultsPerPage,
      filter,
      robot_types,
      robot_levels,
      device_types,
      doctor_status: doctor_status !== -1 ? doctor_status : undefined,
    },
  });
}
export interface IPatientAppointmentStatisticsParams {
  time: "all" | "past" | "today" | "future";
  scheduled: boolean;
  appointment_types?: string[];
  genders?: string[];
  status?: string[];
  from?: string;
  to?: string;
  hf_types?: string[];
  my_doctors?: boolean;
  robot_location?: string[];
}
export function getPatientAppointmentStatisticsOrStaff(
  userType: "staff" | "patient",
  params: IPatientAppointmentStatisticsParams
): Promise<IResponse<IDoctorStatistics[]>> {
  return apiCaller.get(
    userType === "staff" ? STAFF_STATISTICS : PATIENT_STATISTICS,
    { params: params }
  );
}
export function getDoctorStatistics(
  scheduled: boolean,
  time: "all" | "past" | "today" | "future",
  page: number,
  resultsPerPage: number,
  filter?: string,
  appointment_type?: string[],
  gender?: string[],
  hf_types?: string[],
  status?: string[],
  from?: string,
  to?: string,
  robot_types?: string[],
  summary?: boolean,
  robot_location?: string[]
): Promise<IResponse<IDoctorStatistics[]>> {
  return apiCaller.get(DOCTOR_STATISTICS, {
    params: !summary
      ? {
          scheduled,
          time,
          page,
          per_page: resultsPerPage,
          filter,
          appointment_types: appointment_type,
          genders: gender,
          hf_types,
          status,
          from,
          to,
          robot_types,
          robot_location,
        }
      : {
          time,
          page,
          per_page: resultsPerPage,
          filter,
          appointment_types: appointment_type,
          genders: gender,
          hf_types,
          status,
          from,
          to,
          robot_types,
          robot_location,
        },
  });
}

export function getAppointmentsForDoctor(
  scheduled: boolean,
  time: "all" | "past" | "today" | "future",
  page: number,
  resultsPerPage: number,
  filter?: string,
  appointment_type?: string[],
  gender?: string[],
  hf_types?: string[],
  status?: string[],
  from?: string,
  to?: string,
  robot_types?: string[],
  summary?: boolean,
  robot_location?: string[]
): Promise<IResponse<IPaginationTableList<IDoctorAppointment>>> {
  return apiCaller.get(DOCTOR_APPOINTMENTS, {
    params: !summary
      ? {
          scheduled,
          time,
          page,
          per_page: resultsPerPage,
          filter,
          appointment_types: appointment_type,
          genders: gender,
          hf_types,
          status,
          from,
          to,
          robot_types,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          robot_location,
        }
      : {
          time,
          page,
          per_page: resultsPerPage,
          filter,
          appointment_types: appointment_type,
          genders: gender,
          hf_types,
          status,
          from,
          to,
          robot_types,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          robot_location,
        },
  });
}

export function getDoctorSummaryNotes(
  page: number,
  resultsPerPage: number,
  appointment_type?: string[],
  robot_types?: string[],
  hf_types?: string[],
  from?: string,
  to?: string,
  filter?: string,
  patient_id?: string,
  patient_type?: string,
  genders?: string[]
): Promise<IResponse<IPaginationTableList<IDoctorAppointment>>> {
  return apiCaller.get(DOCTOR_APPOINTMENTS, {
    params: {
      page,
      per_page: resultsPerPage,
      filter,
      appointment_types: appointment_type,
      robot_types,
      hf_types,
      from,
      to,
      has_summary_note: true,
      patient_id,
      patient_type,
      genders,
    },
  });
}

//TODO: fix the duplication after completing staff appointment
export function getAppointment(
  appointment_id: number
): Promise<IResponse<IAppointmentDetail>> {
  return apiCaller.get(`${APPOINTMENTS}/${appointment_id}`);
}
export function getPatientAppointment(
  appointment_id: number
): Promise<IResponse<IPatientAppointment>> {
  return apiCaller.get(`${APPOINTMENTS}/${appointment_id}`);
}

export function getNotConfirmedAppointments(
  page: number,
  resultsPerPage: number,
  filter: string,
  gender?: string
): Promise<IResponse<IPaginationTableList<IDoctorAppointment>>> {
  return apiCaller.get(`${APPOINTMENTS}/not-confirmed`, {
    params: {
      page,
      per_page: resultsPerPage,
      filter,
      gender,
    },
  });
}
export interface ICreateAppointmentResponse {
  id: 135;
  appt_id: "loc-0000135";
  appt_type: "local";
  end_date: "2023-04-10T07:00:00.000000Z";
  start_date: "2023-04-10T06:00:00.000000Z";
  visit_type: "first visit";
  robot_city_id: 127775;
  robot_state_id: 4737;
  robot_country_id: 231;
  robot_hf_type: "hospital";
  robot_hf_name: "lyons";
  robot_hf_id: 44;
  robot_type: "tv-robot";
  robot_level: "robo-cam";
  robot_name: "12312313-3123132";
  robot_id: 64;
  local_doctor: {
    id: 35;
    user_id: "D-000000035";
    doctor_id: 22;
    flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg";
    avatar: null;
    first_name: "Cyrus";
    last_name: "Allen";
    city_id: 127775;
    state_id: 4737;
    country_id: 231;
    speciality: "Surgery";
    gender: "male";
    email: "doctor@husan.ir";
    timezone: {
      id: 379;
      name: "America/Adak";
      gmt_offset_name: "UTC-10:00";
    };
  };
  international_doctor: null;
  patient: {
    id: 37;
    gender: "male";
    first_name: "Allen";
    last_name: "Matin";
    birthday: "2000-02-16 00:00:00";
    reference_id: "";
  };
  intake_form: null;
  cost: 10;
  deleted: false;
  completed: false;
  checked_in: false;
}
export interface ICheckoutLink {
  link: string;
}
export function createAppointment(
  appointment: ICreateAppointment
): Promise<IResponse<ICreateAppointmentResponse | ICheckoutLink>> {
  return apiCaller.post(APPOINTMENTS, {
    ...appointment,
  });
}
export function createOnDemandAppointment(appointment: ICreateAppointment) {
  return apiCaller.post("appointments/ondemand", {
    ...appointment,
  });
}

export function updateAppointment(
  appointment_id: number,
  appointment: ICreateAppointment
): Promise<IResponse<ICreateAppointment>> {
  return apiCaller.put(`${APPOINTMENTS}/${appointment_id}`, {
    ...appointment,
  });
}

export function deleteAppointment(
  appointment_id: number
): Promise<IResponse<IAppointment>> {
  return apiCaller.delete(`${APPOINTMENTS}/${appointment_id}`);
}

export type TConfirmStatus = "delay" | "confirm" | "absent";

export function confirmAppointment(
  appointment_id: number,
  status: TConfirmStatus
): Promise<IResponse<ICreateAppointment>> {
  return apiCaller.put(`${APPOINTMENTS}/${appointment_id}/confirm`, {
    status,
  });
}

export function getNoshowReports(
  page: number,
  resultsPerPage: number,
  filter?: string,
  robot_types?: string[],
  robot_levels?: string[],
  device_types?: number[],
  causes?: string[]
): Promise<IResponse<IPaginationTableList<INoshowReport>>> {
  return apiCaller.get(`${APPOINTMENTS}/reports`, {
    params: {
      page,
      per_page: resultsPerPage,
      filter,
      causes,
      robot_types,
      robot_levels,
      device_types,
    },
  });
}

export function getNoShowCauses(
  params: INoShowCausesParams
): Promise<IResponse<IPaginationTableList<INoShowCausesResponse>>> {
  return apiCaller.get(`${APPOINTMENTS}/reports`, {
    params,
  });
}

export function getNoShowCausesStats(): Promise<
  IResponse<INoShowCausesStatsResponse>
> {
  return apiCaller.get(`${APPOINTMENTS}/reports-statistics`);
}

/*

"patient_name": "Doctor -",
                "robot_name": "_9743224-model",
                "flag": "8cRp5N7PVj",
                "country": "Ghana",
                "facility": "Lilian Becker DDS",
                "department": null,
                "device": "EC",
                "date": "1982-09-14T10:26:24.000000Z",
                "time": "21:58",
                "id": 2

*/

export interface IAppointmentResult {
  patient_name: string;
  robot_name: string;
  flag: string;
  country: string;
  facility: string;
  department: string;
  device: string;
  device_type: TDevices;
  date: string;
  time: string;
  id: number;
  data_id: number;
}

export function getAppointmentResults(
  page: number,
  resultsPerPage: number,
  filter?: string
): Promise<IResponse<IPaginationTableList<IAppointmentResult>>> {
  return apiCaller.get(`${APPOINTMENTS}/results`, {
    params: {
      page,
      per_page: resultsPerPage,
      filter,
    },
  });
}

export function reportAppointment({
  appointment_id,
  no_show_cause,
}: {
  appointment_id: number;
  no_show_cause: "doctor-no-show" | "patient-no-show" | "system-caused-no-show";
}): Promise<IResponse<any>> {
  return apiCaller.put(`${APPOINTMENTS}/${appointment_id}/report`, {
    no_show_cause,
  });
}

export function doctorGetReady(
  appointment_id: number,
  ready: boolean
): Promise<IResponse<any>> {
  return apiCaller.put(`${APPOINTMENTS}/${appointment_id}/doctor-ready`, {
    ready,
  });
}

export function staffGetReady(
  appointment_id: number,
  ready: boolean
): Promise<IResponse<any>> {
  return apiCaller.put(`${APPOINTMENTS}/${appointment_id}/staff-ready`, {
    ready,
  });
}

export interface IEditAppointmentParams {
  id: number;
  start_date: string;
  visit_type?: string;
}

export function editAppointment({
  id,
  start_date,
  visit_type,
}: IEditAppointmentParams): Promise<IResponse<any>> {
  return apiCaller.put(`/appointments/${id}`, { start_date, visit_type });
}

export interface ISuperAdminAppointmentStatisticsParams {
  filter: string;
  time?: "all" | "past" | "today" | "future";
  scheduled: boolean;
  appointment_types?: string[];
  robot_types?: string[];
  genders?: string[];
  status?: string[];
  from?: string;
  to?: string;
  hf_types?: string;
  robot_location?: string[];
}

export interface ISuperAdminAppointmentStatistics {
  status: string;
  count: number;
}

export function getSuperAdminAppointmentStatistics(
  data: ISuperAdminAppointmentStatisticsParams
): Promise<IResponse<ISuperAdminAppointmentStatistics[]>> {
  return apiCaller.get("/appointments/super-admin-appointments-statistics", {
    params: data,
  });
}

export interface IUnsignedSummaryNote {
  id: number;
  appointment: {
    id: number;
    type: "local" | "international";
    date: string;
  };
  local_doctor: {
    id: number;
    pid: string;
    gender: "male" | "female" | "others";
    name: string;
    speciality: string;
  };
  international_doctor: {
    id: number;
    pid: string;
    gender: "male" | "female" | "others";
    name: string;
    speciality: string;
  };
  robot: {
    id: number;
    robot_id: string;
    name: string;
    type: string;
    devices: string;
  };
  reason?: string;
  consents: { consent: boolean };
  followup_interval: string;
  followup_doctor: string;
  referral: {
    doctor_id: string;
    doctor_name: string;
    type: string;
    speciality: string;
    interval: string;
    status: boolean;
  };
  history_of_present_illness: string;
  review_of_systems: string;
  physical_exam: string;
  impressions: string;
  prescription: {
    want_prescription: boolean;
    medicines: {
      id: number;
      medicine_name: string;
      dosage: string;
      formulation: string;
      frequency: string;
      quantity: string;
      refills: string;
    }[];
    allow_substitution: boolean;
  };
  constitution: ISelectedItem[];
  past_medical_history: [
    {
      type: "history_of_illnesses";
      value: {
        illnesses: string[];
        comment: string;
        hospitalized: boolean;
        hospitalized_reasons: { reason: string; date: string }[];
        past_surgery: boolean;
        past_surgery_types: { surgery_type: string; date: string }[];
      };
    },
    {
      type: "medications_and_allergies";
      value: {
        allergies: { id: number; allergy: string; reaction: string }[];
        allergic_reactions: string[];
        food_allergic: string[];
        other_allergic: string[];
        medicines: {
          id: number;
          medicine_name: string;
          dosage: string;
          formulation: string;
          frequency: string;
          taking: "Yes" | "No";
          unit: string;
        }[];
      };
    }
  ];
  orders: {
    labs: string[];
    imagings: string[];
    tests: string[];
    plans: string[];
    has_labs: boolean;
    has_imagings: boolean;
    has_tests: boolean;
    has_plans: boolean;
  };
  note: string;
  education: string;
  instructions: string;
  signed_at: string;
}

export function getUnsignedSummaryNote(
  id: number
): Promise<IResponse<IUnsignedSummaryNote>> {
  return apiCaller.get(`/visit-summary-notes/unsigned/${id}`);
}
export interface IStaffAppointments {
  id: number;
  appt_id: string;
  doctor_id: number;
  appt_type: "local" | "international";
  visit_type: string;
  date: string;
  patient: {
    id: number;
    pid: string;
    name: string;
    gender: "Male" | "Female";
    birthday: string;
  };
  robot: {
    id: number;
    robot_id: string;
    level: string;
    type: string;
    name: string;
  };
  doctor: {
    id: number;
    pid: string;
    name: string;
    email: string;
    avatar: string;
    gender: "Male" | "Female";
    speciality: string;
    country_flag: string;
  };
  international_doctor: {
    id: number;
    pid: string;
    name: string;
    email: string;
    avatar: string;
    gender: "Male" | "Female";
    speciality: string;
  };
  visit_cost: number;
  facility: string;
  status: {
    canceled: boolean;
    checking_in: boolean;
    completed: boolean;
    in_session: boolean;
    now_show: boolean;
    patient_is_ready: boolean;
    pending: boolean;
    pending_summary_note: boolean;
    can_join: boolean;
    can_cancel: boolean;
  };
  devices: string[];
  language: string;
  intake_form_id: number;
  summary_note_id: number;
  international_summary_note_id: number;
  addendum: {
    id: number;
    appointment_id: number;
    content: string;
    created_at: string;
    updated_at: string;
  }[];
}

export interface IStaffAppointmentsParams extends IPaginationParams {
  scheduled?: boolean;
  filter: string;
  robot_types?: string[];
  robot_levels?: string[];
  device_types?: string[];
  doctor_status?: number;
  visit_types?: string[];
  status?: string[];
  appointment_types?: string[];
  hf_types?: string[];
  has_summary_note?: boolean;
  from?: string;
  to?: string;
  my_doctors?: boolean;
  robot_location?: string[];
}

export interface IStaffAppointmentsStatisticsParams {
  scheduled?: boolean;
  filter: string;
  robot_types?: string[];
  robot_levels?: string[];
  device_types?: string[];
  doctor_status?: number;
  visit_types?: string[];
  status?: string[];
  appointment_types?: string[];
  hf_types?: string[];
  has_summary_note?: boolean;
  from?: string;
  to?: string;
  robot_location?: string[];
}

export function getStaffAppointments(
  data: IStaffAppointmentsParams
): Promise<IResponse<IPaginationTableList<IStaffAppointments>>> {
  return apiCaller.get("/appointments/staff-appointments", {
    params: {
      ...data,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
}

export interface IStaffStatistics {
  status: string;
  count: number;
}

export function getStaffAppointmentsStatistics(
  data: IStaffAppointmentsStatisticsParams
): Promise<IResponse<IStaffStatistics[]>> {
  return apiCaller.get("/appointments/staff-appointments-statistics", {
    params: data,
  });
}

export interface IPatientAppointmentList {
  id: 7;
  doctor_id: number;
  cost: string;
  appt_id: "loc-0000007";
  appt_type: "local";
  visit_type: "E-consultant";
  date: "2022-11-15 14:00:00";
  robot: {
    id: 3;
    robot_id: "R-000003-cart";
    level: "robo-cart";
    type: "ambulance-robot";
    name: "_7327159-model";
  };
  doctor: {
    avatar: "99a81375-f718-4f96-bbcc-05b0eb2266b7";
    doctor_id: 23;
    email: "demo@lyonsglobal.us";
    flag: "https://cdn.kcak11.com/CountryFlags/countries/al.svg";
    gender: "Female";
    id: 38;
    name: "Bita Lyons";
    pid: "D-000000038";
    speciality: "Heart";
    timezone: { id: 3; name: "Europe/Tirane"; gmt_offset_name: "UTC+01:00" };
    user_id: "D-000000038";
    country_flag: string;
  };
  international_doctor: null;
  intake_form_id: string;
  patient: {
    id: 224;
    pid: "P-000000224";
    name: "Patient Patienty";
    gender: "Female";
    birthday: "1997-06-01 00:00:00";
    avatar: "45bbaad9-f67e-4ad1-a568-38ded7eb5949";
  };
  facility: "Mr. Madisen Sporer PhD";
  status: {
    canceled: boolean;
    checking_in: boolean;
    completed: boolean;
    in_session: boolean;
    now_show: boolean;
    patient_is_ready: boolean;
    pending: boolean;
    pending_summary_note: boolean;
    can_join: boolean;
    can_cancel: boolean;
    can_no_show?: boolean;
  };
  visit_for: string;
  devices: [
    "BP",
    "PO",
    "WS",
    "TM",
    "EC",
    "SC",
    "GM",
    "PC",
    "BP",
    "PO",
    "WS",
    "TM",
    "EC",
    "SC",
    "GM",
    "PC"
  ];
  summary_note_id: 52;
  addendum: [
    {
      id: 5;
      appointment_id: 7;
      content: "This is okay";
      created_at: "2023-02-12T09:26:41.000000Z";
      updated_at: "2023-02-12T09:26:41.000000Z";
    }
  ];
}
export function getAppointmentsForPatientOrStaff(
  userType: "staff" | "patient",
  scheduled: boolean,
  time: "all" | "past" | "today" | "future",
  page: number,
  resultsPerPage: number,
  filter?: string,
  appointment_type?: string[],
  gender?: string[],
  hf_types?: string[],
  status?: string[],
  from?: string,
  to?: string,
  robot_types?: string[],
  my_doctors?: boolean,
  relationship?: string[],
  robot_location?: string[]
): Promise<IResponse<IPaginationTableList<IPatientAppointmentList>>> {
  return apiCaller.get(
    userType === "patient" ? PATIENT_APPOINTMENTS : STAFF_APPOINTMENTS,
    {
      params: {
        scheduled,
        time,
        page,
        per_page: resultsPerPage,
        filter,
        appointment_types: appointment_type,
        genders: gender,
        hf_types,
        status,
        from,
        to,
        robot_types,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        my_doctors,
        relationship,
        robot_location,
      },
    }
  );
}

export interface IShowAppointment {
  id: number;
  appt_id: string;
  appt_type: "local" | "international";
  end_date: string;
  start_date: string;
  visit_type: string;
  visit_type_key: string;
  is_schedule: boolean;
  visit_for: string;
  language: string;
  devices: string[];
  robot: {
    id: number;
    level: string;
    name: string;
    type: string;
    type_value: string;
    type_id: string;
    robot_id: string;
  };
  facility: {
    city_id: number;
    country_id: number;
    id: number;
    name: string;
    state_id: number;
    type: string;
  };
  local_doctor: {
    signature_base: string;
    id: number;
    user_id: string;
    doctor_id: number;
    flag: string;
    avatar: string;
    first_name: string;
    last_name: string;
    city_id: number;
    state_id: number;
    country_id: number;
    speciality: string;
    gender: "male" | "female" | "others";
    email: string;
    timezone: {
      id: number;
      name: string;
      gmt_offset_name: string;
    };
  };
  international_doctor: {
    id: number;
    user_id: string;
    doctor_id: number;
    flag: string;
    avatar: string;
    first_name: string;
    last_name: string;
    city_id: number;
    state_id: number;
    country_id: number;
    speciality: string;
    gender: "male" | "female" | "others";
    email: string;
    timezone: {
      id: number;
      name: string;
      gmt_offset_name: string;
    };
  };
  patient: {
    id: number;
    gender: "male" | "female" | "others";
    first_name: string;
    last_name: string;
    birthday: string;
    reference_id: string;
    user_id: string;
  };
  intake_form: {
    id: number;
    visit_for: string;
    patient_id: number;
    reason: string;
    tests: {
      type: string;
      file: string;
    }[];
    lang: string;
    robot_location: string;
    created_at: string;
    updated_at: string;
  };
  cost: number;
  status: {
    canceled: boolean;
    checking_in: boolean;
    completed: boolean;
    in_session: boolean;
    now_show: boolean;
    patient_is_ready: boolean;
    pending: boolean;
    pending_summary_note: boolean;
    can_join: boolean;
    can_cancel: boolean;
  };
  no_show_cause: string | null;
  session_id: string;
}

export function showAppointment(
  id: number
): Promise<IResponse<IShowAppointment>> {
  return apiCaller.get(`/appointments/${id}`);
}

export function checkInAppointment(id: number): Promise<IResponse<any>> {
  return apiCaller.put(`appointments/${id}/checked-in`, { ready: true });
}
export function checkingInAppointment(id: number): Promise<IResponse<any>> {
  return apiCaller.put(`appointments/${id}/checking-in`);
}

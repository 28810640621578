export const EXCEL_FORMATS = [
  ".csv",
  "application/vnd.MS-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const IMAGE_FORMATS = ["image/png", "image/gif", "image/jpeg"];
export const IMAGE_PDF_FORMATS = [
  "image/png",
  "image/gif",
  "image/jpeg",
  "application/pdf",
];

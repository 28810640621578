import apiCaller, { IResponse } from "./index";
import {
  IPaginationParams,
  IPaginationTableList,
} from "./types/paginationType";

export interface IPatientFollowUp {
  id: number;
  appointment: {
    id: number;
    pid: string;
    type: "local" | "international";
    date: string;
  };
  patient: {
    id: number;
    pid: string;
    name: string;
    birthday: string;
    timezone: {
      id: number;
      zone_name: string;
      gmt_offset: string;
      gmt_offset_name: string;
      country_id: string;
    };
  };
  doctor: {
    id: number;
    pid: string;
    name: string;
    avatar: string;
    speciality: string;
    timezone: {
      id: number;
      zone_name: string;
      gmt_offset: string;
      gmt_offset_name: string;
      country_id: string;
    };
  };
  hf_name: string;
  followup_status: boolean;
}

export interface IPatientHealthRecordParams extends IPaginationParams {
  appointment_types: string[];
  from?: string;
  to?: string;
  filter: string;
  status?: string[];
  relationship?: string[];
}

export function getPatientFollowUps(
  data: IPatientHealthRecordParams
): Promise<IResponse<IPaginationTableList<IPatientFollowUp>>> {
  return apiCaller.get("/health-record/patient/followup", { params: data });
}

export interface IPatientOrders {
  id: number;
  appointment: {
    id: number;
    pid: string;
    type: "local" | "international";
    date: string;
    timezone: string;
  };
  patient: {
    id: number;
    pid: string;
    name: string;
    birthday: string;
  };
  doctor: {
    id: number;
    pid: string;
    name: string;
    avatar: string;
    speciality: string;
  };
  hf_name: string;
}

export function getPatientOrders(
  data: IPatientHealthRecordParams
): Promise<IResponse<IPaginationTableList<IPatientOrders>>> {
  return apiCaller.get("/health-record/patient/orders", { params: data });
}

export interface IPatientReferrals {
  id: number;
  appointment: {
    id: number;
    pid: string;
    type: "local" | "international";
    date: string;
    timezone: string;
  };
  patient: {
    id: number;
    pid: string;
    name: string;
    birthday: string;
  };
  doctor: {
    id: number;
    pid: string;
    name: string;
    avatar: string;
    speciality: string;
  };
  creator_doctor: {
    id: number;
    pid: string;
    name: string;
    avatar: string;
    speciality: string;
  };
  hf_name: string;
  status: boolean;
}

export function getPatientReferrals(
  data: IPatientHealthRecordParams
): Promise<IResponse<IPaginationTableList<IPatientReferrals>>> {
  return apiCaller.get("/health-record/patient/referral", { params: data });
}

export interface IPatientPrescription {
  id: number;
  appointment: {
    id: number;
    pid: string;
    type: "local" | "international";
    date: string;
    timezone: "";
  };
  patient: {
    id: number;
    pid: string;
    name: string;
    birthday: string;
  };
  doctor: {
    id: number;
    pid: string;
    name: string;
    avatar: string;
    speciality: string;
  };
  hf_name: string;
}

export function getPatientPrescriptions(
  data: IPatientHealthRecordParams
): Promise<IResponse<IPaginationTableList<IPatientPrescription>>> {
  return apiCaller.get("/health-record/patient/prescription", { params: data });
}

export interface IShowPatientFollowUp {
  doctor: {
    id: number;
    pid: string;
    signature: string;
    full_name: string;
    gender: "Male" | "Female";
    speciality: string;
    avatar: string;
    language: {
      id: number;
      user_id: number;
      language: string;
      language_fluency: string;
      created_at: string;
      updated_at: string;
      deleted_at: string;
    }[];
  };
  followup: {
    status: boolean;
    interval: string;
    doctor_id: number;
  };
}

export function showPatientFollowUp(
  id: number
): Promise<IResponse<IShowPatientFollowUp>> {
  return apiCaller.get(`health-record/${"patient"}/followup/${id}`);
}

export interface IShowPatientOrder {
  labs: string[];
  imagings: string[];
  tests: string[];
  plans: string[];
}

export function showPatientOrders(
  id: number
): Promise<IResponse<IShowPatientOrder>> {
  return apiCaller.get(`health-record/patient/orders/${id}`);
}

export interface IShowPatientPrescription {
  want_prescription: boolean;
  medicines: {
    id: number;
    medicine_name: string;
    dosage: string;
    formulation: string;
    frequency: string;
    quantity: string;
    refills: string;
  }[];
  allow_substitution: boolean;
}

export function showPatientPrescription(
  id: number
): Promise<IResponse<IShowPatientPrescription>> {
  return apiCaller.get(`health-record/patient/prescription/${id}`);
}

export interface IShowPatientReferral {
  note: string;
  referral: {
    doctor_id: string;
    doctor_name: string;
    type: string;
    speciality: string;
    interval: string;
    status: boolean;
  };
}

export function showPatientReferral(
  id: number
): Promise<IResponse<IShowPatientReferral>> {
  return apiCaller.get(`health-record/patient/referral/${id}`);
}

export interface IGetStaffFollowUpParams extends IPaginationParams {
  appointment_type?: string[];
  from?: string;
  to?: string;
  filter: string;
  type: "referral" | "followup";
  my_doctors?: boolean;
  relationship?: string[];
  status?: string[];
}

export interface IStaffFollowUp {
  id: number;
  appointment: {
    id: number;
    appt_id: string;
    type: "local" | "international";
    date: string;
    facility: { id: number; name: string; type: string };
    timezone: string;
  };
  patient: {
    id: number;
    pid: string;
    name: string;
    relation: string;
    birthday: string;
  };
  hf_name: string;
  doctor: {
    id: number;
    pid: string;
    name: string;
    speciality: string;
    avatar: string;
    timezone: { id: number; name: string; gmt_offset_name: string };
  };
  creator_doctor: {
    id: number;
    pid: string;
    name: string;
    speciality: string;
    avatar: string;
    timezone: { id: number; name: string; gmt_offset_name: string };
  };
  followup_status: boolean;
  followup_date: string;
  referral_status: boolean;
}

export function getStaffFollowUp(
  params: IGetStaffFollowUpParams
): Promise<IResponse<IPaginationTableList<IStaffFollowUp>>> {
  return apiCaller.get("/followup", { params });
}

export interface IChangeFollowupStatusParams {
  id: number;
  status: boolean;
}

export function changeFollowupStatus(
  data: IChangeFollowupStatusParams
): Promise<IResponse<any>> {
  return apiCaller.put(`/health-record/change-followup-status`, data);
}

export function changeReferralStatus(
  data: IChangeFollowupStatusParams
): Promise<IResponse<any>> {
  return apiCaller.put(`/health-record/change-referral-status`, data);
}

import { FormikProps } from "formik";
import { IDefaultProps as IAutoCompleteProps } from "components/core/autoComplete";
import { useDropdownDefaultValue } from "hooks/useDropdownDefaultValue";
import FormAutoComplete from "components/form/formAutoComplete";
import { ManBold } from "components/icon";
import { getFormikFieldProps } from "utils/form";
import { useTranslation } from "react-i18next";
import { useOptions } from "../../../utils/options";

export interface IGenderProps<T> {
  formik: FormikProps<T>;
  genderFieldName: string;
  rootProps?: Partial<IAutoCompleteProps>;
  genderId?: string;
  genderName?: string;
  noPadding?: boolean;
}

//? sets gender in dropdown items

export function Gender<T extends { [key: string]: any }>({
  formik,
  genderFieldName,
  genderId,
  genderName,
  rootProps,
  noPadding,
}: IGenderProps<T>) {
  const { t } = useTranslation("form");
  const { GENDER } = useOptions();

  useDropdownDefaultValue({
    fieldName: genderFieldName,
    formik: formik,
    id: genderId,
    OPTIONS: GENDER,
    name: genderName,
  });
  return (
    <FormAutoComplete
      noPadding={noPadding}
      rootProps={{ icon: ManBold, placeholder: t("gender"), ...rootProps }}
      options={GENDER}
      {...getFormikFieldProps(genderFieldName, t("gender"), formik)}
    />
  );
}

import VideoComponent from "./components/videoComponent";
import TopBar from "./components/topBar";
import BottomBar from "./components/bottomBar";
import SideBar, { IVideoVisitSideBarItem } from "./components/sideBar";
import {
  CameraControlIconBold,
  ImageIcon,
  MedicalExaminationBold,
  MedicalHistoryBold,
  MessagingBold,
  ParticipantsBold,
  PastSummaryBold,
  VitalSignBold,
  WriteSummaryBold,
} from "../../icon";
import classes from "./styles.module.scss";
import clsx from "clsx";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Participants from "./components/participants";
import Messages from "./components/messages";
import CameraControl from "./components/cameraControl";
import Images from "./components/images/indedx";
import VitalSignData from "./components/vitalSignData";
import MinimizedVideoVisit from "./components/minimizedVideoVisit";
import useSession from "hooks/vonage/useSession";
import { toast } from "react-toastify";
import {
  IInfoMessage,
  IVonageMetaData,
  IVonageStream,
  TPtzCommand,
} from "interfaces/vonageStream";
import { IMessage } from "./components/messages/Message";
import MedicalExamination from "./components/medicalExamination";
import FirstPage from "./FirstPage";
// @ts-ignore
import { createFileName, useScreenshot } from "use-react-screenshot";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  consentVideoVisit,
  getVideoVisitStoredResult,
  getVideoVisitTests,
  guestJoinVideoVisit,
  IVideoVisitTests,
  joinAppointment,
} from "../../../api/videoVisit";
import {
  confirmAppointment,
  getAppointmentsForPatientOrStaff,
  getStaffAppointments,
  TConfirmStatus,
} from "../../../api/appointment";
import { humanizedDate, humanizedTime } from "../../../utils/date";
import Alert from "../alert";
import { notify } from "../../core/toast";
import { TRoleName } from "../../../api/user";
import BottomController from "./components/bottomController";
// @ts-ignore
import joinSound from "../../../assets/sounds/show.mp3";
import { IImagesItemProps } from "./components/images/ImagesItem";
import Button from "../../core/button";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { useUnblock } from "../../../hooks/useBlock";
import { doctorAppointmentsKey } from "../../../utils/keyGenerator/keys";
import { useTranslation } from "react-i18next";
import { useBaseUrl } from "../../../utils/route";

function playNotificationSound(): void {
  const audio = new Audio(joinSound);
  audio.play();
}

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

function round(number: number): number {
  const roundedNumber = Math.round(number * 100) / 100;
  if (roundedNumber - number >= 0.5) {
    return roundedNumber + 1;
  } else {
    return roundedNumber;
  }
}
function fToC(fahrenheit?: number): number {
  if (!fahrenheit) return 0;
  const value = ((fahrenheit - 32) * 5) / 9;

  return round(value);
}

interface IVideoVisitCtx {
  session: OT.Session | null;
  messages: IMessage[];
  messageHandler: (
    text: string,
    from: string,
    isMyMessage: boolean
  ) => IMessage;
  setSession: (session: OT.Session) => void;
  micMute: boolean;
  onMicMute: (state: boolean) => void;
  sound: boolean;
  onSoundMute: (state: boolean) => void;
  isCameraOpen: boolean;
  onCameraOpen: (state: boolean) => void;
  streamArr: IVonageStream[];
  infoMessage: IInfoMessage | undefined;
  staffState: string;
  setStaffState: (state: TStaffState) => void;
  hasNewMessage: boolean;
  setHasNewMessage: (state: boolean) => void;
}

export const VideoVisitContext = createContext<IVideoVisitCtx>({
  session: null,
  messages: [],
  messageHandler: (text: string, from: string, isMyMessage: boolean) => {
    return {
      from: "",
      text: "",
      timeText: "",
      isMyMessage: false,
    };
  },
  setSession: (session: OT.Session) => null,
  micMute: false,
  onMicMute: (state: boolean) => {},
  sound: true,
  onSoundMute: (state: boolean) => {},
  isCameraOpen: true,
  onCameraOpen: (state: boolean) => {},
  streamArr: [],
  infoMessage: undefined,
  staffState: "ptz-close",
  setStaffState: (state: TStaffState) => {},
  hasNewMessage: false,
  setHasNewMessage: (state: boolean) => {},
});
type TStaffState =
  | "ptz-open"
  | "webcam-open"
  | "otoscope-open"
  | "examcamera-open";

export interface IVideoSession {
  apiKey: string;
  sessionId: string;
  token: string;
}

export interface IVideoVisitProps {
  date: Date;
  /*session: IVideoSession;*/
  appt_id: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  role?: TRoleName | "guest";
  minimize: boolean;
  setMinimize: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
}

export interface IInitializedDevices {
  mic: string;
  speaker: string;
  volume: number;
  camera: string;
}

// video visit component
const VideoVisit = ({
  date,
  appt_id,
  open,
  setOpen,
  role,
  minimize,
  setMinimize,
  name,
}: IVideoVisitProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  const base = useBaseUrl();

  const portraitDetect =
    window?.screen?.orientation?.type?.includes("portrait") || false;

  const token = useParams<{ token: string }>()?.token;
  const {
    data: joinData,
    isLoading,
    isError,
  } = useQuery(
    [joinAppointment.name, appt_id],
    () => joinAppointment(appt_id),
    { enabled: open && !token, staleTime: Infinity, cacheTime: Infinity }
  );

  const { mutate: consentMutate } = useMutation(() =>
    consentVideoVisit(appt_id)
  );

  const {
    data: guestData,
    isLoading: isLoad,
    isError: isErr,
    mutate: joinGuest,
  } = useMutation(guestJoinVideoVisit);

  useEffect(() => {
    if (name && open) {
      joinGuest({ name, token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, open]);

  const data = token ? guestData : joinData;

  const {
    data: results,
    isLoading: isResultLoading,
    isFetching: isResultFetching,
    refetch,
  } = useQuery(
    [getVideoVisitStoredResult.name, appt_id],
    () => getVideoVisitStoredResult(Number(appt_id)),
    {
      enabled: !!appt_id && role !== "guest",
    }
  );

  const testsData = useQuery(
    [getVideoVisitTests.name, appt_id],
    () => getVideoVisitTests(appt_id),
    { enabled: !!appt_id && role !== "guest" }
  );

  const { mutate, isLoading: isLeaving } = useMutation(
    (status: TConfirmStatus) => confirmAppointment(Number(appt_id), status)
  );

  const client = useQueryClient();
  const unblock = useUnblock();

  const [alertOpen, setAlertOpen] = useState(false);
  const [medOpen, setMedOpen] = useState(false);
  const [consentOpen, setConsentOpen] = useState(false);
  // local open state
  const [openState, setOpenState] = useState(open);
  // joined state
  const [joined, setJoined] = useState(false);
  // element which show in side
  const [element, setElement] = useState(-1);
  // fullscreen control state
  const [topOpen, setTopOpen] = useState<boolean>(
    isMobile ? portraitDetect : true
  );
  // sidebar active index state
  const [activeKey, setActiveKey] = useState("");
  // minimize state
  // const [minimize, setMinimize] = useState(false);
  // mute state
  const [mute, setMute] = useState(false);
  // // cam open state
  const [camOpen, setCamOpen] = useState(true);
  // sound state
  const [sound, setSound] = useState(true);
  // state of staff's camera
  const [staffState, setStaffState] = useState("ptz-close");

  const [hasNewMessage, setHasNewMessage] = useState(false);

  const [initializedDevices, setInitializedDevices] =
    useState<IInitializedDevices>();

  // const videoRef = useRef<HTMLVideoElement>(null);
  const [sessionInitialized, setSessionInitialized] = useState(false);

  const [streamerArr, setStreamerArr] = useState<IVonageStream[]>([]);

  const [screenShereStream, setScreenShareStream] = useState<
    IVonageStream | undefined
  >();

  const [messages, setMessages] = useState<IMessage[]>([]);

  // const [videoElements, setVideoElements] = useState<HTMLElement[]>([]);

  const { initializeSession } = useSession();

  const [vonageSession, setVonageSession] = useState<OT.Session | null>(null);

  const [publisher, setPublisher] = useState<OT.Publisher | null>(null);

  const [speakerVolume, setSpeakerVolume] = useState(100);
  const [infoMessage, setInfoMessage] = useState<IInfoMessage>();
  const [isRefreshed, setIsRefreshed] = useState(false);

  const history = useHistory();

  const onSubscriberJoin = (newStream: IVonageStream) => {
    playNotificationSound();
    setStreamerArr((prevArr) => [...prevArr, newStream]);
  };

  const loc = useLocation();

  useEffect(() => {
    if (loc.pathname?.includes("pdf-viewer")) {
      setMinimize(true);
      return;
    }
    if (!loc.pathname?.includes("panel")) {
      setMinimize(false);
      setOpen(false);
      publisher?.destroy();
      vonageSession?.disconnect();
      restoreScroll();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [loc.pathname]);

  const onSubscriberDis = (id: string) => {
    setActiveKey("");
    if (!(isMobile && !isPortrait)) {
      setTopOpen(true);
    }
    setStreamerArr((prevState) => prevState.filter((e) => e.id !== id));
  };
  const onMicMute = (isMuted: boolean) => {
    setMute(isMuted);
    publisher?.publishAudio(!isMuted);
    vonageSession?.signal(
      { data: `info:mic:${isMuted ? "close" : "open"}` },
      () => {}
    );
  };

  // const onSoundMute = (isMuted: boolean) => {
  //   const videoElements = document.querySelectorAll("video");
  //   videoElements.forEach((e) => {
  //     e.volume = (isMuted === true ? 0 : speakerVolume) / 100;
  //   });
  //   setSound(!isMuted);
  // };

  const onSpeakerVolumeChange = (newVolume: number) => {
    setSpeakerVolume(newVolume);
  };
  const onMicPick = (deviceId: string) => {
    publisher
      ?.setAudioSource(deviceId)
      .then(() => toast.success(t("microphone_changed")))
      .catch((e) => toast.error(t("microphone_change_error", { e: e })));
  };
  const onCameraPick = (deviceId: string) => {
    publisher
      ?.setVideoSource(deviceId)
      .then(() => toast.success(t("camera_changed")))
      .catch((e) => toast.error(t("camera_change_error", { e: e })));
  };
  const ptzController = (direction: TPtzCommand) => {
    vonageSession?.signal(
      {
        type: "ptz",
        data: direction,
      },
      (error) => {
        if (error) {
          toast.error(
            t("sending_error", { name: error?.name, message: error?.message })
          );
        }
      }
    );
  };
  const onCameraOpen = (state: boolean) => {
    setCamOpen(state);
    publisher?.publishVideo(state);
    console.log(publisher);
    vonageSession?.signal(
      { data: `info:camera:${!state ? "close" : "open"}` },
      () => {}
    );
  };
  const ref = useRef<HTMLDivElement>(null);
  const [image, takeScreenshot] = useScreenshot();
  const download = (
    image: string,
    { name = "img", extension = "png" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  useEffect(() => {
    if (image) {
      download(image, { name: "screenshot", extension: "png" });
    }
  }, [image]);

  const [screenShare, setScreenShare] = useState<OT.Publisher>();
  const onScreenShare = () => {
    if (screenShare) {
      screenShare.destroy();
      setScreenShare(undefined);
      return;
    }
    const screenPublisher = OT.initPublisher(undefined, {
      insertDefaultUI: false,
      videoSource: "screen",
      style: { buttonDisplayMode: "off" },
      width: "100%",
      height: "100%",
      publishAudio: false,
      audioFallbackEnabled: false,
    });

    screenPublisher.on("videoElementCreated", function (event) {
      const newStream: IVonageStream = {
        id: "You-Screen",
        name: "You-Screen",
        type: "screen",
        videoObj: event.element,
        stream: event.target.stream!,
        avatar: "",
        role: "",
      };
      // onSubscriberJoin(newStream);
      setScreenShare(screenPublisher);

      setScreenShareStream(newStream);
    });

    screenPublisher.on("streamDestroyed", () => {
      setScreenShare(undefined);
    });

    vonageSession?.publish(screenPublisher);
  };

  function convertTests(originalData?: IVideoVisitTests): IImagesItemProps[] {
    if (!originalData) return [];
    return Object.entries(originalData).map(([key, value]) => ({
      title: key,
      images: value?.map((i) => ({ imgUrl: i })),
      isLoading: testsData?.isLoading,
      onRefresh: () => {
        if (role === "guest") return;
        testsData?.refetch();
      },
    }));
  }

  useEffect(() => {
    if (joined && !data?.data?.data?.doctor_consent && role === "doctor") {
      setConsentOpen(true);
    }
  }, [data?.data?.data?.doctor_consent, role, joined]);

  const sidebarParticipants = {
    title: t("participants"),
    icon: <ParticipantsBold />,
    element: (
      <Participants
        role={role}
        onClose={() => setElement(-1)}
        appointment={data?.data?.data?.appointment}
      />
    ),
  };

  const sidebarChat = {
    title: t("chat"),
    icon: (
      <div className={clsx(classes.messageIcon)}>
        <MessagingBold />
        {hasNewMessage && (
          <div className={clsx(classes.messageIcon__red)}></div>
        )}
      </div>
    ),
    element: (
      <Messages onClose={() => setElement(-1)} isGuest={role === "guest"} />
    ),
  };

  const sidebarMedicalIntake = {
    title: t("medical_intake_form"),
    icon: <MedicalHistoryBold />,
    element: null,
    onClick: () => setMedOpen(true),
    key: "Medical Intake Form",
  };

  const sidebarExamCamera = {
    title: t("exam_camera"),
    icon: <CameraControlIconBold />,
    element: (
      <CameraControl
        appointment={data?.data?.data?.appointment}
        onClose={() => setElement(-1)}
        onBreak={() => ptzController("break")}
        onUpClicked={() => ptzController("up")}
        onDownClicked={() => ptzController("down")}
        onLeftClicked={() => ptzController("left")}
        onRightClicked={() => ptzController("right")}
        onZoomIn={() => ptzController("zoom-in")}
        onZoomOut={() => ptzController("zoom-out")}
        onCaptureClicked={() => {
          takeScreenshot(ref.current);
        }}
        images={[
          {
            title: t("digital_otoscope"),
            images:
              results?.data?.data?.["Digital Otoscope"]?.map((item) => {
                return { imgUrl: item?.attachment };
              }) || [],
            isLoading: isResultLoading || isResultFetching,
            withR: true,
            req: true,
            onRequest: () => {
              vonageSession?.signal(
                {
                  type: "exam-cameras",
                  data: "otoscope",
                },
                (error) => {
                  if (error) {
                    toast.error(
                      t("sending_error", {
                        name: error?.name,
                        message: error?.message,
                      })
                    );
                  }
                }
              );
            },
            onRefresh: () =>
              client.invalidateQueries([getVideoVisitStoredResult.name]),
          },
          {
            title: t("dermatoscope"),
            images:
              results?.data?.data?.["Exam Camera"]?.map((item) => {
                return { imgUrl: item?.attachment };
              }) || [],
            isLoading: isResultLoading || isResultFetching,
            withR: true,
            req: true,
            onRequest: () => {
              vonageSession?.signal(
                {
                  type: "exam-cameras",
                  data: "examcamera",
                },
                (error) => {
                  if (error) {
                    toast.error(
                      t("sending_error", {
                        name: error?.name,
                        message: error?.message,
                      })
                    );
                  }
                }
              );
            },

            onRefresh: () =>
              client.invalidateQueries([getVideoVisitStoredResult.name]),
          },
        ]}
        requests={[
          {
            title: t("main_camera"),
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua!",
            ptz: true,
            onRequest: () => {
              vonageSession?.signal(
                {
                  type: "ptz",
                  data: "open",
                },
                (error) => {
                  if (error) {
                    toast.error(
                      t("sending_error", {
                        name: error?.name,
                        message: error?.message,
                      })
                    );
                  }
                }
              );
            },
          },
        ]}
      />
    ),
  };

  const sidebarVitalSign = {
    title: t("vital_sign"),
    icon: <VitalSignBold />,
    element: (
      <VitalSignData
        setMinimized={setMinimize}
        appointment={data?.data?.data?.appointment}
        isLoading={isResultLoading || isResultFetching}
        onClose={() => setElement(-1)}
        bloodPressureData={{
          data:
            results?.data?.data?.["Blood Pressure"]?.map((r) => {
              return {
                date:
                  humanizedDate(r?.measured_at) +
                  " " +
                  humanizedTime(r?.measured_at),
                result:
                  r?.data?.systolic +
                  "/" +
                  r?.data?.diastolic +
                  r?.data?.unit +
                  "/ HR:" +
                  r?.data?.heart_rate +
                  "/min",
              };
            }) || [],
        }}
        glucometerData={{
          data:
            results?.data?.data?.Glucometer?.map((r) => {
              return {
                date:
                  humanizedDate(r?.measured_at) +
                  " " +
                  humanizedTime(r?.measured_at),
                result: r?.data?.glucose + " " + r?.data?.unit,
              };
            }) || [],
        }}
        thermometerData={{
          data:
            results?.data?.data?.Thermometer?.map((r) => {
              return {
                date:
                  humanizedDate(r?.measured_at) +
                  " " +
                  humanizedTime(r?.measured_at),
                result:
                  r?.data?.temperature +
                  " " +
                  r?.data?.unit +
                  "/" +
                  fToC(r?.data?.temperature) +
                  " C°",
              };
            }) || [],
        }}
        weightScaleData={{
          data:
            results?.data?.data?.["Weight Scale"]?.map((r) => {
              return {
                date:
                  humanizedDate(r?.measured_at) +
                  " " +
                  humanizedTime(r?.measured_at),
                result: r?.data?.weight + " " + r?.data?.unit,
              };
            }) || [],
        }}
        pulseOximeterData={{
          data:
            results?.data?.data?.["Pulse Oximeter"]?.map((r) => {
              return {
                date:
                  humanizedDate(r?.measured_at) +
                  " " +
                  humanizedTime(r?.measured_at),
                result: r?.data?.spO2 + " % / " + r?.data?.pulse_rate,
              };
            }) || [],
        }}
      />
    ),
  };

  const sidebarMedicalExamination = {
    title: t("medical_examination"),
    icon: <MedicalExaminationBold />,
    element: (
      <MedicalExamination
        setMinimized={setMinimize}
        ecgData={[
          {
            title: t("ecg"),
            images:
              results?.data?.data?.ECG?.map((item) => {
                return { imgUrl: item?.attachment, date: item?.measured_at };
              }) || [],
            isLoading: isResultLoading || isResultFetching,
            withR: true,
            onRefresh: () =>
              client.invalidateQueries([getVideoVisitStoredResult.name]),
          },
        ]}
        ultraSoundData={[
          {
            title: t("ultra_sound"),
            images:
              results?.data?.data?.["Ultra Sound"]?.map((item) => {
                return { imgUrl: item?.attachment, date: item?.measured_at };
              }) || [],
            isLoading: isResultLoading || isResultFetching,
            withR: true,
            onRefresh: () =>
              client.invalidateQueries([getVideoVisitStoredResult.name]),
          },
        ]}
        spirometerData={[
          {
            title: t("spirometer"),
            images:
              results?.data?.data?.Spirometer?.map((item) => {
                return { imgUrl: item?.attachment, date: item?.measured_at };
              }) || [],
            isLoading: isResultLoading || isResultFetching,
            withR: true,
            onRefresh: () =>
              client.invalidateQueries([getVideoVisitStoredResult.name]),
          },
        ]}
        appointment={data?.data?.data?.appointment}
        onClose={() => setElement(-1)}
        isLoading={isResultLoading || isResultFetching}
        exams={[
          {
            title: t("stethoscope"),
            items:
              results?.data?.data?.Stethoscope?.map(
                (item) => item?.attachment
              ) || [],
            data: {
              date:
                results?.data?.data?.Stethoscope?.map(
                  (item) =>
                    humanizedDate(item?.measured_at) +
                    " " +
                    humanizedTime(item?.measured_at)
                ) || [],
            },
            onRequest: (mode: "bell" | "diaphragm") => {
              vonageSession?.signal(
                {
                  type: "stethoscope-mode",
                  data: mode,
                },
                (error) => {
                  if (error) {
                    toast.error(
                      t("sending_error", {
                        name: error?.name,
                        message: error?.message,
                      })
                    );
                  }
                }
              );
              toast.success(t("request_sent"));
            },
          },
        ]}
      />
    ),
  };

  const sidebarTestFiles = {
    title: t("test_files"),
    icon: <ImageIcon />,
    element: (
      <Images
        appointment={data?.data?.data?.appointment}
        onClose={() => setElement(-1)}
        items={convertTests(testsData?.data?.data?.data)}
      />
    ),
  };

  const sidebarWriteSummary = {
    title: t("write_summary"),
    icon: <WriteSummaryBold />,
    element: null,
    key: "Write New Summary Note",
  };
  const sidebarPastSummary = {
    title: t("past_summary"),
    icon: <PastSummaryBold />,
    element: null,
    key: "Past Visit Summary Note",
  };

  // sidebar items with proper title icon and jsx(with props for each component)
  let sideBarItems: IVideoVisitSideBarItem[] = [
    sidebarParticipants,
    sidebarChat,
    sidebarMedicalIntake,
    sidebarExamCamera,
    sidebarVitalSign,
    sidebarMedicalExamination,
    sidebarTestFiles,
    sidebarWriteSummary,
    sidebarPastSummary,
  ];

  if (role !== "doctor") {
    sideBarItems = [sidebarParticipants, sidebarChat];
  }
  console.log(role, role, role);
  if (role === "staff") {
    sideBarItems = [
      sidebarParticipants,
      sidebarChat,
      sidebarMedicalIntake,
      sidebarVitalSign,
      sidebarMedicalExamination,
      sidebarTestFiles,
      sidebarPastSummary,
    ];
  }

  // useEffect(() => {
  //   streamerArr.forEach((e) => {
  //     setTimeout(() => {
  //       const videoElement = document
  //         .getElementById(e)
  //         ?.getElementsByClassName("OT_root")[0] as HTMLElement;
  //       if (videoElement) {
  //         console.log(videoElement);
  //         setVideoElements((prevState) => [...prevState, videoElement]);
  //       }
  //     }, 3000);
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [streamerArr]);

  // useEffect(() => {
  //   if (videoElements.length > 0 && minimize) {
  //     setTimeout(() => {
  //       document
  //         .getElementById("video-container")
  //         ?.insertBefore(
  //           videoElements[1],
  //           document.getElementById("video-container")?.firstChild!
  //         );
  //     }, 1000);
  //   } else if (videoElements.length > 0 && !minimize) {
  //     setTimeout(() => {
  //       streamerArr.forEach((e, i) => {
  //         document.getElementById(e)?.appendChild(videoElements[i]);
  //       });
  //     }, 1000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [minimize]);

  // control overflow of screen based on minimize and open state
  useEffect(() => {
    if (openState && !minimize) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [minimize, openState, open]);

  const [isPortrait, setIsPortrait] = useState(portraitDetect);

  useEffect(() => {
    const changeOrientation = () => {
      if (window?.screen?.orientation?.type?.includes("portrait")) {
        if (isMobile) setTopOpen(true);
        setIsPortrait(true);
      } else {
        if (isMobile) setTopOpen(false);
        setIsPortrait(false);
      }
    };
    window?.screen?.orientation?.addEventListener("change", changeOrientation);

    return () => {
      window?.screen?.orientation?.removeEventListener(
        "change",
        changeOrientation
      );
    };
  }, []);

  const restoreScroll = () => {
    document.body.style.overflowY = "auto";
  };

  // sync local and external open state
  useEffect(() => setOpenState(open), [open]);
  useEffect(() => setOpen(openState), [openState, setOpen]);
  useEffect(() => {
    if (openState && !sessionInitialized && joined && initializedDevices) {
      setTimeout(() => {
        const { publisher: tempPublisher, session: tempSession } =
          initializeSession(
            data?.data?.data?.api_key || "",
            data?.data?.data?.session_id || "",
            data?.data?.data?.token || "",
            onSubscriberJoin,
            onSubscriberDis,
            initializedDevices
          );
        setPublisher(tempPublisher);
        setVonageSession(tempSession);
        setIsRefreshed(false);
      }, 3500);
      setSessionInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openState, joined, initializedDevices, sessionInitialized]);

  useEffect(() => {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((e) => {
      e.volume = (!sound ? 0 : speakerVolume) / 100;
    });
  }, [speakerVolume, streamerArr, sound]);

  useEffect(() => {
    vonageSession?.on("signal:end-meet", () => {
      setOpen(false);
      setOpenState(false);
      vonageSession?.disconnect();
      publisher?.destroy();
      if (role === "guest") {
        window.close();
        setTimeout(() => {
          if (!window.closed) {
            window.location.reload();
          }
        }, 300);
      }
      restoreScroll();
      toast.info(t("video_visit_ended_by_doctor"));
      client.invalidateQueries([getStaffAppointments.name]);
      client.invalidateQueries([getAppointmentsForPatientOrStaff.name]);
    });
    vonageSession?.on("signal:info-measurement-general", () => {
      if (role === "guest") return;
      refetch();
      testsData.refetch();
    });
    vonageSession?.on("signal:msg", function (event: any) {
      let isMyMessage =
        event.from.connectionId !== vonageSession.connection?.connectionId;
      let from = event.from.data;
      let message = messageHandler(event.data, from, isMyMessage);
      console.log(message);
      setMessages((prevState) => [...prevState, message]);
      if (isMyMessage) {
        setHasNewMessage(true);
        toast.info(t("new_message"));
      }
    });
    vonageSession?.on("signal:staff-state", function (event: any) {
      setStaffState(event.data);
    });
    vonageSession?.on("signal", function (event) {
      const forcedCommand = event.data;
      switch (forcedCommand) {
        case "camera:close":
          onCameraOpen(false);
          toast.warning(t("camera_closed_doctor"));
          break;
        case "camera:open":
          onCameraOpen(true);
          toast.warning(t("camera_opened_doctor"));
          break;
        case "mic:close":
          onMicMute(true);
          toast.warning(t("microphone_closed_doctor"));
          break;
        case "mic:open":
          onMicMute(false);
          toast.warning(t("microphone_opened_doctor"));
          break;
      }
    });

    vonageSession?.on("signal", function (event) {
      if (event.data?.split(":")[0] === "info") {
        setInfoMessage({ data: event.data!, from: event.from! });
      }
    });
    vonageSession?.on("sessionConnected", (e) => {
      setStreamerArr((prevState) => {
        let pub = prevState[0];
        const userMetaData: IVonageMetaData = JSON.parse(
          e.target.connection?.data!
        );
        try {
          pub.avatar = userMetaData?.avatar;
          pub.role = userMetaData?.role;
        } catch (e) {}
        return [pub];
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vonageSession, publisher]);

  // render full screen version of video visit
  const renderVideoVisit = () => {
    return (
      <VideoVisitContext.Provider
        value={{
          session: vonageSession,
          messages,
          messageHandler,
          setSession: setVonageSession,
          micMute: mute,
          onMicMute: onMicMute,
          sound: sound,
          onSoundMute: setSound,
          isCameraOpen: camOpen,
          onCameraOpen: onCameraOpen,
          streamArr: streamerArr,
          infoMessage: infoMessage,
          staffState,
          setStaffState,
          hasNewMessage,
          setHasNewMessage,
        }}
      >
        <div
          className={clsx(classes.videoVisit, role === "guest" && classes.full)}
        >
          <div className={clsx(classes.videoVisit__contents)}>
            <TopBar
              role={role}
              setMinimize={setMinimize}
              text={
                data?.data?.data?.appointment?.patient?.name +
                " ,DOB:" +
                humanizedDate(
                  data?.data?.data?.appointment?.patient?.birthday || ""
                )
              }
              title={t("video_visit", { name: "LEO360™" })}
              date={new Date(data?.data?.data?.start_datetime || "") || date}
              open={topOpen}
            />
            <div
              className={clsx(
                classes.videoVisit__videoPlaces,
                !topOpen && classes.p0
              )}
            >
              <BottomController
                show={role === "doctor"}
                onUpClicked={() => ptzController("up")}
                onDownClicked={() => ptzController("down")}
                onLeftClicked={() => ptzController("left")}
                onRightClicked={() => ptzController("right")}
                onZoomIn={() => ptzController("zoom-in")}
                onZoomOut={() => ptzController("zoom-out")}
                onBreak={() => ptzController("break")}
              />
              {streamerArr.map((e) => {
                if (!e) return <></>;
                if (activeKey !== "" && activeKey !== e.id) {
                  return <></>;
                }
                return (
                  <VideoComponent
                    isRobot={e?.is_robot}
                    enteredRole={role}
                    streamArr={streamerArr}
                    reference={ref}
                    stream={e.stream}
                    screenShareStream={screenShereStream}
                    className={
                      streamerArr.length === 1 ? classes.one : classes.multi
                    }
                    videoObj={e.videoObj}
                    key={e.id}
                    streamKey={e.id}
                    full={activeKey}
                    title={e.name}
                    onFullscreen={() => {
                      setActiveKey(e.id);
                      setTopOpen(false);
                    }}
                    onDefaultScreen={() => {
                      setActiveKey("");
                      if (!isPortrait && isMobile) return;
                      setTopOpen(true);
                    }}
                    role={
                      e.role === "doctor"
                        ? "Doctor"
                        : e.role === "staff"
                        ? "Staff"
                        : e.role === "patient"
                        ? "Patient"
                        : undefined
                    }
                    onUpClicked={() => ptzController("up")}
                    onDownClicked={() => ptzController("down")}
                    onLeftClicked={() => ptzController("left")}
                    onRightClicked={() => ptzController("right")}
                    onZoomIn={() => ptzController("zoom-in")}
                    onZoomOut={() => ptzController("zoom-out")}
                    onBreak={() => ptzController("break")}
                  />
                );
              })}
              {/* {sessions.map((session) => {
              if (activeKey === "") {
                return (
                  <VideoComponent
                    className={
                      sessions.length === 1 ? classes.one : classes.multi
                    }
                    key={session.key}
                    title={session.title}
                    onFullscreen={() => {
                      setActiveKey(session.key);
                      setTopOpen(false);
                    }}
                    onDefaultScreen={() => {
                      setActiveKey("");
                      setTopOpen(true);
                    }}
                  />
                );
              } else if (activeKey === session.key) {
                return (
                  <VideoComponent
                    key={session.key}
                    title={session.title}
                    onFullscreen={() => {
                      setActiveKey(session.key);
                      setTopOpen(false);
                    }}
                    onDefaultScreen={() => {
                      setActiveKey("");
                      setTopOpen(true);
                    }}
                  />
                );
              }
              return "";
            })} */}
            </div>
            <BottomBar
              isShared={!!screenShare}
              noDisplay={!!activeKey && isMobile && !isPortrait}
              isLeaving={isLeaving}
              role={role}
              initializedVolume={speakerVolume}
              onMinimizeClicked={() => setMinimize(true)}
              onLeaveClicked={() => {
                setAlertOpen(true);
              }}
              onVolumeChange={(newVolume) => onSpeakerVolumeChange(newVolume)}
              onMicPick={(deviceId) => onMicPick(deviceId)}
              onCameraPick={(deviceId) => onCameraPick(deviceId)}
              onScreenShare={() => onScreenShare()}
              isRefreshing={isRefreshed}
              onRefresh={() => {
                if (isRefreshed) return;
                setIsRefreshed(true);
                setMute(false);
                setCamOpen(true);
                setSound(true);
                vonageSession?.off();
                publisher?.off();
                vonageSession?.disconnect();
                publisher?.destroy();
                setStreamerArr([]);
                setTimeout(() => {
                  setSessionInitialized(false);
                }, 100);
              }}
            />
          </div>
          <div
            className={clsx(
              classes.side,
              element !== -1 && topOpen && classes.sideOpen
            )}
          >
            <div
              className={clsx(
                classes.side__sideInside,
                element !== -1 && classes.sideVisible
              )}
            >
              {element !== -1 ? sideBarItems[element].element : ""}
            </div>
          </div>

          <SideBar
            role={role}
            setMinimized={setMinimize}
            appointment={data?.data?.data?.appointment}
            activeIndex={element}
            setActiveIndex={setElement}
            items={sideBarItems}
            setElement={setElement}
            close={!topOpen}
          />
        </div>
        <Alert
          open={consentOpen}
          setOpen={setConsentOpen}
          title={t("consent")}
          text={t("consent_text", {
            name: data?.data?.data?.appointment?.patient?.name,
            birth: humanizedDate(
              data?.data?.data?.appointment?.patient?.birthday || ""
            ),
          })}
          notScroll
          onClose={() => {
            notify.warning(t("consent_message"));
          }}
          onCancel={() => {
            setConsentOpen(false);
            vonageSession?.disconnect();
            publisher?.destroy();
            setOpen(false);
            setOpenState(false);
          }}
          onApprove={() => {
            consentMutate(undefined, {
              onSuccess: () => {
                setConsentOpen(false);
              },
            });
          }}
        />
        <Alert
          notScroll
          open={alertOpen}
          setOpen={setAlertOpen}
          title={role === "doctor" ? t("end") : t("leave")}
          text={role === "doctor" ? t("end_message") : t("leave_message")}
          dismissible={true}
          onApprove={() => {
            if (role === "guest") {
              window.close();
              setTimeout(() => {
                if (!window.closed) {
                  window.location.reload();
                }
                vonageSession?.disconnect();
                publisher?.destroy();
                setOpen(false);
                setOpenState(false);
              }, 300);

              return;
            }
            if (role === "doctor") {
              mutate("confirm", {
                onSuccess: () => {
                  restoreScroll();
                  vonageSession?.signal({ type: "end-meet" }, (error) => {
                    vonageSession?.disconnect();
                    publisher?.destroy();
                    setOpen(false);
                    setOpenState(false);
                    notify.success(t("confirmed"));
                    client.invalidateQueries([doctorAppointmentsKey]);
                  });
                },
              });
            } else {
              setTimeout(() => {
                restoreScroll();
                vonageSession?.disconnect();
                publisher?.destroy();
                setOpen(false);
                setOpenState(false);
              }, 300);
            }
          }}
        />
        <Alert
          notScroll
          open={medOpen}
          setOpen={setMedOpen}
          title={t("medical_intake_form")}
          text={t("choose_page")}
          dismissible={true}
          customButtons={
            <div className={"d-flex gap-2"}>
              <Button
                onClick={() => {
                  unblock();
                  setMedOpen(false);
                  setTimeout(() => {
                    setMinimize(true);
                    history.push({
                      pathname: `${base}medical-history/${data?.data?.data?.appointment?.patient?.id}/${data?.data?.data?.appointment?.visit_for}`,
                    });
                  }, 300);
                }}
              >
                {t("past_medical")}
              </Button>
              <Button
                onClick={() => {
                  unblock();
                  setMedOpen(false);

                  setTimeout(() => {
                    setMinimize(true);
                    history.push({
                      pathname: `${base}edit-intake-form/${data?.data?.data?.appointment?.intake_form_id}`,
                      state: {
                        appointment: data?.data?.data?.appointment,
                      },
                    });
                  }, 300);
                }}
              >
                {t("intake_form")}
              </Button>
            </div>
          }
        />
      </VideoVisitContext.Provider>
    );
  };

  // render minimize version of video visit
  const renderMinimize = () => {
    return (
      <VideoVisitContext.Provider
        value={{
          session: vonageSession,
          messages,
          messageHandler,
          setSession: setVonageSession,
          micMute: mute,
          onMicMute: onMicMute,
          sound: sound,
          onSoundMute: setSound,
          isCameraOpen: camOpen,
          onCameraOpen: onCameraOpen,
          streamArr: streamerArr,
          infoMessage: infoMessage,
          staffState,
          setStaffState,
          hasNewMessage,
          setHasNewMessage,
        }}
      >
        <MinimizedVideoVisit
          role={role}
          streams={streamerArr}
          onFullScreen={() => setMinimize(false)}
          onLeave={() => {
            setAlertOpen(true);
          }}
        />
      </VideoVisitContext.Provider>
    );
  };

  const renderFirstPage = () => {
    return (
      <FirstPage
        isError={isError || isErr}
        setOpen={setOpen}
        isLoading={isLoading || isLoad}
        title={"LEO360™ Video Visit"}
        date={date}
        setJoined={setJoined}
        onDataChange={(data) => {
          setInitializedDevices({
            mic: data.micId,
            speaker: data.speakerId,
            volume: data.volume,
            camera: data.camId,
          });
          setSpeakerVolume(data.volume);
        }}
      />
    );
  };

  if (!openState) return null;

  if (!joined && openState) return renderFirstPage();

  if (minimize) return renderMinimize();

  return renderVideoVisit();
};

const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
};

const messageHandler = (text: string, from: string, isMyMessage: boolean) => {
  const timeText = formatAMPM(new Date());
  return {
    from,
    text,
    timeText,
    isMyMessage,
  };
};

export default VideoVisit;

import {
  MutateFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import { useUnblock } from "hooks/useBlock";
import { useHistory } from "react-router";

/**
 * This hook will do your traditional mutation and unblock and go back after the succeeded mutation.
 * @param mutationFn MutateFunction
 * @param queryKeys give array of query keys if you want the `queryClient` to `removeQueries` of that query keys after the mutation is succeed.
 * @param options UseMutationOptions
 * @returns useMutation
 */
export default function useFormMutation<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  mutationFn: MutateFunction<TData, TError, TVariables, TContext>,
  queryKeys?: QueryKey[],
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
) {
  const queryClient = useQueryClient();
  const unblock = useUnblock();
  const history = useHistory();
  return useMutation(mutationFn, {
    ...options,
    onSuccess(data, variables, context) {
      unblock();
      queryKeys?.forEach((element) => {
        queryClient.invalidateQueries(element);
      });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
      history.goBack();
    },
  });
}

import Checkbox from "../checkbox";
import RadioButton from "../radioButton";
import styles from "./styles.module.scss";
import clsx from "clsx";

// checkboxList's types which determines type of button's
type CheckboxType = "checkbox" | "radio";

/**
 * we have two type for this list:
 * 1. we could select items from one general group -> SimpleItem
 * 2. we have multiple sub-groups which each group has on items and we could select among of them -> ComplexItem
 */
type ComplexItem = {
  title: string;
  list: string[];
};
type SimpleItem = string;
type ListOfItems = ComplexItem[] | SimpleItem[];
/**
 * list interface represents a general interface for list
 * @param list: union of complex and simple structure for list
 * @param chosenItems: list of selected items from list that we are receiving in the first place
 * @param type: checkbox's type which is radio or checkbox
 * @param clickable: determines that our list is selectable or not
 * @param onChangeItem: a function that get fired when an item get selected
 */
interface ListInterface {
  list: ListOfItems;
  chosenItems?: ISelectedItem[];
  type?: CheckboxType;
  clickable?: boolean;
  onChangeItem?: (_items: ISelectedItem[]) => void;
  row?: boolean;
  items: ListOfItems;
}

// our chosenItems structure which title is optional and
// is used when our list has ComplexItems
// and we don't need it when our items is SimpleItem
export interface ISelectedItem {
  title?: string;
  selectedItem: string;
}

const CheckboxList = ({
  list,
  chosenItems = [],
  type = "checkbox",
  clickable = false,
  onChangeItem = (_items: ISelectedItem[]) => {},
  row,
  items,
}: ListInterface) => {
  /* //this state holds selectedItems
   const [selectedItems, setSelectedItems] =
     useState<ISelectedItem[]>(chosenItems);*/

  /* // it runs to fire onChangeItem() when our selectedItems get changed
   useEffect(() => {
     onChangeItem(selectedItems);
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedItems]);*/

  /* useEffect(()=>{setSelectedItems(chosenItems)},[chosenItems])
   */
  // it handles onChangeItem() for simpleItem forms
  const simpleListOnChange = (state: boolean, item: string) => {
    if (state) {
      onChangeItem([...chosenItems, { selectedItem: item }]);
    } else {
      onChangeItem([...chosenItems].filter((e) => e.selectedItem !== item));
    }
  };

  // it handles onChangeItem() for complexItem forms
  const complexListOnChange = (
    state: boolean,
    item: ComplexItem,
    title: string
  ) => {
    if (state) {
      onChangeItem([
        ...chosenItems,
        { title: item.title, selectedItem: title },
      ]);
    } else {
      onChangeItem(
        [...chosenItems].filter(
          (e) => e.selectedItem !== title || e.title !== item.title
        )
      );
    }
  };

  const RadioSection = () => {
    return (
      <div className={styles.radioList}>
        {list.map((item, index) => {
          if (typeof item === "string") {
            const displayedItem = items[index] as typeof item;
            return (
              <div key={item} className={styles.checkbox}>
                <RadioButton
                  title={displayedItem}
                  clickable={clickable}
                  isSelected={
                    !!chosenItems.find((e) => e.selectedItem === item)
                  }
                  titleToRight
                  onChange={(state) => simpleListOnChange(state, item)}
                />
              </div>
            );
          }
          const displayedItem = items[index] as typeof item;
          return (
            <div key={index}>
              <p className={styles.title}>{displayedItem.title}</p>
              {item.list.map((title: string, index) => {
                const displayedRow = displayedItem.list[index];

                return (
                  <div className={styles.checkbox} key={title + index}>
                    <RadioButton
                      title={displayedRow}
                      clickable={clickable}
                      isSelected={
                        !!chosenItems.find(
                          (e) =>
                            e.selectedItem === title && item.title === e.title
                        )
                      }
                      titleToRight
                      onChange={(state) => {
                        complexListOnChange(state, item, title);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const CheckboxSection = () => {
    return (
      <div className={clsx(row ? styles.radioList : styles.list)}>
        {list.map((item, index) => {
          if (typeof item === "string") {
            const displayedItem = items[index] as typeof item;
            return (
              <div
                key={index}
                className={styles.checkbox}
                onClick={() => {
                  if (clickable) {
                    simpleListOnChange(
                      !chosenItems.find((e) => e.selectedItem === item),
                      item
                    );
                  }
                }}
              >
                <Checkbox
                  checked={!!chosenItems.find((e) => e.selectedItem === item)}
                  readonly={true}
                  className={styles.checkboxSquare}
                />
                <p className={clsx(styles.list__title)}>{displayedItem}</p>
              </div>
            );
          }
          const displayedItem = items[index] as typeof item;
          return (
            <div key={index}>
              <p className={styles.title}>{displayedItem.title}</p>
              {item.list.map((title: string) => {
                const displayedRow = displayedItem.list[index];
                return (
                  <div key={title} className={styles.checkbox}>
                    <Checkbox
                      checked={
                        !!chosenItems.find(
                          (e) =>
                            e.selectedItem === title && item.title === e.title
                        )
                      }
                      readonly={!clickable}
                      className={styles.checkboxSquare}
                      onChange={(state) => {
                        complexListOnChange(state, item, title);
                      }}
                    />
                    <p>{displayedRow}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return type === "checkbox" ? CheckboxSection() : RadioSection();
};

export default CheckboxList;

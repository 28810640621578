import { ReactNode, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { ArrowRightLinear, RefreshLinear } from "../../../../icon";
import ImageCard, { IImageCardProps } from "./ImageCard";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "../../../../core/button";
import Loader from "../../../loader";
import { VideoVisitContext } from "../..";
import { useTranslation } from "react-i18next";

export interface IImagesItemProps {
  title: string;
  images: IImageCardProps[];
  withR?: boolean;
  req?: boolean;
  onRequest?: () => void;
  onRefresh?: () => void;
  isLoading?: boolean;
  video?: boolean;
  child?: ReactNode;
}

const ImagesItem = ({
  title,
  images,
  onRefresh,
  withR,
  isLoading,
  onRequest,
  req,
  video,
  child,
}: IImagesItemProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  const { staffState } = useContext(VideoVisitContext);
  const [request, setRequest] = useState(false);

  // data open or close state
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (title === t("digital_otoscope") && staffState === "otoscope-open")
      return setRequest(true);
    if (title === t("dermatoscope") && staffState === "examcamera-open")
      return setRequest(true);
    setRequest(false);
    console.log(staffState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffState]);

  // render component
  return (
    <Accordion
      expanded={open}
      onChange={(event, expanded) => {
        setOpen(expanded);
      }}
      sx={{
        "& .MuiAccordion-root": {
          padding: 0,
        },
        "& .MuiButtonBase-root-MuiAccordionSummary-root": { padding: 0 },
        "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": {
          margin: 0,
        },
        "& .MuiAccordionDetails-root": { padding: 0 },
        "& .MuiPaper-root-MuiAccordion-root": { position: "unset" },
        "& .MuiAccordionSummary-root": { padding: 0 },
        "$ .MuiButtonBase-root": { padding: 0 },
      }}
    >
      <AccordionSummary>
        <div className={clsx(classes.imageItem__head)}>
          <div className={clsx(classes.imageItem__title)}>
            <span>{title}</span>
            <span className={clsx(classes.imageItem__number)}>
              {" "}
              ({images.length})
            </span>
          </div>
          <div
            className={clsx(classes.imageItem__icon, open && classes.rotate)}
          >
            {<ArrowRightLinear />}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {" "}
        <div className={clsx(classes.imageItem__content)}>
          <div
            className={clsx(classes.imageItem__contentInside)}
            onClick={(e) => e.stopPropagation()}
          >
            {withR && (
              <div className={clsx(classes.imageItem__btns)}>
                {!request && req && (
                  <Button
                    className={clsx(classes.imageItem__button)}
                    onClick={() => {
                      if (onRequest) {
                        onRequest();
                      }
                      setRequest(true);
                    }}
                  >
                    {t("request")}
                  </Button>
                )}
                {(request || !req) && (
                  <Button
                    className={clsx(classes.imageItem__button)}
                    icon={RefreshLinear}
                    onClick={() => {
                      if (onRefresh) {
                        onRefresh();
                      }
                    }}
                  >
                    {t("refresh")}
                  </Button>
                )}
              </div>
            )}
            <Loader isLoading={isLoading || false}>
              <div className={clsx(classes.images__place)}>
                {images.map((image, index) => {
                  return (
                    <ImageCard
                      date={image?.date}
                      imgUrl={image.imgUrl}
                      key={index}
                      video={video}
                    />
                  );
                })}
                {images.length === 0 && (
                  <div className={clsx(classes.images__noData)}>
                    {t("no_data")}
                  </div>
                )}
                {child && child}
              </div>
            </Loader>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ImagesItem;

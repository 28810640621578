import clsx from "clsx";
import classes from "../videoComponent/styles.module.scss";
import {
  ArrowDownBold,
  ArrowLeftBold,
  ArrowRightBold,
  ArrowUpBold,
  UndoLinear,
  ZoomInBold,
  ZoomOutBold,
} from "../../../../icon";
import React, { useContext } from "react";
import { VideoVisitContext } from "../../index";
import HoverToolbox from "../../../hoverToolbox";
import { useTranslation } from "react-i18next";

export interface IBottomControllerProps {
  show?: boolean;
  onLeftClicked: () => void;
  onBreak: () => void;
  onRightClicked: () => void;
  onUpClicked: () => void;
  onDownClicked: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
}

const BottomController = ({
  onDownClicked,
  onLeftClicked,
  onRightClicked,
  onUpClicked,
  onZoomOut,
  onZoomIn,
  onBreak,
  show,
}: IBottomControllerProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  const ctx = useContext(VideoVisitContext);

  return (
    <div
      className={clsx(
        classes.parent__sideBottm,
        /*!fullScreen && classes.no,
        role !== "Staff" && classes.no,*/
        !show && classes.no,
        ctx.staffState !== "ptz-open" && classes.no
      )}
    >
      <div className={clsx(classes.parent__ic, classes.parent__centrIc)}>
        <div onMouseDown={onLeftClicked} onMouseUp={onBreak}>
          <HoverToolbox text={t("move_left")}>
            <ArrowLeftBold />
          </HoverToolbox>
        </div>
        <div onMouseDown={onRightClicked} onMouseUp={onBreak}>
          <HoverToolbox text={t("move_right")}>
            <ArrowRightBold />
          </HoverToolbox>
        </div>
        <div onMouseDown={onUpClicked} onMouseUp={onBreak}>
          <HoverToolbox text={t("move_up")}>
            <ArrowUpBold />
          </HoverToolbox>
        </div>
        <div onMouseDown={onDownClicked} onMouseUp={onBreak}>
          <HoverToolbox text={t("move_down")}>
            <ArrowDownBold />
          </HoverToolbox>
        </div>
      </div>
      <div className={clsx(classes.parent__zoom)}>
        <div onMouseDown={onZoomIn} onMouseUp={onBreak}>
          <HoverToolbox text={t("zoom_in")}>
            <ZoomInBold />
          </HoverToolbox>
        </div>
        <div onMouseDown={onZoomOut} onMouseUp={onBreak}>
          <HoverToolbox text={t("zoom_out")}>
            <ZoomOutBold />
          </HoverToolbox>
        </div>
        <div onClick={onZoomOut} className={clsx(classes.parent__undo)}>
          <HoverToolbox text={t("default")}>
            <UndoLinear />
          </HoverToolbox>
        </div>
      </div>
    </div>
  );
};

export default BottomController;

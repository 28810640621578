import classes from "./styles.module.scss";
import useDraggable from "../../../../../hooks/useDraggable";
import {
  CloseLinear,
  MaxIcon,
  MinimizeIcon,
  SearchZoomInLinear,
  SearchZoomOutLinear,
} from "../../../../icon";
import React, { Dispatch, SetStateAction, useState } from "react";
import clsx from "clsx";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

export interface IImageModalProps {
  onClose: Dispatch<SetStateAction<boolean>>;
  imageUrl: string;
  video?: boolean;
}

// image modal component for video visit open when image card clicked
const ImageModal = ({ onClose, imageUrl, video }: IImageModalProps) => {
  // use hook for make modal draggable
  const [ref, position, isDragging] = useDraggable<HTMLDivElement>();
  // scale state for modal
  const [scale, setScale] = useState(1);
  // initial width
  const width = 350;
  const [maximize, setMaximize] = useState(true);
  const [imgScale, setImgScale] = useState(1);

  if (video) {
    return (
      <div
        className={classes.ImageModal}
        {...{
          ref,
          style: {
            position: "fixed",
            left: position.x,
            top: position.y,
            cursor: isDragging ? "move" : "default",
            width: maximize ? "100%" : scale * width + "px",
            height: maximize ? "100vh" : scale * width + "px",
          },
        }}
      >
        <div className={clsx(classes.ImageModal__modalButtons)}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose(false);
            }}
            className={clsx(classes.ImageModal__cross)}
          >
            <CloseLinear />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setMaximize(true);
            }}
            className={clsx(classes.ImageModal__cross)}
          >
            <MaxIcon />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setMaximize(false);
              setScale(1);
            }}
            className={clsx(classes.ImageModal__cross)}
          >
            <MinimizeIcon />
          </button>
        </div>
        <div
          className={clsx(
            classes.imageCard,
            classes.imgPlace,
            maximize && classes.heightCut
          )}
        >
          <video src={imageUrl} style={{ width: "100%" }} controls />
        </div>
        {!maximize && (
          <div className={clsx(classes.ImageModal__btns)}>
            <div
              className={clsx(classes.ImageModal__btn)}
              onClick={() => {
                if (scale > 0.4) {
                  setScale(scale - 0.1);
                }
              }}
            >
              <SearchZoomOutLinear />
            </div>
            <div
              className={clsx(classes.ImageModal__btn)}
              onClick={() => {
                if (scale < 2.5) {
                  setScale(scale + 0.1);
                }
              }}
            >
              <SearchZoomInLinear />
            </div>
          </div>
        )}
      </div>
    );
  }

  // render component
  return (
    <div
      className={classes.ImageModal}
      {...{
        ref,
        style: {
          position: "fixed",
          left: position.x,
          top: position.y,
          cursor: isDragging ? "move" : "default",
          width: maximize ? "100%" : scale * width + "px",
          height: maximize ? "100vh" : scale * width + "px",
        },
      }}
    >
      <div className={clsx(classes.ImageModal__modalButtons)}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
          }}
          className={clsx(classes.ImageModal__cross)}
        >
          <CloseLinear />
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setMaximize(true);
          }}
          className={clsx(classes.ImageModal__cross)}
        >
          <MaxIcon />
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setMaximize(false);
          }}
          className={clsx(classes.ImageModal__cross)}
        >
          <MinimizeIcon />
        </button>
      </div>
      <div className={clsx(classes.imageCard, classes.imgPlace)}>
        <div className={clsx(classes.imageCard__img, classes.image)}>
          <img
            src={imageUrl}
            alt={"img"}
            style={{
              width: `${100 * imgScale}%`,
            }}
          />
        </div>
      </div>
      <div className={clsx(classes.ImageModal__btns)}>
        <div
          className={clsx(classes.ImageModal__btn)}
          onClick={() => {
            if (imgScale > 0.4) {
              setImgScale(imgScale - 0.1);
            }
          }}
        >
          <SearchZoomOutLinear />
        </div>
        <div
          className={clsx(classes.ImageModal__btn)}
          onClick={() => {
            console.log("imgScale", imgScale);
            if (imgScale < 100) {
              setImgScale(imgScale + 0.1);
            }
          }}
        >
          <SearchZoomInLinear />
        </div>
      </div>
    </div>
  );
};

export default ImageModal;

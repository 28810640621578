import { RegistrationProvider } from "../context";
import DoctorRegistrationPage from "./components/registrationLayout";

const DoctorRegistration = () => {
  return (
    <RegistrationProvider>
      <DoctorRegistrationPage />
    </RegistrationProvider>
  );
};

export default DoctorRegistration;

import { TRoleName } from "api/user";
import DropdownItem from "components/core/dropdownItem";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

type Props = {
  rowId: number;
  fullName: string;
  roles: string[];
};

const AddRoleMenuItem = ({ roles, rowId, fullName }: Props) => {
  const { t } = useTranslation("global");
  const history = useHistory();
  if (roles.length === 1 && (roles[0] as TRoleName) === "patient") {
    return null;
  }
  if (roles.includes("super-admin" as TRoleName) && roles.length === 6) {
    return null;
  }
  if (!roles.includes("super-admin" as TRoleName) && roles.length === 5) {
    return null;
  }
  return (
    <DropdownItem
      onClick={() => {
        console.log("rowId: " + rowId, fullName, roles);
        history.push({
          pathname: `${history.location.pathname}/add-role/${rowId}`,
          state: { roles: roles, name: fullName },
        });
      }}
    >
      <span>{t("add_new_role")}</span>
    </DropdownItem>
  );
};

export default AddRoleMenuItem;

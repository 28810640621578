import { getFacilityTypes } from "api/dropdown";
import { useDropdownsOptions } from "./useOptions";
import { useQuery } from "react-query";
import { facilityTypesKey } from "features/healthcareFacility";
//TODO: remove this hook
// hook that fetch and save facility type options
export function useHFTypesOptions() {
  const { data, ...rest } = useQuery(facilityTypesKey, getFacilityTypes);

  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
}

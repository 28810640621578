import { IMenuOption } from "components/core/autoComplete";
import { TChipColor } from "interfaces";

export const EMPTY_OPTION: IMenuOption = { key: "", value: "" };

export const DOCTOR_TYPE_OPTIONS: IMenuOption[] = [
  {
    key: "international",
    value: "International",
  },
  {
    key: "local",
    value: "Local",
  },
];
export const DOCTOR_TITLE_OPTIONS: IMenuOption[] = [
  { key: "MD / DO", value: "MD / DO" },
  { key: "PhD", value: "PhD" },
  { key: "MD/PhD", value: "MD/PhD" },
  { key: "MBBS", value: "MBBS" },
];

export const ROLES: { name: string; color: TChipColor }[] = [
  { name: "doctor", color: "primary-dark" },
  { name: "staff", color: "primary-light" },
  { name: "local-admin", color: "green-light" },
  { name: "doctor-network-admin", color: "gray-dark" },
  { name: "super-admin", color: "gray-light" },
  { name: "patient", color: "error" },
  { name: "cmo", color: "error" },
];

export const ROLE_NAMES: IMenuOption[] = [
  ...ROLES.map((item) => ({
    key: item.name,
    value: item.name.charAt(0).toUpperCase() + item.name.slice(1),
  })),
];

export const ROBOT_TYPES: IMenuOption[] = [
  { key: "hospital-robot", value: "Hospital" },
  { key: "clinic-robot", value: "Clinic" },
  { key: "kiosk-robot", value: "Kiosk" },
  { key: "hotel-robot", value: "Hotel" },
  { key: "home-robot", value: "Home" },
  { key: "tv-robot", value: "TV" },
  { key: "ambulance-robot", value: "Ambulance" },
  { key: "virtual-hf-robot", value: "Virtual HF" },
];

export const ROBOT_LEVELS: IMenuOption[] = [
  { key: "robo-cart", value: "RoboCart" },
  { key: "robo-cam", value: "RoboCam" },
  { key: "robo-life", value: "RoboLife" },
  { key: "robo-doc", value: "RoboDoc" },
  { key: "robo-doc-plus", value: "RoboDoc+" },
];

export const FAMILY_OPTIONS: IMenuOption[] = [
  { key: "brother", value: "Brother" },
  { key: "sister", value: "Sister" },
  { key: "father", value: "Father" },
  { key: "mother", value: "Mother" },
];

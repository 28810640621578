import apiCaller, { IResponse } from "api/index";
import { IUserRole, UserRoleName } from "api/types/userTypes";
import { IProfileResponse } from "./auth";
import {
  IPaginationParams,
  IPaginationTableList,
} from "./types/paginationType";
import { IUserStatistics } from "./types/statisticsTypes";

// ! API is not completed yet.
export type TRoleName =
  | "doctor"
  | "staff"
  | "local-admin"
  | "no-role"
  | "doctor-network-admin"
  | "super-admin"
  | "patient"
  | "cmo";

//#region list of user filter by role name
interface IUsersFilterParams extends IPaginationParams {
  role_name?: TRoleName | TRoleName[];
  keyword?: string;
  hfTypes?: string[];
  departmentsIds?: number[];
  is_active?: 0 | 1;
  gender?: string[];
  status?: string[];
  doctor_type?: string[];
}

export interface IGroupRegistrationParams {
  file: File;
  role_name: string;
  facility_id: number;
  departments?: number[];
  city_id: number;
  cmo_id?: number;
}

export interface IUsersParams {
  role_name?: string;
  hf_type?: string[];
  department_id?: number[];
  gender?: string[];
  status?: string[];
  keyword: string;
  facility_id?: number[];
  doctor_type?: string[];
  page: number;
  per_page: number;
}

export interface IUsers {
  id: number;
  user_id: string;

  avatar: string;
  full_name: string;
  mobile: string;
  gender: string;
  status: "Active" | "Pending" | "Denied";
  is_active: boolean;
  birthday: string;
  facilities: {
    id: number;
    facility_id: string;
    name: string;
    type: string;
  }[];
  departments: { id: number; name: string }[];
  country: {
    name: string;
    flag: string;
  };
  languages: string[];
  more_info: any;
  all_roles: string[];
}

export interface IFacility {
  id: number;
  city: { id: number; name: string };
  state: { id: number; name: string };
  country: {
    id: number;
    name: string;
    flag: string;
  };
  facility_id: string;
  name: string;
  type: string;
  type_value: string;
}

export interface IUsersListResponse {
  id: number; // 170;
  user_id: string; // "D-000000170";
  user_name: string; // "test-5933.094093470868";
  full_name: string; // "Susan N";
  mobile: string; // "+90-5354468877";
  gender: string; //"female";
  status: string; //"Active";
  is_active: boolean; // true;
  birthday: string; // "1991-04-30 00:00:00";
  facilities: [
    {
      id: number; // 101;
      facility_id: string; // "H-TR-000101";
      name: string; //"PPP";
      type: string; // "hospital";
    }
  ];
  departments: [];
  country: {
    name: string; // "Turkey";
    flag: string; //"https://cdn.kcak11.com/CountryFlags/countries/tr.svg";
  };
  languages: [
    {
      language: string; // "English";
      language_fluency: string; //"advanced";
    }
  ];
  more_info: {
    id: number; // 64;
    doctor_type: string; // "local";
    cmo: null | {
      full_name: string; // "Allen Cmo";
      id: number; //61;
      user_name: string; // "cmotest"
    };
    speciality: string; // "Internal Medicine (IM)";
    sub_speciality: string; //"Cardiology EP";
    title: string; //"MD";
    licenses: [
      {
        NPI_number: string; // "8181818188818";
        country_of_license: string; // "United States";
        country_of_license_id: number; //231;
      }
    ];
    biography: null | string;
    website: null | string;
  };
  all_roles: string[];
  avatar: null | string;
}

export interface IUserDetail {
  id: number;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  user_name: string;
  avatar: string;
  status: string;
  is_active: boolean;
  birthday: string;
  gender: "male" | "female" | "others";
  phone: string;
  emergency_phone_number: string;
  insurance: string;
  employee_number: string;
  mrn_number: string;
  national_id: string;
  timezone: {
    id: number;
    name: string;
    gmt_offset_name: string;
  };
  address: {
    id: number;
    name: string;
    phone: string;
    fax: string;
    address: string;
    postal_code: string;
    gmtOffset: number;
    city: {
      id: number;
      name: string;
    };
    state: {
      id: number;
      name: string;
    };
    country: {
      id: number;
      name: string;
      flag: string;
    };
    email: string;
  };
  languages: { language: string; language_fluency: string }[];
  more_info: string[];
  facilities: {
    id: number;
    facility_id: string;
    name: string;
    type: string;
    type_value: string;
    city: {
      id: number;
      name: string;
    };
    state: {
      id: number;
      name: string;
    };
    country: {
      id: number;
      name: string;
      flag: string;
    };
  }[];
  departments: { id: number; name: string }[];
  roles: string[];
  assigned_doctors: string[];
}

export function getUsersByRoleName(
  data: IUsersParams
): Promise<IResponse<IPaginationTableList<IUsers>>> {
  return apiCaller.get("/users", {
    params: data,
  });
}

export function patientDashboardDoctors(
  data: IUsersParams
): Promise<IResponse<IPaginationTableList<IUsers>>> {
  return apiCaller.get("financial-rules/patient-dashboard-doctors", {
    params: data,
  });
}

export function getUserDetail(id: string): Promise<IResponse<IUserDetail>> {
  return apiCaller.get("/users/" + id);
}

export function registerGroupOfUsers(
  data: IGroupRegistrationParams
): Promise<IResponse<any>> {
  return apiCaller.post("/users-group-registration", data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

// TODO: fix these filters
export function getUsers(
  data: IUsersFilterParams
): Promise<IResponse<IPaginationTableList<IUsersListResponse>>> {
  const obj: { [k: string]: any } = {
    // role_name: data.role_name,
    page: data.page,
    per_page: data.per_page,
  };
  if (!!data.keyword) obj["keyword"] = data.keyword;
  if (Array.isArray(data.role_name)) {
    data.role_name.forEach((role, i) => {
      obj[`role_name[${i}]`] = role;
    });
  } else {
    obj["role_name"] = data.role_name;
  }
  if (!!data.hfTypes?.length) {
    data.hfTypes.forEach((t, i) => {
      obj[`hf_type[${i}]`] = t;
    });
  }

  if (!!data.departmentsIds?.length) {
    data.departmentsIds.forEach((dId, i) => {
      obj[`department_id[${i}]`] = dId;
    });
  }

  if (!!data.status?.length) {
    data.status.forEach((status, i) => {
      obj[`status[${i}]`] = status;
    });
  }

  if (!!data.gender?.length) {
    data.gender.forEach((gender, i) => {
      obj[`gender[${i}]`] = gender;
    });
  }
  if (!!data.doctor_type?.length) {
    data.doctor_type.forEach((type, i) => {
      obj[`doctor_type[${i}]`] = type;
    });
  }

  if (!!data.is_active) obj["is_active"] = data.is_active;

  return apiCaller.get(`users`, { params: obj });
}

//#endregion

interface IAddress {
  address: string;
  city: { id: number; name: string };
  country: {
    id: number;
    name: string;
    flag: string;
  };
  email?: null;
  fax?: string;
  gmtOffset?: string;
  id: 868;
  name?: string;
  phone?: string;
  postal_code?: string;
  state: { id: number; name: string };
}

interface IBiography {
  doctor_position: { id: string; value: string }[];
  honors_and_awards: { id: string; value: string }[];
  medical_experiences: {
    id: string;
    medical_doctor_school: string;
    practice_area: string;
    years_in_practice: string;
  }[];
  membership: { id: string; value: string }[];
  short_biography: string;
}

//#region get detail of user
export interface IMoreInfo {
  id: number;
  doctor_type: string;
  cmo?: {
    full_name: string; // "Allen Cmo";
    id: number; //61;
    user_name: string; // "cmotest"
  };
  speciality: string;
  sub_speciality: string;
  title: string;
  licenses: {
    NPI_number: string;
    country_of_license: string;
    country_of_license_id: number;
  }[];
  biography?: IBiography;
  website?: string;
}

export interface IUserLanguages {
  language: string;
  language_fluency: string;
}

export interface ITimezoneResponse {
  gmt_offset_name: string;
  id: number;
  name: string;
}
// const getUser_sample_response = {
//   id: 227,
//   user_id: "P-TR-AB-00227",
//   first_name: "pat",
//   last_name: "patt",
//   email: "pat@g2-holding.co",
//   mobile: "+90-5055455445",
//   user_name: "test-4952.083478388012",
//   avatar: null,
//   status: "Active",
//   is_active: true,
//   birthday: "1997-06-24 00:00:00",
//   gender: "male",
//   phone: "+90-5055455445",
//   emergency_phone_number: null,
//   insurance: null,
//   employee_number: null,
//   mrn_number: null,
//   national_id: null,
//   need_to_change_password: 0,
//   timezone: null,
//   address: {
//     id: 346,
//     name: null,
//     phone: null,
//     fax: null,
//     address: "No28 ankara",
//     postal_code: null,
//     gmtOffset: null,
//     city: {
//       id: 119674,
//       name: "Ankara",
//     },
//     state: {
//       id: 4237,
//       name: "Ankara",
//     },
//     country: {
//       id: 223,
//       name: "Turkey",
//       flag: "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
//     },
//     email: null,
//   },
//   languages: [
//     {
//       language: "Turkish",
//       language_fluency: "advanced",
//     },
//     {
//       language: "English",
//       language_fluency: "advanced",
//     },
//   ],
//   more_info: [],
//   facilities: [
//     {
//       id: 121,
//       facility_id: "HF-TR-00121",
//       name: "aci badam",
//       type: "hospital",
//       city: {
//         id: 119674,
//         name: "Ankara",
//       },
//       state: {
//         id: 4237,
//         name: "Ankara",
//       },
//       country: {
//         id: 223,
//         name: "Turkey",
//         flag: "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
//       },
//     },
//   ],
//   departments: [],
//   roles: [],
//   has_medical_history: true,
// };
export interface IUserResponse {
  avatar?: string;
  mrn_number: null | string;
  national_id: null | string;
  departments?: { id: string; name: string }[];
  zip_code: string;
  birthday: string;
  gender: "male" | "female" | "others";
  id: number;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  is_active: boolean;
  user_name: string;
  timezone: ITimezoneResponse;
  role: IUserRole[];
  country_id: number;
  state_id: number;
  city_id: number;
  password: string;
  employee_number: string;
  emergency_phone_number: string | null;
  address: IAddress;
  facilities: IFacility[];
  more_info?: IMoreInfo;
  languages?: IUserLanguages[];
  phone: string | null;
  status: string;
}

export function getUser(user_id: number): Promise<IResponse<IUserResponse>> {
  return apiCaller.get(`users/${user_id}`);
}

//#endregion

export function getUserData(
  user_id: number
): Promise<IResponse<IProfileResponse>> {
  return apiCaller.get(`users/${user_id}`);
}

//#region create user and assign role in facility
export type TTitle = "MD" | "PhD" | "MD/PhD" | "MBBS";

export interface doctorLanguages {
  language: string;
  language_fluency: string;
}

export interface ISubmitUserData {
  first_name: string;
  last_name: string;
  email: string; // must be unique
  mobile: string; // must be unique
  user_name?: string; // must be unique
  password?: string;
  country_id?: number;
  state_id?: number;
  city_id: number;
  birthday?: Date | string; // in format of yyyy-mm-dd
  gender?: "male" | "female" | "others";
  role_id?: number;
  facility_id?: number;
  address: string;
  timezone_id?: number;
  phone?: string;
  timezone?: string;
  speciality?: string;
  sub_speciality?: string;
  title?: TTitle;
  zip_code?: string;
  emergency_phone_number?: string;
  languages?: doctorLanguages[];
  doctor_type?: string;
  website?: string;
  employee_number?: string;
  postal_code?: string;
  departments?: number[];
  licenses?: { country_of_license?: number; NPI_number?: string }[];
  mrn_number?: string;
  national_id?: string;

  [k: string]: any;
}

export function submitUser(data: ISubmitUserData) {
  return apiCaller.post(`users`, data);
}

//#endregion

//#region update user and assign role in facility
export function updateUser(data: ISubmitUserData, user_id: number) {
  return apiCaller.put(`users/${user_id}`, data);
}

//#endregion

//#region reset password
export function userResetPassword(
  fd: FormData,
  role_name: UserRoleName | null
): Promise<IResponse<null>> {
  return apiCaller.put(`users-reset-password`, { ...fd, role_name: role_name });
}

//#endregion

//#region show user statistics
export function getUserStatistics(
  roleName: UserRoleName
): Promise<IResponse<IUserStatistics>> {
  return apiCaller.get(`users-statistic?role_name=${roleName}`, {
    params: { type: "by_gender_and_country" },
  });
}

interface IUsersStatisticsParams {
  role_name?: TRoleName;
}

export interface IUserStatisticsByStatusResponse {
  status: string;
  count: number;
}

export function getUserStatisticsByStatus(
  params?: IUsersStatisticsParams
): Promise<IResponse<IUserStatisticsByStatusResponse[]>> {
  return apiCaller.get(`users-statistic`, {
    params: { ...params, type: "by_status" },
  });
}

//#endregion

//#region index users
export interface IUsersParams extends IPaginationParams {
  types?: string[];
  keyword: string;
}

export function getUsersList(
  roleName: UserRoleName,
  params: IUsersParams
): Promise<IResponse<IPaginationTableList<IUsersListResponse>>> {
  return apiCaller.get(`users`, {
    params: { ...params, role_name: roleName },
  });
}

//#endregion

interface IUserSearchParams {
  role_name?: string;
  keyword: string;
  country_id?: number;
  state_id?: number;
  city_id?: number;
  speciality?: string;
  DNA_id?: number;
  is_contact?: number;
  per_page?: number;
  page?: number;
}

export interface ISearchUserResponse {
  id: number;
  user_id: string;
  user_name: string;
  full_name: string;
  last_message_created_at: string;
  last_message_updated_at: string;
}

export function searchUser(
  params: IUserSearchParams
): Promise<IResponse<IPaginationTableList<ISearchUserResponse>>> {
  return apiCaller.get("users-list", { params });
}

export interface IChangeUserActivity {
  role_name?: TRoleName;
  id: number;
  status?: "active" | "pending" | "denied";
}

export function changeActivationOfUser(
  data: IChangeUserActivity
): Promise<IResponse<null>> {
  return apiCaller.put("users-approval", { ...data });
}

//#region statistic of list by gender and country
export interface IGenderStatistics {
  gender: string;
  count: number;
}

interface ICountryStatistics {
  country: string;
  count: number;
}

interface IUserStatisticsByGenderAndCountryResponse {
  all_count: number;
  gender_statistic: IGenderStatistics[];
  country_statistic: ICountryStatistics[];
}

export function getUsersStatisticsByGenderAndCountry(
  roleName?: TRoleName
): Promise<IResponse<IUserStatisticsByGenderAndCountryResponse>> {
  return apiCaller.get("users-statistic", {
    params: { role_name: roleName, type: "by_gender_and_country" },
  });
}

//#endregion
//#region statistic of list by roles
interface IByRole {
  "super-admin": number;
  "doctor-network-admin": number;
  "local-admin": number;
  doctor: number;
  staff: number;
}

export interface IUserStatisticsByRolesResponse {
  all_count: number;
  by_role: IByRole;
}

export function getUsersStatisticsByRoles(): Promise<
  IResponse<IUserStatisticsByRolesResponse>
> {
  return apiCaller.get("users-statistic", {
    params: { type: "by_roles" },
  });
}

//#endregion
//#region statistic of list by facility types
interface IByFacilityType {
  Clinic: number;
  "Home office": number;
  Hospital: number;
}

interface IUserStatisticsByFacilityTypeResponse {
  all_count: number;
  by_facility_type: IByFacilityType;
}

export function getUsersStatisticsByFacilityTypes(): Promise<
  IResponse<IUserStatisticsByFacilityTypeResponse>
> {
  return apiCaller.get("users-statistic", {
    params: { role_name: "local-admin", type: "by_facility_type" },
  });
}

//#endregion
//#region statistic of list by DNA impact

interface IUserStatisticsByDNAImpactResponse {
  DNA: string;
  count: number;
}

export function getUsersStatisticsByDNAImpact(): Promise<
  IResponse<IUserStatisticsByDNAImpactResponse[]>
> {
  return apiCaller.get("users-statistic", {
    params: { type: "by_DNA_impact" },
  });
}

//#endregion

//#region group registration
export function submitUsersGroupRegistration(data: FormData) {
  return apiCaller.post("users-group-registration", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

//#endregion

/*export function deleteUser(id: number): Promise<IResponse<any>> {
  return apiCaller.delete("/users/" + id);
}*/

export interface ICreateUserParams {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  user_name: string;
  password?: string;
  city_id: string;
  birthday: string;
  gender: "male" | "female" | "others";
  employee_number?: string;
  mrn_number?: string;
  national_id?: string;
  timezone_id?: string;
  phone?: string;
  address?: string;
  postal_code?: string;
  DNA_id?: string;
  speciality?: string;
  sub_speciality?: string;
  title?: string;
  NPI_number?: string;
  doctor_type?: string;
  country_of_license?: string;
  languages?: { language: string; language_fluency: string }[];
  roles_in_facilities?: { role_name: string; facility_id: string }[];
  departments?: string[];
}

export function createUser(data: ICreateUserParams): Promise<IResponse<any>> {
  return apiCaller.post("/users", data);
}

export interface ILAPatientsParams extends IPaginationParams {
  keyword: string;
  gender?: string[];
  facility_type?: string[];
}

export interface ILAPatients {
  id: number;
  patient_id: string;
  user_name: string;
  relatioinship: string;
  full_name: string;
  birth_date: string;
  phone: string;
  mobile: string;
  gender: "Male" | "Female";
  facility_name: string;
  facility_type: string;
  country: string;
  flag: string;
}

export function getLocalAdminPatients(
  params: ILAPatientsParams
): Promise<IResponse<IPaginationTableList<ILAPatients>>> {
  return apiCaller.get("/LA/patients-list", { params });
}

export interface IAddNewRoleData {
  role: TRoleName;
  cmo_id?: number;
  licenses?: { NPI_number: string; country_of_license: number }[];
  /**
   * pass the value of the dropdown not the key
   */
  speciality?: string;
  /**
   * pass the value of the dropdown not the key
   */
  sub_speciality?: string;
  /**
   * pass the key of the dropdown not the value
   */
  title?: string;
  /**
   * pass the key of the dropdown not the value
   */
  doctor_type?: string;
}
export function AddNewRoleToCurrentFacility(
  data: IAddNewRoleData,
  userId: string
) {
  return apiCaller.post(`/${userId}/new-role`, data);
}

export interface IRemoveRoleData {
  role: TRoleName;
}

export function RemoveRoleFromCurrentFacility(
  userId: string,
  data: IRemoveRoleData
) {
  return apiCaller.post(`/${userId}/deassign-role`, data);
}

import Switch, { ISwitchProps } from "components/core/switch";
import { IField } from "./IField";
import FastField from "./field";

type inputProps = Omit<ISwitchProps, "onChange" | "checked">;

interface IProps<T> extends IField<T, boolean>, inputProps {}

export function FastSwitch<T>({
  formik,
  onChange,
  onChangeAlongside,
  fieldName,
  ...inputProps
}: IProps<T>) {
  return (
    <FastField
      {...{
        formik,
        onChange,
        onChangeAlongside,
        fieldName,
        ...inputProps,
      }}
      onChangeValueMapper={(value: boolean) => value}
    >
      {(props) => (
        <Switch
          {...inputProps}
          checked={props.value}
          onChange={props.onChange}
          name={fieldName}
        />
      )}
    </FastField>
  );
}

// yup-extended.ts
import * as yup from "yup";
import { DateSchema } from "yup";
import { AssertsShape, ObjectShape, TypeOfShape } from "yup/lib/object";
import { AnyObject, Maybe, Optionals } from "yup/lib/types";
import { isValidPhoneNumber } from "react-phone-number-input";

export type IValidationObj = { key: string; fieldName: string };
export function requiredObj(fieldName: string): IValidationObj {
  return { key: `msgType.req`, fieldName: `fieldNames.${fieldName}` };
}
export function emailObj(): IValidationObj {
  return { key: `msgType.email`, fieldName: "" };
}
export function phoneObj(): IValidationObj {
  return { key: `msgType.phone`, fieldName: "" };
}

export function urlObj(): IValidationObj {
  return { key: `msgType.url`, fieldName: "" };
}

export const REQUIRED = "This field is required.";

export function parseDateString(value: string, originalValue: string) {
  if (originalValue === "" || originalValue === undefined) {
    return undefined;
  }
  return value;
}

// check string empty or undefined
yup.addMethod<yup.StringSchema>(yup.string, "emptyAsUndefined", function () {
  return this.transform((value) => (value ? value : undefined));
});

// check number empty or undefined
yup.addMethod<yup.NumberSchema>(yup.number, "emptyAsUndefined", function () {
  return this.transform((value, originalValue) =>
    String(originalValue)?.trim() ? value : undefined
  );
});

// check mix object empty or undefined
yup.addMethod(yup.mixed, "emptyAsUndefined", function () {
  return this.transform((value, originalValue) =>
    String(originalValue)?.trim() ? value : undefined
  );
});

// test from schema and create error if needed
yup.addMethod<yup.ObjectSchema<any>>(yup.object, "dropdown", function (args) {
  return this.test("key-value-test", "", function (value) {
    const { createError } = this;
    const { optional, errorMessage } = args || {
      optional: false,
      errorMessage: REQUIRED,
    };
    if ((!value?.key || !value?.value) && !optional) {
      return createError({ message: errorMessage });
    }
    return true;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, "mac", function () {
  return this.matches(
    /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
    "Invalid MAC address"
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "requiredString", function (args) {
  return this.test("required", "", function (value) {
    const { createError } = this;
    const { optional, errorMessage } = args || {
      optional: false,
      errorMessage: REQUIRED,
    };
    if (!optional && (value === undefined || value.trim() === "")) {
      return createError({ message: errorMessage });
    }
    return true;
  });
});

// validate phone number
yup.addMethod<yup.ObjectSchema<any>>(
  yup.object,
  "phoneNumber",
  function (args) {
    return this.test("phone-number-test", "", function (value) {
      const { createError } = this;
      const { optional, errorMessage } = args || {
        optional: false,
        errorMessage: REQUIRED,
      };
      // eslint-disable-next-line no-useless-escape
      // const regex = /^(?=.*\d)[\d-]{7,}$/;
      if (!value?.number && !optional) {
        return createError({ message: errorMessage || phoneObj() });
      }
      if (value?.number) {
        const phoneText = (value?.code + value?.number)?.replace(" ", "");
        console.log(phoneText);
        if (!isValidPhoneNumber(phoneText)) {
          return createError({ message: phoneObj() });
        }
      }

      return true;
    });
  }
);

// declare yup model
yup.addMethod<yup.ObjectSchema<any>>(yup.object, "timeInput", function (args) {
  return this.test("time-input-test", "", function (value) {
    const { createError } = this;
    const { optional } = args || { optional: false };
    if ((!value?.hour || !value?.minute || !value?.period) && !optional) {
      return createError({ message: REQUIRED });
    }
    return true;
  });
});

yup.addMethod<DateSchema>(yup.date, "minAge", function (args) {
  return this.test("min-age-test", "", function (value) {
    const { createError } = this;
    const { optional, min, errorMessage } = args || {
      optional: false,
      errorMessage: REQUIRED,
    };
    var dateOfBirth = new Date(value || "");
    var differenceMs = Date.now() - dateOfBirth.getTime();
    var dateFromEpoch = new Date(differenceMs);
    var yearFromEpoch = dateFromEpoch.getUTCFullYear();
    var age = Math.abs(yearFromEpoch - 1970);

    if (!value && !optional) {
      return createError({ message: errorMessage });
    }
    if (age < min && !optional) {
      return createError({ message: `Age Should be over ${min}` });
    }

    return true;
  });
});

yup.addMethod<DateSchema>(yup.date, "maxAge", function (args) {
  return this.test("max-age-test", "", function (value) {
    const { createError } = this;
    const { optional, max } = args || { optional: false };
    var dateOfBirth = new Date(value || "");
    var differenceMs = Date.now() - dateOfBirth.getTime();
    var dateFromEpoch = new Date(differenceMs);
    var yearFromEpoch = dateFromEpoch.getUTCFullYear();
    var age = Math.abs(yearFromEpoch - 1970);

    if (!value && !optional) {
      return createError({ message: REQUIRED });
    }
    if (age > max && !optional) {
      return createError({ message: `Age Should be under ${max}` });
    }

    return true;
  });
});

declare module "yup" {
  interface ObjectSchema<
    TShape extends ObjectShape,
    TContext extends AnyObject = AnyObject,
    TIn extends Maybe<TypeOfShape<TShape>> = TypeOfShape<TShape>,
    TOut extends Maybe<AssertsShape<TShape>> =
      | AssertsShape<TShape>
      | Optionals<TIn>
  > extends yup.BaseSchema<TIn, TContext, TOut> {
    dropdown(args?: {
      optional?: boolean;
      errorMessage?: string | IValidationObj;
    }): ObjectSchema<TShape, TContext, TIn>;
    phoneNumber(args?: {
      optional?: boolean;
      errorMessage?: string | IValidationObj;
    }): ObjectSchema<TShape, TContext, TIn>;
    timeInput(args?: {
      optional: boolean;
    }): ObjectSchema<TShape, TContext, TIn>;
  }

  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): StringSchema<TType, TContext>;
    mac(): StringSchema<TType, TContext>;
    requiredString(args?: {
      optional?: boolean;
      errorMessage?: string | IValidationObj;
    }): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): NumberSchema<TType, TContext>;
  }

  interface DateSchema {
    minAge(args?: {
      optional?: boolean;
      min: number;
      errorMessage?: string | IValidationObj;
    }): DateSchema;
    maxAge(args?: {
      optional?: boolean;
      max: number;
      errorMessage?: string | IValidationObj;
    }): DateSchema;
  }
}

export type ShapeOf<T> = Record<keyof T, yup.AnySchema>;

export function generateErrorMessage(fieldName: string) {
  return `The ${fieldName} field is required.`;
}

export default yup;

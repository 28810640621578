import {HospitalBold} from "components/icon";
import {getDropdownValue} from "utils/dropdowns";
import {FastDropdown} from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import {useFacilitiesByTypeDropdown} from "features/healthcareFacility";
import {useTranslation} from "react-i18next";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the `HF Type` that this `HF Name` is dependent on it.
   *
   * without knowing the `HF Type` we can not fetch the states of that `HF Type`, so the `HF Name` depends on the `HF Type`.
   */
  hfTypeFieldName: keyof T & string;
}

export function FacilityByType<T>({
  formik,
  hfTypeFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the hfType value from formik values and extract its key
  const { key: hfTypeKey } = getDropdownValue(formik, hfTypeFieldName);

  // fetch data
  const { data, isLoading, isError } = useFacilitiesByTypeDropdown(hfTypeKey);

  return (
    <FastDropdown
      label={t("hf_name")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!hfTypeKey || disabled}
      icon={HospitalBold}
      {...props}
    />
  );
}

import { EventEmitter } from "events";
import { IAlertify } from "./types";
import { alertEmitterKey } from "constants/eventEmitter";

const emitter = new EventEmitter();

export default emitter;

export { alertEmitterKey };

export const alertify = (alertifyProps: IAlertify) => {
  emitter.emit(alertEmitterKey, alertifyProps);
  emitter.setMaxListeners(2);
};

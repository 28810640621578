import { PropsWithChildren } from "react";
import { VideoVisitLogo } from "../../icon";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface ILoaderProps extends PropsWithChildren<any> {
  isLoading: boolean;
  isError?: boolean;
}

//? Creates a 'isLoading . . .' message

const Loader = ({ isLoading, isError, children }: ILoaderProps) => {
  const { t } = useTranslation("global");

  if (isLoading) {
    return (
      <div
        className={clsx("d-flex justify-content-center my-5", classes.loader)}
      >
        <VideoVisitLogo />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="d-flex justify-content-center">{t("error_message")}</div>
    );
  }

  return <>{children}</>;
};

export default Loader;

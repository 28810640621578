import { useCallback, useEffect } from "react";
import Modal from "../../core/modal";
import Button from "../../core/button";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { useUnblock } from "../../../hooks/useBlock";
import { alertModalNaxWidth, IAlertProps } from "./types";
import { useTranslation } from "react-i18next";

const Alert = ({
  open,
  setOpen,
  dismissible,
  onClose,
  title,
  text,
  customButtons,
  onCancel,
  onApprove,
  submit,
  noCancel,
  approveText,
  modalMaxWidth = alertModalNaxWidth,
  notScroll,
  isLoading,
}: IAlertProps) => {
  const { t } = useTranslation("form");
  const unblock = useUnblock();

  const approve = useCallback(() => {
    if (!isLoading) {
      setOpen(false);
    }
    if (onApprove) {
      unblock();
      setTimeout(() => onApprove(), 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onApprove, setOpen, unblock]);

  useEffect(() => {
    if (open) {
      const handleEscKey = (event: KeyboardEvent) => {
        if (event.key === "Escape" && dismissible) {
          if (onClose) {
            onClose();
          } else {
            setOpen(false);
          }
        }
      };

      document.addEventListener("keydown", handleEscKey);
      return () => {
        document.removeEventListener("keydown", handleEscKey);
      };
    }
  }, [open, setOpen, onClose, dismissible]);

  useEffect(() => {
    if (open) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter" && onApprove) {
          event.preventDefault();
          approve();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open, setOpen, onClose, dismissible, onApprove, approve]);

  return (
    <Modal
      notScroll={notScroll}
      open={open}
      backdrop={true}
      draggable={false}
      onClose={() => {
        if (dismissible) {
          if (onClose) {
            onClose();
          } else {
            setOpen(false);
          }
        }
      }}
      maxWidth={modalMaxWidth}
    >
      <div tabIndex={0} className={clsx(classes.alert)}>
        <h6 className={clsx(classes.alert__title)}>{title}</h6>
        <p className={clsx(classes.alert__text)}>{text}</p>
        <div className={clsx(classes.alert__btns)}>
          {!customButtons && (
            <>
              {!noCancel && (
                <Button
                  variant={"outlined"}
                  color={"secondary"}
                  onClick={() => {
                    if (onCancel) {
                      onCancel();
                    }
                    setOpen(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              )}
              <Button
                isLoading={isLoading}
                type={submit ? "submit" : undefined}
                onClick={() => {
                  approve();
                }}
              >
                {approveText || t("approve")}
              </Button>
            </>
          )}
          {!!customButtons && customButtons}
        </div>
      </div>
    </Modal>
  );
};

export default Alert;

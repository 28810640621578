import clsx from "clsx";
import classes from "../styles.module.scss";
import { useHistory } from "react-router";
import { IShowPatientReferral } from "../../../../api/healthRecord";
import { IShowAppointment } from "../../../../api/appointment";
import React, { useEffect, useState } from "react";
import useDownloadPdf from "../../../../hooks/useDownloadPdf";
import { notify } from "../../../../components/core/toast";
import Page from "../../../../components/layout/page";
import PrintNotif from "../../../../components/common/printNotif";
import ListItem from "../../../../components/common/list/components/listItem";
import { ListInfo } from "../../../../components/icon/listInfo";
import InformationCard from "../../../../components/common/informationCard";
import { humanizedDate, humanizedTime } from "../../../../utils/date";
import DataCard from "../../../../components/common/dataCard";
import { useParams } from "react-router-dom";
import { getLinkData } from "../../../../api/share";
import { useQuery } from "react-query";
import Loader from "../../../../components/common/loader";
import ImageCard from "../../../../components/common/videoVisit/components/images/ImageCard";
import { useTranslation } from "react-i18next";

const ShareShowReferral = () => {
  const { t } = useTranslation(["global", "table"]);
  const history = useHistory();

  const id = useParams<{ id: string }>()?.id;
  if (!id) {
    history.push("/404");
  }
  const { data, isLoading, isError } = useQuery([getLinkData.name, id], () =>
    getLinkData(id)
  );
  const search = new URLSearchParams(data?.data?.data?.data);
  const encodedData = search.get("data");
  let d: undefined | IShowPatientReferral = undefined;
  try {
    d = JSON.parse(encodedData || "");
  } catch (e) {}
  const encodedAppt = search.get("appt");

  let appt: IShowAppointment | undefined = undefined;

  try {
    appt = JSON.parse(encodedAppt || "");
  } catch (e) {}

  const [printOpen, setPrintOpen] = useState(false);

  const printWebpage = useDownloadPdf();

  useEffect(() => {
    if (d && !isLoading) {
      notify.info(t("pdf_wait"));
      printWebpage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, isLoading, isError]);
  return (
    <Page
      title={t("health_record.referral.name")}
      type={"inner"}
      backTo={"pop"}
      noButtons={true}
    >
      <Loader isLoading={isLoading} isError={isError}>
        <div className={clsx(classes.modal)}>
          <DataCard
            variant={"patientFollowUp"}
            avatar={appt?.local_doctor?.avatar}
            tableData={{
              appt_id: appt?.appt_id || "- - -",
              appt_type: appt?.appt_type || "- - -",
              visit_date: humanizedDate(appt?.start_date || "") || "- - -",
              visit_time: humanizedTime(appt?.start_date || "") || "- - -",
              timezone: appt?.local_doctor?.timezone?.name || "- - -",
              patient_name:
                appt?.patient?.first_name + " " + appt?.patient?.last_name ||
                "- - -",
              birth: humanizedDate(appt?.patient?.birthday || "") || "- - -",
              hf_name: appt?.facility?.name || "- - -",
              status: {
                label: d?.referral?.status
                  ? t("health_record.performed")
                  : t("health_record.un_performed"),
                value: d?.referral?.status,
              },
            }}
            id={appt?.local_doctor?.user_id || ""}
            name={
              appt?.local_doctor?.first_name +
              " " +
              appt?.local_doctor?.last_name
            }
            specialty={appt?.local_doctor?.speciality || ""}
          />
          <InformationCard
            icon={<ListInfo />}
            title={t("health_record.referral.name")}
            parentClassName={clsx(classes.modal__back)}
          >
            <div className={clsx(classes.healthRecord__column, "mt-2")}>
              <ListItem
                numberLabel={1}
                data={{
                  title: d?.referral?.doctor_name,
                  columns: [
                    {
                      label: t("columns.referral_type", { ns: "table" }) + ": ",
                      value: t(d?.referral?.type) || "- - -",
                    },
                    {
                      label: t("columns.speciality", { ns: "table" }) + ": ",
                      value: d?.referral?.speciality || "- - -",
                    },
                    {
                      label: t("columns.date_referral", { ns: "table" }) + ": ",
                      value: d?.referral?.interval || "",
                    },
                  ],
                }}
              />
              <div>
                <span
                  className={clsx(classes.modal__bigText, classes.modal__bold)}
                >
                  {t("columns.date_referral", { ns: "table" })}:&nbsp;&nbsp;
                </span>
                <span className={clsx(classes.modal__bigText)}>
                  {d?.referral?.interval || ""}
                </span>
              </div>
              <div>
                <div
                  className={clsx(classes.modal__bigText, classes.modal__bold)}
                >
                  {t("columns.note_specialist", { ns: "table" })}
                </div>
                <div className={clsx(classes.modal__bigText)}>{d?.note}</div>
              </div>
            </div>
            <div className={classes.modal__sig}>
              <ImageCard
                imgUrl={appt?.local_doctor?.signature_base || ""}
                noZoom
              />
            </div>
          </InformationCard>
        </div>
      </Loader>
      <PrintNotif open={printOpen} setOpen={setPrintOpen} name={"Referral"} />
    </Page>
  );
};

export default ShareShowReferral;

import React from "react";
import { TRoleName } from "../../api/user";

/*export const VIDEO_VISIT_CONTEXT = "VIDEO";*/

export interface IVideoVisitContext {
  open: boolean;
  onJoin: (appt_id: string, role: TRoleName) => void;
}

const VisitContext = React.createContext<IVideoVisitContext>({
  open: false,
  onJoin: () => {},
});

export default VisitContext;

import { Dispatch, ReactNode, SetStateAction } from "react";

export const alertModalNaxWidth = 600;

export type IAlertProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dismissible?: boolean;
  onClose?: () => any;
  onCancel?: () => any;
  customButtons?: ReactNode;
  title: string;
  text: string;
  onApprove?: () => any;
  submit?: boolean;
  noCancel?: boolean;
  approveText?: string;
  /**
   * modal max-width in px
   */
  modalMaxWidth?: number;
  notScroll?: boolean;
  isLoading?: boolean;
};

export type IAlertify = Omit<IAlertProps, "open" | "setOpen">;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpApi, { HttpBackendOptions } from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init<HttpBackendOptions>({
    lng: "en",
    load: "languageOnly",
    fallbackLng: process.env.NODE_ENV === "development" ? false : "en",
    backend: {
      loadPath: "/translations/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

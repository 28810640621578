import UploadInput, {
  IUploadInputDefaultProps,
} from "components/core/uploadInput";
import { IField } from "./IField";
import FastField from "./field";

type inputProps = Omit<
  IUploadInputDefaultProps,
  "onChange" | "filesList" | "name"
>;

interface IProps<T> extends IField<T, File[]>, inputProps {}
export function FastUploadInput<T>({
  formik,
  onChange,
  onChangeAlongside,
  ...inputProps
}: IProps<T>) {
  return (
    <FastField
      onChangeValueMapper={(value: File[]) => value}
      {...{ formik, onChange, onChangeAlongside, ...inputProps }}
    >
      {(props) => (
        <UploadInput
          {...inputProps}
          filesList={props.value}
          onChange={props.onChange}
          onBlur={props.validateOnBlur}
          // error={props.error}
        />
      )}
    </FastField>
  );
}
FastUploadInput.defaultProps = {
  type: "excel",
  fileUploadCount: 1,
};

import IDropdown from "../formikFields/IDropdown";
import { FastDropdown } from "../formikFields";
import { EmojiHappyBold } from "components/icon";
import { useRobotTypeOptions } from "features/registeredRobots";
import { useTranslation } from "react-i18next";

interface IProps<T> extends IDropdown<T> {
  /**
   * filter `home-robot` option from robot types
   */
  noHomeRobot?: boolean;
}

export function RobotType<T>({ noHomeRobot, ...props }: IProps<T>) {
  const { t } = useTranslation("form");

  // get the device types from server
  const { OPTIONS, isError, isLoading } = useRobotTypeOptions();

  const filteredOptions =
    noHomeRobot && OPTIONS
      ? OPTIONS.filter((item) => item.key !== "home-robot")
      : OPTIONS;

  return (
    <FastDropdown
      label={t("robot_type")}
      options={filteredOptions}
      isError={isError}
      isLoading={isLoading}
      icon={EmojiHappyBold}
      {...props}
    />
  );
}

import { submitResetPassword } from "api/auth";
import clsx from "clsx";
import Button from "components/core/button";
import FormPassword from "components/form/password";
import { FormikErrors, useFormik } from "formik";
import { useContext } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { getFormikFieldProps } from "utils/form";
import { PasswordRecoveryContext } from "../passwordRecovery";
import LoginLayout from "./loginLayout";
import classes from "./styles.module.scss";
import { useHistory } from "react-router";
import { notify } from "components/core/toast";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

interface IOnFieldForm {
  password: string;
  confirmPassword: string;
}

const INITIAL_ONE_FIELD_FORM: IOnFieldForm = {
  password: "",
  confirmPassword: "",
};

export default function CreatePasswordPage() {
  const { t } = useTranslation(["global", "form"]);

  let { OTP, email } = useContext(PasswordRecoveryContext);
  let history = useHistory();
  let createPasswordMutation = useMutation(submitResetPassword, {
    onSuccess: () => {
      notify.success(t("password_changed", { ns: "form" }));
      history.push("/login");
    },
  });

  const formik = useFormik<IOnFieldForm>({
    initialValues: INITIAL_ONE_FIELD_FORM,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, actions) => {
      createPasswordMutation.mutate({
        email,
        otp: OTP,
        new_password: values.password,
        new_password_confirmation: values.confirmPassword,
      });
    },
    validate: (values) => {
      const errors: FormikErrors<IOnFieldForm> = {};

      if (!values.confirmPassword) {
        errors.confirmPassword = t("password_confirmation_required", {
          ns: "form",
        });
      }

      if (values.password.length < 8) {
        errors.password = t("password_length_err", { ns: "form" });
      } else if (!/^(?=.*[a-z])/.test(values.password)) {
        errors.password = t("password_lowercase_err", { ns: "form" });
      } else if (!/^(?=.*[A-Z])/.test(values.password)) {
        errors.password = t("password_uppercase_err", { ns: "form" });
      } else if (!/^(?=.*[!@#?$%^&*])/.test(values.password)) {
        errors.password = t("password_special_err", { ns: "form" });
      } else {
        if (
          values.confirmPassword &&
          values.password !== values.confirmPassword
        ) {
          errors.confirmPassword = t("password_not_same", { ns: "form" });
        }
      }
      return errors;
    },
  });
  return (
    <LoginLayout
      form_title={t("create_new_password.title")}
      form_description={t("create_new_password.description")}
      step={3}
    >
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormPassword
          rootProps={{ placeholder: t("new_password", { ns: "form" }) }}
          className="p-0 mt-4"
          {...getFormikFieldProps(
            "password",
            t("new_password", { ns: "form" }),
            formik
          )}
        />
        <FormPassword
          rootProps={{ placeholder: t("confirm_password", { ns: "form" }) }}
          className="p-0 mt-4"
          {...getFormikFieldProps(
            "confirmPassword",
            t("confirm_password", { ns: "form" }),
            formik
          )}
        />
        <Button
          className="mt-4 w-100"
          type="submit"
          disabled={createPasswordMutation.isLoading}
        >
          {createPasswordMutation.isLoading ? (
            <div className="d-flex mt-2">
              <BeatLoader color="#fff" size={10} margin={2} />
            </div>
          ) : (
            t("save")
          )}
        </Button>
        <div className="d-flex mt-2">
          <span>
            <span className={clsx(["ME-2", classes.text])}>
              {t("forget_password.back")}
            </span>
            <Link className={classes.link} to={"/login"}>
              {t("click_here")}
            </Link>
          </span>
        </div>
      </form>
    </LoginLayout>
  );
}

import { ReactComponent as NotFoundSVG } from "assets/images/404.svg";
import classes from "./styles.module.scss";

function NotFoundIcon(props: { [key: string]: any }) {
  return (
    <NotFoundSVG data-variant="bulk" {...props} className={classes.logo} />
  );
}

export { NotFoundIcon };

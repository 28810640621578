import { CalendarBold, MailTrackingBold, UserBold } from "components/icon";
import { formSubmission } from "utils/form";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { IRegistrationStepProps } from "../../doctor/components/registrationLayout";
import Grid from "components/core/Grid";
import { useTranslation } from "react-i18next";
import {
  FastDatePicker,
  FastInput,
  FastPhoneInput,
} from "components/formikFields";
import { Gender } from "components/dropdown";

const RegistrationFirstPage = ({
  formik,
  onSetAlert,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");

  return (
    <form autoComplete="off" onSubmit={(e) => formSubmission(e, formik)}>
      <Grid>
        <FastInput
          formik={formik}
          fieldName={"firstName"}
          label={t("first_name")}
          icon={UserBold}
        />
        <FastInput
          formik={formik}
          fieldName={"lastName"}
          label={t("last_name")}
          icon={UserBold}
        />
        <Gender
          formik={formik}
          fieldName="gender"
          id={formik.values.gender.key}
        />
        <FastDatePicker
          formik={formik}
          fieldName={"birthday"}
          icon={CalendarBold}
          label={t("birth")}
        />
        <FastInput
          formik={formik}
          fieldName={"nationalId"}
          label={t("nid")}
          icon={UserBold}
        />
        <FastInput
          formik={formik}
          fieldName={"mrnNumber"}
          label={t("mrn")}
          icon={UserBold}
        />
        <FastPhoneInput
          formik={formik}
          fieldName="mobile"
          label={t("mobile")}
        />
        <FastPhoneInput
          formik={formik}
          fieldName="alternativePhoneNumber"
          label={t("phone")}
        />
        <FastPhoneInput
          formik={formik}
          fieldName="emergencyPhoneNumber"
          label={t("emergency_phone")}
        />
        <FastInput
          formik={formik}
          fieldName={"email"}
          label={t("email")}
          icon={MailTrackingBold}
        />

        <div className={classes.buttonContainer}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => onSetAlert(true)}
          >
            {t("cancel")}
          </Button>
          <Button type="submit">{t("continue")}</Button>
        </div>
      </Grid>
    </form>
  );
};

export default RegistrationFirstPage;

import clsx from "clsx";
import VitalCard, { IVitalCardProps } from "../vitalCard";
import Ruler from "../ruler";
import StatusCard from "../statusCard";
import bodyImg from "../../../assets/images/body-illustration.svg";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";

// VitalSign props type interface
export interface IVitalSignProps {
  healthInfo: IVitalCardProps[];
  height?: number;
  weight?: number;
  reasonForVisit: string;
  birth: string;
  gender: string;
  noInfo?: boolean;
}

// bmi object type interface
export interface IBmiCondition {
  type: "normal" | "warning" | "danger";
  text: string;
}

// generate object that have message and condition based on bmi value
function generateConditionFromBmiValue(bmi: number) {
  if (bmi < 18.5) {
    return {
      type: "warning",
      text: "You're Underweight",
    };
  } else if (bmi <= 24.9) {
    return {
      type: "normal",
      text: "You're Healthy",
    };
  } else if (bmi <= 29.9) {
    return {
      type: "warning",
      text: "You're Overweight",
    };
  } else {
    return {
      type: "danger",
      text: "You're Unhealthy",
    };
  }
}

// vital sign component for show health condition
const VitalSign = ({
  healthInfo,
  height,
  weight,
  reasonForVisit,
  birth,
  gender,
  noInfo,
}: IVitalSignProps) => {
  const { t } = useTranslation("global");
  // calculating bmi
  const bmi =
    !weight || !height
      ? 0
      : Number(((weight * 10000) / (height * height)).toFixed(1));

  let bmiCondition: IBmiCondition;
  bmiCondition = generateConditionFromBmiValue(bmi) as IBmiCondition;

  // render component
  return (
    <div className={clsx(classes.vitalSign)}>
      <div className={clsx(classes.vitalSign__cardsPlace)}>
        {renderCards(healthInfo)}
      </div>
      <div className={clsx(classes.vitalSign__rightPart)}>
        <div className={clsx(classes.vitalSign__bluePart)}></div>
        <div
          className={clsx(
            classes.vitalSign__rightInside,
            classes.vitalSign__row
          )}
        >
          <div className={clsx(classes.vitalSign__mCards)}>
            <div>
              <Ruler color={"light"} type={t("height")} number={height} />
            </div>
            <div>
              <Ruler color={"blue"} type={t("weight")} number={weight} />
            </div>
          </div>
          <div className={clsx(classes.vitalSign__status)}>
            <StatusCard
              title={t("bmi")}
              number={bmi}
              conditionText={t(bmiCondition.text)}
              condition={bmiCondition.type}
              min={10}
              max={50}
            />
          </div>
        </div>
        <div className={clsx(classes.vitalSign__rightInside)}></div>
        <div className={clsx(classes.vitalSign__content)}>
          <div>
            <img
              src={bodyImg}
              alt="body"
              className={clsx(classes.vitalSign__human)}
            />
          </div>
          <div className={clsx(classes.vitalSign__infoPlace)}>
            {!noInfo && (
              <>
                <div>
                  <h1 className={clsx(classes.vitalSign__title)}>
                    {t("reason_for_visit")}
                  </h1>
                  <p className={clsx(classes.vitalSign__text)}>
                    {reasonForVisit}
                  </p>
                </div>
                <div>
                  <h1 className={clsx(classes.vitalSign__title)}>
                    {t("birth")}
                  </h1>
                  <p className={clsx(classes.vitalSign__text)}>{birth}</p>
                </div>
                <div>
                  <h1 className={clsx(classes.vitalSign__title)}>
                    {t("gender")}
                  </h1>
                  <p className={clsx(classes.vitalSign__text)}>
                    {t("genders." + gender)}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// function to map to health info array items and render VitalCard based on that item information
const renderCards = (healthInfo: IVitalCardProps[]) => {
  return healthInfo.map(
    (
      { icon, title, number, unit, conditionText, condition, pastData, dates },
      index
    ) => {
      return (
        <VitalCard
          dates={dates}
          number={number}
          unit={unit}
          conditionText={conditionText}
          icon={icon}
          title={title}
          pastData={pastData}
          condition={condition}
          key={index}
        />
      );
    }
  );
};

export default VitalSign;

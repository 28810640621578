import { ReactComponent as OndemandIconSVG } from "assets/icons/bulk/ondemand-icon.svg";
import classes from "./styles.module.scss";

function OndemandIcon(props: { [key: string]: any }) {
  return (
    <OndemandIconSVG data-variant="bulk" className={classes.icon} {...props} />
  );
}

export { OndemandIcon };

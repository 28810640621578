import { EventEmitter } from "events";
import { IAlertify } from "./types";
import { notificationAlertEmitterKey } from "constants/eventEmitter";

const emitter = new EventEmitter();

export default emitter;

export { notificationAlertEmitterKey };

export const notificationAlertify = (alertifyProps: IAlertify) => {
  emitter.emit(notificationAlertEmitterKey, alertifyProps);
  emitter.setMaxListeners(2);
};

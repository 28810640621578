import React, { useState, memo, useEffect, useCallback } from "react";
import Alert from "../alert";
import { alertModalNaxWidth, IAlertify } from "./types";
import emitter, { alertEmitterKey } from "./emitter";
import { useTranslation } from "react-i18next";

const getAlertDefaultProps = (approveDefaultText: string): IAlertify => ({
  //   open: false,
  //   setOpen: () => {},
  text: "",
  title: "",
  approveText: approveDefaultText,
  customButtons: undefined,
  dismissible: true,
  modalMaxWidth: alertModalNaxWidth,
  noCancel: false,
  onApprove() {},
  onCancel() {},
  onClose() {},
  submit: false,
});

const AlertContainer = memo(() => {
  const { t } = useTranslation("form");
  const alertDefaultProps = getAlertDefaultProps(t("approve"));

  const [open, setOpen] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertify>(alertDefaultProps);

  const resetAlertProps = useCallback(() => {
    setAlertProps(alertDefaultProps);
    setOpen(false);
  }, [alertDefaultProps]);

  useEffect(() => {
    emitter.on(alertEmitterKey, (alertProps: IAlertify) => {
      setAlertProps({
        ...alertProps,
        onApprove() {
          if (alertProps.onApprove) alertProps.onApprove();
          resetAlertProps();
        },
        onClose() {
          if (alertProps.onClose) alertProps.onClose();
          resetAlertProps();
        },
        onCancel() {
          if (alertProps.onCancel) alertProps.onCancel();
          resetAlertProps();
        },
      });
      setOpen(true);
    });

    return () => {
      emitter.off(alertEmitterKey, () => {
        console.log("EMITTER HAS REMOVED.");
      });
    };
  }, [resetAlertProps]);

  return <Alert {...alertProps} open={open} setOpen={setOpen} />;
});
export { AlertContainer };

import {
  createContext,
  ProviderProps,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";
import { ILoginSecondStepData, loginSecondStep, submitLogout } from "api/auth";
import { Redirect } from "react-router";
import { setAxiosFacility, setAxiosRole, setAxiosToken } from "api";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useProfile } from "hooks/useProfile";
import { UserRoleName } from "api/types/userTypes";
import clsx from "clsx";
import classes from "../common/loader/styles.module.scss";
import { VideoVisitLogo } from "../icon";
import { notify } from "components/core/toast";
import Cookies from "js-cookie";

// constants keys for holding the data in local storage
const STORAGE_TOKEN_KEY = "token";
const STORAGE_ROLE_KEY = "role";

/**     STRIPE and PAYPAL redirect URLs        **/
const STRIPE_SUCCESS = "stripe/success";
const STRIPE_CANCEL = "stripe/cancel";
const PAYPAL_SUCCESS = "paypal/success";
const PAYPAL_CANCEL = "paypal/cancel";

interface IRole {
  role: UserRoleName;
  roleName: string;
  facility: string;
  facilityId: number;
}

// an interface for user logged-in and it's token determination
interface IAuthData {
  isLoggedIn: boolean;
  token: string | null;
  role?: string;
  roles?: IRole[];
}

// interface for authenticate's context
interface IAuthContext extends IAuthData {
  logIn: (token: string, role: UserRoleName, fid?: number) => void;
  logOut: () => void;
  changeRole: (role: UserRoleName) => boolean;
}

// get users information from local storage incase that user had logged-in before
function getDefaultData(): IAuthData {
  // const browserToken = localStorage.getItem(STORAGE_TOKEN_KEY);
  // const browserRole = localStorage.getItem(STORAGE_ROLE_KEY);
  const browserToken = localStorage.getItem(STORAGE_TOKEN_KEY);
  const browserRole = localStorage.getItem(STORAGE_ROLE_KEY);
  const facilityId = localStorage.getItem("fid");

  if (browserToken && browserRole && facilityId) {
    setAxiosToken(browserToken);
    setAxiosRole(browserRole as UserRoleName);
    setAxiosFacility(Number(facilityId));

    return {
      isLoggedIn: true,
      token: browserToken,
      role: browserRole,
    };
  }

  return {
    isLoggedIn: false,
    token: null,
  };
}

// this function helps us to clear storage data incase that user logout from account
function clearLocalStorage() {
  // localStorage.removeItem(STORAGE_TOKEN_KEY);
  // localStorage.removeItem(STORAGE_ROLE_KEY);
  localStorage.removeItem(STORAGE_TOKEN_KEY);
  localStorage.removeItem(STORAGE_ROLE_KEY);
  localStorage.removeItem("fid");
}

// initializing authentication context
const AuthContext = createContext<IAuthContext>({
  ...getDefaultData(),
  logIn: (_) => {},
  logOut: () => {},
  changeRole: (_name) => false,
});

// authProvider gives us capability of use the context
export function AuthProvider({
  children,
}: Partial<ProviderProps<IAuthContext>>) {
  // holds user's authentication information
  const [value, setValue] = useState<IAuthData>({ ...getDefaultData() });
  // call api for /profile and get userInformation if user authenticated
  const profileQuery = useProfile(value.isLoggedIn);

  //TODO:i don't have any idea what is this for
  const roles: IRole[] | undefined =
    profileQuery.data?.data.data?.facilities.reduce<IRole[]>(
      (perv, current) => {
        const newResult = current.roles.map(({ slug }) => ({
          facilityId: current.id,
          roleName: slug,
          role: mapServerRoleName(slug),
          facility: current.name,
        }));

        return [...perv, ...newResult];
      },
      []
    );

  // this function gets token and role and save them in local storage
  // beside it sets that token to axios for further api calls
  // and in the end it sets user's authentication information inside value state
  const logIn = useCallback(
    (token: string, role: UserRoleName, fid?: number) => {
      // localStorage.setItem(STORAGE_TOKEN_KEY, token);
      // localStorage.setItem(STORAGE_ROLE_KEY, role);
      localStorage.setItem(STORAGE_TOKEN_KEY, token);
      localStorage.setItem(STORAGE_ROLE_KEY, role);
      Cookies.set("leoCookie", token);
      if (fid) localStorage.setItem("fid", String(fid));
      setAxiosRole(role);
      setAxiosToken(token);
      if (fid) setAxiosFacility(Number(fid));

      setValue({
        isLoggedIn: true,
        token: token,
        role: role,
      });
    },
    []
  );

  // this function clears the storage and removes the token after user get logged out
  // and sets user's authentication information to null inside value state
  const logOut = useCallback(() => {
    clearLocalStorage();
    setAxiosToken(null);

    setValue({
      isLoggedIn: false,
      token: null,
      role: undefined,
    });
    Cookies.remove("leoCookie");
  }, []);

  //TODO:i don't have any idea what is this for
  const changeRole = useCallback(
    (role: UserRoleName): boolean => {
      if (!profileQuery.isSuccess || roles!.length === 0) {
        logOut();
        throw new Error("Roles are not provided!");
      }

      if (roles && !!roles.find((item) => item.role === role)) {
        //  localStorage.setItem(STORAGE_ROLE_KEY, role);
        localStorage.setItem(STORAGE_ROLE_KEY, role);
        setValue((perv) => ({ ...perv, role: role }));
        return true;
      }

      return false;
    },
    [roles, profileQuery.isSuccess, logOut]
  );

  return (
    <AuthContext.Provider
      value={{ ...value, roles, logOut, logIn, changeRole }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// this interface used for useAuth hook
interface IUseAuth extends IAuthData {
  logIn: (data: ILoginSecondStepData) => Promise<void>;
  logOut: (callAPI?: boolean) => Promise<void>;
  changeRole: (role: UserRoleName, roleId: number) => void;
}

// this hook loads auth context and has methods for login and logout request
export function useAuth(): IUseAuth {
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient();

  if (!authContext) {
    throw new Error("useAuth is only usable under AuthProvider!");
  }
  // used for send login request
  const loginRequest = useMutation(loginSecondStep);
  // used for send logout request
  const logoutRequest = useMutation(submitLogout, {
    onSuccess: () => {
      notify.success("You are logged out successfully.");
    },
  });

  // trigger login api with given login data
  const logIn = async (data: ILoginSecondStepData) => {
    const response = await loginRequest.mutateAsync(data);
    const token = response.data.data?.token;
    const roles = response.data.data?.roles;
    const fid = response.data.data?.facilities[0]?.id;

    if (!token || !roles) {
      throw new Error("Token is not provided in the response.");
    }

    const role = mapServerRoleName(roles[0].slug);
    authContext.logIn(token, role, fid);
  };

  // trigger logout api
  const logOut = async (callAPI = true) => {
    if (callAPI) {
      await logoutRequest.mutateAsync(); // call api.
    }

    authContext.logOut();
    queryClient.clear(); // clear local catch.
  };

  //TODO:i don't have any idea what is this for
  const changeRole = (role: UserRoleName, roleId: number) => {
    localStorage.setItem("fid", String(roleId));
    setAxiosFacility(roleId);
    setAxiosRole(role);
    queryClient.clear();
    authContext.changeRole(role);
  };

  return { ...authContext, logIn, logOut, changeRole };
}

// this function maps server role names to UserRoleName enum
function mapServerRoleName(name: string): UserRoleName {
  switch (name) {
    case "Super Admin":
      return UserRoleName.SuperAdmin;

    case "Doctor":
      return UserRoleName.Doctor;

    case "Doctor Network Admin":
      return UserRoleName.DoctorNetworkAdmin;

    case "Staff":
      return UserRoleName.Staff;

    case "Local Admin":
      return UserRoleName.LocalAdmin;

    case "CMO":
      return UserRoleName.CMO;

    case "Patient":
      return UserRoleName.Patient;

    default:
      throw new Error("Can't recognize role name.");
  }
}

// this wrapper check if user authenticated or not
// and take actions based on authentication state
export function WithAuth({
  children,
}: {
  children: ReactElement | ReactElement[];
}) {
  const { isLoggedIn, role: selectedRole, roles } = useAuth();
  const profileQuery = useProfile(isLoggedIn);
  const { location } = useHistory();

  // console.log(selectedRole, roles);

  if (!isLoggedIn) {
    return <Redirect to={"/login"} />;
  }

  if (profileQuery.isLoading) {
    return (
      <div
        className={clsx(
          "d-flex justify-content-center py-5 align-items-center",
          classes.loader
        )}
        style={{ height: "100vh" }}
      >
        <VideoVisitLogo style={{ width: "200px", height: "200px" }} />
      </div>
    );
  }

  if (roles === undefined || roles.length === 0) {
    return <Redirect to={"/login"} />;
  }

  const pathName = location.pathname.split("/")[2];

  const haveRole =
    selectedRole && roles.find((item) => item.role === selectedRole);
  // console.log(location.search);

  // stripe and paypal redirect
  const role = !haveRole ? roles[0].role : selectedRole;

  /**
   * patient must fill past medical history
   */
  // console.log("hmh: ", profileQuery.data?.data.data?.has_medical_history);
  const patient_medical_history_path = `/panel/patient/past-medical-history/${profileQuery.data?.data.data?.id}/My Self`;
  if (
    role === "patient" &&
    profileQuery.data?.data.data?.has_medical_history === false &&
    location.pathname !== patient_medical_history_path
  ) {
    return <Redirect to={patient_medical_history_path} />;
  }

  switch (location.pathname) {
    case `/panel/${STRIPE_SUCCESS}`:
      return (
        <Redirect to={`/panel/${role}/${STRIPE_SUCCESS}${location.search}`} />
      );
    case `/panel/${STRIPE_CANCEL}`:
      return (
        <Redirect to={`/panel/${role}/${STRIPE_CANCEL}${location.search}`} />
      );
    case `/panel/${PAYPAL_SUCCESS}`:
      return (
        <Redirect to={`/panel/${role}/${PAYPAL_SUCCESS}${location.search}`} />
      );
    case `/panel/${PAYPAL_CANCEL}`:
      return (
        <Redirect to={`/panel/${role}/${PAYPAL_CANCEL}${location.search}`} />
      );
  }

  if (!haveRole) {
    return <Redirect to={`/panel/${roles[0].role}/`} />;
  }

  if (!selectedRole || selectedRole !== pathName) {
    return <Redirect to={`/panel/${selectedRole}/`} />;
  }

  return <>{children}</>;
}

/*export function WithoutAuth({
  children,
}: {
  children: ReactElement | ReactElement[];
}) {
  const { isLoggedIn, role } = useAuth();

  if (isLoggedIn) {
    return <Redirect to={`/${role}/`} />;
  }

  return <>{children}</>;
}*/

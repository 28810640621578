import { useDropdownsOptions } from "../useOptions";
import { useQuery } from "react-query";
import { getRobotLocations } from "api/dropdown";
import { robotLocationsKey } from "../../utils/keyGenerator/keys";

const useRobotLocationOptions = () => {
  const { data, ...rest } = useQuery(robotLocationsKey, getRobotLocations);

  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
};

export default useRobotLocationOptions;

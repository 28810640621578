import apiCaller, { IResponse } from "api/index";
import {
  IFacilitiesDepartmentsDropdownParams,
  IFacilityListParams,
  IFacilityData,
  IFacilityListResponse,
  IFacilityResponse,
  IFacilityStatisticsTypes,
  IFacilitiesByTypeDropdownParams,
} from "./type";
import { IPaginationTableList } from "api/types/paginationType";
import { IDropdownResponse } from "api/dropdown";

export function createFacility(data: IFacilityData): Promise<IResponse<null>> {
  return apiCaller.post("facilities", data);
}

export function updateFacility(
  data: IFacilityData,
  facility_id: number
): Promise<IResponse<null>> {
  return apiCaller.put(`facilities/${facility_id}`, data);
}

export function deleteFacility(facility_id: number): Promise<IResponse<null>> {
  return apiCaller.delete(`facilities/${facility_id}`);
}

export function getFacilityDetail(
  facility_id: number
): Promise<IResponse<IFacilityResponse>> {
  return apiCaller.get(`facilities/${facility_id}`);
}

export function getFacilityList(
  params: IFacilityListParams
): Promise<IResponse<IPaginationTableList<IFacilityListResponse>>> {
  return apiCaller.get(`facilities`, { params: params });
}

export function getFacilityStatistics(): Promise<
  IResponse<IFacilityStatisticsTypes>
> {
  return apiCaller.get(`facilities/statistics`);
}

export function getFacilitiesDepartmentsDropdown(
  params: IFacilitiesDepartmentsDropdownParams
): Promise<IResponse<IDropdownResponse[]>> {
  return apiCaller.get(`dropdown/facilities/departments`, { params: params });
}

export function getAllDepartmentsDropdown(): Promise<
  IResponse<IDropdownResponse[]>
> {
  return apiCaller.get(`dropdown/department-names`);
}

export function getFacilityTypesDropdown(): Promise<
  IResponse<IDropdownResponse[]>
> {
  return apiCaller.get(`dropdown/facilities/types`);
}

export function getFacilitiesByTypeDropdown(
  params: IFacilitiesByTypeDropdownParams
): Promise<IResponse<IDropdownResponse[]>> {
  return apiCaller.get(`dropdown/facilities/names`, { params: params });
}

export function getCityFacilitiesByTypeDropdown(
  city_id: number,
  type: string
): Promise<IResponse<IDropdownResponse[]>> {
  return apiCaller.get(`dropdown/cities/${city_id}/type/${type}/facilities`);
}

import {
  City,
  Country,
  Department,
  HFName,
  HFType,
  State,
} from "components/dropdown";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { IRegistrationStepProps } from "./registrationLayout";
import Grid from "components/core/Grid";
import { useTranslation } from "react-i18next";
import { formSubmission } from "utils/form";

const RegistrationThirdPage = ({
  formik,
  onSetAlert,
  backButton,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");

  return (
    <form autoComplete="off" onSubmit={(e) => formSubmission(e, formik)}>
      <Grid>
        <Country
          formik={formik}
          fieldName="hfCountry"
          dependencies={["hfState"]}
          id={formik.values.hfCountry.key}
          label={t("hf_country")}
        />
        <State
          formik={formik}
          fieldName="hfState"
          countryFieldName="hfCountry"
          dependencies={["hfCity"]}
          id={formik.values.hfState.key}
          label={t("hf_state")}
        />
        <City
          formik={formik}
          fieldName="hfCity"
          stateFieldName="hfState"
          dependencies={["hfName"]}
          id={formik.values.hfCity.key}
          label={t("hf_city")}
        />
        <HFType
          formik={formik}
          fieldName="hfType"
          dependencies={["hfName"]}
          id={formik.values.hfType.key}
        />
        <HFName
          formik={formik}
          fieldName="hfName"
          cityFieldName="hfCity"
          hfTypeFieldName="hfType"
          id={formik.values.hfName.key}
        />
        <Department
          formik={formik}
          fieldName="department"
          HFNameFieldName={"hfName"}
          label={t("department_optional")}
          id={formik.values.department.key}
        />

        <div className={classes.buttonContainer}>
          {backButton}
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => onSetAlert(true)}
          >
            {t("cancel")}
          </Button>
          <Button type="submit">{t("continue")}</Button>
        </div>
      </Grid>
    </form>
  );
};

export default RegistrationThirdPage;

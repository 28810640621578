import { ReactComponent as ScheduledIconSVG } from "assets/icons/bulk/scheduled-icon.svg";
import classes from "./styles.module.scss";

function ScheduledIcon(props: { [key: string]: any }) {
  return (
    <ScheduledIconSVG data-variant="bulk" className={classes.icon} {...props} />
  );
}

export { ScheduledIcon };

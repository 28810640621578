import { getDropdownValue } from "utils/dropdowns";
import IDropdown from "../formikFields/IDropdown";
import { FastDropdown } from "../formikFields";
import { AwardBold } from "components/icon";
import { useQuery } from "react-query";
import { getSubSpecialities } from "api/dropdown";
import { useTranslation } from "react-i18next";
import { subSpecialitiesKey } from "../../utils/keyGenerator/keys";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the speciality that field is dependent on it.
   *
   * without knowing the speciality we can not fetch the sub-speciality of that speciality, so the sub-speciality depends on the speciality.
   */
  specialityFieldName: keyof T & string;
}

export function SubSpeciality<T>({
  formik,
  specialityFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the speciality value from formik values and extract its key
  const { key: specialityId } = getDropdownValue(formik, specialityFieldName);

  // get the sub speciality from server
  const { data, isLoading, isError } = useQuery(
    [subSpecialitiesKey, specialityId],
    () => getSubSpecialities(specialityId + ""),
    {
      enabled: !!specialityId,
    }
  );

  return (
    <FastDropdown
      formik={formik}
      label={t("sub_speciality")}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!specialityId || disabled}
      icon={AwardBold}
      {...props}
    />
  );
}

import { ReactNode, useState } from "react";
import clsx from "clsx";
import classes from "./styles.module.scss";
import Stepbar from "../stepbar";
import { useTranslation } from "react-i18next";

export interface IPaginatorProps {
  components: ReactNode[];
  headers: string[];
}

const Paginator = ({ components, headers }: IPaginatorProps) => {
  const { t } = useTranslation("global", { keyPrefix: "questions_titles" });

  const [index, setIndex] = useState(0);
  const pages: string[] = [
    t("page1"),
    t("page2"),
    t("page3"),
    t("page4"),
    t("page5"),
    t("page6"),
    t("page7"),
    t("page8"),
    t("page9"),
  ];

  return (
    <div className={clsx(classes.paginator)}>
      <div className={clsx(classes.paginator__row, classes.paginator__gap)}>
        <Stepbar
          items={pages}
          activePage={index}
          onClick={(index) => setIndex(index)}
        />
      </div>
      <div className={clsx(classes.paginator__row, classes.paginator__text)}>
        {headers.map((item, i) => {
          return (
            <div
              key={i}
              className={clsx(
                index === i && classes.visible,
                index !== i && classes.invisible
              )}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className={clsx(classes.paginator__row)}>
        {components.map((item, i) => {
          return (
            <div
              key={i}
              className={clsx(
                index === i && classes.visible,
                index !== i && classes.invisible
              )}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Paginator;

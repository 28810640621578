import React, { PropsWithChildren, useState } from "react";
import { generateUUIDv4 } from "utils/uuid";
import BackGroup from "./components/backGroup";
import MainGroup from "./components/mainGroup";
import classes from "./styles.module.scss";
import EditGroup from "./components/editGroup";
import { RefreshLinear } from "../../icon";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router";
import usePage, { pageType } from "hooks/usePage";
import { useTranslation } from "react-i18next";
import { fileKey, profileKey } from "../../../utils/keyGenerator/keys";

interface IPageDefaultProps extends PropsWithChildren<any> {
  /**
   * in some page we have to show and underlying the user's name (e.g. reset password), so in that case you write $name instead of the username and pass the username in 'name' property. for example: title:"Reset $name Password" and pass the value 'Ali' in name property, the result will be: "Reset <u>Ali's</u> Password"
   */
  title: string;
  /**
   * title of page in view profile mode.
   * *NOTE: view profile mode is handling inside of this component.
   */
  viewProfileTitle?: string;
  /**
   * title of page in editing mode.
   * *NOTE: editing mode is handling inside of this component.
   */
  editingTitle?: string;
  icon?: any;
  iconProps?: { [key: string | number | symbol]: any };
  name?: string;
  noButtons?: boolean;
  onLoad?: () => void;
  children?:
    | React.ReactNode
    | React.ReactNode[]
    | ((pageType: pageType) => React.ReactNode)
    | ((pageType: pageType) => React.ReactNode[]);
}

interface IPageMainProps {
  type: "main";
  propClassName?: string;
}

interface IPageInnerProps {
  /**
   * type `inner` has back button in header navbar.
   * type `edit inner` has both back button and edit button in header navbar.
   */
  type: "inner" | "edit-inner";
  backTo?: "pop" | string;

  /**
   * edit path to replace in history when clicking on edit button in header navbar.
   */
  editPath?: string;
  editPathState?: any;
}

type TPageProps = IPageDefaultProps & (IPageMainProps | IPageInnerProps);

/**
 * A function to replace a text with a JSXElement
 * @param separator A string that identifies character or characters to use in separating the string.
 * @param replaceJSXElement A JSXElement to replace
 * @param text A string to perform a replacement on
 * @returns
 */
const replaceJSX = (
  separator: string,
  ReplaceJSXElement: JSX.Element,
  text: string
) => {
  return text
    .split(separator)
    .flatMap((item) => [item, ReplaceJSXElement])
    .slice(0, -1);
};

const getPageType = (
  isEditing: boolean,
  isViewingProfile: boolean
): pageType => {
  if (isViewingProfile) {
    if (isEditing) return "edit";
    else return "viewProfile";
  }
  return "register";
};

function Page({
  title,
  icon: Icon,
  iconProps,
  type,
  backTo,
  children,
  editPath,
  editPathState,
  name,
  noButtons,
  editingTitle,
  viewProfileTitle,
  onLoad,
  propClassName,
}: TPageProps) {
  const { isViewingProfile, isEditing: urlIsEditing } = usePage();
  // this is for memorizing if the user has clicked the edit button in header.
  const [isEditing, setIsEditing] = useState(false);

  const pageType = getPageType(isEditing, isViewingProfile);

  // get the title for show in the header
  function getTitle() {
    let _title = title;
    if (isViewingProfile && !isEditing && !!viewProfileTitle)
      _title = viewProfileTitle;
    else if (
      (isViewingProfile && isEditing && editingTitle) ||
      (!isViewingProfile && urlIsEditing && editingTitle)
    )
      _title = editingTitle;
    while (name?.includes("undefined")) {
      name = name?.replace("undefined", "");
    }

    if (name && name !== " ") {
      return replaceJSX(
        "$name",
        <u key={generateUUIDv4()} className={classes.username}>{`${
          name || ""
        } `}</u>,
        _title
      );
    }
    return _title.replace("$name", "");
  }

  const queryClient = useQueryClient();

  const invalidateQueriesExceptOne = (keyToKeep: string[]) => {
    const queryCache = queryClient.getQueryCache();

    queryCache.findAll().forEach((query) => {
      let exist = false;
      if (typeof query.queryKey === "string") {
        exist = keyToKeep?.includes(query.queryKey);
      } else {
        for (const key of query.queryKey) {
          if (keyToKeep?.includes(key as string)) {
            exist = true;
            break;
          }
        }
      }

      if (!exist) {
        if (typeof query.queryKey !== "string") {
          queryClient.invalidateQueries([query.queryKey[0]]);
        } else {
          queryClient.invalidateQueries([query.queryKey]);
        }
      }
    });
  };

  const path = useLocation().pathname;
  const noRefresh = path.includes("password") || path.includes("profile");
  const { t } = useTranslation("global");

  return (
    <div id={"printPage"} onLoad={onLoad}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <p className={classes.title}>
            <span>{getTitle()} </span>
          </p>
        </div>
        {!noButtons && (
          <div className={classes.iconsGroupContainer}>
            {!noRefresh && (
              <button
                className={classes.refreshIconContainer}
                onClick={() =>
                  invalidateQueriesExceptOne([profileKey, fileKey])
                }
              >
                <RefreshLinear className={classes.mainIcon} />
                {t("refresh")}
              </button>
            )}
            {type === "main" ? (
              <MainGroup />
            ) : (
              <>
                {type === "edit-inner" && !isEditing && (
                  <EditGroup
                    setIsEditing={setIsEditing}
                    editPath={editPath}
                    editPathState={editPathState}
                  />
                )}
                {backTo && <BackGroup backTo={backTo} />}
              </>
            )}
          </div>
        )}
      </div>

      <div className={classes.pageContentContainer}>
        {typeof children === "function" ? children(pageType) : children}
      </div>
    </div>
  );
}

export default Page;

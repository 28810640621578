import { useHistory } from "react-router";
import {
  getCurrentPageParamName,
  getResultsPerPageParamName,
} from "utils/filterParams";
import { useEffect, useState } from "react";

export interface IPagination {
  currentPage: number;
  maxPage: number;
  resultsPerPage: number;
}

const DEFAULT_PAGE_SIZE = 10;

/**
 * hook that manage pagination
 * @param index index of pagination. If u have more than one pagination in your page, u have to give an index to handle url parameters separately.
 * @returns
 */
function usePagination(index?: number | string) {
  // console.log("render from usePagination");

  const history = useHistory();
  const { search, state } = history.location;
  const params = new URLSearchParams(search);

  const currentPageParamName = getCurrentPageParamName(index);
  const resultPerPagePageParamName = getResultsPerPageParamName(index);

  const currentPageParamValue = params.get(currentPageParamName);
  const resultPerPagePageParamValue = params.get(resultPerPagePageParamName);

  const [pagination, setPagination] = useState({
    currentPage:
      currentPageParamValue === null ? 1 : Number(currentPageParamValue),
    maxPage: 1,
    resultsPerPage:
      resultPerPagePageParamValue === null
        ? DEFAULT_PAGE_SIZE
        : Number(resultPerPagePageParamValue),
  });

  const setParam = (
    name: string,
    value: string | number,
    newParams?: URLSearchParams
  ) => {
    params.set(name, encodeURIComponent(value));
    newParams?.set(name, encodeURIComponent(value));
    history.replace({
      search: newParams ? newParams.toString() : params.toString(),
      state: state,
    });
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => {
        setParam(currentPageParamName, prev.currentPage - 1);
        return {
          ...prev,
          currentPage: prev.currentPage - 1,
        };
      });
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.maxPage) {
      setPagination((prev) => {
        setParam(currentPageParamName, prev.currentPage + 1);
        return {
          ...prev,
          currentPage: prev.currentPage + 1,
        };
      });
    }
  };

  const handleGotoPage = (page: number) => {
    if (page > 0 && page <= pagination.maxPage) {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }
  };

  const handleResultsPerPageChange = (
    resultsPerPage: number,
    totalResultsCount?: number
  ) => {
    setPagination({
      currentPage: 1,
      maxPage: totalResultsCount
        ? Math.floor(totalResultsCount / resultsPerPage) + 1
        : 1,
      resultsPerPage,
    });
    setParam(resultPerPagePageParamName, resultsPerPage);
    setParam(currentPageParamName, 1);
  };

  const updateMaxPage = (maxPage: number) => {
    setPagination((prev) => ({
      ...prev,
      maxPage,
    }));
  };

  const resetPage = (newParams: URLSearchParams) => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setParam(currentPageParamName, 1, newParams);
  };

  const resetPageAndSize = () => {
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      resultsPerPage: DEFAULT_PAGE_SIZE,
    }));
  };

  useEffect(() => {
    if (resultPerPagePageParamValue === null) {
      setPagination((perv) => ({ ...perv, resultsPerPage: DEFAULT_PAGE_SIZE }));
    }
    if (currentPageParamValue === null) {
      setPagination((perv) => ({ ...perv, currentPage: 1 }));
    }
  }, [resultPerPagePageParamValue, currentPageParamValue]);

  return {
    pagination,
    handlePreviousPage,
    handleNextPage,
    handleGotoPage,
    handleResultsPerPageChange,
    updateMaxPage,
    resetPage,
    resetPageAndSize,
  };
}

export default usePagination;

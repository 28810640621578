import clsx from "clsx";
import classes from "../styles.module.scss";
import { useHistory } from "react-router";
import { IShowPatientOrder } from "../../../../api/healthRecord";
import { IShowAppointment } from "../../../../api/appointment";
import React, { useEffect, useState } from "react";
import useDownloadPdf from "../../../../hooks/useDownloadPdf";
import { notify } from "../../../../components/core/toast";
import Page from "../../../../components/layout/page";
import DataCard from "../../../../components/common/dataCard";
import PrintNotif from "../../../../components/common/printNotif";
import LabelCard from "../../../../components/common/labelCard";
import InformationCard from "components/common/informationCard";
import { ListInfo } from "../../../../components/icon/listInfo";
import { humanizedDate, humanizedTime } from "../../../../utils/date";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getLinkData } from "../../../../api/share";
import Loader from "../../../../components/common/loader";
import { useTranslation } from "react-i18next";
import ImageCard from "../../../../components/common/videoVisit/components/images/ImageCard";

const ShareShowOrder = () => {
  const { t } = useTranslation("global");
  const history = useHistory();

  const id = useParams<{ id: string }>()?.id;
  if (!id) {
    history.push("/404");
  }
  const { data, isLoading, isError } = useQuery([getLinkData.name, id], () =>
    getLinkData(id)
  );
  const search = new URLSearchParams(data?.data?.data?.data);
  const encodedData = search.get("data");
  let d: undefined | IShowPatientOrder = undefined;
  try {
    d = JSON.parse(encodedData || "");
  } catch (e) {}
  const encodedAppt = search.get("appt");

  let appt: IShowAppointment | undefined = undefined;

  try {
    appt = JSON.parse(encodedAppt || "");
  } catch (e) {}

  const [printOpen, setPrintOpen] = useState(false);

  const printWebpage = useDownloadPdf();

  useEffect(() => {
    if (d && !isLoading) {
      notify.info(t("pdf_wait"));
      printWebpage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, isLoading, isError]);

  return (
    <Page
      title={t("health_record.order.title")}
      type={"inner"}
      backTo={"pop"}
      noButtons={true}
    >
      <Loader isLoading={isLoading} isError={isError}>
        <div className={clsx(classes.modal)}>
          <DataCard
            variant={"patientOrder"}
            avatar={appt?.local_doctor?.avatar}
            tableData={{
              appt_id: appt?.appt_id || "- - -",
              appt_type: appt?.appt_type || "- - -",
              visit_date: humanizedDate(appt?.start_date || "") || "- - -",
              visit_time: humanizedTime(appt?.start_date || "") || "- - -",
              timezone: appt?.local_doctor?.timezone?.name || "- - -",
              patient_name:
                appt?.patient?.first_name + " " + appt?.patient?.last_name ||
                "- - -",
              birth: humanizedDate(appt?.patient?.birthday || "") || "- - -",
              hf_name: appt?.facility?.name || "- - -",
            }}
            id={appt?.local_doctor?.user_id || ""}
            name={
              appt?.local_doctor?.first_name +
              " " +
              appt?.local_doctor?.last_name
            }
            specialty={appt?.local_doctor?.speciality || ""}
          />
          <InformationCard
            icon={<ListInfo />}
            title={t("health_record.order.title")}
            parentClassName={clsx(classes.modal__back)}
          >
            <div className={clsx(classes.healthRecord__column)}>
              <div className={clsx(classes.healthRecord__row)}>
                <LabelCard
                  type={"styled"}
                  title={t("pvs.labs") + ": "}
                  answer={
                    d?.labs?.toString().replace("[", "").replace("]", "") ||
                    "- - -"
                  }
                />
                <LabelCard
                  type={"styled"}
                  title={t("pvs.imaging") + ": "}
                  answer={
                    d?.imagings?.toString().replace("[", "").replace("]", "") ||
                    "- - -"
                  }
                />
              </div>
              <div className={clsx(classes.healthRecord__row)}>
                <LabelCard
                  type={"styled"}
                  title={t("pvs.test") + ": "}
                  answer={
                    d?.tests?.toString().replace("[", "").replace("]", "") ||
                    "- - -"
                  }
                />
                <LabelCard
                  type={"styled"}
                  title={t("pvs.plan") + ": "}
                  answer={
                    d?.plans?.toString().replace("[", "").replace("]", "") || ""
                  }
                />
              </div>
            </div>
          </InformationCard>
          <div className={classes.modal__sig}>
            <ImageCard
              imgUrl={appt?.local_doctor?.signature_base || ""}
              noZoom
            />
          </div>
        </div>
      </Loader>
      <PrintNotif open={printOpen} setOpen={setPrintOpen} name={"Orders"} />
    </Page>
  );
};

export default ShareShowOrder;

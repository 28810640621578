import { IResponse } from "api";
import { IPaginationTableList } from "api/types/paginationType";
import { TRoleName } from "api/user";
import { TabObject } from "components/core/tabs";
import {
  BalanceLinear,
  BloodPresureLinear,
  GlucoseMeterLinear,
  MedicalCameraLinear,
  OximeterLinear,
  StethoscopeLinear,
  ThermometerLinear,
  WebcamLinear,
} from "components/icon";
import { IPagination } from "hooks/usePagination";
import { TDevices } from "interfaces/devices";
import { useTranslation } from "react-i18next";

// function that return device icons
export function getDevicesIcon(type: TDevices) {
  switch (type) {
    case "SP":
      return (
        <div data-attr="fill">
          <BloodPresureLinear />
        </div>
      );
    case "BP":
      return (
        <div data-attr="fill">
          <BloodPresureLinear />
        </div>
      );
    case "PO":
      return (
        <div data-attr="fill">
          <OximeterLinear />{" "}
        </div>
      );
    case "WS":
      return (
        <div data-attr="stroke">
          <BalanceLinear />{" "}
        </div>
      );
    case "TM":
      return (
        <div data-attr="fill">
          <ThermometerLinear />
        </div>
      );
    case "EC":
      return (
        <div data-attr="stroke">
          <WebcamLinear />
        </div>
      );
    case "SC":
      return (
        <div data-attr="stroke">
          <StethoscopeLinear />
        </div>
      );
    case "GM":
      return (
        <div data-attr="fill">
          <GlucoseMeterLinear />
        </div>
      );
    case "PC":
      return (
        <div data-attr="fill">
          <MedicalCameraLinear />
        </div>
      );
    case "CT":
      return (
        <div data-attr="fill">
          <BloodPresureLinear />
        </div>
      );
    default:
      return;
  }
}

// function that return url based on roles
export function getURLByRole(
  role: TRoleName | undefined,
  id: number,
  mode: "edit" | "view"
) {
  const viewProfile = mode === "view" ? "view-profile/" : "";

  switch (role) {
    case "patient":
      return `patient-management/${viewProfile}${id}`;
    case "doctor":
      return `doctor-management/${viewProfile}${id}`;
    case "doctor-network-admin":
      return `dna/${id}`;
    case "local-admin":
      return `local-management/${viewProfile}${id}`;
    case "staff":
      return `staff-management/${viewProfile}${id}`;
    case "cmo":
      return `cmo-management/${viewProfile}${id}`;
    default:
      return "";
  }
}

// function that get full role name by its label
export function getFullRoleLabel(label: string) {
  switch (label) {
    case "super":
      return "Super Admin";
    case "super-admin":
      return "Super Admin";
    case "doctor":
      return "Doctor";
    case "dna":
      return "Doctor Network Admin";
    case "local-admin":
      return "Local Admin";
    case "staff":
      return "Staff";
    case "cmo":
      return "CMO";
    case "patient":
      return "Patient";
    default:
      return "";
  }
}

export function useAppointmentTabs(scheduled: boolean): TabObject[] {
  const { t } = useTranslation("global");

  if (scheduled) {
    return [
      {
        id: "all",
        title: t("time.all"),
        icon: null,
      },
      {
        id: "past",
        title: t("time.past"),
        icon: null,
      },
      {
        id: "today",
        title: t("time.today"),
        icon: null,
      },
      {
        id: "future",
        title: t("time.future"),
        icon: null,
      },
    ];
  }
  return [
    {
      id: "all",
      title: t("time.all"),
      icon: null,
    },
    {
      id: "past",
      title: t("time.past"),
      icon: null,
    },
    {
      id: "today",
      title: t("time.today"),
      icon: null,
    },
  ];
}

export function getTablesData<T>(
  data: IResponse<IPaginationTableList<T>> | undefined,
  pagination: IPagination
) {
  const responseData = data?.data.data;
  const fixedData = {
    data: responseData ? responseData.list : [],
    ...pagination,
  };
  return { fixedData: fixedData, total: responseData?.total };
}

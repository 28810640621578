import ParticipantsCard from "./participantsCard";
import SideBartTop from "../sideBartTop";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { VideoVisitContext } from "../..";
import { useContext, useState } from "react";
import Button from "../../../../core/button";
import { AddUserLinear } from "../../../../icon";
import { useMutation } from "react-query";
import { inviteGuest } from "../../../../../api/videoVisit";
import { IDoctorAppointment } from "../../../../../api/appointment";
import { TRoleName } from "../../../../../api/user";
import ShareModal from "../../../shareModal";
import { useTranslation } from "react-i18next";

export interface IParticipant {
  avatar: string;
  name: string;
  hasMic: boolean;
  hasCamera: boolean;
  role?: string;
}

export interface IParticipantsProps {
  onClose: () => void;
  appointment?: IDoctorAppointment;
  role?: TRoleName | "guest";
}

// Participants Component for Video Visit Component
const Participants = ({ onClose, appointment, role }: IParticipantsProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  const { mutate, isLoading } = useMutation(inviteGuest);
  const [url, setUrl] = useState("");
  const [shareOpen, setShareOpen] = useState(false);

  const { streamArr, session } = useContext(VideoVisitContext);
  // render component
  return (
    <div className={clsx(classes.participants)}>
      <SideBartTop
        onClick={() => onClose()}
        title={"Participants"}
        text={t("user_in", { count: streamArr?.length })}
      />
      <div className={clsx(classes.participantContainer)}>
        {streamArr.map((participant, index) => {
          return (
            <ParticipantsCard
              avatar={role === "guest" ? undefined : participant?.avatar}
              name={participant.name}
              hasMic={
                session!.capabilities.forceDisconnect === 1 && index !== 0
              }
              hasCamera={
                session!.capabilities.forceDisconnect === 1 && index !== 0
              }
              key={index}
              role={participant.role}
              stream={participant.stream}
            />
          );
        })}
      </div>
      <div className={clsx(classes.btn, role === "guest" && classes.none)}>
        <Button
          icon={AddUserLinear}
          className={clsx(classes.btnIn)}
          isLoading={isLoading}
          onClick={() => {
            mutate(appointment?.id || "", {
              onSuccess: (data) => {
                setUrl("/guest-join/" + data?.data?.data?.token);
                setShareOpen(true);
              },
            });
          }}
        >
          {t("invite_guest")}
        </Button>
      </div>
      <ShareModal
        noScroll
        title={t("invite_guest")}
        url={url}
        open={shareOpen}
        setOpen={setShareOpen}
        isLoading={false}
        setIsLoading={() => {}}
        setTitle={() => {}}
        setUrl={setUrl}
        apptId={appointment?.id || ""}
        isJoinGuest
      />
    </div>
  );
};

export default Participants;

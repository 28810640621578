import { useQuery } from "react-query";
import {
  createDevice,
  createGroupDevices,
  deleteDevice,
  getDevice,
  getDevicesList,
  getDevicesStatistics,
  getDeviceTypeDevicesDropdown,
  getDeviceTypesDropdown,
  updateDevice,
} from "./api";
import { IDeviceData, IDevicesListParams } from "./type";
import useFormMutation from "hooks/useFormMutation";
import { useDropdownsOptions } from "hooks/useOptions";
import { IPagination } from "hooks/usePagination";
import useTableQuery from "hooks/useTableQuery";
import { useDependentMutation } from "hooks/useDependentMutation";
import {
  devicesKey,
  deviceStatisticsKey,
  deviceTypeDevicesKey,
  deviceTypeDropdownKey,
  getDeviceKey,
} from "./keys";

export const useCreateDevice = () => {
  const mutationResult = useFormMutation(createDevice, [
    [deviceTypeDevicesKey],
  ]);
  return mutationResult;
};

export const useCreateGroupDevices = () => {
  const mutationResult = useFormMutation(createGroupDevices, [
    [[deviceTypeDevicesKey]],
  ]);
  return mutationResult;
};

export const useUpdateDevice = (id: number) => {
  const mutationResult = useFormMutation(
    (data: IDeviceData) => updateDevice(data, id),
    [[getDeviceKey], [deviceTypeDevicesKey]]
  );
  return mutationResult;
};

export const useDevice = (id: string | undefined) => {
  const queryResult = useQuery(
    [getDeviceKey, id],
    () => getDevice(Number(id)),
    {
      enabled: !!id,
    }
  );
  return queryResult;
};

export const useDevicesStatistics = () => {
  const queryResult = useQuery(deviceStatisticsKey, getDevicesStatistics);
  return queryResult;
};

export function useDevicesList(
  params: IDevicesListParams,
  pagination: IPagination,
  maxPageUpdater: (maxPage: number) => void
) {
  const queryResult = useTableQuery(
    getDevicesList,
    devicesKey,
    params,
    pagination,
    maxPageUpdater
  );
  return queryResult;
}

export const useDeleteDevice = () => {
  const mutationResult = useDependentMutation(
    (data: number) => deleteDevice(Number(data)),
    [[deviceTypeDevicesKey]],
    [[deviceStatisticsKey], [devicesKey]]
  );
  return mutationResult;
};

export const useDeviceTypesDropdown = () => {
  const queryResult = useQuery([deviceTypeDropdownKey], getDeviceTypesDropdown);
  return queryResult;
};

export const useDeviceTypesOptions = () => {
  const { data, ...rest } = useDeviceTypesDropdown();
  const options = useDropdownsOptions({ data: data });
  return { OPTIONS: options, ...rest };
};

export const useDeviceTypeDevicesDropdown = (
  deviceTypeId: number | undefined
) => {
  const queryResult = useQuery(
    [deviceTypeDevicesKey, deviceTypeId],
    () => getDeviceTypeDevicesDropdown(Number(deviceTypeId)),
    {
      enabled: !!deviceTypeId,
    }
  );
  return queryResult;
};

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./i18n";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

window.addEventListener("message", function (event) {
  if (event.data.request !== undefined) {
    switch (event.data.request) {
      case "set_token": {
        let token = event.data.token;
        let role = event.data.role;

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("role", role);

        window.location.href = "/panel/patient/dashboard";

        break;
      }

      case "get_path": {
        let path = window.location.pathname;
        let status = "false";

        if (event.data.fn_name === "show_switch_button") {
          let div = document.querySelectorAll("div#root > div")[0];

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < div.classList.length; i++) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const class_name = div.classList[i];

            if (class_name.includes("loader")) {
              status = "true";
              return;
            }
          }
        }

        let message = {
          path: path,
          status: status,
          request: event.data.request,
          fn_name: event.data.fn_name,
        };

        // @ts-ignore
        event.source.postMessage(JSON.stringify(message), event.origin);

        break;
      }

      case "disable_logout": {
        const spans = document.querySelectorAll("span.MS-2");

        spans.forEach((span) => {
          if (span.textContent !== null) {
            if (
              span.textContent.includes("Logout") ||
              span.textContent.includes("Switch Account")
            ) {
              const parent = span.parentNode;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              parent.parentNode.removeChild(parent);
            }
          }
        });

        break;
      }

      case "delete_token": {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("role");

        window.location.href = "/login";

        break;
      }
    }
  }
});

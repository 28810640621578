import { useEffect, useMemo, useRef, useState } from "react";
import usePagination from "./usePagination";
import { useHistory } from "react-router";
import {
  getFilterParamName,
  getSearchParamName,
  getTabParamNAme,
} from "utils/filterParams";
import { IFilterValue, jsonToHumanUrl } from "components/core/filter";
import { TabObject } from "components/core/tabs";

const SEARCH_TIME = 800; //ms
const FILTER_TIME = 1000; //ms
const useTableParams = (index?: string | number) => {
  const pagination = usePagination(index);

  const [searchValue, debouncedSearch, setSearch] = useSearchParam(
    index,
    pagination.resetPage
  );
  const [filtersValue, debouncedFilters, setFilters] = useFilterParam(
    index,
    pagination.resetPage
  );

  return {
    searchParam: { searchValue, debouncedSearch, setSearch },
    filterParams: {
      filtersValue,
      debouncedFilters,
      setFilters,
    },
    paginationParams: pagination,
  };
};

export default useTableParams;

export const useTabParam = (
  tabs: TabObject[],
  index?: string | number
): [TabObject["id"], (tabId: TabObject["id"]) => void] => {
  const history = useHistory();
  const { search, state } = history.location;
  const tabParamName = getTabParamNAme(index);
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const tabParamValue = params.get(tabParamName);
  const initialValue = tabParamValue !== null ? tabParamValue : tabs[0].id;

  const [selectedTab, setSelectedTab] = useState<TabObject["id"]>(initialValue);

  // declare set and delete param to/from the URL
  const setParam = (value: string) => {
    params.set(tabParamName, encodeURIComponent(value));
    history.replace({ search: params.toString(), state: state });
  };

  const onTabChangeHandler = (tabId: TabObject["id"]) => {
    setSelectedTab(tabId);
    setParam(tabId);
  };
  return [selectedTab, onTabChangeHandler];
};

/**
 * user typed something.
 * we set the timer.
 * if the value of the search input has changed before finishing the timeout:
 *   YES => refresh the timeout
 *   NO => we set the new timer
 * in the callback of the timeout we have to clear the timer and set the debounced value.
 *   and if the onChange is not undefined call it.
 * finally return value and onChange to handle the search input value.
 *    and debouncedValue to use in query.
 */
/**
 *
 * @param index
 * @param onChange a function to call when the debounced value has changed.
 */
const useSearchParam = (
  index?: string | number,
  onChange?: (value: URLSearchParams) => void
): [string, string, (e: React.ChangeEvent<HTMLInputElement>) => void] => {
  const history = useHistory();
  const { search, state } = history.location;
  const searchParamName = getSearchParamName(index);
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const searchParamValue = params.get(searchParamName);

  // get the search param value from the URL
  const initialValue =
    searchParamValue !== null ? decodeURIComponent(searchParamValue) : "";

  // state for store the input value and debounced value
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  // use ref to store the timer
  const timer = useRef<NodeJS.Timeout | null>(null);

  // declare set and delete param to/from the URL
  const setParam = (value: string) => {
    params.set(searchParamName, encodeURIComponent(value));
    history.replace({ search: params.toString(), state: state });
  };
  const deleteParam = () => {
    params.delete(searchParamName);
    history.replace({ search: params.toString(), state: state });
  };

  // timeout callback function
  const setKeyword = (value: string) => {
    // set the url param
    if (value === "") deleteParam();
    else setParam(value);

    //set the debounced value
    setDebouncedValue(value);
    if (onChange) onChange(params);

    // clear the timer
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = null;
  };

  //input change handler
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    const time = e.target.value.trim() === "" ? 0 : SEARCH_TIME;

    if (timer.current === null) {
      // set the timeout
      timer.current = setTimeout(() => setKeyword(e.target.value.trim()), time);
    } else {
      // refresh the timer
      clearTimeout(timer.current);
      timer.current = setTimeout(() => setKeyword(e.target.value.trim()), time);
    }
  };

  useEffect(() => {
    if (searchParamValue === null) {
      setDebouncedValue("");
      setValue("");
    }
  }, [searchParamValue]);

  return [value, debouncedValue, changeHandler];
};

/**
 * user selected some filters.
 * we set the timer.
 * if the value of the filters has changed before finishing the timeout:
 *   YES => refresh the timeout
 *   NO => we set the new timer
 * in the callback of the timeout we have to clear the timer and set the debounced value.
 *   and if the onChange is not undefined call it.
 * finally return value and onChange to handle the filters value.
 *    and debouncedValue to use in query.
 */
/**
 *
 * @param index
 * @param onChange a function to call when the debounced value has changed.
 */
const useFilterParam = (
  index?: string | number,
  onChange?: (value: URLSearchParams) => void
): [IFilterValue[], IFilterValue[], (e: IFilterValue[]) => void] => {
  const history = useHistory();
  const { search, state } = history.location;
  const filterParamName = getFilterParamName(index);
  const params = new URLSearchParams(search);
  const filterParam = params.get(filterParamName);

  // get the search param value from the URL
  const initialValue =
    filterParam !== null ? JSON.parse(decodeURIComponent(filterParam)) : [];

  // state for store the input value and debounced value
  const [value, setValue] = useState<IFilterValue[]>(initialValue);
  const [debouncedValue, setDebouncedValue] =
    useState<IFilterValue[]>(initialValue);

  // use ref to store the timer
  const timer = useRef<NodeJS.Timeout | null>(null);

  // declare set and delete param to/from the URL
  const setParam = (value: IFilterValue[]) => {
    params.set(
      filterParamName,
      encodeURIComponent(jsonToHumanUrl(JSON.stringify(value)))
    );
    history.replace({ search: params.toString(), state: state });
  };

  // timeout callback function
  const setKeyword = (value: IFilterValue[]) => {
    console.log(value);

    // set the url param
    setParam(value);

    //set the debounced value
    setDebouncedValue(JSON.parse(JSON.stringify(value)));
    if (onChange) onChange(params);

    // clear the timer
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = null;
  };

  //input change handler
  const changeHandler = (e: IFilterValue[]) => {
    setValue(e);

    if (timer.current === null) {
      // set the timeout
      timer.current = setTimeout(() => setKeyword(e), FILTER_TIME);
    } else {
      // refresh the timer
      clearTimeout(timer.current);
      timer.current = setTimeout(() => setKeyword(e), FILTER_TIME);
    }
  };

  useEffect(() => {
    if (filterParam === null) {
      setDebouncedValue([]);
      setValue([]);
    }
  }, [filterParam]);

  return [value, debouncedValue, changeHandler];
};

import { IResponse } from "api";
import { changeActivationOfUser, IChangeUserActivity } from "api/user";
import DropdownItem from "components/core/dropdownItem";
import { USER_STATUS_TYPES } from "constants/enumTypes";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useRole } from "../../../utils/route";
import { useTranslation } from "react-i18next";
import { notificationAlertify } from "components/core/notificationAlert";
import { notify } from "components/core/toast";

//? toggle between active and inactive status

export interface IActiveInactiveCellProps {
  userId: number;
  cellProps: any;
  refetch?: () => void;
  noPending?: boolean;
  data: any;
}

export function ActiveInactiveCell({
  userId,
  cellProps,
  refetch,
  noPending,
  data,
}: IActiveInactiveCellProps) {
  const { t } = useTranslation("global");

  const { isLoading, mutateAsync } = useMutation(changeActivationOfUser);
  const [status, setStatus] = useState<string>(cellProps.status.toLowerCase());
  const history = useHistory();
  const role = useRole();
  const changeActivationHandler = (
    newStatus: USER_STATUS_TYPES,
    pendingMessage: string
  ) => {
    console.log("change activating on user with id: " + userId);
    const data: IChangeUserActivity = {
      role_name: role,
      id: Number(userId),
      status: newStatus,
    };
    const success = (data: IResponse<null>) => {
      toast.success(data.data.message);
      setStatus(newStatus);
      /* console.log(data);
      if (data?.status === 303 && data?.data?.message === "rule") {
        history.push("/panel/super/financial-rule/create");
      }*/
      if (refetch) refetch();
    };
    const error = (error: any) => {
      if (
        // eslint-disable-next-line eqeqeq
        error?.response?.status == 303 &&
        error?.response?.data?.message === "Doctor doesnt have financial rule."
      ) {
        history.push({
          pathname: "/panel/super/financial-rule/create",
          state: { id: userId },
        });
      }
    };
    if (!isLoading) {
      notificationAlertify({
        onApprove() {
          notify.promise(
            () =>
              mutateAsync(data, {
                onSuccess: success,
                onError: error,
              }),
            pendingMessage
          );
        },
        contnet: t("user_status.alert_content", {
          ns: "global",
          new_status: newStatus,
        }),
        title: t("user_status.alert_title"),
      });
    }
  };
  return (
    <>
      {status !== USER_STATUS_TYPES.active && (
        <DropdownItem
          onClick={() =>
            changeActivationHandler(
              USER_STATUS_TYPES.active,
              t("user_status.change_to_active")
            )
          }
        >
          <span>{t("change_active")}</span>
        </DropdownItem>
      )}
      {status !== USER_STATUS_TYPES.pending && !noPending && (
        <DropdownItem
          onClick={() =>
            changeActivationHandler(
              USER_STATUS_TYPES.pending,
              t("user_status.change_to_pending")
            )
          }
        >
          <span>{t("change_pending")}</span>
        </DropdownItem>
      )}
      {status !== USER_STATUS_TYPES.denied && (
        <DropdownItem
          onClick={() =>
            changeActivationHandler(
              USER_STATUS_TYPES.denied,
              t("user_status.change_to_denied")
            )
          }
        >
          <span>{t("change_denied")}</span>
        </DropdownItem>
      )}
    </>
  );
}

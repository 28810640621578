import clsx from "clsx";
import classes from "./styles.module.scss";
import DetailCard from "../../../../components/common/detailCard";
import {
  AppointmentBulk,
  BirthConsentIcon,
  BloodPressureIcon,
  BloodSugarIcon,
  CalendarBold,
  ClockBold,
  ClockIcon,
  EducationIcon,
  FollowUpIcon,
  HeartRateIcon,
  InstructionIcon,
  LanguageConsentIcon,
  MedicalDeviceBold,
  PulseOximeterIcon,
  ReferralIcon,
  ReviewBulk,
  RobotBold,
  RobotIcon,
  TemperatureIcon,
  TickConsentIcon,
  UserBulk,
} from "../../../../components/icon";
import ConsentCard from "../../../../components/common/consentCard";
import CardContainer from "../../../../components/common/cardContainer";
import BasicTable from "../../../../components/core/basicTable";
import LabelCard from "../../../../components/common/labelCard";
import InformationCard from "../../../../components/common/informationCard";
import { ListInfo } from "../../../../components/icon/listInfo";
import VitalSign from "../../../../components/common/vitalSign";
import { PropsWithChildren } from "react";
import { humanizedDate, humanizedTime } from "../../../../utils/date";
import { useTranslation } from "react-i18next";
import Textarea from "../../../../components/core/textarea";
import { getReason } from "../../../../utils/convert";
import Paginator from "../../../../components/common/paginator";
import DoctorDetailCard from "../../../../components/common/doctorDetailCard";
import PatientDetailCard from "../../../../components/common/patientDetailCard";
import CheckboxList, {
  ISelectedItem,
} from "../../../../components/core/checkboxList";
import { Cell } from "react-table";

export interface IShowSummaryNoteProps extends PropsWithChildren<any> {
  appt_type: string;
  appt_id: string;
  appt_date: string;
  appt_time: string;
  local_doctor_name: string;
  local_doctor_id: string;
  local_doctor_gender: string;
  local_doctor_speciality: string;
  international_doctor_name: string;
  international_doctor_id: string;
  international_doctor_gender: string;
  international_doctor_speciality: string;
  robot_name: string;
  robot_id: string;
  robot_type: string;
  assigned_devices: string[];
  patient_name: string;
  patient_id: string;
  patient_gender: string;
  patient_birth: string;
  visit_for: string;
  name_consent: boolean;
  birth_consent: boolean;
  language_consent: boolean;
  visit_consent: boolean;
  language: string;
  tele_health: string;
  medications: {
    medicine_name: string;
    dosage: string;
    formulation: string;
    frequency: string;
    taking: string;
  }[];
  allergies: {
    allergy: string;
    reaction: string;
  }[];
  allergic_reactions: string[];
  food_allergies: string[];
  other_allergies: string[];
  illnesses: string[];
  hospitalized: boolean;
  hospitalized_reasons: {
    reason: string;
    date: string;
  }[];
  had_surgery: boolean;
  surgeries: {
    surgery_type: string;
    date: string;
  }[];
  blood_sugar: {
    data: number[];
    dates: string[];
    condition: "normal" | "warning" | "danger";
    number: number;
  };
  heart_rate: {
    data: number[];
    dates: string[];
    condition: "normal" | "warning" | "danger";
    number: number;
  };
  blood_pressure: {
    data: number[];
    dates: string[];
    condition: "normal" | "warning" | "danger";
    number: string | number;
  };
  temperature: {
    data: number[];
    dates: string[];
    condition: "normal" | "warning" | "danger";
    number: number;
  };
  pulse_oximeter: {
    data: number[];
    dates: string[];
    condition: "normal" | "warning" | "danger";
    number: number | string;
  };
  height?: number;
  weight?: number;
  reason_for_visit: string | object;
  history_of_present_illness: string;
  review_of_system: string;
  physical_exam: string;
  impression: string;
  prescription: {
    medicine_name: string;
    dosage: string;
    formulation: string;
    frequency: string;
    quantity: string;
    refills: string;
  }[];
  orders: {
    labs: string[];
    tests: string[];
    imaging: string[];
    plan: string[];
  };
  instruction: string;
  followUp_date?: string;
  referral?: {
    doctor_id: string;
    doctor_name: string;
    type: string;
    speciality: string;
    interval: string;
  };
  note: string;
  education?: string;
  reason: string;
  comment: string;
  constitution: ISelectedItem[];
}

const ShowSummaryNote = ({
  reason,
  appt_type,
  appt_id,
  appt_date,
  appt_time,
  international_doctor_gender,
  international_doctor_name,
  international_doctor_id,
  international_doctor_speciality,
  local_doctor_gender,
  local_doctor_id,
  local_doctor_speciality,
  local_doctor_name,
  assigned_devices,
  patient_gender,
  patient_id,
  patient_birth,
  robot_id,
  robot_name,
  robot_type,
  patient_name,
  blood_sugar,
  note,
  birth_consent,
  blood_pressure,
  education,
  followUp_date,
  food_allergies,
  had_surgery,
  heart_rate,
  allergies,
  allergic_reactions,
  hospitalized,
  instruction,
  language,
  language_consent,
  impression = "- - -",
  name_consent,
  pulse_oximeter,
  history_of_present_illness = "- - -",
  other_allergies,
  review_of_system = "- - -",
  reason_for_visit,
  height,
  illnesses,
  referral,
  orders,
  surgeries,
  temperature,
  visit_consent,
  visit_for,
  tele_health,
  medications,
  hospitalized_reasons,
  weight,
  prescription,
  physical_exam = "- - -",
  children,
  comment = "- - -",
  constitution,
}: IShowSummaryNoteProps) => {
  const { t } = useTranslation(["global", "table", "form"]);

  console.log(reason_for_visit);

  const MEDICATION_COLUMNS = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: t("columns.medicine", { ns: "table" }),
      accessor: "medicine_name",
    },
    {
      Header: t("columns.dosage", { ns: "table" }),
      accessor: "dosage",
    },
    {
      Header: t("columns.formulation", { ns: "table" }),
      accessor: "formulation",
    },
    {
      Header: t("columns.frequency", { ns: "table" }),
      accessor: "frequency",
    },
    {
      Header: t("columns.currently_taking", { ns: "table" }),
      accessor: "taking",
      Cell: ({ value }: Cell) => t(value || "- - -"),
    },
  ];

  const ALLERGIES_COLUMNS = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: t("columns.allergy", { ns: "table" }),
      accessor: "allergy",
    },
    {
      Header: t("columns.reaction", { ns: "table" }),
      accessor: "reaction",
    },
  ];

  const PRESCRIPTION_COLUMNS = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: t("columns.medicine", { ns: "table" }),
      accessor: "medicine_name",
    },
    {
      Header: t("columns.dosage", { ns: "table" }),
      accessor: "dosage",
    },
    {
      Header: t("columns.formulation", { ns: "table" }),
      accessor: "formulation",
    },
    {
      Header: t("columns.frequency", { ns: "table" }),
      accessor: "frequency",
    },
    {
      Header: t("columns.quantity", { ns: "table" }),
      accessor: "quantity",
    },
    {
      Header: t("columns.refills", { ns: "table" }),
      accessor: "refills",
    },
  ];

  return (
    <div className={clsx(classes.mtx, classes.cards)}>
      <div
        className={clsx(classes.column, classes.showSummaryNote, classes.gx)}
      >
        <div className={clsx(classes.column, classes.gb)}>
          <div className={clsx(classes.row, classes.pg)}>
            <DetailCard
              icon={<AppointmentBulk />}
              title={t("pvs.appointment_detail")}
              subTitle={
                <>
                  <span className={clsx(classes.showSummaryNote__title)}>
                    {t("pvs.appt_type")}
                    {": "}
                  </span>
                  {appt_type === "international"
                    ? t("pvs.inter_appt")
                    : t("pvs.local_appt")}
                </>
              }
              text={
                <span className={clsx(classes.colorPrimary)}>{appt_id}</span>
              }
              chips={[
                {
                  icon: <CalendarBold />,
                  color: "#9C89B8",
                  texts: [
                    <>
                      <span className={clsx(classes.showSummaryNote__title)}>
                        {t("pvs.appt_date")}
                        {": "}
                      </span>
                      {humanizedDate(appt_date)}
                    </>,
                  ],
                },
                {
                  icon: <ClockBold />,
                  color: "#A2C5AC",
                  texts: [
                    <>
                      <span className={clsx(classes.showSummaryNote__title)}>
                        {t("pvs.appt_time")}
                        {": "}
                      </span>
                      {humanizedTime(appt_time)}
                    </>,
                  ],
                },
              ]}
            />
          </div>
          <div className={clsx(classes.row, classes.gb, classes.pg)}>
            <DoctorDetailCard
              name={local_doctor_name}
              id={local_doctor_id}
              gender={local_doctor_gender}
              speciality={local_doctor_speciality}
            />
            <DoctorDetailCard
              name={international_doctor_name}
              id={international_doctor_id}
              gender={international_doctor_gender}
              speciality={international_doctor_speciality}
              international
            />
          </div>
          <div className={clsx(classes.row, classes.pg)}>
            <DetailCard
              icon={<RobotIcon />}
              title={t("pvs.robot_detail")}
              subTitle={
                <>
                  <span className={clsx(classes.showSummaryNote__title)}>
                    {t("pvs.robot_name")}
                    {": "}
                  </span>
                  {robot_name}
                </>
              }
              text={
                <span className={clsx(classes.colorPrimary)}>{robot_id}</span>
              }
              chips={[
                {
                  icon: <RobotBold />,
                  color: "#EC0B43",
                  texts: [
                    <>
                      <span className={clsx(classes.showSummaryNote__title)}>
                        {t("pvs.robot_type")}
                        {": "}
                      </span>
                      {robot_type}
                    </>,
                  ],
                },
                {
                  icon: <MedicalDeviceBold />,
                  color: "#05668D",
                  texts: [
                    <>
                      <span className={clsx(classes.showSummaryNote__title)}>
                        {t("pvs.assigned_devices")}
                        {": "}
                      </span>
                      {assigned_devices
                        ?.toString()
                        .replace("[", "")
                        .replace("]", "")}
                    </>,
                  ],
                },
              ]}
            />
          </div>
          <div className={clsx(classes.row, classes.pg)}>
            <PatientDetailCard
              name={patient_name}
              id={patient_id}
              gender={patient_gender}
              birth={patient_birth}
            />
          </div>
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.consent")}
          </div>
          <div className={clsx(classes.column, classes.gb)}>
            <div className={clsx(classes.row, classes.gb)}>
              <ConsentCard
                type={"styled"}
                contentResult={name_consent ? t("Yes") : t("No")}
                contentResultType={name_consent ? "success" : "danger"}
                icon={<UserBulk />}
                text={patient_name}
                title={t("pvs.patient_name")}
                content={t("pvs.visit_for_q")}
                options={[t("my_self"), t("other")]}
                selectedOptions={[
                  visit_for
                    ?.toLowerCase()
                    ?.replace(" ", "")
                    ?.replace("-", "")
                    ?.replace("_", "") === "myself"
                    ? t("my_self")
                    : t("other"),
                ]}
              />
              <ConsentCard
                type={"styled"}
                contentResultType={language_consent ? "success" : "danger"}
                contentResult={language_consent ? t("Yes") : t("No")}
                icon={<LanguageConsentIcon />}
                text={language}
                title={t("pvs.language")}
                content={t("pvs.language_q")}
              />
            </div>
            <div className={clsx(classes.row, classes.gm)}>
              <ConsentCard
                type={"styled"}
                contentResultType={birth_consent ? "success" : "danger"}
                contentResult={birth_consent ? t("Yes") : t("No")}
                icon={<BirthConsentIcon />}
                text={patient_birth}
                title={t("pvs.birth")}
                content={t("pvs.birth_q")}
              />
              <ConsentCard
                type={"styled"}
                contentResultType={visit_consent ? "success" : "danger"}
                contentResult={visit_consent ? t("Yes") : t("No")}
                icon={<TickConsentIcon />}
                text={tele_health}
                title={t("pvs.consent")}
                content={t("pvs.consent_q")}
              />
            </div>
          </div>
        </div>
        <Paginator
          headers={[
            t("reason_questions.q1", { ns: "form" }),
            t("reason_questions.q2", { ns: "form" }),
            t("reason_questions.q3", { ns: "form" }),
            t("reason_questions.q4", { ns: "form" }),
            t("reason_questions.q5", { ns: "form" }),
            t("reason_questions.q6", { ns: "form" }),
            t("reason_questions.q7", { ns: "form" }),
            t("reason_questions.q8", { ns: "form" }),
            t("reason_questions.q9", { ns: "form" }),
          ]}
          components={[
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason)}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "1")}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "2")}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "3")}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "4")}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "5")}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "6")}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "7")}
                />
              </div>
            </>,
            <>
              <div
                className={clsx(
                  classes.summary__row,
                  classes.summary__bm,
                  classes.summary__gb,
                  classes.summary__card
                )}
              >
                <Textarea
                  placeholder={t("reason", { ns: "form" })}
                  readOnly
                  className={"w-100"}
                  value={getReason(reason, "8")}
                />
              </div>
            </>,
          ]}
        />
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.medications")}
          </div>
          <div className={clsx(classes.row)}>
            <CardContainer>
              <BasicTable
                columns={MEDICATION_COLUMNS}
                data={medications?.map((m, i) => {
                  return { ...m, id: i + 1 };
                })}
              />
            </CardContainer>
          </div>
        </div>
        <div className={clsx(classes.column, classes.gb, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.allergies")}
          </div>
          <div className={clsx(classes.row)}>
            <CardContainer>
              <BasicTable
                columns={ALLERGIES_COLUMNS}
                data={allergies?.map((a, i) => {
                  return { ...a, id: i + 1 };
                })}
              />
            </CardContainer>
          </div>
          <div className={clsx(classes.row, classes.gm, classes.pg)}>
            <LabelCard
              type={"styled"}
              title={t("pvs.latex_q")}
              answer={
                allergic_reactions?.includes("Latex") ? t("Yes") : t("No")
              }
            />
            <LabelCard
              type={"styled"}
              title={t("pvs.insect")}
              answer={
                allergic_reactions?.includes("Insect Bite") ? t("Yes") : t("No")
              }
            />
          </div>
          <div className={clsx(classes.row, classes.gm, classes.pg)}>
            <LabelCard
              type={"styled"}
              title={t("pvs.iodine")}
              answer={
                allergic_reactions?.includes("Iodine") ? t("Yes") : t("No")
              }
            />
          </div>
          <div className={clsx(classes.column, classes.gb, classes.pg)}>
            <LabelCard
              type={"styled"}
              title={t("pvs.food_q")}
              answer={food_allergies?.length > 0 ? t("Yes") : t("No")}
              content={food_allergies
                ?.toString()
                .replace("[", "")
                .replace("]", "")}
            />
            <LabelCard
              type={"styled"}
              title={t("pvs.other_q")}
              answer={other_allergies?.length > 0 ? t("Yes") : t("No")}
              content={other_allergies
                ?.toString()
                .replace("[", "")
                .replace("]", "")}
            />
          </div>
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pmh.title")}
          </div>
          <InformationCard icon={<ListInfo />} title={t("pmh.list_illnesses")}>
            <div className={clsx(classes.column, classes.gm, classes.mtm)}>
              {illnesses?.length === 0
                ? "- - -"
                : t("items", { count: illnesses?.length })}
            </div>
            <div
              className={clsx(
                classes.row,
                classes.gm,
                classes.wrap,
                classes.mtm
              )}
            >
              {illnesses?.map((illness, index) => {
                return (
                  <div
                    key={index}
                    className={clsx(classes.showSummaryNote__primaryBadge)}
                  >
                    {illness}
                  </div>
                );
              })}
            </div>
          </InformationCard>
          <div className={clsx(classes.column, classes.gm, classes.pg)}>
            <div className={clsx(classes.showSummaryNote__secondary)}>
              {t("pvs.comment")}
            </div>
            <CardContainer type={"basic"}>{comment}</CardContainer>
          </div>
          <ConsentCard
            icon={<ClockIcon />}
            title={t("pmh.hospitalized_history")}
            content={t("pmh.hospitalized_q")}
            contentResult={hospitalized ? t("Yes") : t("No")}
            contentResultType={hospitalized ? "success" : "danger"}
            type={"basic"}
          >
            <div className={clsx(classes.column, classes.gm, classes.mtm)}>
              {hospitalized_reasons?.map((item, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className={clsx(classes.showSummaryNote__smallGray)}
                    >
                      {humanizedDate(item.date)}
                    </div>
                    <div
                      key={index}
                      className={clsx(classes.showSummaryNote__smallGray)}
                    >
                      {item.reason}
                    </div>
                  </>
                );
              })}
            </div>
          </ConsentCard>
          <ConsentCard
            icon={<ClockIcon />}
            title={t("pmh.surgical")}
            content={t("pmh.surgical_q")}
            contentResult={had_surgery ? t("Yes") : t("No")}
            contentResultType={had_surgery ? "success" : "danger"}
            type={"basic"}
          >
            <div className={clsx(classes.column, classes.gm, classes.mtm)}>
              {surgeries?.map((item, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className={clsx(classes.showSummaryNote__smallGray)}
                    >
                      {humanizedDate(item.date)}
                    </div>
                    <div
                      key={index}
                      className={clsx(classes.showSummaryNote__smallGray)}
                    >
                      {item.surgery_type}
                    </div>
                  </>
                );
              })}
            </div>
          </ConsentCard>
        </div>

        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pmh.history_illnesses")}
          </div>
          <CardContainer type={"basic"} padding={"none"}>
            <div className={clsx(classes.column, classes.gx)}>
              <InformationCard
                icon={<ReviewBulk />}
                title={t("pmh.history_illnesses")}
              >
                <div className={clsx(classes.showSummaryNote__smallGray)}>
                  {history_of_present_illness}
                </div>
              </InformationCard>
              <InformationCard
                icon={<ReviewBulk />}
                title={t("pvs.review_system")}
              >
                <div className={clsx(classes.showSummaryNote__smallGray)}>
                  {review_of_system || "- - -"}
                </div>
              </InformationCard>
            </div>
          </CardContainer>
          <CardContainer>
            <div className={"mt-3"}>
              <CheckboxList
                type="radio"
                clickable
                chosenItems={constitution}
                list={[
                  {
                    title: "Constitutional",
                    list: [
                      "Chills",
                      "Fatigue",
                      "Fever",
                      "Night Sweats",
                      "Weight changes",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Eyes",
                    list: [
                      "Blurred Vision",
                      "Eye Drainage",
                      "Eye Pain",
                      "Irritation",
                      "Vision change",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Cardiovascular",
                    list: [
                      "Chest Pain",
                      "Orthopnea",
                      "Palpitations",
                      "PND",
                      "Edema",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Ears/Nose/Throat",
                    list: [
                      "Ear Pain",
                      "Bloody Nose",
                      "Congestion",
                      "Hoarse Voice",
                      "Sore Throat",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Respiratory",
                    list: [
                      "Cough",
                      "Dyspnea",
                      "TB Exposure",
                      "Hemoptysis",
                      "Wheezing",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Endocrine",
                    list: [
                      "Hair loss",
                      "Heat/cold in",
                      "Polydipsia",
                      "Polyphagia",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Heme/Lymphatic",
                    list: [
                      "Bruising",
                      "Bleeding",
                      "Adenopathy",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Gastrointestinal",
                    list: [
                      "Abdominal Pain",
                      "Blood in Stool",
                      "Constipation",
                      "Melena",
                      "Nausea /Vomiting /Diar",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Genitourinary",
                    list: [
                      "Dysuria",
                      "Hematuria",
                      "Nocturia",
                      "Polyuria",
                      "Incontinence",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Skin/Integument",
                    list: [
                      "Atypical moles",
                      "Dry Skin",
                      "Itching",
                      "Rashes",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Breast",
                    list: ["Discharge", "Masses", "Unremarkable", "N/A"],
                  },
                  {
                    title: "Musculoskeletal",
                    list: [
                      "Arthralgias",
                      "Back Pain",
                      "Joint stiffness",
                      "Limb Pain",
                      "Myalgia",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Neurologic",
                    list: [
                      "Ataxia",
                      "Fainting",
                      "Headache",
                      "Seizures",
                      "Weakness",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "Allergies/immunology",
                    list: [
                      "Seasonal all",
                      "Perennial all",
                      "Freq infections",
                      "HIV risk factors",
                      "Urticaria",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                  {
                    title: "ALL SYSTEMS REVIEWED",
                    list: [
                      "Negative",
                      "All others negative",
                      "Unremarkable",
                      "N/A",
                    ],
                  },
                ]}
                items={[
                  {
                    title: t("columns.Constitutional", { ns: "table" }),
                    list: [
                      t("columns.Chills", { ns: "table" }),
                      t("columns.Fatigue", { ns: "table" }),
                      t("columns.Fever", { ns: "table" }),
                      t("columns.Night Sweats", { ns: "table" }),
                      t("columns.Weight changes", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Eyes", { ns: "table" }),
                    list: [
                      t("columns.Blurred Vision", { ns: "table" }),
                      t("columns.Eye Drainage", { ns: "table" }),
                      t("columns.Eye Pain", { ns: "table" }),
                      t("columns.Irritation", { ns: "table" }),
                      t("columns.Vision change", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Cardiovascular", { ns: "table" }),
                    list: [
                      t("columns.Chest Pain", { ns: "table" }),
                      t("columns.Orthopnea", { ns: "table" }),
                      t("columns.Palpitations", { ns: "table" }),
                      t("columns.PND", { ns: "table" }),
                      t("columns.Edema", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Ears/Nose/Throat", { ns: "table" }),
                    list: [
                      t("columns.Ear Pain", { ns: "table" }),
                      t("columns.Bloody Nose", { ns: "table" }),
                      t("columns.Congestion", { ns: "table" }),
                      t("columns.Hoarse Voice", { ns: "table" }),
                      t("columns.Sore Throat", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Respiratory", { ns: "table" }),
                    list: [
                      t("columns.Cough", { ns: "table" }),
                      t("columns.Dyspnea", { ns: "table" }),
                      t("columns.TB Exposure", { ns: "table" }),
                      t("columns.Hemoptysis", { ns: "table" }),
                      t("columns.Wheezing", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Endocrine", { ns: "table" }),
                    list: [
                      t("columns.Hair loss", { ns: "table" }),
                      t("columns.Heat/cold in", { ns: "table" }),
                      t("columns.Polydipsia", { ns: "table" }),
                      t("columns.Polyphagia", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Heme/Lymphatic", { ns: "table" }),
                    list: [
                      t("columns.Bruising", { ns: "table" }),
                      t("columns.Bleeding", { ns: "table" }),
                      t("columns.Adenopathy", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Gastrointestinal", { ns: "table" }),
                    list: [
                      t("columns.Abdominal Pain", { ns: "table" }),
                      t("columns.Blood in Stool", { ns: "table" }),
                      t("columns.Constipation", { ns: "table" }),
                      t("columns.Melena", { ns: "table" }),
                      t("columns.Nausea /Vomiting /Diar", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Genitourinary", { ns: "table" }),
                    list: [
                      t("columns.Dysuria", { ns: "table" }),
                      t("columns.Hematuria", { ns: "table" }),
                      t("columns.Nocturia", { ns: "table" }),
                      t("columns.Polyuria", { ns: "table" }),
                      t("columns.Incontinence", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Skin/Integument", { ns: "table" }),
                    list: [
                      t("columns.Atypical moles", { ns: "table" }),
                      t("columns.Dry Skin", { ns: "table" }),
                      t("columns.Itching", { ns: "table" }),
                      t("columns.Rashes", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Breast", { ns: "table" }),
                    list: [
                      t("columns.Discharge", { ns: "table" }),
                      t("columns.Masses", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Musculoskeletal", { ns: "table" }),
                    list: [
                      t("columns.Arthralgias", { ns: "table" }),
                      t("columns.Back Pain", { ns: "table" }),
                      t("columns.Joint stiffness", { ns: "table" }),
                      t("columns.Limb Pain", { ns: "table" }),
                      t("columns.Myalgia", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Neurologic", { ns: "table" }),
                    list: [
                      t("columns.Ataxia", { ns: "table" }),
                      t("columns.Fainting", { ns: "table" }),
                      t("columns.Headache", { ns: "table" }),
                      t("columns.Seizures", { ns: "table" }),
                      t("columns.Weakness", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.Allergies/immunology", {
                      ns: "table",
                    }),
                    list: [
                      t("columns.Seasonal all", { ns: "table" }),
                      t("columns.Perennial all", { ns: "table" }),
                      t("columns.Freq infections", { ns: "table" }),
                      t("columns.HIV risk factors", { ns: "table" }),
                      t("columns.Urticaria", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                  {
                    title: t("columns.ALL SYSTEMS REVIEWED", {
                      ns: "table",
                    }),
                    list: [
                      t("columns.Negative", { ns: "table" }),
                      t("columns.All others negative", { ns: "table" }),
                      t("columns.Unremarkable", { ns: "table" }),
                      t("columns.N/A", { ns: "table" }),
                    ],
                  },
                ]}
              />
            </div>
          </CardContainer>
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("vital_sign.title")}
          </div>
          <VitalSign
            healthInfo={[
              {
                icon: <BloodSugarIcon />,
                title: t("glucometer"),
                number: blood_sugar.number,
                conditionText: blood_sugar.condition,
                condition: blood_sugar.condition,
                pastData: blood_sugar.data,
                unit: "mg / dL",
                dates: blood_sugar.dates,
              },
              {
                icon: <HeartRateIcon />,
                title: t("heart_rate"),
                number: heart_rate.number,
                conditionText: heart_rate.condition,
                condition: heart_rate.condition,
                pastData: heart_rate.data,
                unit: "bpm",
                dates: heart_rate.dates,
              },
              {
                icon: <BloodPressureIcon />,
                title: t("blood_pressure"),
                number: blood_pressure.number,
                conditionText: blood_pressure.condition,
                condition: blood_pressure.condition,
                pastData: blood_pressure.data,
                unit: "mmHG",
                dates: blood_pressure.dates,
              },
              {
                icon: <TemperatureIcon />,
                title: t("temperature"),
                number: temperature.number,
                conditionText: temperature.condition,
                condition: temperature.condition,
                pastData: temperature.data,
                unit: "°F",
                dates: temperature.dates,
              },
              {
                icon: <PulseOximeterIcon />,
                title: t("pulse_oximeter"),
                number: pulse_oximeter.number,
                conditionText: pulse_oximeter.condition,
                condition: pulse_oximeter.condition,
                pastData: pulse_oximeter.data,
                unit: "%",
                dates: pulse_oximeter.dates,
              },
            ]}
            height={height}
            weight={weight}
            reasonForVisit={
              typeof reason_for_visit === "string"
                ? reason_for_visit
                : getReason(JSON.stringify(reason_for_visit))
            }
            birth={patient_birth}
            gender={patient_gender}
          />
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.physical_exam")}
          </div>
          <CardContainer type={"basic"}>{physical_exam}</CardContainer>
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.impressions")}
          </div>
          <CardContainer type={"basic"}>{impression || "- - -"}</CardContainer>
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.prescription")}
          </div>
          <CardContainer type={"basic"}>
            <BasicTable
              columns={PRESCRIPTION_COLUMNS}
              data={prescription?.map((p, i) => {
                return { ...p, id: i + 1 };
              })}
            />
          </CardContainer>
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.orders")}
          </div>
          <div className={clsx(classes.row, classes.gm, classes.pg)}>
            <LabelCard
              type={"styled"}
              title={`${t("pvs.labs")}: `}
              answer={
                orders.labs?.toString()?.replace("[", "").replace("]", "") ||
                "- - -"
              }
            />
            <LabelCard
              type={"styled"}
              title={`${t("pvs.imaging")}: `}
              answer={
                orders.imaging
                  ?.toString()
                  ?.replace("[", "")
                  ?.replace("]", "") || "- - -"
              }
            />
          </div>
          <div className={clsx(classes.row, classes.gm, classes.pg)}>
            <LabelCard
              type={"styled"}
              title={`${t("pvs.test")}: `}
              answer={
                orders.tests?.toString()?.replace("[", "")?.replace("]", "") ||
                "- - -"
              }
            />
            <LabelCard
              type={"styled"}
              title={`${t("pvs.plan")}: `}
              answer={
                orders?.plan?.toString()?.replace("[", "")?.replace("]", "") ||
                "- - -"
              }
            />
          </div>
        </div>
        <div className={clsx(classes.column, classes.gm, classes.pg)}>
          <div className={clsx(classes.showSummaryNote__secondary)}>
            {t("pvs.ifre")}
          </div>
          <InformationCard icon={<InstructionIcon />} title={t("instructions")}>
            <div className={clsx(classes.showSummaryNote__smallGray)}>
              {instruction}
            </div>
          </InformationCard>
          <ConsentCard
            type={"basic"}
            contentResultType={followUp_date ? "success" : "danger"}
            contentResult={followUp_date ? t("Yes") : t("No")}
            icon={<FollowUpIcon />}
            title={t("pvs.follow_up")}
            content={t("pvs.follow_up_q")}
          >
            {followUp_date && (
              <div className={clsx(classes.showSummaryNote__smallGray)}>
                {followUp_date}
              </div>
            )}
          </ConsentCard>
          <ConsentCard
            type={"basic"}
            contentResultType={referral ? "success" : "danger"}
            contentResult={referral ? t("Yes") : t("No")}
            icon={<ReferralIcon />}
            title={t("pvs.referral")}
            content={t("pvs.referral_q")}
          >
            {referral && (
              <div className={clsx(classes.column, classes.gb)}>
                <div className={clsx(classes.showSummaryNote__smallGray)}>
                  {t("pvs.doctor_name") + ": " + referral.doctor_name}
                </div>
                <div className={clsx(classes.showSummaryNote__smallGray)}>
                  {t("pvs.referral_type") + ": " + referral.type}
                </div>
                <div className={clsx(classes.showSummaryNote__smallGray)}>
                  {t("pvs.speciality") + ": " + referral.speciality}
                </div>
                <div className={clsx(classes.showSummaryNote__smallGray)}>
                  {t("pvs.referral_visit_time") +
                    ": " +
                    referral.interval +
                    " later"}
                </div>
              </div>
            )}
          </ConsentCard>
          <InformationCard icon={<ListInfo />} title={t("pvs.note_for")}>
            <div className={clsx(classes.showSummaryNote__smallGray)}>
              {note}
            </div>
          </InformationCard>
          <ConsentCard
            type={"basic"}
            contentResultType={education ? "success" : "danger"}
            contentResult={education ? t("Yes") : t("No")}
            icon={<EducationIcon />}
            title={t("pvs.education")}
            content={t("pvs.education_q")}
          >
            {education && (
              <div className={clsx(classes.showSummaryNote__smallGray)}>
                {education}
              </div>
            )}
          </ConsentCard>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ShowSummaryNote;

import {
  RedirectToDashboard,
  RedirectToDashboardAbs,
} from "components/common/redirectToDashboard";
import { lazy } from "react";
import { addInnerRoutes, TRoute } from "config/router";
import { DASHBOARD_GLOBAL_ROUTES } from "./globalDashboard";
import { lazyRetry } from "utils/route";

const PATIENT_ROUTES: TRoute = {
  path: "patient",
  exact: true,
  component: RedirectToDashboard,
  inners: [
    {
      path: "500",
      exact: true,
      component: lazy(() => lazyRetry(() => import("pages/500"))),
    },
    {
      path: "403",
      exact: true,
      component: lazy(() => lazyRetry(() => import("pages/403"))),
    },
    {
      path: "400",
      exact: true,
      component: lazy(() => lazyRetry(() => import("pages/400"))),
    },
    {
      path: "503",
      exact: true,
      component: lazy(() => lazyRetry(() => import("pages/503"))),
    },
    {
      path: "no-connection",
      exact: true,
      component: lazy(() => lazyRetry(() => import("pages/noInternet"))),
    },
    {
      path: "stripe/success",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/successPayment"))
      ),
      exact: true,
    },
    {
      path: "stripe/cancel",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/failurePayment"))
      ),
      exact: true,
    },
    {
      path: "paypal/success",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/successPayment"))
      ),
      exact: true,
    },
    {
      path: "paypal/cancel",
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/failurePayment"))
      ),
      exact: true,
    },
    {
      path: "dashboard",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/home"))
      ),
      exact: true,
    },
    {
      path: "registration",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/registration"))
      ),
      exact: true,
    },
    {
      path: "appointments/scheduled",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/appointments"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/patient/appointments/form"))
          ),
        },
      ],
    },
    {
      path: "appointments/on-demand",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/appointments"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/patient/appointments/form"))
          ),
        },
      ],
    },
    {
      path: "reschedule-appointment/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/appointmentManagement/innerPages/reScheduled"
            )
        )
      ),
      exact: true,
    },
    {
      path: "doctor-available-time",
      component: lazy(() =>
        lazyRetry(
          () =>
            import("pages/dashboard/patient/appointments/doctorAvailableTime")
        )
      ),
      exact: true,
    },
    {
      path: "financial-records",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/patient/financial/financialRecords")
        )
      ),
      exact: true,
    },
    {
      path: "wallet-balance",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/patient/financial/walletBalance")
        )
      ),
      exact: true,
    },
    {
      path: "family-members",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/familyMembers"))
      ),
      exact: true,
      inners: [
        {
          path: "add-dependancy",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/patient/familyMembers/components/addFamilyMember"
                )
            )
          ),
          exact: true,
        },
        {
          path: ":id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/patient/familyMembers/components/updateFamilyMember"
                )
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "profile-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/profileManagement"))
      ),
    },
    {
      path: "intake-form-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/intakeForms"))
      ),
      exact: true,
      inners: [
        {
          path: ":intake_id",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
          ),
          exact: true,
        },
        {
          path: ":intake_id/edit",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
          ),
          exact: true,
        },
      ],
    },
    {
      path: "follow-up",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/healthRecords"))
      ),
      exact: true,
    },
    {
      path: "patient-followup/:appt_id/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import("pages/dashboard/staff/healthRecords/followUp/showFollowup")
        )
      ),
    },
    {
      path: "patient-orders/:appt_id/:id",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/patient/healthRecords/orders/showOrder")
        )
      ),
    },
    {
      path: "patient-prescription/:appt_id/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/patient/healthRecords/prescription/showPrescription"
            )
        )
      ),
    },
    {
      path: "patient-referral/:appt_id/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/patient/healthRecords/referral/showReferral"
            )
        )
      ),
    },
    {
      path: "visit-summary",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/summaryNotes"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/download",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-medical-history",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/patientMedicalHistory"))
      ),
      exact: true,
    },
    {
      path: "past-medical-history/:pid/:patient_type",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/medicalHistory"))
      ),
      exact: true,
    },
    {
      path: "view-stored-result",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/storingResults"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member/:appointment_id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "new-self-report",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/patient/selfReport"))
      ),
      exact: true,
    },
    {
      path: "view-doctor-profile/:id",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/staff/doctorList/viewDoctorProfile")
        )
      ),
      exact: true,
    },
    {
      path: "view-appointment-detail/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/appointmentDetail"))
      ),
      exact: true,
    },
    {
      path: "view-appointment-detail/:id/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/appointmentDetail"))
      ),
      exact: true,
    },
    { component: RedirectToDashboardAbs, exact: true, path: "help-center" },
    ...addInnerRoutes(DASHBOARD_GLOBAL_ROUTES, {
      routePath: "notification",
      routes: [],
    }),
    {
      path: "appointments-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/appointmentReports")
        )
      ),
    },
  ],
};

export default PATIENT_ROUTES;

import AutoComplete, { IMenuOption } from "components/core/autoComplete";
import { useDropdownDefaultValue } from "hooks/useDropdownDefaultValue";
import React, { useCallback, useEffect } from "react";
import { useDropdownsOptions } from "hooks/useOptions";
import { IResponse } from "api";
import { IDropdownResponse } from "api/dropdown";
import IDropdown from "./IDropdown";
import FastField from "./field";

const EMPTY: IMenuOption = { key: "", value: "" };

export interface IDropdownProps<T> extends IDropdown<T> {
  /**
   * The data that u fetched. pass the data or options
   */
  data?: IResponse<IDropdownResponse[]>;
  isLoading: boolean;
  isError: boolean;
  /**
   * pass the data or options
   */
  options?: IMenuOption[];
}

export function FastDropdown<T>({
  fieldName,
  formik,
  id,
  name,
  data,
  label,
  disabled,
  isError,
  isLoading,
  dependencies,
  multiSelectDependencies,
  options,
  placeholder,
  removeErrorOnValueChange,
  onChange,
  onChangeAlongside,
  ...rest
}: IDropdownProps<T>) {
  // map fetched data to IMenuOption type
  const dataOptions = useDropdownsOptions({ data: data });

  const OPTIONS = !!options ? options : dataOptions;

  // search and select (if can) in options where
  // options.key === specialtyId OR options.value === specialtyName
  useDropdownDefaultValue({
    fieldName: fieldName,
    formik: formik,
    id: id,
    OPTIONS: OPTIONS,
    name: name,
  });

  const value = formik.values[fieldName] as unknown as IMenuOption | undefined;

  useEffect(() => {
    if (!!removeErrorOnValueChange) {
      if (value && value.key !== "") {
        formik.setFieldError(fieldName, undefined);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  const onChangeAlongSideHandler = useCallback(
    (value: IMenuOption) => {
      dependencies?.forEach((element) => {
        formik.setFieldValue(element, EMPTY);
      });
      multiSelectDependencies?.forEach((element) => {
        formik.setFieldValue(element, []);
      });
      if (onChangeAlongside) onChangeAlongside(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeAlongside]
  );

  const memorized_OPTIONS = React.useMemo(() => OPTIONS || [], [OPTIONS]);

  return (
    <FastField
      onChangeValueMapper={(value: IMenuOption) => value}
      validateOnValueChanged
      {...{
        formik,
        fieldName,
        label,
        onChange,
        onChangeAlongside: onChangeAlongSideHandler,
        ...rest,
      }}
    >
      {(props) => (
        <AutoComplete
          size="big"
          {...rest}
          placeholder={placeholder ? placeholder : label}
          disabled={isLoading || disabled}
          options={memorized_OPTIONS}
          isError={isError}
          isLoading={isLoading}
          name={fieldName}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.validateOnBlur}
        />
      )}
    </FastField>
  );
}

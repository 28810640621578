import { useQuery } from "react-query";
import { getCities } from "api/dropdown";
import { GPSBold } from "components/icon";
import { getDropdownValue } from "utils/dropdowns";
import IDropdown from "components/formikFields/IDropdown";
import { FastDropdown } from "components/formikFields";
import { useTranslation } from "react-i18next";
import { citiesKey } from "../../utils/keyGenerator/keys";

interface ICityProps<T> extends IDropdown<T> {
  /**
   * The field name of the state that this City is dependent on it.
   *
   * without knowing the state we can not fetch the Cities of that state, so the City depends on the state.
   */
  stateFieldName: keyof T & string;
}

export function City<T>({
  stateFieldName,
  formik,
  disabled,
  ...props
}: ICityProps<T>) {
  const { t } = useTranslation("form");

  // get the state value from formik values and extract its key
  const { key: stateId } = getDropdownValue(formik, stateFieldName);

  // fetch data
  const { data, isLoading, isError } = useQuery(
    [citiesKey, stateId],
    () => getCities(Number(stateId)),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!stateId,
    }
  );

  return (
    <FastDropdown
      label={t("city")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!stateId || disabled}
      icon={GPSBold}
      {...props}
    />
  );
}

export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export function validatePassword(value: string) {
  if (value.length < 8) {
    return "Password length can't be less than 8 characters.";
  } else if (!/^(?=.*[a-z])/.test(value)) {
    return "Password must include at least lowercase letter";
  } else if (!/^(?=.*[A-Z])/.test(value)) {
    return "Password must include at least uppercase letter";
  } else if (!/^(?=.*[!@#?$%^&*])/.test(value)) {
    return "Password must include a special character";
  } else {
    return "";
  }
}

type IValidationQueueFn = {
  validator: (() => boolean) | (() => Promise<boolean>);
  onValidated?: (result: boolean) => void;
};
/**
 * This is used for multi-step validation.
 * Add your validation functions by `push` fn, which will treat them like a queue.
 * the second validation will run if the first step was valid.
 */
export class ValidationQueue {
  private validationFns: IValidationQueueFn[] = [];
  push(validationObj: IValidationQueueFn) {
    this.validationFns.push(validationObj);
  }
  async run() {
    for (let index = 0; index < this.validationFns.length; index++) {
      const validationObj = this.validationFns[index];
      const result = await validationObj.validator();
      if (validationObj.onValidated !== undefined)
        validationObj.onValidated(result);
      if (result === false) break;
    }
  }
}

import {
  RedirectToDashboard,
  RedirectToDashboardAbs,
} from "components/common/redirectToDashboard";
import { lazy } from "react";
import { addInnerRoutes, TRoute } from "config/router";
import {
  DASHBOARD_GLOBAL_ROUTES,
  NEW_NOTIFICATION_ROUTE,
} from "./globalDashboard";
import { UserRoleName } from "api/types/userTypes";
import { initCEVRoutes, lazyRetry } from "utils/route";
import ServerError from "../../pages/500";
import Forbidden from "../../pages/403";
import BadRequest from "../../pages/400";
import Unavailable from "../../pages/503";
import NoInternet from "../../pages/noInternet";

const ResetPassword = lazy(() =>
  lazyRetry(
    () =>
      import(
        "pages/dashboard/superAdmin/localAdmin/staffManagement/resetPassword"
      )
  )
);

const SUPER_ADMIN_ROUTES: TRoute = {
  path: "super",
  exact: true,
  component: RedirectToDashboard,
  inners: [
    {
      component: lazy(() =>
        lazyRetry(() => import("pages/globalPages/privacy"))
      ),
      exact: true,
      path: "privacy-policy",
    },
    { component: RedirectToDashboardAbs, exact: true, path: "help-center" },
    {
      path: "500",
      exact: true,
      component: ServerError,
    },
    {
      path: "403",
      exact: true,
      component: Forbidden,
    },
    {
      path: "400",
      exact: true,
      component: BadRequest,
    },
    {
      path: "503",
      exact: true,
      component: Unavailable,
    },
    {
      path: "no-connection",
      exact: true,
      component: NoInternet,
    },
    {
      path: "dashboard",
      exact: true,
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/home"))
      ),
    },
    {
      path: "profile-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/profileManagement"))
      ),
    },
    {
      path: "healthcare-facility",
      exact: true,
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/HealthcareFacilityManagement/index"
            )
        )
      ),
      inners: initCEVRoutes(
        lazy(() =>
          lazyRetry(
            () =>
              import(
                "pages/dashboard/superAdmin/HealthcareFacilityManagement/form"
              )
          )
        )
      ),
    },
    {
      path: "robot",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/robotManagement/robotRegistration"
            )
        )
      ),
      exact: true,
      inners: initCEVRoutes(
        lazy(() =>
          lazyRetry(
            () =>
              import(
                "pages/dashboard/superAdmin/robotManagement/robotRegistration/form"
              )
          )
        )
      ),
    },
    {
      path: "medical-device",
      exact: true,
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/robotManagement/medicalDeviceManagement"
            )
        )
      ),
      inners: initCEVRoutes(
        lazy(() =>
          lazyRetry(
            () =>
              import(
                "pages/dashboard/superAdmin/robotManagement/medicalDeviceManagement/form"
              )
          )
        )
      ),
    },
    {
      exact: true,
      path: "create-robot",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/robotManagement/createRobot")
        )
      ),
      inners: initCEVRoutes(
        lazy(() =>
          lazyRetry(
            () =>
              import(
                "pages/dashboard/superAdmin/robotManagement/createRobot/form"
              )
          )
        )
      ),
    },
    {
      exact: true,
      path: "assign-robot-to-healthcare-facility",
      component: lazy(() =>
        lazyRetry(
          () =>
            import("pages/dashboard/superAdmin/assignRobot/healthcareFacility")
        )
      ),
      inners: initCEVRoutes(
        lazy(() =>
          lazyRetry(
            () =>
              import(
                "pages/dashboard/superAdmin/assignRobot/healthcareFacility/form"
              )
          )
        )
      ),
    },
    {
      exact: true,
      path: "assign-robot-to-doctor",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/assignRobot/doctor"))
      ),
      inners: initCEVRoutes(
        lazy(() =>
          lazyRetry(
            () => import("pages/dashboard/superAdmin/assignRobot/doctor/form")
          )
        )
      ),
    },
    {
      exact: true,
      path: "doctor-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/doctorManagement"))
      ),
      inners: [
        {
          exact: true,
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
        {
          exact: true,
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
        {
          exact: true,
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/viewDoctorProfilePage"
                )
            )
          ),
        },
        {
          exact: true,
          path: "view-profile/:id/reset-password",
          component: ResetPassword,
        },
        {
          path: "reset-password/:id",
          component: ResetPassword,
          exact: true,
        },
      ],
    },
    {
      exact: true,
      path: "doctor-approval",
      component: lazy(() =>
        lazyRetry(
          () =>
            import("pages/dashboard/superAdmin/doctorManagement/doctorApproval")
        )
      ),
      inners: [
        {
          exact: true,
          path: ":id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
        {
          exact: true,
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
      ],
    },
    {
      path: "user-management",
      exact: true,
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/userManagement"))
      ),
      inners: [
        {
          path: "reset-password/:id",
          component: ResetPassword,
          exact: true,
        },
        {
          path: "add-role/:id",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/superAdmin/addRole"))
          ),
        },
        {
          path: "remove-role/:id",
          component: lazy(() =>
            lazyRetry(() => import("pages/dashboard/superAdmin/removeRole"))
          ),
        },
      ],
    },
    {
      exact: true,
      path: "dna",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/localAdminManagement/dnaRegistration"
            )
        )
      ),
      inners: [
        {
          exact: true,
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/localAdminManagement/dnaRegistration/form"
                )
            )
          ),
        },
        {
          exact: true,
          path: ":id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/localAdminManagement/dnaRegistration/form"
                )
            )
          ),
        },
        {
          exact: true,
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/localAdminManagement/dnaRegistration/form"
                )
            )
          ),
        },
      ],
    },
    {
      path: "local-management",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/localAdminRegistration")
        )
      ),
      exact: true,
      inners: [
        ...initCEVRoutes(
          lazy(() =>
            lazyRetry(
              () =>
                import("pages/dashboard/superAdmin/localAdminRegistration/form")
            )
          )
        ),
        {
          path: "reset-password/:id",
          component: () => <ResetPassword roleName={UserRoleName.LocalAdmin} />,
          exact: true,
        },
      ],
    },
    {
      path: "cmo-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/cmoManagement"))
      ),
      exact: true,
      inners: [
        ...initCEVRoutes(
          lazy(() =>
            lazyRetry(
              () =>
                import("pages/dashboard/superAdmin/localAdminRegistration/form")
            )
          )
        ),
        {
          path: "reset-password/:id",
          component: () => <ResetPassword roleName={UserRoleName.CMO} />,
          exact: true,
        },
      ],
    },
    {
      path: "staff-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/staffManagement"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/staffManagement/staffRegisteration"
                )
            )
          ),
        },
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/staffManagement/viewStaffProfile"
                )
            )
          ),
          exact: true,
        },
        {
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/staffManagement/staffRegisteration"
                )
            )
          ),
        },
        {
          path: "reset-password/:id",
          component: ResetPassword,
          exact: true,
        },
      ],
    },
    {
      path: "report",
      component: RedirectToDashboardAbs,
      exact: true,
    },
    {
      path: "reset-password/:id",
      component: () => <ResetPassword />,
      exact: true,
    },
    {
      path: "appointment-management",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/appointmentManagement")
        )
      ),
      exact: true,
    },
    {
      path: "reschedule-appointment/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/appointmentManagement/innerPages/reScheduled"
            )
        )
      ),
      exact: true,
    },
    {
      path: "visit-summary",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/summaryNotes"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/download",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "patient-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/patientManagement"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/patientManagement/patientRegistration"
                )
            )
          ),
        },
        {
          path: "reset-password/:id",
          component: () => <ResetPassword />,
          exact: true,
        },
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/patientManagement/viewPatientProfile"
                )
            )
          ),
          exact: true,
        },
        {
          path: "edit/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/patientManagement/patientRegistration"
                )
            )
          ),
        },
        {
          path: ":id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/patientManagement/patientEdit"
                )
            )
          ),
        },
      ],
    },
    {
      path: "view-appointment-detail/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/appointmentDetail"))
      ),
      exact: true,
    },
    {
      path: "create-promotion",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/financialManagement/createPromotion"
            )
        )
      ),
      exact: true,
    },
    {
      path: "create-new-promotion",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/financialManagement/createPromotion/createPromotionForm"
            )
        )
      ),
      exact: true,
    },
    {
      path: "edit-promotion/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/financialManagement/createPromotion/createPromotionForm"
            )
        )
      ),
      exact: true,
    },
    {
      path: "financial-rule",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/financialManagement/financialRule"
            )
        )
      ),
      exact: true,
      inners: [
        {
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/financialManagement/financialRule/visitCost"
                )
            )
          ),
          exact: true,
          path: "year-configuration",
        },
        {
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/financialManagement/financialRule/visitCost"
                )
            )
          ),
          exact: true,
          path: "create",
        },
        {
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/financialManagement/financialRule/visitCost"
                )
            )
          ),
          exact: true,
          path: "edit/:id",
        },
        {
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/financialManagement/financialRule/visitCost"
                )
            )
          ),
          exact: true,
          path: "view-profile/:id",
        },
      ],
    },
    {
      path: "patient-wallet",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/financialManagement"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/staff/financialManagement/components/createNewWallet"
                )
            )
          ),
          exact: true,
        },
        {
          path: "increase/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/staff/financialManagement/components/addCredit"
                )
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "accounting-reports",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/localAdmin/financialManagment/accountingReports"
            )
        )
      ),
      exact: true,
    },
    {
      path: "payment-method",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/financialManagement/paymentMethods"
            )
        )
      ),
      exact: true,
    },
    {
      path: "PACS",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/pacs/pacs"))
      ),
    },
    {
      path: "user-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/userReports")
        )
      ),
    },
    {
      path: "robot-usage-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/robotReports")
        )
      ),
    },
    {
      path: "appointments-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/appointmentReports")
        )
      ),
    },
    {
      path: "financial-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/financialReports")
        )
      ),
    },
    {
      path: "clinical-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/clinicalReports")
        )
      ),
    },
    {
      path: "device-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/deviceUsage")
        )
      ),
    },
    {
      path: "robot-appointments/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/robotAppointments"))
      ),
    },
    ...addInnerRoutes(DASHBOARD_GLOBAL_ROUTES, {
      routePath: "notifications",
      routes: [NEW_NOTIFICATION_ROUTE],
    }),
  ],
};

export default SUPER_ADMIN_ROUTES;

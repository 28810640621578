import clsx from "clsx";
import classes from "./styles.module.scss";
import { HomeBold, VideoVisitLogo } from "../../../../icon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  humanizedDate,
  humanizedTime,
  padWithZero,
} from "../../../../../utils/date";
import { TRoleName } from "../../../../../api/user";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useBaseUrl } from "../../../../../utils/route";

export interface ITopBar {
  title: string;
  date: Date;
  open: boolean;
  text: string;
  setMinimize: Dispatch<SetStateAction<boolean>>;
  role?: TRoleName | "guest";
}

function getTime(d: Date) {
  const x = Date.now() - d.getTime();
  let seconds = Math.floor(x / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  return `${padWithZero(hours, 2)}:${padWithZero(minutes, 2)}:${padWithZero(
    seconds,
    2
  )}`;
}

// video visit topbar
const TopBar = ({ title, date, open, text, setMinimize, role }: ITopBar) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  const history = useHistory();
  const base = useBaseUrl();
  const [duration, setDuration] = useState("00:00:00");

  useEffect(() => {
    const timer = setInterval(() => setDuration(getTime(date)), 1000);
    return () => clearInterval(timer);
  }, [date]);

  // render component
  return (
    <div className={clsx(classes.topBar, !open && classes.topBarClose)}>
      <div className={clsx(classes.topBar__start)}>
        <VideoVisitLogo className={clsx(classes.logo)} />
        <div className={clsx(classes.topBar__content)}>
          <div className={clsx(classes.topBar__title)}>{title}</div>
          <div className={clsx(classes.topBar__subTitle)}>
            <span>{text}&nbsp;| &nbsp;&nbsp;</span>
            {t("join_at")}: ({humanizedDate(date)} | {humanizedTime(date)})
          </div>
        </div>
      </div>
      <div className={classes.topBar__rightSide}>
        {role !== "guest" && (
          <div
            className={clsx(classes.topBar__homeIcon)}
            onClick={() => {
              history.push(`${base}dashboard`);
              setMinimize(true);
            }}
          >
            <HomeBold />
          </div>
        )}
        <div className={clsx(classes.topBar__time)}>{duration}</div>
      </div>
    </div>
  );
};

export default TopBar;

import { getURLByRole } from "utils/mappers";
import ProfileBtn from "./profileBtn";
import { useRole } from "../../../utils/route";

export function ViewProfileByRole(props: any) {
  //   console.log("allRoles: ", props.row.original.all_roles);
  //   console.log("userId: ", props.row.original.id);
  const userId = props.row.original.id;
  const baseURL = "/panel/super/";
  const role = useRole();
  const roleURL = getURLByRole(role, userId, "view");
  const editURL = baseURL + roleURL;
  //   console.log("editURL: ", editURL);

  //? Gets the role of user then returns the profile

  return <ProfileBtn path={editURL} disabled={!roleURL} />;
}

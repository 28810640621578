import generateKey, { QGET, QGET_ALL } from "utils/keyGenerator";
import {
  DETAIL_TYPE,
  DROPDOWN_TYPE,
  LEVEL_TYPE,
  NOT_BUILD_TYPE,
  STATISTICS_TYPE,
  TYPE,
} from "utils/keyGenerator/keyTypes";

const entityName = "ROBOTS";

export const robotsKey = generateKey({
  name: entityName,
  method: QGET_ALL,
});
export const robotDetailKey = generateKey({
  name: entityName,
  method: QGET,
  type: [DETAIL_TYPE],
});

export const robotTypesKey = generateKey({
  name: entityName,
  method: QGET,
  type: [DROPDOWN_TYPE, TYPE],
});

export const robotLevelsKey = generateKey({
  name: entityName,
  method: QGET,
  type: [DROPDOWN_TYPE, LEVEL_TYPE],
});

export const notBuildedRobotsDropdownKey = generateKey({
  name: entityName,
  method: QGET,
  type: [NOT_BUILD_TYPE, DROPDOWN_TYPE],
});

export const robotStatisticsKey = generateKey({
  name: entityName,
  method: QGET,
  type: [STATISTICS_TYPE],
});

import { useRobotTypesOptions } from "../../hooks/useRobotTypesOptions";
import { useHFTypesOptions } from "../../hooks/useHFTypesOptions";
import { useDeviceTypesOptions } from "../../hooks/useDeviceTypesOptions";
import { useVisitTypeOptions } from "../../hooks/dropdownOptions/useVisitTypeOptions";
import useRobotLevelOptions from "../../hooks/dropdownOptions/useRobotLevelOptions";
import useRobotLocationOptions from "../../hooks/dropdownOptions/useRobotLocationOptions";
import { IFilter } from "components/core/filter";
import { useTranslation } from "react-i18next";
import { IMenuOption } from "../../components/core/autoComplete";
import { useQuery } from "react-query";
import { getLanguage } from "../../api/dropdown";
import { useDropdownsOptions } from "../../hooks/useOptions";

export const useOptions = () => {
  const { t } = useTranslation("global");

  const APPOINTMENT_TYPES = [
    { key: "local", value: t("appointment_types.local") },
    // { key: "international", value: t("appointment_types.international") },
  ];

  const RELATION_TYPES = [
    { key: "child", value: t("relation_types.child") },
    { key: "others", value: t("relation_types.others") },
  ];

  const ASSIGNED_TYPES = [
    { key: "assigned", value: t("assigned_types.assigned") },
    { key: "unassigned", value: t("assigned_types.unassigned") },
  ];

  const FULL_RELATION_TYPES = [
    { key: "grand_father", value: t("full_relation_types.grand_father") },
    { key: "grand_mother", value: t("full_relation_types.grand_mother") },
    { key: "father", value: t("full_relation_types.father") },
    { key: "mother", value: t("full_relation_types.mother") },
    { key: "brother", value: t("full_relation_types.brother") },
    { key: "sister", value: t("full_relation_types.sister") },
    { key: "others", value: t("full_relation_types.others") },
  ];

  const DOCTOR_TYPES = [
    { key: "local", value: t("doctor_types.local") },
    // { key: "international", value: t("doctor_types.international") },
  ];

  const APPOINTMENT_STATUS = [
    { key: "pending", value: t("appointment_statuses.pending") },
    { key: "checking_in", value: t("appointment_statuses.checking_in") },
    {
      key: "patient_is_ready",
      value: t("appointment_statuses.patient_is_ready"),
    },
    { key: "in_session", value: t("appointment_statuses.in_session") },
    {
      key: "pending_summary_note",
      value: t("appointment_statuses.pending_summary_note"),
    },
    { key: "completed", value: t("appointment_statuses.completed") },
    { key: "canceled", value: t("appointment_statuses.canceled") },
  ];

  const GENDER = [
    { key: "male", value: t("genders.male") },
    { key: "female", value: t("genders.female") },
    { key: "others", value: t("genders.others") },
  ];

  const ROLES = [
    { key: "doctor", value: t("roles.doctor") },
    { key: "staff", value: t("roles.staff") },
    { key: "local-admin", value: t("roles.local-admin") },
    { key: "super-admin", value: t("roles.super-admin") },
    { key: "patient", value: t("roles.patient") },
    { key: "cmo", value: t("roles.cmo") },
  ];

  const LANGUAGE_FLUENCY_OPTIONS: IMenuOption[] = [
    { key: "beginner", value: t("language_fluency.beginner") },
    { key: "intermediate", value: t("language_fluency.intermediate") },
    { key: "advanced", value: t("language_fluency.advanced") },
  ];

  const STATUS: IMenuOption[] = [
    {
      key: "active",
      value: t("status.active"),
    },
    {
      key: "pending",
      value: t("status.pending"),
    },
    {
      key: "denied",
      value: t("status.denied"),
    },
  ];
  const AVAILABIILITY_TYPES: IMenuOption[] = [
    { key: "scheduled", value: t("availability_types.scheduled") },
    { key: "ondemand", value: t("availability_types.ondemand") },
  ];

  const TRANSACTIONS_TYPES: IMenuOption[] = [
    { key: "withdraw", value: t("transaction_types.withdraw") },
    { key: "deposit", value: t("transaction_types.deposit") },
  ];

  const NOSHOW_TYPES: IMenuOption[] = [
    { key: "doctor-no-show", value: t("no_show_types.doctor-no-show") },
    { key: "patient-no-show", value: t("no_show_types.patient-no-show") },
    {
      key: "system-caused-no-show",
      value: t("no_show_types.system-caused-no-show"),
    },
  ];

  const REPEAT_PATTERNS: IMenuOption[] = [
    { key: "daily", value: t("repeat_patterns.daily") },
    { key: "weekly", value: t("repeat_patterns.weekly") },
    { key: "monthly", value: t("repeat_patterns.monthly") },
  ];

  return {
    APPOINTMENT_TYPES,
    RELATION_TYPES,
    ASSIGNED_TYPES,
    FULL_RELATION_TYPES,
    DOCTOR_TYPES,
    APPOINTMENT_STATUS,
    GENDER,
    ROLES,
    LANGUAGE_FLUENCY_OPTIONS,
    STATUS,
    AVAILABIILITY_TYPES,
    TRANSACTIONS_TYPES,
    NOSHOW_TYPES,
    REPEAT_PATTERNS,
  };
};

export const useLanguagesOption = () => {
  const { data: langData, ...rest } = useQuery("getLanguages", getLanguage, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const OPTIONS = useDropdownsOptions({
    data: langData && {
      ...langData,
      data: {
        ...langData.data,
        data: langData?.data.data?.map((item) => ({
          id: item.id.code,
          name: item.name.name,
        })),
      },
    },
  });

  return { OPTIONS: OPTIONS || [], ...rest } || [];
};

export const useAppointmentTypesOption = () => {
  const { t } = useTranslation("filter");

  const { APPOINTMENT_TYPES } = useOptions();

  return {
    title: t("titles.appointment_type"),
    filters: APPOINTMENT_TYPES,
  };
};

export const useAppointmentStatusOption = () => {
  const { t } = useTranslation("filter");

  const { APPOINTMENT_STATUS } = useOptions();
  return {
    title: t("titles.status"),
    filters: APPOINTMENT_STATUS,
  };
};

export const useMyDoctorFilterOptions = () => {
  const { t } = useTranslation("filter");

  return {
    title: t("titles.patient_list"),
    filters: [
      { key: "my_doctors", value: t("items.my_patients") },
      { key: "all_doctors", value: t("items.all_patients") },
    ],
  };
};

export const useAssignStatusFilterOption = () => {
  const { t } = useTranslation("filter");

  const { ASSIGNED_TYPES } = useOptions();

  return {
    title: t("titles.status"),
    filters: ASSIGNED_TYPES,
  };
};

export const useRobotTypeFilterOption = () => {
  const { t } = useTranslation("filter");

  const { OPTIONS } = useRobotTypesOptions();

  return {
    title: t("titles.robot_type"),
    filters: OPTIONS ? OPTIONS : [],
  };
};

export const useRobotLocationFilterOption = () => {
  const { t } = useTranslation("filter");

  const { OPTIONS } = useRobotLocationOptions();

  return {
    title: t("titles.robot_location"),
    filters: OPTIONS ? OPTIONS : [],
  };
};

export const useHFTypeFilterOption = () => {
  const { OPTIONS } = useHFTypesOptions();
  const { t } = useTranslation("filter");

  return {
    title: t("titles.hf_type"),
    filters: OPTIONS ? OPTIONS : [],
  };
};

export const useUserStatusOption = () => {
  const { t } = useTranslation("filter");

  const { STATUS } = useOptions();

  return {
    title: t("titles.status"),
    filters: STATUS,
  };
};

export const useGenderFilterOption = () => {
  const { t } = useTranslation("filter");

  const { GENDER } = useOptions();

  return {
    title: t("titles.gender"),
    filters: GENDER,
  };
};

export const useDoctorTypesFilterOption = () => {
  const { t } = useTranslation("filter");

  const { DOCTOR_TYPES } = useOptions();

  return {
    title: t("titles.doctor_type"),
    filters: DOCTOR_TYPES,
  };
};

/*export const useFacilityParentFilterOption = ()=>{
  const { OPTIONS } = useFacilityParentsOptions();

  return {
    title: "HF Type",
    filters: OPTIONS ? OPTIONS : [],
  };
}*/

export const useDeviceTypeFilterOption = () => {
  const { t } = useTranslation("filter");

  const { OPTIONS } = useDeviceTypesOptions();

  return {
    title: t("titles.medical_device"),
    filters: OPTIONS ? OPTIONS?.filter((item) => item.key !== "0") : [],
  };
};

export const useRolesFilterOption = (key?: string[]) => {
  const { t } = useTranslation("filter");

  const { ROLES } = useOptions();

  if (key) {
    return {
      title: t("titles.role"),
      filters: ROLES?.filter((r) => key?.includes(r?.key)),
    };
  }

  return {
    title: t("titles.role"),
    filters: ROLES,
  };
};

export const useTimespanFilterOption = (): IFilter => {
  const { t } = useTranslation("filter");

  return {
    title: t("titles.timespan"),
    filters: [
      {
        key: "custom",
        value: t("items.custom"),
        variant: "timespan",
      },
    ],
  };
};

export const useRelationshipFilterOption = () => {
  const { t } = useTranslation("filter");

  const { RELATION_TYPES } = useOptions();

  return {
    title: t("titles.relationships"),
    filters: RELATION_TYPES || [],
  };
};

export const useFollowupFilterStatus = () => {
  const { t } = useTranslation("filter");

  return {
    title: t("titles.status"),
    filters: [
      { key: "true", value: t("items.complete") },
      { key: "false", value: t("items.incomplete") },
    ],
  };
};

export const useAllRelationshipFilterOption = () => {
  const { t } = useTranslation("filter");

  const { RELATION_TYPES } = useOptions();

  return {
    title: t("titles.relationships"),
    filters: [...RELATION_TYPES, { key: "my_self", value: t("my_self") }],
  };
};

export const useTransactionTypesFilterOption = () => {
  const { t } = useTranslation("filter");

  const { TRANSACTIONS_TYPES } = useOptions();

  return {
    title: t("titles.transaction"),
    filters: TRANSACTIONS_TYPES,
  };
};

export const useVisitTypeFilterOptions = () => {
  const { t } = useTranslation("filter");

  const { OPTIONS } = useVisitTypeOptions();

  return {
    title: t("titles.visit_type"),
    filters: OPTIONS || [],
  };
};

export const useRobotLevelFilterOption = () => {
  const { t } = useTranslation("filter");

  const { OPTIONS: ROBOT_LEVEL_OPTIONS } = useRobotLevelOptions();

  return {
    title: t("titles.robot_level"),
    filters: ROBOT_LEVEL_OPTIONS ? ROBOT_LEVEL_OPTIONS : [],
  };
};

export const useNoShowFilterOption = () => {
  const { t } = useTranslation("filter");
  const { NOSHOW_TYPES } = useOptions();

  return {
    title: t("titles.no_show"),
    filters: NOSHOW_TYPES,
  };
};

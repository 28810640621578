import apiCaller, { IResponse } from "api";
import { IPaginationTableList } from "./types/paginationType";
import { IUserRole } from "./types/userTypes";
import { IDropdownResponse } from "./dropdown";

export interface INotificationResponse {
  id: number;
  created_at: string;
  updated_at: string;
  subject: string;
  content: string;
  from: {
    name: string;
    role: IUserRole;
    avatar?: string;
  };
  to: string;
  is_read: number;
}

interface ISendNotificationData {
  receiver_id: number;
  subject: string;
  content: string;
}
interface ISendNotificationByRoleData {
  sender_role: string;
  role: string;
  subject: string;
  content: string;
  facility_id?: number;
  department_id?: number;
}

interface IGetNotificationsParams {
  page: number;
  per_page: number;
  index_type?: string[];
  order_by?: string;
  recent?: string[];
  filter?: string;
}

export function getNotifications(params: IGetNotificationsParams): Promise<
  // params: IGetNotificationsParams
  IResponse<IPaginationTableList<INotificationResponse>>
> {
  return apiCaller.get("notifications", { params });
}

export function getNotificationById(
  id: string
): Promise<IResponse<INotificationResponse>> {
  return apiCaller.get(`notifications/${id}`);
}

export function sendNotification(
  data: ISendNotificationData
): Promise<IResponse<INotificationResponse>> {
  return apiCaller.post("notifications", data);
}

export function sendNotificationByRole(
  data: ISendNotificationByRoleData
): Promise<IResponse<ISendNotificationByRoleData>> {
  return apiCaller.post("notifications/role", data);
}

export function getNotifiableFacilities(): Promise<
  IResponse<IDropdownResponse[]>
> {
  return apiCaller.get("/dropdown/notifiable-facilities");
}

export function getNotifiableDepartments(
  facility_id: string
): Promise<IResponse<IDropdownResponse[]>> {
  return apiCaller.get("/dropdown/notifiable-departments", {
    params: { facility_id },
  });
}
export function getUnreadCounts(): Promise<
  IResponse<{ messages_count: number; notifications_count: number }>
> {
  return apiCaller.get("/unread-counts");
}

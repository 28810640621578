import apiCaller, { IResponse } from "api";
import { CancelToken } from "axios";

export function uploadFile(
  data: FormData,
  progressCallBack?: (progressEvent: ProgressEvent) => void,
  cancelToken?: CancelToken
): Promise<IResponse<any>> {
  return apiCaller.post("files", data, {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressCallBack && progressCallBack(progressEvent);
    },
    cancelToken: cancelToken,
  });
}

export function uploadFileGroup(
  data: { files: any },
  progressCallBack?: (progressEvent: ProgressEvent) => void,
  cancelToken?: CancelToken
): Promise<IResponse<any>> {
  return apiCaller.post("files/group", data, {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressCallBack && progressCallBack(progressEvent);
    },
    cancelToken: cancelToken,
    maxBodyLength: Infinity,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export function showFile(fileId: string): any {
  return apiCaller
    .get(`files/${fileId}`, { responseType: "blob" })
    .then((res) => {
      const blobFile = new Blob([res.data], { type: res?.data?.type });
      if (String(res?.data?.type)?.includes("pdf")) {
        return URL.createObjectURL(blobFile) + ".pdf";
      }
      return URL.createObjectURL(blobFile);
    });
}

export async function isPdf(url: string) {
  console.log(url);
  const blob = await fetch(url).then((response) => response.blob());
  console.log("blob", blob);
  const blobType = blob.type;
  return blobType === "application/pdf";
}

export function downloadFile(fileId: string) {
  return apiCaller.get(`files/${fileId}`, {
    responseType: "arraybuffer",
    headers: {
      // Accept: "text/csv",
    },
  });
}

import LoginLayout from "../../../../../pages/login/components/loginLayout";
import { FastInput } from "../../../../formikFields";
import { useFormik } from "formik";
import yup, { requiredObj } from "../../../../../utils/yupExtended";
import { UserBold } from "../../../../icon";
import Button from "../../../../core/button";
import VideoVisit from "../../index";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Define the shape of the form parameter
export interface IJoinGuestFormParam {
  name: string;
}
// Define the component
const GuestJoinPage = () => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  // Define state for the video visit modal
  const [open, setOpen] = useState(false);
  // Define formik for form management
  const formik = useFormik<IJoinGuestFormParam>({
    // Define initial values
    initialValues: {
      name: "",
    },
    // Define onSubmit function
    onSubmit: () => {
      setOpen(true);
    },
    // Define validation schema
    validationSchema: yup.object().shape({
      name: yup.string().required(requiredObj("name")),
    }),
  });
  // Render the component
  return (
    <>
      {/* Render the login layout */}
      <LoginLayout
        form_title={t("guest_join")}
        form_description={t("enter_guest_text")}
      >
        {/* Render the form */}
        <form
          className={"d-flex gap-5 flex-column"}
          onSubmit={formik.handleSubmit}
        >
          {/* Render the fast input field */}
          <FastInput
            formik={formik}
            fieldName={"name"}
            placeholder={t("name")}
            icon={UserBold}
          />
          {/* Render the join button */}
          <Button className={"w-100"} type={"submit"}>
            {t("join")}
          </Button>
        </form>
      </LoginLayout>
      {/* Render the video visit modal */}
      <VideoVisit
        date={new Date()}
        appt_id={""}
        open={open}
        setOpen={setOpen}
        minimize={false}
        setMinimize={() => {}}
        name={formik.values.name}
        role={"guest"}
      />
    </>
  );
};
// Export the component
export default GuestJoinPage;

export const QGET = "GET";
export const QPUT = "PUT";
export const QPOST = "POST";
export const QDEL = "DEL";
export const QGET_ALL = "GET_ALL";

export type HttpMethod =
  | typeof QGET
  | typeof QPUT
  | typeof QPOST
  | typeof QDEL
  | typeof QGET_ALL;

export interface IGenerateKey {
  name: string;
  method: HttpMethod;
  type?: string[];
  data?: { [key: string]: string };
}
const generateKey = ({ method, name, data, type }: IGenerateKey) => {
  if (data) {
    return `key-${name}--${method}${
      type ? `--${convertType(type)}` : ""
    }--${btoa(JSON.stringify(data))}`;
  }
  return `key-${name}--${method}${type ? `--${convertType(type)}` : ""}`;
};

const convertType = (type: string[]) => {
  return `[${type.sort().join()}]`;
};

export default generateKey;

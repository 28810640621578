import { CalendarBold, MailTrackingBold, UserBold } from "components/icon";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { useHistory } from "react-router";
import { IMenuOption, IPhoneNumber } from "interfaces";
import { IRegistrationStepProps } from "../../doctor/components/registrationLayout";
import Grid from "components/core/Grid";
import { useTranslation } from "react-i18next";
import {
  FastDatePicker,
  FastInput,
  FastPhoneInput,
} from "components/formikFields";
import { Gender } from "components/dropdown";
import { formSubmission } from "utils/form";

export interface IRegisterForm {
  firstName: string;
  lastName: string;
  userName: string;
  // password: string;
  birthday: Date | string;
  gender: IMenuOption;
  countryOfLicense: IMenuOption;
  licenseNo: string;
  speciality: IMenuOption;
  subSpecialty: IMenuOption;
  title: IMenuOption;
  country: IMenuOption;
  state: IMenuOption;
  city: IMenuOption;
  hfCountry: IMenuOption;
  hfState: IMenuOption;
  hfCity: IMenuOption;
  hfType: IMenuOption;
  hfName: IMenuOption;
  department?: IMenuOption;
  docType: IMenuOption;
  zipCode: string;
  email: string;
  mobile: IPhoneNumber;
  alternativePhoneNumber: IPhoneNumber;
  timezone: IMenuOption;
  address: string;
  languages: { language: ""; language_fluency: "" }[];
  employeeNumber: string;
  NPINumber: string;
  nationalId: string;
  mrnNumber: string;
  cmoNumber: string;
}

export const REGISTER_INITIAL_VALUES: IRegisterForm = {
  firstName: "",
  lastName: "",
  userName: "",
  // password: "",
  birthday: "",
  gender: { key: "", value: "" },
  countryOfLicense: { key: "", value: "" },
  licenseNo: "",
  speciality: { key: "", value: "" },
  subSpecialty: { key: "", value: "" },
  title: { key: "", value: "" },
  country: { key: "", value: "" },
  state: { key: "", value: "" },
  city: { key: "", value: "" },
  hfCountry: { key: "", value: "" },
  hfState: { key: "", value: "" },
  hfCity: { key: "", value: "" },
  hfType: { key: "", value: "" },
  hfName: { key: "", value: "" },
  department: { key: "", value: "" },
  docType: { key: "", value: "" },
  zipCode: "",
  email: "",
  mobile: { code: "", number: "" },
  alternativePhoneNumber: { code: "", number: "" },
  timezone: { key: "", value: "" },
  address: "",
  languages: [],
  employeeNumber: "",
  NPINumber: "",
  nationalId: "",
  mrnNumber: "",
  cmoNumber: "",
};

const RegistrationFirstPage = ({
  formik,
  onSetAlert,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");

  const history = useHistory();
  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        formSubmission(e, formik);
      }}
    >
      <Grid>
        <FastInput
          formik={formik}
          fieldName="firstName"
          label={t("first_name")}
          icon={UserBold}
        />
        <FastInput
          formik={formik}
          fieldName="lastName"
          label={t("last_name")}
          icon={UserBold}
        />
        <Gender
          formik={formik}
          fieldName="gender"
          id={formik.values.gender.key}
        />
        <FastDatePicker
          formik={formik}
          fieldName="birthday"
          label={t("birth")}
          icon={CalendarBold}
        />
        <FastInput
          formik={formik}
          fieldName={"employeeNumber"}
          icon={UserBold}
          label={t("employee_number")}
        />

        <FastPhoneInput
          formik={formik}
          fieldName={"mobile"}
          label={t("mobile")}
        />
        <FastPhoneInput
          formik={formik}
          fieldName={"alternativePhoneNumber"}
          label={t("phone")}
        />
        <FastInput
          formik={formik}
          fieldName={"email"}
          icon={MailTrackingBold}
          label={t("email")}
        />

        <div className={classes.buttonContainer}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => history.push("/login")}
          >
            {t("cancel")}
          </Button>
          <Button type="submit">{t("continue")}</Button>
        </div>
      </Grid>
    </form>
  );
};

export default RegistrationFirstPage;

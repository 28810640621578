import { EmojiHappyBold } from "components/icon";
import { getDropdownKey } from "utils/dropdowns";
import { FastDropdown } from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import { useNotBuiltRobotsDropdown } from "features/registeredRobots";
import { useTranslation } from "react-i18next";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the `Robot level` that this `Robot` is dependent on it.
   *
   * without knowing the `Robot level` we can not fetch the `robot names`.
   */
  robotLevelFieldName: keyof T & string;

  /**
   * The field name of the `Robot Type` that this `Robot Name` is dependent on it.
   *
   * without knowing the `Robot Type` we can not fetch the states of that `robot names`.
   */
  robotTypeFieldName: keyof T & string;
}

export function NotBuildedRobots<T>({
  formik,
  robotLevelFieldName,
  robotTypeFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the country value from formik values and extract its key
  const robotLevelKey = getDropdownKey(formik, robotLevelFieldName);
  const robotTypeKey = getDropdownKey(formik, robotTypeFieldName);

  // fetch data
  const { data, isLoading, isError } = useNotBuiltRobotsDropdown(
    robotLevelKey,
    robotTypeKey
  );

  return (
    <FastDropdown
      label={t("robot_name")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!robotLevelKey || !robotTypeKey || disabled}
      icon={EmojiHappyBold}
      {...props}
    />
  );
}

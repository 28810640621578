import { E_DEPARTMENT, E_FACILITY } from "utils/entities";
import generateKey, { QGET, QGET_ALL } from "utils/keyGenerator";
import {
  DROPDOWN_TYPE,
  LEVEL_TYPE,
  LIST,
  NOT_ASSIGNED,
  STATISTICS_TYPE,
  TYPE,
} from "utils/keyGenerator/keyTypes";

const entityName = "FACILITY_ROBOTS";

export const facilityRobotsByTypeAndLevelKey = generateKey({
  name: entityName,
  method: QGET,
  type: [TYPE, DROPDOWN_TYPE, LEVEL_TYPE],
});

export const notAssignedFacilityRobotsKey = generateKey({
  name: entityName,
  method: QGET,
  type: [TYPE, DROPDOWN_TYPE, LEVEL_TYPE, NOT_ASSIGNED],
});

export const facilityRobotsKey = generateKey({
  name: entityName,
  method: QGET,
  type: [TYPE, E_FACILITY],
});

export const departmentRobotsKey = generateKey({
  name: entityName,
  method: QGET,
  type: [TYPE, E_DEPARTMENT],
});

export const facilityRobotsStatisticsKey = generateKey({
  name: entityName,
  method: QGET,
  type: [STATISTICS_TYPE],
});

export const facilityRobotsListKey = generateKey({
  name: entityName,
  method: QGET_ALL,
  type: [LIST],
});

import {HospitalBold} from "components/icon";
import {getDropdownKey} from "utils/dropdowns";
import {FastDropdown} from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import {useCityFacilitiesByTypeDropdown} from "features/healthcareFacility";
import {useTranslation} from "react-i18next";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the `city` that this `HF Name` is dependent on it.
   *
   * without knowing the `city` we can not fetch the states of that `city`, so the `HF Name` depends on the `city`.
   */
  cityFieldName: keyof T & string;

  /**
   * The field name of the `HF Type` that this `HF Name` is dependent on it.
   *
   * without knowing the `HF Type` we can not fetch the states of that `HF Type`, so the `HF Name` depends on the `HF Type`.
   */
  hfTypeFieldName: keyof T & string;
}

export function HFName<T>({
  formik,
  cityFieldName,
  hfTypeFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the country value from formik values and extract its key
  const cityId = getDropdownKey(formik, cityFieldName);
  const hfTypeKey = getDropdownKey(formik, hfTypeFieldName);

  // fetch data
  const { data, isLoading, isError } = useCityFacilitiesByTypeDropdown(
    cityId,
    hfTypeKey
  );

  return (
    <FastDropdown
      label={t("hf_name")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!cityId || !hfTypeKey || disabled}
      icon={HospitalBold}
      {...props}
    />
  );
}

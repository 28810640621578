export const STATISTICS_TYPE = "statistics";
export const DASHBOARD_TYPE = "dashboard";
export const MUTATION_TYPE = "mutation";
export const TYPE = "type";
export const DROPDOWN_TYPE = "dropdown";
export const DETAIL_TYPE = "detail";
export const CREATED_TYPE = "created";
export const BUILD_TYPE = "built";
export const NOT_BUILD_TYPE = "not_built";
export const DEVICE_TYPE = "device_type";
export const LEVEL_TYPE = "level";
export const PARENT_TYPE = "parent";
export const ASSIGNED = "assigned";
export const NOT_ASSIGNED = "not_assigned";
export const LIST = "list";
export const ASSIGNED_TYPE = "assigned";
export const NOT_ASSIGNED_TYPE = "not_assigned";
export const SPECIALITY_TYPE = "speciality";
export const STATUS_TYPE = "status";
export const SUB_TYPE = "sub";
export const VISIT_TYPE = "visit";
export const LOCATION_TYPE = "location";
export const ACCOUNTING__TYPE = "accounting";
export const REPORT_TYPE = "report";
export const WALLET_TYPE = "wallet";
export const PROMOTION_TYPE = "promotion";
export const TEMP_TYPE = "temp";
export const MEDICAL_TYPE = "medical";

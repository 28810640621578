import { downloadFile } from "api/files";
import { convertToAPIFullDateFreeze } from "utils/date";
import classes from "../styles.module.scss";
import { PaperClipLinear } from "components/icon";

interface IAttachmentListProps {
  attachments: string[];
}

const AttachmentList = ({ attachments }: IAttachmentListProps) => {
  const handleDownloadFile = (id: string) => {
    downloadFile(id).then((response) => {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Attachment_${convertToAPIFullDateFreeze(new Date())}`;
      link.click();
    });
  };

  return (
    <div className={classes.attachment}>
      <div className={classes.attachment__container}>
        {attachments.map((att) => (
          <span
            className={classes.attachment__file}
            onClick={() => handleDownloadFile(att)}
          >
            <PaperClipLinear />
            {att}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AttachmentList;

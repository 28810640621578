import Button from "../button";
import Modal from "../modal";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { alertModalNaxWidth, INotificationAlertProps } from "./types";
import { useCallback } from "react";

const NotificationAlert = ({
  contnet,
  onApprove,
  onCancel,
  noCancel,
  approveTitle,
  modalMaxWidth = alertModalNaxWidth,
  open,
  onClose,
  ...props
}: INotificationAlertProps) => {
  const { t } = useTranslation("form");
  // const handleApprove = () => {
  //   if (onClose) onClose();
  //   if (!!onApprove) onApprove();
  // };
  const handleApprove = useCallback(() => {
    if (onClose) onClose();
    if (onApprove) {
      setTimeout(() => onApprove(), 200);
    }
  }, [onApprove, onClose]);

  return (
    <div>
      <Modal
        {...props}
        onClose={onClose}
        open={open}
        maxWidth={modalMaxWidth}
        notScroll
      >
        <p className={classes.alertText}>{contnet}</p>
        <div className="d-flex justify-content-end">
          {!noCancel && (
            <Button
              variant="outlined"
              color="secondary"
              className="ME-2"
              onClick={!!onCancel ? onCancel : onClose}
            >
              {t("cancel")}
            </Button>
          )}
          <Button color="secondary" onClick={handleApprove}>
            {approveTitle || t("approve")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default NotificationAlert;

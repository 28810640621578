import Button from "components/core/button";
import { useHistory } from "react-router-dom";
import classes from "./styles.module.scss";
import { ServerErrorIcon } from "../../components/icon/serverError";
import { useAuth } from "../../hooks/useAuth";

function ServerError() {
  const history = useHistory();
  const { roles, isLoggedIn } = useAuth();
  const handleRedirect = () => {
    if (isLoggedIn && roles) {
      history.push(`/panel/${roles[0].role}/`);
    } else history.push("/login");
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.pageContent}>
        <ServerErrorIcon />
        <Button className={classes.btnStyle} onClick={handleRedirect}>
          {isLoggedIn && roles ? "Back to Home" : "Back"}
        </Button>
      </div>
    </div>
  );
}

export default ServerError;

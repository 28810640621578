import React, { useContext, useEffect, useState } from "react";
import LANGUAGES from "../constants/languages";
import { useTranslation } from "react-i18next";
import { setAxiosLanguage } from "../api";

export interface IUserLanguage {
  langCode: string;
  langName: string;
  dir: "rtl" | "ltr";
}

const LanguageContext = React.createContext<{
  language: IUserLanguage;
  changeLang: (lang: IUserLanguage) => void;
}>({
  language: {
    dir: "ltr",
    langCode: "en",
    langName: "English",
  },
  changeLang: () => {},
});

export const findLanguage = () => {
  return (
    LANGUAGES.find((l) =>
      window?.navigator?.language?.includes(l.langCode)
    ) || {
      dir: "ltr",
      langCode: "en",
      langName: "English",
    }
  );
};

export const LanguageProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation("global");
  const [language, setLanguage] = useState<IUserLanguage>({
    dir: "ltr",
    langCode: "en",
    langName: "English",
  });
  const setLang = async (lang: IUserLanguage) => {
    localStorage.setItem("lang", JSON.stringify(lang));
    setLanguage(lang);
    await i18n.changeLanguage(lang?.langCode);
  };
  const loadLanguage = async () => {
    const langJson = localStorage.getItem("lang");
    const lang: IUserLanguage = langJson
      ? JSON.parse(langJson)
      : findLanguage();
    setAxiosLanguage(lang?.langCode);
    await setLang(lang);
  };

  useEffect(() => {
    loadLanguage();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        language: language,
        changeLang: (lang: IUserLanguage) => setLang(lang),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};

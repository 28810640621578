import { useQuery } from "react-query";
import {
  createRobot,
  getNotBuiltRobotsDropdown,
  getRobotDetail,
  getRobotLevelsDropdown,
  getRobotsList,
  getRobotsStatistics,
  getRobotTypesDropdown,
  updateRobot,
} from "./api";
import { convertToString } from "utils/form";
import { IRobotData, IRobotsListParams } from "./type";
import useFormMutation from "hooks/useFormMutation";
import { useDropdownsOptions } from "hooks/useOptions";
import { IPagination } from "hooks/usePagination";
import useTableQuery from "hooks/useTableQuery";
import {
  notBuildedRobotsDropdownKey,
  robotDetailKey,
  robotLevelsKey,
  robotsKey,
  robotStatisticsKey,
  robotTypesKey,
} from "./keys";

export const useNotBuiltRobotsDropdown = (
  robotLevelKey: string | undefined,
  robotTypeKey: string | undefined
) => {
  const queryResult = useQuery(
    //* we have to clear the fetched data after updating or building or deleting a robot successfully.
    [notBuildedRobotsDropdownKey, robotLevelKey, robotTypeKey],
    () =>
      getNotBuiltRobotsDropdown({
        level: convertToString(robotLevelKey),
        type: convertToString(robotTypeKey),
      }),
    {
      enabled: !!robotLevelKey && !!robotTypeKey,
    }
  );
  return queryResult;
};

export const useCreateRobot = () => {
  const mutationResult = useFormMutation(createRobot, [
    [notBuildedRobotsDropdownKey],
  ]);
  return mutationResult;
};

export const useUpdateRobot = (id: number) => {
  const mutationResult = useFormMutation(
    (data: IRobotData) => updateRobot(data, id),
    [[notBuildedRobotsDropdownKey], [robotDetailKey]]
  );
  return mutationResult;
};

export const useRobotDetail = (id: string | undefined) => {
  const queryResult = useQuery(
    [robotDetailKey, id],
    //* we have to clear the fetched data after updating or deleting successfully.
    () => getRobotDetail(Number(id)),
    {
      enabled: !!id,
    }
  );
  return queryResult;
};

export const useRobotLevelDropdown = () => {
  const queryResult = useQuery(robotLevelsKey, getRobotLevelsDropdown);
  return queryResult;
};

export const useRobotLevelOptions = () => {
  const { data, ...rest } = useRobotLevelDropdown();
  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
};

export const useRobotTypeDropdown = () => {
  const queryResult = useQuery(robotTypesKey, getRobotTypesDropdown);
  return queryResult;
};

export const useRobotTypeOptions = () => {
  const { data, ...rest } = useRobotTypeDropdown();
  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
};

export const useGetRobotsStatistics = () => {
  const queryResult = useQuery(robotStatisticsKey, getRobotsStatistics);
  return queryResult;
};

export function useGetRobotsList(
  params: IRobotsListParams,
  pagination: IPagination,
  maxPageUpdater: (maxPage: number) => void
) {
  const queryResult = useTableQuery(
    getRobotsList,
    robotsKey,
    params,
    pagination,
    maxPageUpdater
  );
  return queryResult;
}

export const useDeleteRobot = () => {
  throw new Error("useDeleteRobot is not implemented yet.");
};

import { IField } from "./IField";
import FastField from "./field";
import DatePicker, { IDatePickerProps } from "components/core/datePicker";

type inputProps = Omit<
  IDatePickerProps,
  "onDateSelect" | "value" | "name" | "onChange"
>;
interface IProps<T> extends IField<T, Date | null>, inputProps {}

export function FastDatePicker<T>({
  formik,
  fieldName,
  onChange,
  onChangeAlongside,
  ...inputProps
}: IProps<T>) {
  return (
    <FastField
      onChangeValueMapper={(value: Date | null) => value}
      validateOnValueChanged
      {...{
        formik,
        fieldName,
        onChange,
        onChangeAlongside,
        ...inputProps,
      }}
    >
      {(props) => (
        <DatePicker
          {...inputProps}
          placeholder={inputProps.placeholder || inputProps.label || ""}
          name={fieldName}
          value={props.value}
          onDateSelect={props.onChange}
          onBlur={props.validateOnBlur}
        />
      )}
    </FastField>
  );
}

import { getStates } from "api/dropdown";
import FormAutoComplete from "components/form/formAutoComplete";
import { GPSBold } from "components/icon";
import { useQuery } from "react-query";
import { getFormikFieldProps } from "utils/form";
import { IDefaultProps as IAutoCompleteProps } from "components/core/autoComplete";
import { FormikProps } from "formik";
import { useDropdownDefaultValue } from "hooks/useDropdownDefaultValue";
import { useDropdownsOptions } from "hooks/useOptions";
import { useEffect } from "react";
import { statesKey } from "../../../utils/keyGenerator/keys";
import { useTranslation } from "react-i18next";

export interface IStateProps<T> {
  formik: FormikProps<T>;
  stateFieldName: keyof T & string;
  cityFieldName: keyof T & string;
  countryFieldName: keyof T & string;
  rootProps?: Partial<IAutoCompleteProps>;
  stateId?: string;
  stateName?: string;
  disabled?: boolean;
  noPadding?: boolean;
  label?: string;
}

//? Gets the country and shows states in dropdown

export function State<T extends { [key: string]: any }>({
  formik,
  cityFieldName,
  stateFieldName,
  countryFieldName,
  rootProps,
  stateId,
  stateName,
  disabled,
  noPadding,
  label,
}: IStateProps<T>) {
  const { t } = useTranslation("form");

  const { data, isLoading, isError } = useQuery(
    [statesKey, formik.values[countryFieldName]?.key],
    () => getStates(Number(formik.values[countryFieldName].key)),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!formik.values[countryFieldName]?.key,
    }
  );
  const OPTIONS = useDropdownsOptions({ data: data });
  useDropdownDefaultValue({
    fieldName: stateFieldName,
    formik: formik,
    id: stateId,
    OPTIONS: OPTIONS,
    name: stateName,
  });

  useEffect(() => {
    if (
      countryFieldName &&
      !!formik.values[countryFieldName] &&
      !formik.values[countryFieldName]?.key
    ) {
      formik.setFieldValue(cityFieldName, { key: "", value: "" });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityFieldName, countryFieldName]);

  const changeHandler = (value: any) => {
    formik.setFieldValue(stateFieldName, value);
    formik.setFieldValue(cityFieldName, { key: "", value: "" });
  };

  return (
    <FormAutoComplete
      noPadding={noPadding}
      rootProps={{
        icon: GPSBold,
        placeholder: t("state"),
        disabled:
          !formik.values[countryFieldName] ||
          !formik.values[countryFieldName].key ||
          isError ||
          isLoading ||
          disabled,
        isError,
        isLoading,
        ...rootProps,
      }}
      options={OPTIONS ? OPTIONS : []}
      {...getFormikFieldProps(
        stateFieldName,
        label ? label : t("state"),
        formik
      )}
      onChange={changeHandler}
    />
  );
}

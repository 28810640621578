import IDropdown from "../formikFields/IDropdown";
import {FastDropdown} from "../formikFields";
import {TeacherBold} from "components/icon";
import {DOCTOR_TITLE_OPTIONS} from "constants/autocompleteOptions";
import {useTranslation} from "react-i18next";

interface IProps<T> extends IDropdown<T> {}

export function Degree<T>(props: IProps<T>) {
  const { t } = useTranslation("form");

  return (
    <FastDropdown
      label={t("degree")}
      options={DOCTOR_TITLE_OPTIONS}
      isError={false}
      isLoading={false}
      icon={TeacherBold}
      {...props}
    />
  );
}

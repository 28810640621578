import Input from "components/core/input";
import { useEffect, useRef, useState } from "react";
import { ISearchInputProps } from "..";
import { useHistory } from "react-router";
import { getSearchParamName } from "utils/filterParams";

const TIME = 800;

const SearchInput = ({
  optimizedOnChange,
  placeholder,
  index,
  disable,
}: ISearchInputProps) => {
  const history = useHistory();
  const paramName = getSearchParamName(index);
  const filters_params = new URLSearchParams(history.location.search).get(
    paramName
  );
  const initialValue =
    filters_params !== null ? decodeURIComponent(filters_params) : "";

  // state for store the input value
  const [value, setValue] = useState(initialValue);

  // use ref to store the timer
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const filters_params = new URLSearchParams(history.location.search).get(
      paramName
    );
    if (filters_params !== null) {
      optimizedOnChange?.(decodeURIComponent(filters_params));
    }
    return () => {
      // Clear the interval when the component unmounts
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
    };
    //eslint-disable-next-line
  }, []);

  // timeout callback function
  const setKeyword = (value: string) => {
    // console.log("keyword set to:", value);

    const params = new URLSearchParams(history.location.search);
    if (value.trim() === "") {
      params.delete(paramName);
      history.replace({
        search: params.toString(),
        state: history.location.state,
      });
    } else {
      // params.delete(paramName);
      params.set(paramName, encodeURIComponent(value));
      history.replace({
        search: params.toString(),
        state: history.location.state,
      });
    }

    if (optimizedOnChange) optimizedOnChange(value);
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = null;
  };

  // input change handler
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    const time = e.target.value.trim() === "" ? 0 : TIME;

    if (timer.current === null) {
      // set the timeout
      timer.current = setTimeout(() => setKeyword(e.target.value.trim()), time);
    } else {
      // refresh the timer
      clearTimeout(timer.current);
      timer.current = setTimeout(() => setKeyword(e.target.value.trim()), time);
    }
  };
  return (
    <Input
      disabled={disable}
      type="search"
      name="filter-search"
      className="w-100 tableSearchInput"
      value={value}
      onChange={changeHandler}
      placeholder={placeholder}
    />
  );
};

export default SearchInput;

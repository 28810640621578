import { getDoctorTypes } from "api/dropdown";
import { IMenuOption } from "components/core/autoComplete";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { doctorTypesKey } from "../utils/keyGenerator/keys";

export function useDoctorTypesOptions() {
  const { data, ...rest } = useQuery(doctorTypesKey, getDoctorTypes);

  const OPTIONS: IMenuOption[] | undefined = useMemo(() => {
    return data?.data.data
      ?.map((item) => {
        return { key: item.id.toString(), value: String(item.name) };
      })
      .filter((opt) => opt.key.toLowerCase() !== "international"); // TEMPORARY
  }, [data]);
  return { OPTIONS: OPTIONS, ...rest };
}

import { useDropdownsOptions } from "../useOptions";
import { useQuery } from "react-query";
import { getVisitTypes } from "api/dropdown";
import { visitTypeKey } from "../../utils/keyGenerator/keys";

export function useVisitTypeOptions() {
  const { data, ...rest } = useQuery(visitTypeKey, getVisitTypes);

  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
}

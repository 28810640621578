import clsx from "clsx";
import classes from "../../styles.module.scss";
import { ArrowRightLinear } from "../../../../icon";

export interface ISideBarTopProps {
  title: string;
  text: string;
  onClick: () => void;
}

// Sidebar header for display on every sidebar page top
const SideBartTop = ({ title, text, onClick }: ISideBarTopProps) => {
  return (
    <div className={clsx(classes.side__sideTop)}>
      <div className={clsx(classes.side__sideLeft)}>
        <h1 className={clsx(classes.side__sideTitle)}>{title}</h1>
        <div className={clsx(classes.side__sideText)}>{text}</div>
      </div>
      <div className={clsx(classes.side__sideBtn)} onClick={() => onClick()}>
        <ArrowRightLinear />
      </div>
    </div>
  );
};

export default SideBartTop;

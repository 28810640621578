import { getCountryTimezones } from "api/dropdown"; // TODO
import FormAutoComplete from "components/form/formAutoComplete";
import { ClockBold } from "components/icon";
import { useQuery } from "react-query";
import { getFormikFieldProps } from "utils/form";
import { IDefaultProps as IAutoCompleteProps } from "components/core/autoComplete";
// import { useDropdownDefaultValue } from "hooks/useDropdownDefaultValue";
// import { getDropdownOption } from "utils/dropdowns";
// import { useEffect } from "react";
import { useDropdownDefaultValue } from "hooks/useDropdownDefaultValue";
import { useDropdownsOptions } from "hooks/useOptions";
import { countryTimezoneKey } from "../../../utils/keyGenerator/keys";
import { useTranslation } from "react-i18next";

export interface ITimezoneProps {
  formik: any; // TODO : fix this
  countryFieldName?: string;
  rootProps?: Partial<IAutoCompleteProps>;
  timezoneId?: string;
  timezoneName?: string;
  filedName: string;
  noPadding?: boolean;
  user_id: string | number | undefined;
}

//? gets the country and defines time zone

export function Timezone({
  countryFieldName = "",
  formik,
  rootProps,
  timezoneId,
  timezoneName,
  filedName,
  noPadding,
  user_id,
}: ITimezoneProps) {
  const { t } = useTranslation("form");

  const { data, isLoading, isError } = useQuery(
    [countryTimezoneKey, formik.values[countryFieldName]?.key, user_id],
    () => getCountryTimezones(formik.values[countryFieldName]?.key, user_id),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!formik.values[countryFieldName]?.key && !!countryFieldName,
    }
  );

  const OPTIONS = useDropdownsOptions({ data: data });
  // useEffect(() => {
  //   if (!!OPTIONS?.length && !!timezoneId) {
  //     formik.setFieldValue(
  //       filedName,
  //       getDropdownOption(OPTIONS, "", timezoneId)
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, timezoneId]);

  useDropdownDefaultValue({
    fieldName: filedName,
    formik: formik,
    id: timezoneId,
    OPTIONS: OPTIONS,
    name: timezoneName,
  });

  return (
    <FormAutoComplete
      noPadding={noPadding}
      rootProps={{
        icon: ClockBold,
        placeholder: t("timezone"),
        // disabled: isLoading || isError || !formik.values[countryFieldName].key,
        disabled:
          isLoading ||
          isError ||
          !formik.values[countryFieldName]?.key ||
          !countryFieldName,
        isError,
        isLoading,
        ...rootProps,
      }}
      options={OPTIONS ? OPTIONS : []}
      {...getFormikFieldProps(filedName, t("timezone"), formik)}
    />
  );
}

Timezone.defaultProps = {
  filedName: "timezone",
};

import clsx from "clsx";
import classes from "./styles.module.scss";

// StatusCard props type interface
export interface IStatusCardProps {
  title: string;
  number: number;
  conditionText: string;
  condition: "normal" | "warning" | "danger";
  min: number;
  max: number;
  className?: string;
}

// component to show measurement in range like component
const StatusCard = ({
  title,
  number,
  conditionText,
  condition,
  min,
  max,
  className,
}: IStatusCardProps) => {
  // calculate size of each step of range
  const step = (max - min) / 4;

  // render component
  return (
    <div className={clsx(classes.statusCard, classes)}>
      <h3 className={clsx(classes.statusCard__title)}>{title}</h3>
      <div
        className={clsx(
          classes.statusCard__body,
          classes.statusCard__fullWidth
        )}
      >
        <div className={clsx(classes.statusCard__num)}>{number || "- - -"}</div>
        <div
          className={clsx(
            classes.statusCard__condition,
            condition === "normal" && classes.normal,
            condition === "warning" && classes.warning,
            condition === "danger" && classes.danger
          )}
        >
          {conditionText}
        </div>
      </div>
      <div
        className={clsx(
          classes.statusCard__range,
          classes.statusCard__fullWidth
        )}
      >
        <div
          className={clsx(classes.statusCard__fullWidth)}
          style={{
            paddingLeft: `${Math.min(
              96,
              ((number - min) / (max - min)) * 100 - 4
            )}%`,
          }}
        >
          <div className={clsx(classes.statusCard__indicator)}></div>
        </div>
        <div
          className={clsx(
            classes.statusCard__gradient,
            classes.statusCard__fullWidth
          )}
        ></div>
        <div
          className={clsx(
            classes.statusCard__labelPlace,
            classes.statusCard__fullWidth
          )}
        >
          <div className={clsx(classes.statusCard__smallText)}>{min}</div>
          <div className={clsx(classes.statusCard__smallText)}>
            {min + step}
          </div>
          <div className={clsx(classes.statusCard__smallText)}>
            {min + step * 2}
          </div>
          <div className={clsx(classes.statusCard__smallText)}>
            {min + step * 3}
          </div>
          <div className={clsx(classes.statusCard__smallText)}>{max}</div>
        </div>
      </div>
    </div>
  );
};

export default StatusCard;

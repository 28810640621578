import CardContainer from "../../../cardContainer";
import { useContext, useEffect, useState } from "react";
import {
  CameraCloseLinear,
  CameraOpenLinear,
  MuteIcon,
  UnmuteIcon,
} from "../../../../icon";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { VideoVisitContext } from "../..";
import { toast } from "react-toastify";
import Avatar from "../../../../core/avatar";
import { useTranslation } from "react-i18next";

export interface IParticipantsCardProps {
  role?: string;
  avatar?: string;
  name: string;
  hasMic: boolean;
  hasCamera: boolean;
  stream: OT.Stream | undefined;
}

const ParticipantsCard = ({
  avatar,
  name,
  hasMic,
  role,
  hasCamera,
  stream,
}: IParticipantsCardProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  // mute or unmute state for participant
  const [mute, setMute] = useState(stream ? !stream.hasAudio : false);
  // cam open or not state for participant
  const [cameraOpen, setCameraOpen] = useState(stream ? stream.hasVideo : true);
  const { session, infoMessage } = useContext(VideoVisitContext);

  useEffect(() => {
    if (stream) {
      if (infoMessage?.from.connectionId === stream.connection.connectionId) {
        if (infoMessage?.data.split(":")[1] === "camera") {
          setCameraOpen(infoMessage?.data.split(":")[2] === "open");
        } else {
          setMute(infoMessage?.data.split(":")[2] === "close");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoMessage]);
  // render component
  return (
    <CardContainer type={"basic"} padding={"small"}>
      <div className={clsx(classes.participantCard)}>
        <div className={clsx(classes.participantCard__info)}>
          <div className={clsx(classes.participantCard__avatarContainer)}>
            <Avatar
              name={name}
              src={avatar}
              className={classes.participantCard__avatar}
            />
          </div>
          <div className={clsx(classes.participantCard__item)}>
            <div className={clsx(classes.participantCard__title)}>{name}</div>
            {role && (
              <div className={clsx(classes.participantCard__role)}>{role}</div>
            )}
          </div>
        </div>
        <div className={clsx(classes.participantCard__icons)}>
          {hasMic ? (
            <div
              onClick={() => {
                session!.signal(
                  {
                    to: stream!.connection,
                    data: `mic:${mute ? "open" : "close"}`,
                  },
                  (e) => {
                    if (!e) toast.success(t("mic_modified"));
                  }
                );
                setMute(!mute);
              }}
              className={clsx(classes.participantCard__icon)}
            >
              {mute ? (
                <MuteIcon className={clsx(classes.neg)} />
              ) : (
                <UnmuteIcon />
              )}
            </div>
          ) : (
            ""
          )}
          {hasCamera ? (
            <div
              onClick={() => {
                session!.signal(
                  {
                    to: stream!.connection,
                    data: `camera:${!cameraOpen ? "open" : "close"}`,
                  },
                  (e) => {
                    if (!e) toast.success(t("camera_modified"));
                  }
                );
                setCameraOpen(!cameraOpen);
              }}
              className={clsx(classes.participantCard__icon)}
            >
              {cameraOpen ? (
                <CameraOpenLinear />
              ) : (
                <CameraCloseLinear className={clsx(classes.neg)} />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </CardContainer>
  );
};

export default ParticipantsCard;

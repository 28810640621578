import { FormikProps } from "formik";
import { IDefaultProps as IAutoCompleteProps } from "components/core/autoComplete";
import { DOCTOR_TITLE_OPTIONS } from "constants/autocompleteOptions";
import { useDropdownDefaultValue } from "hooks/useDropdownDefaultValue";
import FormAutoComplete from "components/form/formAutoComplete";
import { TeacherBold } from "components/icon";
import { getFormikFieldProps } from "utils/form";
import { useTranslation } from "react-i18next";

export interface ITitleProps<T> {
  formik: FormikProps<T>;
  titleFieldName: string;
  rootProps?: Partial<IAutoCompleteProps>;
  titleId?: string;
  titleName?: string;
  noPadding?: boolean;
}

//? returns the title

export function Title<T extends { [key: string]: any }>({
  formik,
  titleFieldName,
  titleId,
  titleName,
  rootProps,
  noPadding,
}: ITitleProps<T>) {
  const { t } = useTranslation("form");

  useDropdownDefaultValue({
    fieldName: titleFieldName,
    formik: formik,
    id: titleId,
    OPTIONS: DOCTOR_TITLE_OPTIONS,
    name: titleName,
  });
  return (
    <FormAutoComplete
      noPadding={noPadding}
      rootProps={{ icon: TeacherBold, placeholder: t("degree"), ...rootProps }}
      options={DOCTOR_TITLE_OPTIONS}
      {...getFormikFieldProps(titleFieldName, t("degree"), formik)}
    />
  );
}

import { useQuery } from "react-query";
import { getRobots } from "api/dropdown";
import { EmojiHappyBold } from "components/icon";
import { getDropdownKey } from "utils/dropdowns";
import { FastDropdown } from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import { convertToString } from "utils/form";
import { useTranslation } from "react-i18next";
import { robotsKey } from "features/registeredRobots";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the `Robot Type` that this `Robot Name` is dependent on it.
   *
   * without knowing the `Robot Type` we can not fetch the`Robot Name`.
   */
  robotTypeFieldName: keyof T & string;
  /**
   * The field name of the `Robot Level` that this `Robot Name` is dependent on it.
   *
   * without knowing the `Robot Level` we can not fetch the`Robot Name`.
   */
  robotLevelFieldName: keyof T & string;
}

export function RobotID<T>({
  formik,
  robotLevelFieldName,
  robotTypeFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the values from formik values and extract its key
  const robotTypeKey = getDropdownKey(formik, robotTypeFieldName);
  const robotLevelKey = getDropdownKey(formik, robotLevelFieldName);

  // fetch data
  const { data, isLoading, isError } = useQuery(
    [robotsKey, robotTypeKey, robotLevelKey],
    () =>
      getRobots({
        level: convertToString(robotLevelKey),
        type: convertToString(robotTypeKey),
      }),
    {
      enabled: !!robotTypeKey && !!robotLevelKey,
    }
  );

  return (
    <FastDropdown
      label={t("robot_id")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!robotTypeKey || !robotLevelKey || disabled}
      icon={EmojiHappyBold}
      {...props}
    />
  );
}

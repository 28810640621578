import SideBartTop from "../sideBartTop";
import {
  ArrowDownBold,
  ArrowLeftBold,
  ArrowRightBold,
  ArrowRightLinear,
  ArrowUpBold,
} from "../../../../icon";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { useState } from "react";
import ImageItems from "../images/imageItems";
import { IImagesItemProps } from "../images/ImagesItem";
import { IDoctorAppointment } from "../../../../../api/appointment";
import { humanizedDate } from "../../../../../utils/date";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ICameraControlProps {
  onUpClicked?: () => any;
  onDownClicked?: () => any;
  onLeftClicked?: () => any;
  onRightClicked?: () => any;
  onCaptureClicked?: () => any;
  onZoomIn?: () => any;
  onZoomOut?: () => any;
  onBreak?: () => void;
  requests: {
    title: string;
    text: string;
    onRequest?: () => any;
    ptz?: boolean;
  }[];
  images: IImagesItemProps[];
  onClose: () => void;
  appointment?: IDoctorAppointment;
}

// camera controlling component for video visit
const CameraControl = ({
  onCaptureClicked,
  onDownClicked,
  onLeftClicked,
  onRightClicked,
  onUpClicked,
  requests,
  onZoomOut,
  onZoomIn,
  images,
  onClose,
  onBreak,
  appointment,
}: ICameraControlProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  // render component
  return (
    <div className={clsx(classes.cameraControl)}>
      <SideBartTop
        onClick={() => onClose()}
        title={t("camera_controller")}
        text={
          appointment?.patient?.name +
          ` ,${t("dob")}: ` +
          humanizedDate(appointment?.patient?.birthday || "")
        }
      />
      <div className={clsx(classes.cameraControl__inside)}>
        {RenderRequest(
          requests,
          onZoomIn,
          onZoomOut,
          onCaptureClicked,
          onLeftClicked,
          onRightClicked,
          onUpClicked,
          onDownClicked,
          onBreak
        )}
        <ImageItems items={images} />
      </div>
    </div>
  );
};

// render control page
const renderControl = (
  t: any,
  onZoomIn?: (() => any) | undefined,
  onZoomOut?: (() => any) | undefined,
  onCaptureClicked?: (() => any) | undefined,
  onLeftClicked?: (() => any) | undefined,
  onRightClicked?: (() => any) | undefined,
  onUpClicked?: (() => any) | undefined,
  onDownClicked?: (() => any) | undefined,
  onBreak?: () => void | undefined
) => {
  // render component
  return (
    <div className={clsx(classes.cameraControl__main)}>
      <div className={clsx(classes.cameraControl__outCirc)}>
        <ArrowRightBold
          className={clsx(
            classes.cameraControl__icon,
            classes.cameraControl__right
          )}
          onMouseDown={onRightClicked}
          onMouseUp={onBreak}
        />
        <ArrowLeftBold
          className={clsx(
            classes.cameraControl__icon,
            classes.cameraControl__left
          )}
          onMouseDown={onLeftClicked}
          onMouseUp={onBreak}
        />
        <ArrowUpBold
          className={clsx(
            classes.cameraControl__icon,
            classes.cameraControl__up
          )}
          onMouseDown={onUpClicked}
          onMouseUp={onBreak}
        />
        <ArrowDownBold
          className={clsx(
            classes.cameraControl__icon,
            classes.cameraControl__down
          )}
          onMouseDown={onDownClicked}
          onMouseUp={onBreak}
        />
      </div>
      <div
        className={clsx(
          classes.cameraControl__btn,
          classes.cameraControl__btn_selected,
          classes.cameraControl__fullW
        )}
        onMouseDown={onZoomIn}
        onMouseUp={onBreak}
      >
        {t("zoom_in")}
      </div>
      <div
        className={clsx(
          classes.cameraControl__btn,
          classes.cameraControl__btn_selected,
          classes.cameraControl__fullW
        )}
        onMouseDown={onZoomOut}
        onMouseUp={onBreak}
      >
        {t("zoom_out")}
      </div>
    </div>
  );
};

// render request page
const RenderRequest = (
  requests: { title: string; text: string; onRequest?: () => any }[],
  onZoomIn?: (() => any) | undefined,
  onZoomOut?: (() => any) | undefined,
  onCaptureClicked?: (() => any) | undefined,
  onLeftClicked?: (() => any) | undefined,
  onRightClicked?: (() => any) | undefined,
  onUpClicked?: (() => any) | undefined,
  onDownClicked?: (() => any) | undefined,
  onBreak?: () => void | undefined
) => {
  // render component
  return requests.map((req) => {
    return (
      <Item
        req={req}
        onZoomIn={onZoomIn}
        onCaptureClicked={onCaptureClicked}
        onUpClicked={onUpClicked}
        onLeftClicked={onLeftClicked}
        onDownClicked={onDownClicked}
        onRightClicked={onRightClicked}
        onZoomOut={onZoomOut}
        onBreak={onBreak}
      />
    );
  });
};

const Item = ({
  req,
  onCaptureClicked,
  onLeftClicked,
  onRightClicked,
  onDownClicked,
  onUpClicked,
  onZoomIn,
  onZoomOut,
  onBreak,
}: {
  req: { title: string; text: string; onRequest?: () => any; ptz?: boolean };
  onZoomIn?: (() => any) | undefined;
  onZoomOut?: (() => any) | undefined;
  onCaptureClicked?: (() => any) | undefined;
  onLeftClicked?: (() => any) | undefined;
  onRightClicked?: (() => any) | undefined;
  onUpClicked?: (() => any) | undefined;
  onDownClicked?: (() => any) | undefined;
  onBreak?: () => void | undefined;
}) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });
  const [open, setOpen] = useState(false);
  // const [accept, setAccept] = useState(false);

  return (
    <Accordion
      className={clsx(classes.item)}
      expanded={open}
      onChange={(event, expanded) => {
        setOpen(expanded);
      }}
    >
      <AccordionSummary
        onClick={() => {
          if (req.onRequest) req?.onRequest();
        }}
      >
        <div className={clsx(classes.item__head)}>
          <div className={clsx(classes.item__title)}>{req.title}</div>
          <div className={clsx(classes.item__icon, open && classes.rotate)}>
            {<ArrowRightLinear />}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={clsx(classes.item__content)}>
        {renderControl(
          t,
          onZoomIn,
          onZoomOut,
          onCaptureClicked,
          onLeftClicked,
          onRightClicked,
          onUpClicked,
          onDownClicked,
          onBreak
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CameraControl;

import { useContext, useEffect, useState } from "react";
import {
  CallBold,
  CameraBold,
  CameraCloseBold,
  FullscreenBold,
  MicBold,
  MicMuteBold,
} from "../../../../icon";
import useDraggable from "../../../../../hooks/useDraggable";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { IVonageStream } from "interfaces/vonageStream";
import { SwapUser } from "components/icon/swapUser";
import { VideoVisitContext } from "../..";
import { TRoleName } from "../../../../../api/user";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

export interface IMinimizedVideoVisitProps {
  onFullScreen?: () => void;
  onLeave?: () => void;
  streams: IVonageStream[];
  doctor?: boolean;
  noSwap?: boolean;
  role?: TRoleName | "guest";
  streamId?: string;
  defaultStreamIndex?: number;
}

const MinimizedVideoVisit = ({
  onFullScreen,
  onLeave,
  streams,
  doctor,
  noSwap,
  role,
  streamId,
  defaultStreamIndex,
}: IMinimizedVideoVisitProps) => {
  // make component draggable
  const [ref, position, isDragging] = useDraggable<HTMLDivElement>(
    {
      x: "50%",
      y: "15%",
    },
    true
  );
  // mute state
  // const [mute, setMute] = useState(false);
  // camera open state

  const [swapUserShade, setSwapUserShade] = useState(false);

  const [userCameraIndex, setUserCameraIndex] = useState(0);

  const loc = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (defaultStreamIndex) {
      setUserCameraIndex(defaultStreamIndex);
      return;
    }
    if (!doctor) {
      if (role === "doctor") {
        streams.forEach((item, index) => {
          if (item.role === "doctor") {
            setUserCameraIndex(index);
          }
        });
        streams.forEach((item, index) => {
          if (item.role === "staff") {
            setUserCameraIndex(index);
          }
        });
        streams.forEach((item, index) => {
          if (item.role === "patient") {
            setUserCameraIndex(index);
          }
        });
      } else {
        streams.forEach((item, index) => {
          if (item.role === "patient") {
            setUserCameraIndex(index);
          }
        });

        streams.forEach((item, index) => {
          if (item.role === "staff") {
            setUserCameraIndex(index);
          }
        });
        streams.forEach((item, index) => {
          if (item.role === "doctor") {
            setUserCameraIndex(index);
          }
        });
      }
    } else {
      streams.forEach((item, index) => {
        streams.forEach((item, index) => {
          if (item.role === "staff") {
            setUserCameraIndex(index);
          }
        });
        streams.forEach((item, index) => {
          if (item.role === "doctor") {
            setUserCameraIndex(index);
          }
        });
        streams.forEach((item, index) => {
          if (item.role === "patient") {
            setUserCameraIndex(index);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streams, defaultStreamIndex]);
  const ctx = useContext(VideoVisitContext);

  const swapUser = () => {
    if (userCameraIndex < streams.length - 1) {
      const stream = streams[userCameraIndex + 1];
      console.log("==========");
      console.log(streamId, streamId);
      console.log(stream);
      if (stream.id === streamId) {
        return setUserCameraIndex(
          (prevIndx) => (prevIndx + 2) % streams?.length
        );
      }
      return setUserCameraIndex((prevIndx) => prevIndx + 1);
    }
    return setUserCameraIndex(0);
  };

  // trigger change function on update
  // useEffect(() => {
  //   if (mute) {
  //     if (onMute) {
  //       onMute();
  //     }
  //   } else {
  //     if (onUnmute) {
  //       onUnmute();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mute]);

  useEffect(() => {
    if (streams[userCameraIndex]?.videoObj) {
      streams[userCameraIndex].videoObj.style.height = "100%";
      // streams[0].videoObj.style.minHeight = "100%";
      // document.getElementById("video-container")!.innerHTML = ''
      const videoContainer = document.getElementById("video-container");
      const children = videoContainer!.children;
      for (let i = 0; i < children.length; i++) {
        console.log(children[i].tagName);
        if (children[i].tagName === "VIDEO") {
          videoContainer!.removeChild(children[i]);
        }
      }
      // videoContainer!.removeChild(children as ChildNode);
      videoContainer!.append(streams[userCameraIndex].videoObj);
    }
  }, [streams, userCameraIndex]);

  // render component
  return (
    <div
      className={clsx(classes.minimize)}
      {...{
        ref,
        style: {
          position: "fixed",
          left: position.x,
          top: position.y,
          cursor: isDragging ? "move" : "default",
          backgroundColor: "black",
          zIndex: "999999",
        },
      }}
    >
      <div className={classes.videoContainer} id="video-container">
        <div
          className={clsx(
            classes.swapUser,
            swapUserShade && classes.swapUser__hide
          )}
        >
          <SwapUser
            style={{ cursor: noSwap ? "default" : "pointer" }}
            onMouseEnter={() => {
              if (!noSwap) setSwapUserShade(true);
            }}
            onMouseLeave={() => {
              setSwapUserShade(false);
            }}
            onClick={() => {
              if (!noSwap) swapUser();
            }}
          />
          <p className={classes.fontStyle}>Swap User</p>
        </div>
        {/* <div className={clsx(classes.minimize__top)}>
        <div className={clsx(classes.minimize__imgContainer)}>
          <div className={clsx(classes.minimize__imgPlace)}>
            <img
              src={avatarSrc}
              alt={"avatar"}
              className={clsx(classes.minimize__img)}
            />
          </div>
        </div>
      </div> */}
      </div>
      <div className={clsx(classes.btnContainer)}>
        <div
          className={clsx(
            classes.minimize__btn,
            ctx.micMute ? classes.white : classes.primary
          )}
          onClick={() => {
            ctx.onMicMute(!ctx.micMute);
            console.log(ctx.micMute);
          }}
        >
          {ctx.micMute ? <MicMuteBold /> : <MicBold />}
        </div>
        <div
          className={clsx(
            classes.minimize__btn,
            ctx.isCameraOpen ? classes.primary : classes.white
          )}
          onClick={() => ctx.onCameraOpen(!ctx.isCameraOpen)}
        >
          {ctx.isCameraOpen ? <CameraBold /> : <CameraCloseBold />}
        </div>
        {onFullScreen && (
          <div
            className={clsx(classes.minimize__btn, classes.white)}
            onClick={() => {
              if (onFullScreen) {
                if (loc.pathname?.includes("pdf-viewer")) {
                  history.goBack();
                }
                onFullScreen();
              }
            }}
          >
            <FullscreenBold />
          </div>
        )}
        {onLeave && (
          <div
            className={clsx(classes.minimize__btn, classes.danger)}
            onClick={() => {
              if (onLeave && onFullScreen) {
                if (loc.pathname?.includes("pdf-viewer")) {
                  history.goBack();
                }
                onFullScreen();
                onLeave();
              }
            }}
          >
            <CallBold />
          </div>
        )}
      </div>
    </div>
  );
};

export default MinimizedVideoVisit;

import { ITitle, submitRegister } from "api/auth";
import NotificationAlert from "components/core/notificationAlert";
import { notify } from "components/core/toast";
import { FormikProps, useFormik } from "formik";
import LoginLayout from "pages/login/components/loginLayout";
import { useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { convertBirthday } from "utils/date";
import { encodePhone } from "utils/form";
import { URL_REGEX } from "utils/validation";
import yup, {
  emailObj,
  parseDateString,
  phoneObj,
  requiredObj,
  urlObj,
} from "utils/yupExtended";
import { StepNumber } from "../../context";
import RegistrationFifthPage from "./fifthPage";
import RegistrationFirstPage from "./firstPage";
import RegistrationFourthPage from "./fourthPage";
import RegistrationSecondPage from "./secondPage";
import RegistrationThirdPage from "./thirdPage";
import { IRegisterForm, REGISTER_INITIAL_VALUES } from "../../IForm";
import Button from "components/core/button";
import { getStepNumByFieldName } from "../../stepFieldNames";
import { useTranslation } from "react-i18next";

export interface IRegistrationStepProps {
  formik: FormikProps<IRegisterForm>;
  isMutationLoading?: boolean;
  onSetAlert: React.Dispatch<React.SetStateAction<boolean>>;
  backButton?: JSX.Element;
}

const DoctorRegistrationPage = () => {
  const { t } = useTranslation("form");

  const getStepSchema = (step: StepNumber) => {
    switch (step) {
      case 1:
        return yup.object().shape({
          firstName: yup.string().required(requiredObj("first_name")),
          lastName: yup.string().required(requiredObj("last_name")),
          gender: yup
            .object()
            .dropdown({ errorMessage: requiredObj("gender") }),
          birthday: yup
            .date()
            .minAge({
              min: 18,
              errorMessage: requiredObj("birthday"),
            })
            .transform(parseDateString)
            .required(requiredObj("birthday")),
          cmoNumber: yup.string().optional(),
          docType: yup.object().dropdown(),
        });
      case 2:
        return yup.object().shape({
          mobile: yup.object().phoneNumber({ errorMessage: phoneObj() }),
          alternativePhoneNumber: yup
            .object()
            .phoneNumber({ errorMessage: phoneObj() }),
          email: yup.string().email(emailObj()).required(requiredObj("email")),
          website: yup.string().matches(URL_REGEX, urlObj()).optional(),
          speciality: yup
            .object()
            .dropdown({ errorMessage: requiredObj("speciality") }),
          subSpecialty: yup
            .object()
            .dropdown({ errorMessage: requiredObj("sub_speciality") }),
          licenseNo: yup.string().required(requiredObj("license_number")),
          title: yup.object().dropdown({ errorMessage: requiredObj("title") }),
          countryOfLicense: yup.object().dropdown({
            errorMessage: requiredObj("country_license"),
          }),
        });
      case 3:
        return yup.object().shape({
          hfCountry: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_country") }),
          hfState: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_state") }),
          hfCity: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_city") }),
          hfType: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_type") }),
          hfName: yup
            .object()
            .dropdown({ errorMessage: requiredObj("hf_name") }),
          department: yup.object().dropdown({ optional: true }),
        });
      case 4:
        return yup.object().shape({
          languages: yup
            .array()
            .of(
              yup.object().shape({
                language: yup.string(),
                language_fluency: yup.string(),
              })
            )
            .required()
            .min(1),
        });
      case 5:
        return yup.object().shape({
          country: yup
            .object()
            .dropdown({ errorMessage: requiredObj("country") }),
          state: yup.object().dropdown({ errorMessage: requiredObj("state") }),
          city: yup.object().dropdown({ errorMessage: requiredObj("city") }),
          timezone: yup
            .object()
            .dropdown({ errorMessage: requiredObj("timezone") }),
          address: yup.string().required(requiredObj("address")),
          zipCode: yup.string().required(requiredObj("zipcode")),
        });
    }
  };

  const history = useHistory();
  // const { data, step, setStep } = useContext(RegistrationContext);
  const { mutate, isLoading } = useMutation(submitRegister);
  const [step, setStep] = useState<StepNumber>(1);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const formik = useFormik<IRegisterForm>({
    initialValues: REGISTER_INITIAL_VALUES,
    validationSchema: getStepSchema(step),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      // setData(values);

      if (step !== 5) {
        setStep((step + 1) as StepNumber);
      } else {
        mutate(
          {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            mobile: encodePhone(values.mobile),
            // user_name: string,
            // password: string,
            birthday: convertBirthday(values.birthday), // with format of yyyy-mm-dd
            gender: values.gender.key as "male" | "female" | "others",
            // country_id: string; // select from list
            // state_id: string; // select from list
            city_id: values.city.key, // select from list
            timezone: values.timezone.key,
            role_name: "doctor",
            facility_id: values.hfName.key,
            departments:
              values.department.key && values.department.key !== "0"
                ? [values.department.key]
                : undefined,
            languages: values.languages,
            address: values.address,
            user_name: `test-${Math.random() * 10000}`,
            phone: encodePhone(values.alternativePhoneNumber),
            speciality: values.speciality.key,
            sub_speciality: values.subSpecialty.key,
            title: values.title.key as ITitle,
            doctor_type: values.docType.key,
            zip_code: values.zipCode,
            website: values?.website,
            licenses: [
              {
                country_of_license: +values.countryOfLicense.key,
                NPI_number: values.licenseNo,
              },
            ],
          },
          {
            onSuccess: ({ data }) => {
              notify.success(data?.message);
              history.replace("/login");
            },
            onError: (error: any) => {
              const errStepNum = getStepNumByFieldName(
                Object.keys(error.response.data.errors),
                "doctor"
              );
              errStepNum > 0 && setStep(errStepNum as StepNumber);
            },
          }
        );
      }
    },
  });

  const BackButton = (
    <Button
      className="ME-auto"
      color="secondary"
      variant="outlined"
      onClick={() => setStep((prevStep) => (prevStep - 1) as StepNumber)}
    >
      {t("back")}
    </Button>
  );

  return (
    <LoginLayout
      form_title={t("doctor_registration")}
      form_description={
        step === 3 ? t("complete_hf_info") : t("complete_personal_info")
      }
      stepsCount={5}
      step={step}
      onSetStep={setStep}
    >
      <div>
        {step === 1 && (
          <RegistrationFirstPage onSetAlert={setIsAlertOpen} formik={formik} />
        )}
        {step === 2 && (
          <RegistrationSecondPage
            onSetAlert={setIsAlertOpen}
            formik={formik}
            backButton={BackButton}
          />
        )}
        {step === 3 && (
          <RegistrationThirdPage
            onSetAlert={setIsAlertOpen}
            formik={formik}
            backButton={BackButton}
          />
        )}
        {step === 4 && (
          <RegistrationFourthPage
            onSetAlert={setIsAlertOpen}
            formik={formik}
            backButton={BackButton}
          />
        )}
        {step === 5 && (
          <RegistrationFifthPage
            onSetAlert={setIsAlertOpen}
            formik={formik}
            isMutationLoading={isLoading}
            backButton={BackButton}
          />
        )}
      </div>
      <NotificationAlert
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onApprove={() => {
          history.push("/login");
        }}
        title={t("register_cancel")}
        contnet={t("register_cancel_text")}
      />
    </LoginLayout>
  );
};

export default DoctorRegistrationPage;

import { useFormik } from "formik";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { useEffect, useState } from "react";
import List from "components/common/list";
import ListItem from "components/common/list/components/listItem";
import yup, { generateErrorMessage } from "utils/yupExtended";
import { IRegistrationStepProps } from "../../doctor/components/registrationLayout";
import Grid from "components/core/Grid";
import SecondaryButton from "../../../../../components/core/secondaryButton";
import { notify } from "components/core/toast";
import { Language, LanguageFluency } from "components/dropdown";
import { useTranslation } from "react-i18next";
import { formSubmission } from "utils/form";

export interface ILanguage {
  language: { key: string; value: string };
  language_fluency: { key: string; value: string };
}

const ThirdStepValidation = yup.object().shape({
  language: yup
    .object()
    .dropdown({ errorMessage: generateErrorMessage("Language") }),
  language_fluency: yup
    .object()
    .dropdown({ errorMessage: generateErrorMessage("Language Fluency") }),
});

const RegistrationThirdPage = ({
  formik,
  onSetAlert,
  backButton,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");

  const getDefaultLangs = () => {
    return (
      formik.values?.languages.map((lang) => ({
        language: { key: lang.language, value: lang.language },
        language_fluency: {
          key: lang.language_fluency,
          value: lang.language_fluency,
        },
      })) || []
    );
  };

  const [languages, setLanguages] = useState<ILanguage[]>(() =>
    getDefaultLangs()
  );

  useEffect(() => {
    const languagesFormatted = languages.map((lang) => ({
      language: lang.language.value,
      language_fluency: lang.language_fluency.key,
    }));

    formik.setFieldValue("languages", languagesFormatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages]);

  const langformik = useFormik<ILanguage>({
    initialValues: {
      language: { key: "English", value: "English" },
      language_fluency: { key: "", value: "" },
    },
    validationSchema: ThirdStepValidation,
    validateOnChange: false,
    validateOnBlur: false,
    // enableReinitialize: true,
    onSubmit: () => {
      const duplicateIndex = languages.findIndex(
        (lang) => lang.language.value === langformik.values.language.value
      );

      duplicateIndex < 0
        ? setLanguages((prevLangs) => [
            ...prevLangs,
            {
              language: langformik.values.language,
              language_fluency: langformik.values.language_fluency,
            },
          ])
        : notify.error(t("duplicate_language", { index: duplicateIndex + 1 }));
      langformik.resetForm();
      langformik.setFieldValue("language", { key: "", value: "" });
    },
  });

  return (
    <form autoComplete="off" onSubmit={(e) => formSubmission(e, formik)}>
      <Grid>
        <Language
          formik={langformik}
          fieldName="language"
          name={langformik.values.language.value}
        />

        <LanguageFluency formik={langformik} fieldName="language_fluency" />

        <Grid.Button>
          <SecondaryButton onClick={langformik.submitForm}>
            {t("add")}
          </SecondaryButton>
        </Grid.Button>

        <List className="mt-4">
          {languages.map((language, idx) => (
            <ListItem
              className={"w-100 overflow-auto"}
              key={idx}
              numberLabel={idx + 1}
              data={{
                title: language.language.value,
                columns: [
                  {
                    label: t("language_fluency") + ":",
                    value: language.language_fluency.value,
                  },
                ],
              }}
              deletable
              onDeleteItem={() => {
                setLanguages(
                  languages.filter(
                    (lang) => lang.language !== language.language
                  )
                );
              }}
            />
          ))}
        </List>

        <div className={classes.buttonContainer}>
          {backButton}
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => onSetAlert(true)}
          >
            {t("cancel")}
          </Button>
          <Button type="submit">{t("continue")}</Button>
        </div>
      </Grid>
    </form>
  );
};

export default RegistrationThirdPage;

import { FORM_ROUTE_NAME } from "interfaces/route";
import { ComponentType, LazyExoticComponent } from "react";
import { useLocation } from "react-router";
import { TRoleName } from "../../api/user";

export function isViewProfile(pathName: string) {
  const splitedPathName = pathName.split("/");
  return splitedPathName[splitedPathName.length - 2] === "view-profile";
}

export function getRolePath(path: string) {
  const p = path?.split("/");
  path = p[2];
  if (!path) return "/";
  if (path.includes("super")) {
    return "/panel/super/";
  }
  if (path.includes("patient")) {
    return "/panel/patient/";
  }
  if (path.includes("doctor")) {
    return "/panel/doctor/";
  }
  if (path.includes("staff")) {
    return "/panel/staff/";
  }
  if (path.includes("local-admin")) {
    return "/panel/local-admin/";
  }
  if (path.includes("cmo")) {
    return "/panel/cmo/";
  }
}
export const newBaseUrl = () => {
  const URL = "https://pacs.leo360.tech/server/";
  return URL;
};

export function getRoleFromPath(path: string): TRoleName | undefined {
  const p = path?.split("/");
  path = p[2];
  if (path.includes("patient")) {
    return "patient";
  }
  if (path.includes("doctor")) {
    return "doctor";
  }
  if (path.includes("staff")) {
    return "staff";
  }
  if (path.includes("local")) {
    return "local-admin";
  }
  if (path.includes("cmo")) {
    return "cmo";
  }
  if (path.includes("super")) {
    return "super-admin";
  }
}

export const useBaseUrl = () => {
  const loc = useLocation();
  return getRolePath(loc.pathname);
};

export const useRole = () => {
  const loc = useLocation();
  return getRoleFromPath(loc.pathname);
};

/**
 * By URL path convention we have to set the paths:
 *  `<baseURL>/create?<url params>` to register page,
 *  `<baseURL>/edit/:id?<url params>` to edit page,
 *  `<baseURL>/view-profile/:id?<url params>` to view profile page,
 *
 * @param component LazyExoticComponent
 * @returns returns `create`, `edit/:id` and `view-profile/:id` inner paths(by create, edit and view-profile URL path convention) for use in routes.
 */
export const initCEVRoutes = (
  component: LazyExoticComponent<React.ComponentType<any>>
) => {
  return [
    {
      exact: true,
      path: FORM_ROUTE_NAME.create,
      component: component,
    },
    {
      exact: true,
      path: `${FORM_ROUTE_NAME.edit}/:id`,
      component: component,
    },
    {
      exact: true,
      path: `${FORM_ROUTE_NAME.viewProfile}/:id`,
      component: component,
    },
  ];
};

export const lazyRetry = (
  componentImport: () => Promise<any>
): Promise<{
  default: ComponentType<any>;
}> =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(
      componentImport.toString()
    )}`;
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || "false"
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, "false");
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, "true");
          alert(
            "A new version has been released. Press the 'Ok' button to refresh the page automatically."
          );
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, "true");
          alert(
            "A new version has been released. Press the 'Ok' button to refresh the page automatically."
          );
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

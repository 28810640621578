import clsx from "clsx";
import classes from "./styles.module.scss";
import { ArrowRightBold } from "../../../../icon";
import { Dispatch, SetStateAction } from "react";
import { IDoctorAppointment } from "../../../../../api/appointment";
import { useHistory } from "react-router-dom";
import { TRoleName } from "../../../../../api/user";
import { useBaseUrl } from "../../../../../utils/route";

export interface IVideoVisitSideBarItem {
  title: string;
  icon: any;
  element: any;
  onClick?: () => void;
  key?: string;
}

export interface ISideBarProps {
  items: IVideoVisitSideBarItem[];
  setElement: (state: any) => any;
  close?: boolean;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  appointment?: IDoctorAppointment;
  setMinimized: Dispatch<SetStateAction<boolean>>;
  role?: TRoleName | "guest";
}

// video visit sidebar component
const SideBar = ({
  items,
  setElement,
  close,
  setActiveIndex,
  activeIndex,
  appointment,
  setMinimized,
  role,
}: ISideBarProps) => {
  const history = useHistory();

  const base = useBaseUrl();

  // render component
  return (
    <div
      className={clsx(
        classes.sideBar,
        close && classes.sideBar__close,
        role === "doctor" && classes.doc
      )}
    >
      {items.map((item, index) => {
        return (
          <div
            className={clsx(classes.sideBar__item)}
            onClick={() => {
              if (item?.onClick) {
                item?.onClick();
                return;
              }
              if (index === activeIndex) {
                setActiveIndex(-1);
                setElement(-1);
              } else {
                if (item?.key === "Write New Summary Note") {
                  history.push({
                    pathname:
                      "/panel/doctor/write-summary-note/" +
                      appointment?.patient?.id +
                      "/" +
                      appointment?.id,
                    state: { appointment: appointment },
                  });
                  setMinimized(true);
                  return;
                }
                if (item?.key === "Past Visit Summary Note") {
                  setMinimized(true);
                  history.push(
                    `${base}patients/past-visit-summary/${appointment?.patient?.id}/${appointment?.visit_for_key}`
                  );
                  return;
                }
                if (item?.key === "Medical Intake Form") {
                  history.push({
                    pathname: `${base}medical-history/${appointment?.patient?.id}/${appointment?.visit_for}`,
                  });
                  setMinimized(true);
                  return;
                }
                setActiveIndex(index);
                setElement(index);
              }
            }}
          >
            <div className={clsx(activeIndex === index && classes.primary)}>
              <div className={clsx(classes.sideBar__in)}>
                {item.icon}
                {item.title}
              </div>
            </div>
            <div className={clsx(classes.sideBar__label)}>
              <div className={clsx(classes.sideBar__title)}>{item.title}</div>
              <ArrowRightBold />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SideBar;

import apiCaller, { IResponse } from "api";
import { IDoctorAppointment } from "./appointment";

interface IJoinAppointmentResponse {
  appointment_id: string;
  video_visit_id: number;
  session_id: string;
  token: string;
  api_key: string;
  name: string;
  start_datetime: string;
  appointment: IDoctorAppointment;
  doctor_consent: boolean;
}

export function consentVideoVisit(id: string): Promise<IResponse<any>> {
  return apiCaller.post(`/appointments/consent-video-visit/${id}`);
}

export function joinAppointment(
  appointment_id: string
): Promise<IResponse<IJoinAppointmentResponse>> {
  return apiCaller.get(`appointments/join_video_visit`, {
    params: { appointment_id },
  });
}

export interface IGuestJoinVideoVisitParams {
  token: string;
  name: string;
}

export function guestJoinVideoVisit(
  data: IGuestJoinVideoVisitParams
): Promise<IResponse<IJoinAppointmentResponse>> {
  return apiCaller.post(`/guest/join-video-visit`, data);
}

export interface IVideoStoredResult {
  "Blood Pressure": {
    id: number;
    measured_at: string;
    data: {
      systolic: number;
      diastolic: number;
      unit: string;
      meanArterialPressure: number;
      heart_rate: number;
    };
    attachment: null;
  }[];
  "Pulse Oximeter": [
    {
      id: number;
      measured_at: string;
      data: {
        spO2: number;
        pulse_rate: number;
      };
      attachment: null;
    }
  ];
  "Weight Scale": [
    {
      id: number;
      measured_at: string;
      data: {
        weight: number;
        unit: string;
      };
      attachment: null;
    }
  ];
  Thermometer: [
    {
      id: number;
      measured_at: string;
      data: {
        temperature: number;
        temperatureType: string;
        unit: string;
        hasFever: number;
      };
      attachment: null;
    }
  ];
  "Exam Camera": [
    {
      id: number;
      measured_at: string;
      data: [];
      attachment: string;
    }
  ];
  Stethoscope: [
    {
      id: number;
      measured_at: string;
      data: [];
      attachment: string;
    }
  ];
  Glucometer: [
    {
      id: number;
      measured_at: string;
      data: {
        glucose: number;
        unit: string;
      };
      attachment: null;
    }
  ];
  "PTZ Camera": [
    {
      id: number;
      measured_at: string;
      data: [];
      attachment: string;
    }
  ];
  "Ultra Sound": [
    {
      id: number;
      measured_at: string;
      data: [];
      attachment: string;
    }
  ];
  ECG: [
    {
      id: number;
      measured_at: string;
      data: [];
      attachment: string;
    }
  ];
  "Digital Otoscope": [
    {
      id: number;
      measured_at: string;
      data: [];
      attachment: string;
    }
  ];
  Spirometer: [
    {
      id: number;
      measured_at: string;
      data: [];
      attachment: string;
    }
  ];
}

export function getVideoVisitStoredResult(
  id: number
): Promise<IResponse<IVideoStoredResult>> {
  return apiCaller.get(`video-visit/${id}/results`);
}

export interface IVideoVisitTests {
  [key: string]: string[];
}

export function getVideoVisitTests(
  id: number | string
): Promise<IResponse<IVideoVisitTests>> {
  return apiCaller.get(`video-visit/${id}/tests`);
}

export function inviteGuest(
  id: number | string
): Promise<IResponse<{ token: string }>> {
  return apiCaller.get(`/video-visit/${id}/invite`);
}

export function inviteGuestEmail(data: {
  id: number | string;
  emails: string[];
}): Promise<IResponse<{ token: string }>> {
  return apiCaller.post(`/video-visit/${data.id}/invite`, {
    emails: data.emails,
  });
}

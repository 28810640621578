import clsx from "clsx";
import Avatar from "components/core/avatar";
// import Button from "components/core/button/index";
import classes from "./styles.module.scss";
// import { CanceledLinear, Finished, Timer } from "components/icon";
// import { ReactComponent as EditIcon } from "assets/icons/linear/edit-linear.svg";
import BasicTable from "components/core/basicTable";
import { ReactNode } from "react";
import CountryFlag from "../countryFlag";
import { RobotStatusChip, RobotTypeChip } from "../tableCell";
import { Cell } from "react-table";
import AttachmentList from "./components/attachments";
import { useTranslation } from "react-i18next";

//? Returns required columns for table
const useDataCardTableColumns = (type: string) => {
  const { t } = useTranslation("table");

  const APPOINTMENT_COLUMNS = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_name",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.patient_id"),
      accessor: "patient_id",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const DOCTOR_APPT_COLUMNS1 = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.robot_name"),
      accessor: "robot_name",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];
  const DOCTOR_APPT_COLUMNS2 = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.robot_name"),
      accessor: "robot_name",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
    {
      Header: t("columns.doctor_name"),
      accessor: "doctor_name",
    },
    {
      Header: t("columns.speciality"),
      accessor: "speciality",
    },
  ];

  const DOCTOR_APPOINTMENT_COLUMNS = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.patient_id"),
      accessor: "patient_id",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.gender"),
      accessor: "gender",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];
  const PATIENT_APPOINTMENT_COLUMNS = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_type"),
      accessor: "visit_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];
  const STAFF_APPOINTMENT_COLUMNS = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_type"),
      accessor: "visit_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.dob"),
      accessor: "dob",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const DOCTOR_SUMMARY_COLUMNS = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.patient_id"),
      accessor: "patient_id",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.dob"),
      accessor: "birth",
    },
    {
      Header: t("columns.gender"),
      accessor: "gender",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const STORING_RESULTS_COLUMNS = [
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.relationship"),
      accessor: "relationship",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: ({ value }: Cell) => (
        <RobotTypeChip value={value || "home-robot"} />
      ),
    },
    {
      Header: t("columns.robot_location"),
      accessor: "robot_location",
      Cell: ({ value }: Cell) => value || "- - -",
    },
    {
      Header: t("columns.measurement_date"),
      accessor: "measurement_date",
    },
    {
      Header: t("columns.measurement_time"),
      accessor: "measurement_time",
    },
  ];

  const SUPER_ADMIN_APPOINTMENTS = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_type"),
      accessor: "visit_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_name"),
      accessor: "robot_name",
    },
    {
      Header: t("columns.patient_id"),
      accessor: "patient_id",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const SUPER_ADMIN_SUMMARY_NOTES = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_type"),
      accessor: "visit_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_name"),
      accessor: "robot_name",
    },
    {
      Header: t("columns.patient_id"),
      accessor: "patient_id",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];
  const SUPER_ADMIN_SUMMARY_REPORT = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_type"),
      accessor: "visit_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    {
      Header: t("columns.robot_name"),
      accessor: "robot_name",
    },
    {
      Header: t("columns.patient_id"),
      accessor: "patient_id",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
    {
      Header: t("columns.visit_cost"),
      accessor: "visit_cost",
    },
  ];

  const PATIENT_FOLLOW_UP = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_date"),
      accessor: "visit_date",
    },
    {
      Header: t("columns.visit_time"),
      accessor: "visit_time",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.patient_dob"),
      accessor: "birth",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
    {
      Header: t("columns.status"),
      accessor: "status",
      Cell: ({ value }: Cell) => (
        <RobotStatusChip
          value={{
            value: value.value,
            label:
              value?.value === undefined ? "- - -" : value?.label || "- - -",
          }}
        />
      ),
    },
  ];

  const PATIENT_ORDER = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_date"),
      accessor: "visit_date",
    },
    {
      Header: t("columns.visit_time"),
      accessor: "visit_time",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.patient_dob"),
      accessor: "birth",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const PATIENT_REFERRAL = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_date"),
      accessor: "visit_date",
    },
    {
      Header: t("columns.visit_time"),
      accessor: "visit_time",
    },
    {
      Header: t("columns.timezone"),
      accessor: "timezone",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.patient_dob"),
      accessor: "birth",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const PATIENT_PRESCRIPTION = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_date"),
      accessor: "visit_date",
    },
    {
      Header: t("columns.visit_time"),
      accessor: "visit_time",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.patient_dob"),
      accessor: "birth",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const PATIENT_STORED_RESULT = [
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.patient_gender"),
      accessor: "gender",
    },
    {
      Header: t("columns.report_for"),
      accessor: "report_for",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
    {
      Header: t("columns.measurement_date"),
      accessor: "date",
    },
    {
      Header: t("columns.measurement_time"),
      accessor: "time",
    },
  ];

  const STAFF_INTAKE_COLUMNS = [
    {
      Header: t("columns.appt_id"),
      accessor: "id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_type"),
      accessor: "visit_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.robot_type"),
      accessor: "robot_type",
      Cell: RobotTypeChip,
    },
    {
      Header: t("columns.robot_id"),
      accessor: "robot_id",
    },
    { Header: t("columns.patient_id"), accessor: "patient_id" },
    { Header: t("columns.patient_name"), accessor: "patient_name" },
    { Header: t("columns.dob"), accessor: "dob" },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const PATIENT_SUMMARY_NOTES = [
    {
      Header: t("columns.appt_id"),
      accessor: "appt_id",
    },
    {
      Header: t("columns.appt_type"),
      accessor: "appt_type",
    },
    {
      Header: t("columns.visit_type"),
      accessor: "visit_type",
    },
    {
      Header: t("columns.appt_date"),
      accessor: "appt_date",
    },
    {
      Header: t("columns.appt_time"),
      accessor: "appt_time",
    },
    {
      Header: t("columns.dob"),
      accessor: "birth",
    },
    {
      Header: t("columns.relationship"),
      accessor: "relationship",
    },
    {
      Header: t("columns.patient_id"),
      accessor: "patient_id",
    },
    {
      Header: t("columns.patient_name"),
      accessor: "patient_name",
    },
    {
      Header: t("columns.hf_name"),
      accessor: "hf_name",
    },
  ];

  const data = {
    appointment: APPOINTMENT_COLUMNS,
    doctorAppointment: DOCTOR_APPOINTMENT_COLUMNS,
    patientAppointment: PATIENT_APPOINTMENT_COLUMNS,
    staffAppointment: STAFF_APPOINTMENT_COLUMNS,
    storingResult: STORING_RESULTS_COLUMNS,
    superAppointments: SUPER_ADMIN_APPOINTMENTS,
    superSummary: SUPER_ADMIN_SUMMARY_NOTES,
    superReport: SUPER_ADMIN_SUMMARY_REPORT,
    doctorSummary: DOCTOR_SUMMARY_COLUMNS,
    patientFollowUp: PATIENT_FOLLOW_UP,
    patientOrder: PATIENT_ORDER,
    patientReferral: PATIENT_REFERRAL,
    patientPrescription: PATIENT_PRESCRIPTION,
    patientStoredResult: PATIENT_STORED_RESULT,
    staffIntakeForms: STAFF_INTAKE_COLUMNS,
    patientSummary: PATIENT_SUMMARY_NOTES,
    doctorAppt1: DOCTOR_APPT_COLUMNS1,
    doctorAppt2: DOCTOR_APPT_COLUMNS2,
  };
  const index = type as keyof typeof data;
  return data[index] || [];
};

export interface IDataCardProps {
  avatar?: string;
  avatarSrc?: string;
  cardActions?: ReactNode;
  classname?: string;
}

export interface IAppointmentData {
  appt_id: string;
  appt_type: string;
  appt_date: string;
  appt_time: string;
  robot_id: string;
  robot_name: string;
  patient_id: string;
  patient_name: string;
  hf_name: string;
}

type ConditionalProps =
  | {
      variant: "appointment";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "doctorAppt1";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "doctorAppt2";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "doctorAppointment";
      icon?: never;
      name?: never;
      role?: never;
      flag?: never;
      date?: never;
      id?: never;
      deviceType?: never;
      specialty?: never;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "patientAppointment";
      icon?: never;
      name?: string | null;
      role?: never;
      flag?: never;
      date?: never;
      id?: string | null;
      deviceType?: never;
      specialty?: string | null;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "staffAppointment";
      icon?: never;
      name?: string | null;
      role?: never;
      flag?: never;
      date?: never;
      id?: string | null;
      deviceType?: never;
      specialty?: string | null;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "doctorSummary";
      icon?: never;
      name?: never;
      role?: never;
      flag?: never;
      date?: never;
      id?: never;
      deviceType?: never;
      specialty?: never;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "message";
      icon?: never;
      name: string;
      role?: string;
      flag: string;
      date?: string;
      id?: never;
      deviceType?: never;
      specialty?: string;
      receiver?: never;
      message?: string;
      tableData?: never;
      columns?: never;
      attachments?: string[];
    }
  | {
      variant: "notification";
      icon?: never;
      name: string;
      role: string;
      flag?: never;
      date: string;
      id?: never;
      deviceType?: never;
      specialty?: never;
      receiver?: string;
      message?: string;
      tableData?: never;
      columns?: never;
      attachments?: never;
    }
  | {
      variant: "storingResult";
      avatar: string;
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType: string;
      specialty?: never;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "superAppointments";
      icon?: never;
      name?: string;
      role?: never;
      flag?: never;
      date?: never;
      id?: string;
      deviceType?: never;
      specialty?: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "superSummary";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "superReport";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "patientFollowUp";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "patientOrder";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "patientReferral";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "patientPrescription";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "patientStoredResult";
      avatar?: never;
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id?: never;
      deviceType?: string;
      specialty?: never;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "staffIntakeForms";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    }
  | {
      variant: "patientSummary";
      icon?: never;
      name: string;
      role?: never;
      flag?: never;
      date?: never;
      id: string;
      deviceType?: never;
      specialty: string;
      receiver?: never;
      message?: never;
      tableData: {};
      columns?: any[];
      attachments?: never;
    };

type DataCardProps = IDataCardProps & ConditionalProps;

const DataCard = ({
  name,
  variant = "appointment",
  avatar,
  role,
  date,
  id,
  deviceType,
  specialty,
  receiver,
  message,
  flag,
  tableData,
  icon,
  cardActions,
  classname,
  columns,
  attachments,
  ...rest
}: DataCardProps) => {
  const tableColumns = useDataCardTableColumns(variant);

  return (
    <>
      <div className={clsx([classes.card, classname])}>
        <div className={classes.card__body}>
          {(avatar ||
            (variant !== "doctorAppointment" &&
              variant !== "doctorSummary")) && (
            <div className={classes.card__info}>
              {variant !== "patientStoredResult" && (
                <div className="d-flex align-items-center">
                  {icon ? (
                    <div className={classes.card__icon}> {icon} </div>
                  ) : (
                    <Avatar
                      size="big"
                      name={name!}
                      src={avatar || ""}
                      className={classes.card__avatar}
                    />
                  )}
                </div>
              )}

              <div className="d-flex flex-column justify-content-center">
                <div className={classes.card__title}>
                  <div className="PE-1">{name}</div>
                  {variant === "message" ? (
                    <>
                      <div className={classes.card__id}>
                        {id || role ? `  (${id || role})` : ""}{" "}
                      </div>
                      <div className={classes.card__flag}>
                        <CountryFlag flag={flag!} />
                      </div>
                    </>
                  ) : (
                    variant !== "patientStoredResult" && (
                      <div className={classes.card__id}>
                        {id || role ? `  (${id || role})` : ""}{" "}
                      </div>
                    )
                  )}
                </div>

                {(specialty || date || deviceType) && (
                  <span
                    className={date ? classes.card__date : classes.card__type}
                  >
                    {specialty || date || deviceType}
                  </span>
                )}
              </div>
            </div>
          )}

          <div
            className={clsx(
              "d-flex",
              (variant === "doctorAppointment" ||
                variant === "superAppointments") &&
                "flex-grow-1",
              classes.overflowAuto
            )}
            // style={
            //   variant === "doctorAppointment" || variant === "superAppointments"
            //     ? {
            //         display: "flex",
            //         width: "100%",
            //       }
            //     : { display: "flex" }
            // }
          >
            {cardActions}
          </div>
        </div>

        {message && (
          <div className={classes.card__content}>
            {/* <span className={classes.card__type}> to: {receiver} </span> */}
            <div
              dangerouslySetInnerHTML={{ __html: message }}
              className={clsx(
                classes.card__message,
                variant === "notification" && classes.textOverflow
              )}
            />
          </div>
        )}

        {attachments && attachments?.length > 0 && (
          <AttachmentList attachments={attachments} />
        )}

        {tableData && (
          <div className={classes.card__content}>
            <BasicTable columns={columns || tableColumns} data={[tableData]} />
          </div>
        )}
      </div>
    </>
  );
};

export default DataCard;

import { IMenuOption, IPhoneNumber } from "interfaces";

export interface IRegisterForm {
  firstName: string;
  lastName: string;
  userName: string;
  // password: string;
  birthday: Date | string;
  gender: IMenuOption;
  countryOfLicense: IMenuOption;
  licenseNo: string;
  speciality: IMenuOption;
  subSpecialty: IMenuOption;
  title: IMenuOption;
  country: IMenuOption;
  state: IMenuOption;
  city: IMenuOption;
  hfCountry: IMenuOption;
  hfState: IMenuOption;
  hfCity: IMenuOption;
  hfType: IMenuOption;
  hfName: IMenuOption;
  department: IMenuOption;
  departments: IMenuOption[];
  docType: IMenuOption;
  zipCode: string;
  email: string;
  mobile: IPhoneNumber;
  alternativePhoneNumber: IPhoneNumber;
  emergencyPhoneNumber: IPhoneNumber;
  timezone: IMenuOption;
  address: string;
  languages: { language: ""; language_fluency: "" }[];
  employeeNumber: string;
  NPINumber: string;
  nationalId: string;
  mrnNumber: string;
  cmoNumber: string;
  website?: string;
}

export const REGISTER_INITIAL_VALUES: IRegisterForm = {
  firstName: "",
  lastName: "",
  userName: "",
  // password: "",
  birthday: "",
  gender: { key: "", value: "" },
  countryOfLicense: { key: "", value: "" },
  licenseNo: "",
  speciality: { key: "", value: "" },
  subSpecialty: { key: "", value: "" },
  title: { key: "", value: "" },
  country: { key: "", value: "" },
  state: { key: "", value: "" },
  city: { key: "", value: "" },
  hfCountry: { key: "", value: "" },
  hfState: { key: "", value: "" },
  hfCity: { key: "", value: "" },
  hfType: { key: "", value: "" },
  hfName: { key: "", value: "" },
  department: { key: "", value: "" },
  departments: [],
  docType: { key: "", value: "" },
  zipCode: "",
  email: "",
  mobile: { code: "", number: "" },
  alternativePhoneNumber: { code: "", number: "" },
  emergencyPhoneNumber: { code: "", number: "" },
  timezone: { key: "", value: "" },
  address: "",
  languages: [],
  employeeNumber: "",
  NPINumber: "",
  nationalId: "",
  mrnNumber: "",
  cmoNumber: "",
  website: "",
};

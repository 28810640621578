import { useQuery } from "react-query";
import { getFacilityRobotsByTypeAndLevel } from "api/dropdown";
import { EmojiHappyBold } from "components/icon";
import { getDropdownKey } from "utils/dropdowns";
import { FastDropdown } from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import { convertToString } from "utils/form";
import { useTranslation } from "react-i18next";
import { facilityRobotsByTypeAndLevelKey } from "features/facilityRobot/keys";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the `Robot Type` that this `Robot Name` is dependent on it.
   *
   * without knowing the `Robot Type` we can not fetch the`Robot Name`.
   */
  robotTypeFieldName: keyof T & string;
  /**
   * The field name of the `Robot Level` that this `Robot Name` is dependent on it.
   *
   * without knowing the `Robot Level` we can not fetch the`Robot Name`.
   */
  robotLevelFieldName: keyof T & string;
  /**
   * The field name of the `Facility` that this `Robot Name` is dependent on it.
   *
   * without knowing the `Facility` we can not fetch the`Robot Name`.
   */
  hfFieldName: keyof T & string;

  /**
   * you can filter your robots one level more by department of HFFieldName value. (its optional)
   */
  departmentFieldName?: keyof T & string;
}

export function FacilityRobots<T>({
  formik,
  robotLevelFieldName,
  robotTypeFieldName,
  hfFieldName,
  departmentFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the values from formik values and extract its key
  const robotTypeKey = getDropdownKey(formik, robotTypeFieldName);
  const robotLevelKey = getDropdownKey(formik, robotLevelFieldName);
  const hfNameKey = getDropdownKey(formik, hfFieldName);
  const departmentKey = getDropdownKey(formik, departmentFieldName);

  // fetch data
  const departmentId = !!departmentKey ? Number(departmentKey) : undefined;
  const { data, isLoading, isError } = useQuery(
    [
      facilityRobotsByTypeAndLevelKey,
      robotTypeKey,
      robotLevelKey,
      hfNameKey,
      departmentKey,
    ],
    () =>
      getFacilityRobotsByTypeAndLevel(
        Number(hfNameKey),
        convertToString(robotTypeKey),
        convertToString(robotLevelKey),
        departmentId
      ),
    {
      enabled: !!robotTypeKey && !!robotLevelKey && !!hfNameKey,
    }
  );

  return (
    <FastDropdown
      label={t("robot_id")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!robotTypeKey || !robotLevelKey || !hfNameKey || disabled}
      icon={EmojiHappyBold}
      {...props}
    />
  );
}

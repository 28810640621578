import classes from "./styles.module.scss";
import { ErrorBold } from "components/icon";
import clsx from "clsx";

type Props = {
  message?: string;
  parentClassName?: string;
  errorIconClassName?: string;
  messageClassName?: string;
};

const ErrorTailIcon = ({
  message = "An Error occurred.",
  errorIconClassName,
  messageClassName,
  parentClassName,
}: Props) => {
  return (
    <div className={clsx(["d-flex align-items-center g-1", parentClassName])}>
      <ErrorBold className={clsx([classes.errorIcon, errorIconClassName])} />
      <div className={clsx(["d-flex px-2 py-2", messageClassName])}>
        {message}
      </div>
    </div>
  );
};

export default ErrorTailIcon;

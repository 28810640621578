import clsx from "clsx";
import classes from "./styles.module.scss";
import {
  BigMicIcon,
  BigVideoIcon,
  BigVolumeIcon,
  CameraCloseLinear,
  CameraOpenLinear,
  ErrorIcon,
  HeadphoneBold,
  MicBold,
  OkIcon,
  PendingIcon,
  TimerBold,
  VideoVisitLogo,
} from "../../icon";
import Button from "../../core/button";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import AutoComplete from "../../core/autoComplete";
import Switch from "../../core/switch";
import { ArrowUpLinear } from "../../icon/ArrowUpLinear";
import { Dropdown } from "../../core/dropdown";
import { DropdownMenu } from "../../core/dropdownMenu";
import DropdownItem from "../../core/dropdownItem";
import Loader from "react-spinners/BeatLoader";
import { notify } from "../../core/toast";
import { humanizedDate } from "../../../utils/date";
import { useTranslation } from "react-i18next";
// @ts-ignore
import beep from "assets/sounds/ding.mp3";
import * as Sentry from "@sentry/react";

export interface IFirstPageProps {
  isLoading: boolean;
  isError: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  date: Date;
  title: string;
  setJoined: Dispatch<SetStateAction<boolean>>;
  onDataChange: (data: {
    micId: string;
    camId: string;
    volume: number;
    speakerId: string;
  }) => any;
}
const restoreScroll = () => {
  document.body.style.overflowY = "auto";
};

// function that get input device list
const getMediaDevices = (
  setDevices: (
    value:
      | ((prevState: MediaDeviceInfo[]) => MediaDeviceInfo[])
      | MediaDeviceInfo[]
  ) => void
) => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((stream) => {
      setDevices(stream);
    })
    .catch((error) => {
      console.log(error);
    });
};

function openCamera(
  v: boolean,
  setCam: (value: ((prevState: string) => string) | string) => void,
  camId: string,
  videoRef: React.RefObject<HTMLVideoElement>
) {
  if (videoRef.current) {
    videoRef.current?.pause();
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: false })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        console.error(`An error occurred: ${err}`);
        setCam("error");
      });
  }
  if (v) {
    setCam(camId === "" ? "open" : camId);
    navigator.mediaDevices
      .getUserMedia({
        video: camId === "" ? true : { deviceId: camId },
        audio: false,
      })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        setCam("error");
        console.error(`An error occurred: ${err}`);
      });
  } else {
    setCam("");
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: false })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        console.error(`An error occurred: ${err}`);
        setCam("error");
      });
  }
}

const FirstPage = ({
  date,
  title,
  setJoined,
  onDataChange,
  isLoading,
  isError,
  setOpen,
}: IFirstPageProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  const [page, setPage] = useState(0);
  // cam open state
  const [camOpen, setCamOpen] = useState(false);
  // sound state
  const [sound, setSound] = useState(0);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [volume] = useState(100);
  const [cam, setCam] = useState("");
  const [mic, setMic] = useState("");
  const [camId, setCamId] = useState("");
  const [micId, setMicId] = useState("");
  const [speakerId, setSpeakerId] = useState("");
  const [checking, setChecking] = useState(false);

  const [isRequested, setIsRequested] = useState(false);

  /* // Function to play the test sound
  function playTestSound(selectedOutputId: string): void {
    if (!audioContext) setAudioContext(new window.AudioContext());
    if (!audioContext) return;
    // Get the selected audio output device
    const selectedOutputDevice = audioContext.destination;

    // Create a stereo panning node
    const panner = audioContext.createStereoPanner();
    panner.pan.value = 0; // Center the sound

    // Connect the panner to the selected output device
    panner.connect(selectedOutputDevice);

    // Create an oscillator node for the test sound
    const oscillator = audioContext.createOscillator();
    oscillator.type = "sine"; // Sine wave
    oscillator.frequency.value = 440; // 440Hz

    // Connect the oscillator to the panner
    oscillator.connect(panner);
    // Start the oscillator
    oscillator.start();

    // Stop the oscillator after 1 second
    setTimeout(() => {
      oscillator.stop();
    }, 2000);
  }*/

  const playBeepSound = async (deviceId: string, volume: number = 100) => {
    try {
      const audioOutputConstraints = {
        audio: deviceId
          ? {
              deviceId: { exact: deviceId },
            }
          : true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(
        audioOutputConstraints
      );
      const audioContext = new window.AudioContext();
      const source = audioContext.createMediaStreamSource(stream);

      const response = await fetch(beep);
      const arrayBuffer = await response.arrayBuffer();

      // Decode the audio file data
      audioContext.decodeAudioData(
        arrayBuffer,
        (audioBuffer) => {
          const soundSource = audioContext.createBufferSource();
          soundSource.buffer = audioBuffer;

          const gainNode = audioContext.createGain();
          gainNode.gain.value = Math.min(volume / 100, 1); // Control the volume

          soundSource.connect(gainNode);
          gainNode.connect(audioContext.destination);

          soundSource.start(0); // Play the sound

          soundSource.onended = () => {
            source.disconnect();
            stream.getTracks().forEach((track) => track.stop());
          };
        },
        (error) => {}
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (isError) {
      restoreScroll();
      setOpen(false);
    }
  }, [isError, setOpen]);

  useEffect(() => getMediaDevices(setDevices), []);
  useEffect(() => {
    // onDataChange({
    //   micId: micId,
    //   camId: camId === "" ? renderVideoDevices(devices)[0]?.deviceId : camId,
    //   volume: volume,
    //   speakerId: speakerId,
    // });
  }, [volume, camId, micId, speakerId, onDataChange, devices]);
  let scriptProcessor: ScriptProcessorNode;
  const getVolume = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: micId === "" ? true : { deviceId: micId },
      })
      .then(function (stream) {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = function () {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          const average = arraySum / array.length;
          setSound(Math.round(average));
        };
      })
      .catch(function (err) {
        /* handle the error */
        console.error(err);
      });
  };

  const renderLevels = () => {
    const items = [];

    for (let i = 0; i < 30; i++) {
      items.push(
        <div
          className={clsx(
            classes.firstPage__level,
            sound / 2 > i && classes.firstPage__levelActive
          )}
        ></div>
      );
    }

    return items;
  };

  function stopCamera() {
    // Get the user's media stream
    const stream = videoRef?.current?.srcObject;

    // If the stream is null, return early
    if (!stream) {
      return;
    }

    // Get all tracks in the stream and stop each one
    if ("getTracks" in stream) {
      stream?.getTracks()?.forEach((track) => track.stop());
    }

    // Remove the stream from the video element
    videoRef.current.srcObject = null;
  }

  const renderCamera = () => {
    function getDropdownMenu() {
      return (
        <>
          <DropdownItem
            onClick={(e) => e.stopPropagation()}
            className={clsx(classes.dropdown__title)}
          >
            {t("select_camera")}:
          </DropdownItem>
          {renderVideoDevices(devices).map((m, index) => {
            return (
              <DropdownItem
                key={index}
                className={clsx(classes.dropdown__item)}
                onClick={() => {
                  setCam(m.deviceId);
                  setCamId(m.deviceId);
                  openCamera(camOpen, setCam, camId, videoRef);
                }}
              >
                {m.label}
              </DropdownItem>
            );
          })}
        </>
      );
    }

    return (
      <div className={clsx(classes.firstPage__content)}>
        <div className={clsx(classes.firstPage__head)}>
          <div className={clsx(classes.firstPage__iconPlace)}>
            <BigVideoIcon />
            <div className={clsx(classes.firstPage__iconAbove)}>
              {cam === "" ? (
                <PendingIcon />
              ) : cam === "error" ? (
                <ErrorIcon />
              ) : (
                <OkIcon />
              )}
            </div>
          </div>
          <div>{t("video_setting")}</div>
        </div>
        <div className={clsx(classes.firstPage__videoPart)}>
          <div className={clsx(classes.firstPage__video)}>
            {camOpen && (
              <div
                className={clsx(
                  "embed-responsive embed-responsive-16by9",
                  classes.firstPage__vid
                )}
              >
                <video
                  style={{
                    width: "100%",
                    height: "90%",
                    objectFit: "contain",
                  }}
                  ref={videoRef}
                  className={clsx("embed-responsive-item")}
                />
              </div>
            )}
            <div className={clsx(classes.firstPage__videoContent)}>
              <div className={clsx(classes.firstPage__videoTitle)}>
                {t("camera_off")}
              </div>
              <div className={clsx(classes.firstPage__icons)}>
                <div
                  className={clsx(classes.firstPage__btn, classes.white)}
                  onClick={() => {
                    navigator.mediaDevices
                      .getUserMedia({ video: true })
                      .then(() => {
                        getMediaDevices(setDevices);
                      });
                  }}
                >
                  <div>
                    {camOpen ? <CameraOpenLinear /> : <CameraCloseLinear />}
                  </div>
                  <Switch
                    checked={camOpen}
                    onChange={(v) => {
                      openCamera(v, setCam, camId, videoRef);
                      setCamOpen(!camOpen);
                    }}
                  />
                  <Dropdown anchor={"top"}>
                    <div id={"asldjasldasdaskj"}>
                      <ArrowUpLinear />
                    </div>
                    <DropdownMenu
                      anchor={"top-center"}
                      toggleId={"asldjasldasdaskj"}
                      className={clsx(classes.dropdown)}
                    >
                      {getDropdownMenu()}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(classes.firstPage__right)}>
          <Button
            onClick={() => {
              stopCamera();
              setCamOpen(false);
              setPage((p) => p - 1);
            }}
            variant={"outlined"}
            color={"secondary"}
            className={clsx(classes.firstPage__button)}
          >
            {t("back")}
          </Button>
          <Button
            onClick={() => {
              stopCamera();
              onDataChange({
                micId: micId,
                camId: /*camId === ""
                    ? renderVideoDevices(devices)[0]?.deviceId
                    : */ camId,
                volume: volume,
                speakerId: speakerId,
              });
              setJoined(true);
            }}
            className={clsx(classes.firstPage__button)}
            // disabled={cam === ""}
          >
            {t("join_now")}
          </Button>
        </div>
      </div>
    );
  };

  const renderAgree = () => {
    return (
      <div className={clsx(classes.firstPage__content)}>
        <div className={clsx(classes.firstPage__head)}>
          <div>{t("agreement")}</div>
        </div>
        <div className={clsx(classes.firstPage__form)}>
          <div
            className={clsx(
              classes.firstPage__text,
              classes.firstPage__justify
            )}
          >
            {t("agreement_text")}
          </div>

          <div className={clsx(classes.firstPage__right)}>
            <Button
              color={"secondary"}
              variant={"outlined"}
              onClick={() => {
                restoreScroll();
                setOpen(false);
              }}
              className={clsx(
                classes.firstPage__button,
                classes.firstPage__bigBtn
              )}
            >
              {t("cancel")}
            </Button>

            <Button
              isLoading={isRequested}
              onClick={() => {
                setIsRequested(true);
                navigator.mediaDevices
                  .getUserMedia({ video: true, audio: true })
                  .then((stream) => {
                    setIsRequested(false);
                    setPage((page) => page + 1);

                    setTimeout(() => {
                      stream?.getTracks()?.forEach((track) => track?.stop());
                    }, 100);
                  })
                  .catch((err) => {
                    console.error(err);
                    Sentry.captureException(err);
                    setIsRequested(false);
                    if (
                      err.name === "NotFoundError" ||
                      err.name === "DevicesNotFoundError"
                    ) {
                      //required track is missing
                      notify.error(t("required_track_error"));
                    } else if (
                      err.name === "NotReadableError" ||
                      err.name === "TrackStartError"
                    ) {
                      //webcam or mic are already in use
                      notify.error(t("already_in_use_error"));
                    } else if (
                      err.name === "OverconstrainedError" ||
                      err.name === "ConstraintNotSatisfiedError"
                    ) {
                      //constraints can not be satisfied by avb. devices
                      notify.error(t("constraints_error"));
                    } else if (
                      err.name === "NotAllowedError" ||
                      err.name === "PermissionDeniedError"
                    ) {
                      //permission denied in browser
                      notify.error(t("permission_denied_error"));
                    } else if (
                      err.name === "TypeError" ||
                      err.name === "TypeError"
                    ) {
                      //empty constraints object
                      notify.error(t("constraint_type_error"));
                    } else {
                      //other errors
                      notify.error(t("device_occurred_error"));
                    }
                  });
              }}
              className={clsx(
                classes.firstPage__button,
                classes.firstPage__bigBtn
              )}
            >
              {t("continue")}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const renderVoice = () => {
    return (
      <div className={clsx(classes.firstPage__content)}>
        <div className={clsx(classes.firstPage__head)}>
          <div className={clsx(classes.firstPage__iconPlace)}>
            <BigMicIcon />
            <div className={clsx(classes.firstPage__iconAbove)}>
              {mic === "" ? (
                <PendingIcon />
              ) : mic === "error" ? (
                <ErrorIcon />
              ) : (
                <OkIcon />
              )}
            </div>
          </div>
          <div className={"text-center"}>{t("mic_setting")}</div>
        </div>
        <div className={clsx(classes.firstPage__form)}>
          <div
            className={clsx(classes.firstPage__input)}
            onClick={() => {
              navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(() => {
                  getMediaDevices(setDevices);
                })
                .catch(() => {
                  notify.error(t("give_permission"));
                });
            }}
          >
            <div className={clsx(classes.firstPage__inputTitle)}>
              {t("input_device")}
            </div>
            <AutoComplete
              placeholder={"Default"}
              icon={MicBold}
              onChange={(e) => {
                setMic(e.key);
                setMicId(e.key);
              }}
              value={{
                key: micId,
                value:
                  devices.filter((d) => micId === d.deviceId)[0]?.label || "",
              }}
              options={devices
                .filter((d) => d.kind === "audioinput")
                .map((d) => {
                  return { key: d.deviceId, value: d.label };
                })}
            />
          </div>
          <div className={clsx(classes.firstPage__input)}>
            <div className={clsx(classes.firstPage__inputTitle)}>
              {t("mic_test")}
            </div>
            <div className={clsx(classes.firstPage__text)}>
              {t("mic_test_text")}
            </div>
            <Button
              className={clsx(
                classes.firstPage__button,
                classes.firstPage__smallBtn
              )}
              onClick={() => {
                if (!checking) {
                  setChecking(true);
                  getVolume();
                  setTimeout(() => {
                    setChecking(false);
                    scriptProcessor.disconnect();
                    setSound(0);
                  }, 10000);
                }
              }}
            >
              {checking ? <Loader size={10} color={"#fff"} /> : t("check")}
            </Button>
            <div className={clsx(classes.firstPage__levels)}>
              {renderLevels()}
            </div>
          </div>
        </div>
        <div className={clsx(classes.firstPage__right)}>
          <Button
            color={"secondary"}
            variant={"outlined"}
            onClick={() => {
              restoreScroll();
              setOpen(false);
            }}
            className={clsx(
              classes.firstPage__button,
              classes.firstPage__bigBtn
            )}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => setPage((p) => p + 1)}
            className={clsx(
              classes.firstPage__button,
              classes.firstPage__bigBtn
            )}
            // disabled={mic === ""}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    );
  };

  const renderVolume = () => {
    return (
      <div className={clsx(classes.firstPage__content)}>
        <div className={clsx(classes.firstPage__head)}>
          <div className={clsx(classes.firstPage__iconPlace)}>
            <BigVolumeIcon />
            <div className={clsx(classes.firstPage__iconAbove)}>
              <OkIcon />
            </div>
          </div>
          <div>{t("volume_setting")}</div>
        </div>
        <div className={clsx(classes.firstPage__form)}>
          <div
            className={clsx(classes.firstPage__input)}
            onClick={() => {
              navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(() => {
                  getMediaDevices(setDevices);
                })
                .catch(() => {
                  notify.error(t("give_permission"));
                });
            }}
          >
            <div className={clsx(classes.firstPage__inputTitle)}>
              {t("output_device")}
            </div>
            <AutoComplete
              placeholder={t("default")}
              icon={HeadphoneBold}
              onChange={(e) => {
                setSpeakerId(e.key);
              }}
              value={{
                key: speakerId,
                value:
                  devices.filter((d) => d.deviceId === speakerId)[0]?.label ||
                  "",
              }}
              options={devices
                .filter((d) => d.kind === "audiooutput")
                .map((d) => {
                  return { key: d.deviceId, value: d.label };
                })}
            />
          </div>
          {/*  <div className={clsx(classes.firstPage__input)}>
            <div className={clsx(classes.firstPage__inputTitle)}>
              Output Volume
            </div>
            <input
              type={"range"}
              className={clsx(classes.firstPage__range)}
              value={volume}
              max={100}
              min={0}
              onChange={(e) => setVolume(Number(e.target.value))}
            />
          </div>*/}
        </div>
        <Button onClick={() => playBeepSound(speakerId)}>
          {t("play_sound")}
        </Button>
        <div className={clsx(classes.firstPage__right)}>
          <Button
            onClick={() => setPage((p) => p - 1)}
            variant={"outlined"}
            color={"secondary"}
            className={clsx(classes.firstPage__button)}
          >
            {t("back")}
          </Button>
          <Button
            onClick={() => setPage((p) => p + 1)}
            className={clsx(classes.firstPage__button)}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={clsx(classes.firstPage__content, classes.loading)}>
        <div>
          <TimerBold />
        </div>
        <div className={clsx(classes.loading__title)}>{t("wait")}</div>
        <Button
          variant={"outlined"}
          color={"secondary"}
          onClick={() => {
            restoreScroll();
            setOpen(false);
          }}
        >
          {t("cancel")}
        </Button>
      </div>
    );
  };

  return (
    <div className={clsx(classes.firstPage)}>
      <div className={clsx(classes.firstPage__top)}>
        <div className={clsx(classes.videoVisit__start)}>
          <VideoVisitLogo />
          <div className={clsx(classes.videoVisit__content)}>
            <div className={clsx(classes.firstPage__title)}>{title}</div>
            <div className={clsx(classes.firstPage__subTitle)}>
              {humanizedDate(date, true)}
            </div>
          </div>
        </div>
      </div>
      {page === 3 && !isLoading && renderCamera()}
      {page === 2 && !isLoading && renderVolume()}
      {page === 1 && !isLoading && renderVoice()}
      {page === 0 && !isLoading && renderAgree()}
      {isLoading && renderLoading()}
    </div>
  );
};

const renderVideoDevices = (devices: MediaDeviceInfo[]) => {
  return devices.filter((d) => d.kind === "videoinput");
};
export default FirstPage;

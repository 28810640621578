import IDropdown from "components/formikFields/IDropdown";
import {FastDropdown} from "../formikFields";
import {GPSBold} from "components/icon";
import {useDoctorTypesOptions} from "hooks/useDoctorTypesOptions";
import {useTranslation} from "react-i18next";

interface IProps<T> extends IDropdown<T> {}

export function DoctorType<T>(props: IProps<T>) {
  const { t } = useTranslation("form");

  // get the doctor types from server
  const { OPTIONS, isError, isLoading } = useDoctorTypesOptions();

  return (
    <FastDropdown
      label={t("doctor_type")}
      options={OPTIONS}
      isError={isError}
      isLoading={isLoading}
      icon={GPSBold}
      {...props}
    />
  );
}

import DatePicker from "../datePicker";
import { CalendarBold } from "../../icon";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export interface ITimespanFilterProps {
  from: Date;
  to: Date;
  setFrom: (d: Date) => any;
  setTo: (d: Date) => any;
  disabled?: boolean;
}

const TimespanFilter = ({
  to,
  setTo,
  from,
  setFrom,
  disabled,
}: ITimespanFilterProps) => {
  const { t } = useTranslation("filter");

  return (
    <div>
      <div className={clsx("d-flex flex-column gap-2")}>
        <div>{t("from")}</div>
        <DatePicker
          disabled={disabled}
          placeholder={t("from")}
          icon={CalendarBold}
          value={from}
          onDateSelect={(d) => setFrom(d)}
        />
        <div>{t("to")}</div>
        <DatePicker
          disabled={disabled}
          placeholder={t("to")}
          icon={CalendarBold}
          value={to}
          onDateSelect={(d) => setTo(d)}
        />
      </div>
    </div>
  );
};

export default TimespanFilter;

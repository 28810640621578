import { notify } from "../components/core/toast";
import { useHistory } from "react-router";
// @ts-ignore
import * as html2pdf from "html2pdf.js";

const opt = {
  margin: 12.7,
  html2canvas: { scale: 4 },
  jsPDF: { format: "a2", orientation: "portrait" },
};
const useDownloadPdf = () => {
  const history = useHistory();
  return () => {
    setTimeout(() => {
      const element = document.getElementById("printPage");
      if (element) {
        html2pdf()
          .from(element)
          .set(opt)
          .save()
          .then(() => {
            history.goBack();
            notify.success("PDF generated successfully");
          })
          .catch((error: any) => {
            notify.error("Error generating PDF");
          });
      } else {
        notify.error("Element not found");
      }
    }, 3000);
  };
};

export default useDownloadPdf;

import { RedirectToDashboard } from "components/common/redirectToDashboard";
import { lazy } from "react";
import { TRoute } from "config/router";
import { DASHBOARD_GLOBAL_ROUTES } from "./globalDashboard";
import { lazyRetry } from "utils/route";

const DNA_ROUTES: TRoute = {
  path: "dna",
  exact: true,
  component: RedirectToDashboard,
  inners: [
    {
      path: "dashboard",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/dna/dashboard/index"))
      ),
      exact: true,
    },
    {
      path: "doctor-availability-time",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/dna/doctorAvaiabilityTime"))
      ),
      exact: true,
    },
    {
      path: "doctor-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/doctorManagement"))
      ),
      exact: true,
      inners: [
        {
          path: "create",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
        {
          path: ":id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/superAdmin/doctorManagement/doctorRegistration/form"
                )
            )
          ),
        },
      ],
    },
    ...DASHBOARD_GLOBAL_ROUTES,
  ],
};

export default DNA_ROUTES;

import { IMenuOption } from "components/core/autoComplete";
import { FormikProps } from "formik";

export function getDropdownOption(
  options: IMenuOption[] | undefined,
  name?: string,
  id?: string
) {
  const foundOption = options?.find((item) => {
    if (id) {
      return item.key.toLowerCase() === id.toLowerCase();
    } else if (name) {
      return item.value.toLowerCase() === name.toLowerCase();
    }
    return undefined;
  });

  if (foundOption) {
    return foundOption;
  }
  return { key: "", value: "" };
}

export function getMultiSelectDropdownOptions(
  options: IMenuOption[] | undefined,
  names?: string[],
  ids?: string[]
) {
  const result: IMenuOption[] = [];
  if (options) {
    if (ids) {
      ids.forEach((id) => {
        const foundOption = getDropdownOption(options, undefined, id);
        if (foundOption.key !== "") result.push(foundOption);
      });
    } else if (names) {
      names.forEach((name) => {
        const foundOption = getDropdownOption(options, name, undefined);
        if (foundOption.key !== "") result.push(foundOption);
      });
    }
  }

  return result;
}

/**
 * extract the option and return itself and its key and value
 * @param formik
 * @param fieldName the field name of the dropdown that u want to extract its value from the formik.values use in formik
 * @returns option and its key and value in a object
 */
export function getDropdownValue<T>(
  formik: FormikProps<T>,
  fieldName: (keyof T & string) | undefined
): {
  option: IMenuOption | undefined;
  key: string | undefined;
  value: string | undefined;
} {
  const empty = { option: undefined, key: undefined, value: undefined };
  if (fieldName === undefined) {
    return empty;
  }
  const option = formik.values[fieldName] as unknown as IMenuOption | undefined;
  if (option === undefined) {
    return empty;
  }
  return { option: option, key: option.key, value: option.value };
}
export function getDropdownKey<T>(
  formik: FormikProps<T>,
  fieldName?: keyof T & string
) {
  if (!fieldName) {
    return undefined;
  }
  return getDropdownValue(formik, fieldName).key;
}

import React, { memo, useMemo } from "react";
import { EXCEL_FORMATS, IMAGE_FORMATS } from "constants/uploader";
import ExcelFile from "assets/images/dragAndDrop/excelFile.svg";
import PDF from "assets/images/pdf_image.png";
import classes from "./styles.module.scss";
import { TrashLinear } from "components/icon";
import { humanizedFileSize } from "utils/convert";

interface IFileRow {
  file: File;
  index: number;
  onRemove?: (index: number) => void;
  filePreview?: boolean;
}

// row component for list of uploaded file
function FileRow({ file, onRemove, index, filePreview = true }: IFileRow) {
  const fileType = useMemo(() => {
    if (EXCEL_FORMATS.includes(file.type)) return "excel";
    if (IMAGE_FORMATS.includes(file.type)) return "image";
  }, [file.type]);

  //render component
  return (
    <div className={classes.fileRow}>
      {filePreview && (
        <img
          className={
            fileType === "image"
              ? classes.fileRow__image
              : classes.fileRow__excel
          }
          src={
            file?.type?.includes("pdf")
              ? PDF
              : fileType === "excel"
              ? ExcelFile
              : URL.createObjectURL(file)
          }
          alt="file"
        />
      )}

      <div className={classes.fileRow__info}>
        {file.name} {"("}
        {humanizedFileSize(file.size)}
        {")"}
      </div>
      {/*      <div className="d-flex ">
        <SuccessSquareLinear />
      </div>*/}
      {onRemove && (
        <div className="d-flex">
          <TrashLinear
            className={classes.fileRow__trash}
            onClick={() => onRemove(index)}
          />
        </div>
      )}
    </div>
  );
}

export default memo(FileRow);

import { useEffect, useState } from "react";

type Props = {
  isMenuOpen: boolean;
  onBlur?: () => void;
};

const useIsDropdownDirty = ({ isMenuOpen, onBlur }: Props) => {
  /**
   * for checking if the user has opened the menu yet.
   */
  const [isDirty, setIsDirty] = useState(false);

  /**
   * The story: calling onBlur when the input is dirty
   * Initial states: isMenuOpen:false , isDirty: false
   * deps: isMenuOpen
   * user clicked the input -> set isMenuOpen to true
   * so we go to the below useEffect
   * check for the isMenuOpen state to be true
   * if yse set isDirty to true
   * use clicked outside -> set isMenuOpen to false
   * got to the useEffect
   * check if the isMenuOpen is false and isDirty is true
   * call the onBlur
   */
  useEffect(() => {
    if (isMenuOpen && !isDirty) setIsDirty(true);
    else if (!isMenuOpen && isDirty) onBlur?.();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);
  return { isDirty };
};

export default useIsDropdownDirty;

import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { IField } from "./IField";
import FormBaseElement from "components/form/base";
import { getInnerValue } from "utils/form";
import { IValidationObj } from "utils/yupExtended";

type p<U, V> = {
  value: U;
  onChange: (value: V) => void;
  onBlur: () => void;
  validateOnBlur: () => void;
  error: string | IValidationObj;
};
interface IProps<T, U, V> extends IField<T, U>, PropsWithChildren<any> {
  /**
   *
   * @param value in some input's onChange function, the argument type is not the same as the type of value that u set in formik state, so u need a mapper to do this for u.
   *
   * e.x in input component the type the value is `string` but the type of the value of onChange is `React.ChangeEvent<HTMLInputElement>` so you need to extract the value from the target.
   * @returns
   */
  onChangeValueMapper: (...value: any[]) => U;
  children: ((props: p<U, V>) => ReactNode) | ReactNode;
  /**
   * if you put this property to true, in addition to validating onBlur event it will be validating on value changing.
   */
  validateOnValueChanged?: boolean;
}

function FastField<T, U, V>({
  fieldName,
  innerName,
  formik,
  label,
  children,
  fieldClassName,
  validateOnValueChanged,
  onChange,
  onChangeAlongside,
  onChangeValueMapper,
}: IProps<T, U, V>) {
  const value = formik.values[fieldName] as unknown as U;

  //#region handling field touched in ourselves
  const [lastValue, setLastValue] = useState<U>(value);
  const [isDirty, setIsDirty] = useState(false);

  if (JSON.stringify(value) !== JSON.stringify(lastValue)) {
    setIsDirty(true);
    setLastValue(value);
  }
  //#endregion

  const innerValue = getInnerValue(innerName, value);
  const error = formik.errors[fieldName] as string | IValidationObj;
  useEffect(() => {
    // formik touched state does not work properly.
    // so we have to handle it ourselves
    // because in the first time we stuck in this use effect, we have to check if the field is touched or not. so we add isDirty state in its dependencies.
    if (!!validateOnValueChanged && isDirty) {
      if (formik.getFieldMeta(fieldName).touched) {
        try {
          formik.validateField(fieldName);
        } catch (error) {
          console.error(`An error accrued in validating the '${fieldName}'.`);
          console.error(error);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, lastValue]);

  const onChangeHandler = React.useCallback(
    (...value: any[]) => {
      const _value = onChangeValueMapper(...value);
      if (onChange) onChange(_value);
      else {
        const name = innerName ? fieldName + "." + innerName : fieldName;
        formik.setFieldValue(name, _value);
        onChangeAlongside?.(_value);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, onChangeAlongside]
  );
  const validateOnBlurHandler = React.useCallback(
    () => {
      formik.setFieldTouched(fieldName, true);
      try {
        formik.validateField(fieldName);
      } catch (error) {
        console.error(
          `An error accrued in validating the '${fieldName}' field.`
        );
        console.error(error);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onBlurHandler = React.useCallback(
    () => {
      formik.setFieldTouched(fieldName, true);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <FormBaseElement
      error={error}
      label={label || ""}
      nameId={fieldName}
      className={fieldClassName}
    >
      {typeof children === "function"
        ? children({
            value: innerValue !== undefined ? innerValue : value,
            onChange: onChangeHandler,
            onBlur: onBlurHandler,
            validateOnBlur: validateOnBlurHandler,
            error: error,
          })
        : children}
    </FormBaseElement>
  );
}

export default FastField;

import Button from "../../../../core/button";
import {
  CameraLinear,
  CameraOffLinear,
  MicrophoneLinear,
  MicrophoneOffLinear,
  RefreshLinear,
  ScreenShareLinear,
  VolumeLinear,
  VolumeOffLinear,
} from "../../../../icon";
import { ArrowUpLinear } from "../../../../icon/ArrowUpLinear";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { Dropdown } from "../../../../core/dropdown";
import { DropdownMenu } from "../../../../core/dropdownMenu";
import DropdownItem from "../../../../core/dropdownItem";
import Switch from "../../../../core/switch";
import { VideoVisitContext } from "../..";
import IconButton from "../../../../core/iconButton";
import { TRoleName } from "../../../../../api/user";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@react-hook/media-query";
import Slider from "rc-slider";

export interface IBottomBarProps {
  initializedVolume: number;
  onMinimizeClicked?: () => any;
  onVolumeChange?: (volume: number) => any;
  onLeaveClicked?: () => any;
  onMicPick: (deviceId: string) => void;
  onCameraPick: (deviceId: string) => void;
  onScreenShare: () => void;
  onCameraOff?: (off: boolean) => any;
  onGrid?: (grid: boolean) => any;
  role?: TRoleName | "guest";
  isLeaving?: boolean;
  noDisplay?: boolean;
  isShared?: boolean;
  onRefresh?: () => void;
  isRefreshing?: boolean;
}

// function to split device to and audio or video category
function getDeviceLabel(
  devices: MediaDeviceInfo[],
  type: "audioinput" | "videoinput" | "audiooutput",
  onClick: (deviceId: string) => void
) {
  return devices.map((device, index) => {
    if (type === "audioinput")
      if (device.kind === "audioinput")
        return (
          <DropdownItem
            onClick={() => onClick(device.deviceId)}
            key={index}
            className={clsx(classes.dropdown__item)}
          >
            {device.label}
          </DropdownItem>
        );
    if (type === "videoinput")
      if (device.kind === "videoinput")
        return (
          <DropdownItem
            onClick={() => onClick(device.deviceId)}
            key={index}
            className={clsx(classes.dropdown__item)}
          >
            {device.label}
          </DropdownItem>
        );
    if (type === "audiooutput")
      if (device.kind === "audiooutput")
        return (
          <DropdownItem
            onClick={() => onClick(device.deviceId)}
            key={index}
            className={clsx(classes.dropdown__item)}
          >
            {device.label}
          </DropdownItem>
        );
    return <div></div>;
  });
}

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const BottomBar = ({
  initializedVolume,
  onVolumeChange,
  onLeaveClicked,
  onMinimizeClicked,
  onMicPick,
  onCameraPick,
  onScreenShare,
  onCameraOff,
  role,
  isLeaving,
  noDisplay,
  isShared,
  onRefresh,
  isRefreshing,
}: IBottomBarProps) => {
  const { t } = useTranslation("global", { keyPrefix: "video_visit" });

  const mediaQuery = useMediaQuery("(max-width: 714px)");

  // slider percent state for track audio volume
  const [sliderPercent, setSliderPercent] = useState(initializedVolume);
  // slider reference
  // label style for show or hide slider label

  // active video and audio input device state
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const ctx = useContext(VideoVisitContext);

  const [cameraOff] = useState(false);
  // const [micMute, setMicMute] = useState(false);

  // trigger volume change function on every update
  useEffect(() => {
    if (onVolumeChange) {
      onVolumeChange(sliderPercent);
    }
  }, [onVolumeChange, sliderPercent]);

  // initialize media device list
  useEffect(() => getMediaDevices(setDevices), []);

  useEffect(() => {
    if (onCameraOff) {
      onCameraOff(cameraOff);
    }
  }, [cameraOff, onCameraOff]);

  // useEffect(() => {
  //   if (onMicMute) {
  //     // onMicMute(micMute);
  //     ctx.onMicMute(micMute);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [micMute, onMicMute]);

  const renderSoundDropdown = () => {
    return (
      <>
        <DropdownItem className={clsx(classes.dropdown__title)}>
          {t("select_speaker")}:
        </DropdownItem>
        {getDeviceLabel(devices, "audiooutput", (id) => {
          OT.setAudioOutputDevice(id);
        })}
        <div
          className={clsx(classes.dropdown__sliderItem)}
          onClick={(e) => e.stopPropagation()}
        >
          <div>{t("set_volume")}:</div>
          <div>
            <span>{sliderPercent}</span>
            <div>
              <Slider
                value={sliderPercent}
                onChange={(e) => {
                  if (typeof e === "number") {
                    ctx.onSoundMute(true);
                    setSliderPercent(e);
                  }
                }}
                classNames={{
                  handle: classes.bottomBar__sliderHandler,
                  track: classes.bottomBar__sliderTrack,
                  rail: classes.bottomBar__slider,
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const controls = (
    <div className={clsx(classes.bottomBar__controls)}>
      <div className={clsx(classes.bottomBar__controlButton)}>
        <div onClick={() => ctx.onSoundMute(!ctx.sound)}>
          {!ctx.sound ? (
            <VolumeOffLinear className={clsx(classes.bottomBar__dIcon)} />
          ) : (
            <VolumeLinear className={clsx(classes.bottomBar__icons)} />
          )}
        </div>
        <Switch
          className={clsx(classes.switch)}
          checked={ctx.sound}
          onChange={() => ctx.onSoundMute(!ctx.sound)}
        />
        <Dropdown anchor={"top"}>
          <div
            id={"asdnalsndlaksnd"}
            onClick={() => {
              getMediaDevices(setDevices);
            }}
          >
            <ArrowUpLinear />
          </div>
          <DropdownMenu anchor={"top-start"} toggleId={"asdnalsndlaksnd"}>
            {renderSoundDropdown()}
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className={clsx(classes.bottomBar__controlButton)}>
        <div onClick={() => ctx.onMicMute(!ctx.micMute)}>
          {ctx.micMute ? (
            <MicrophoneOffLinear className={clsx(classes.bottomBar__dIcon)} />
          ) : (
            <MicrophoneLinear className={clsx(classes.bottomBar__icons)} />
          )}
        </div>
        <Switch
          className={clsx(classes.switch)}
          checked={!ctx.micMute}
          onChange={() => ctx.onMicMute(!ctx.micMute)}
        />
        <Dropdown anchor={"top"}>
          <div
            id={"sddsnasdn"}
            onClick={() => {
              getMediaDevices(setDevices);
            }}
          >
            <ArrowUpLinear />
          </div>
          <DropdownMenu anchor={"top-center"} toggleId={"sddsnasdn"}>
            <>
              <DropdownItem
                className={clsx(classes.dropdown__title)}
                onClick={(e) => e.stopPropagation()}
              >
                {t("select_mic")}:
              </DropdownItem>
              {getDeviceLabel(devices, "audioinput", onMicPick)}
            </>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className={clsx(classes.bottomBar__controlButton)}>
        <div onClick={() => ctx.onCameraOpen(!ctx.isCameraOpen)}>
          {!ctx.isCameraOpen ? (
            <CameraOffLinear className={clsx(classes.bottomBar__dIcon)} />
          ) : (
            <CameraLinear className={clsx(classes.bottomBar__icons)} />
          )}
        </div>
        <Switch
          className={clsx(classes.switch)}
          checked={ctx.isCameraOpen}
          onChange={() => ctx.onCameraOpen(!ctx.isCameraOpen)}
        />
        <Dropdown anchor={"top"}>
          <div
            id={"sddsndasdasdadaasdn"}
            onClick={() => {
              getMediaDevices(setDevices);
            }}
          >
            <ArrowUpLinear />
          </div>
          <DropdownMenu anchor={"top-end"} toggleId={"sddsndasdasdadaasdn"}>
            <>
              <DropdownItem
                className={clsx(classes.dropdown__title)}
                onClick={(e) => e.stopPropagation()}
              >
                {t("select_camera")}:
              </DropdownItem>
              {getDeviceLabel(devices, "videoinput", (deviceId) => {
                onCameraPick(deviceId);
              })}
            </>
          </DropdownMenu>
        </Dropdown>
      </div>
      {!isMobile && (
        <div
          onClick={() => onScreenShare()}
          className={clsx(classes.bottomBar__controlButton)}
        >
          <ScreenShareLinear
            className={clsx(
              classes.bottomBar__icons,
              isShared && classes.dangerIcon
            )}
          />
        </div>
      )}
    </div>
  );

  if (mediaQuery) {
    return (
      <div className={clsx(classes.bottomBar, noDisplay && classes.noDisplay)}>
        {controls}
        <Button
          color={"primary"}
          size={"big"}
          className={clsx(
            classes.bottomBar__buttons,
            role === "guest" && classes.none
          )}
          onClick={onMinimizeClicked}
        >
          {t("minimize")}
        </Button>
        <div className={clsx(classes.bottomBar__left)}>
          <IconButton
            className={clsx(
              classes.bottomBar__dangerButton,
              isRefreshing && classes.bottomBar__refreshAnim
            )}
            icon={RefreshLinear}
            color={"error"}
            variant={"contained"}
            onClick={() => (onRefresh ? onRefresh() : window.location.reload())}
          />
          <Button
            className={clsx(
              classes.bottomBar__dangerButton,
              classes.bottomBar__buttons
            )}
            size={"big"}
            onClick={onLeaveClicked}
            isLoading={isLeaving}
          >
            {role === "doctor" ? t("end") : t("leave")}
          </Button>
        </div>
      </div>
    );
  }

  // render component
  return (
    <div className={clsx(classes.bottomBar, noDisplay && classes.noDisplay)}>
      <Button
        color={"primary"}
        size={"big"}
        className={clsx(
          classes.bottomBar__buttons,
          role === "guest" && classes.none
        )}
        onClick={onMinimizeClicked}
      >
        {t("minimize")}
      </Button>
      {controls}
      <div className={clsx(classes.bottomBar__left)}>
        <IconButton
          className={clsx(
            classes.bottomBar__dangerButton,
            isRefreshing && classes.bottomBar__refreshAnim
          )}
          icon={RefreshLinear}
          color={"error"}
          variant={"contained"}
          onClick={() => (onRefresh ? onRefresh() : window.location.reload())}
        />
        <Button
          className={clsx(
            classes.bottomBar__dangerButton,
            classes.bottomBar__buttons
          )}
          size={"big"}
          onClick={onLeaveClicked}
          isLoading={isLeaving}
        >
          {role === "doctor" ? t("end") : t("leave")}
        </Button>
      </div>
    </div>
  );
};

// function that get input device list
const getMediaDevices = (
  setDevices: (
    value:
      | ((prevState: MediaDeviceInfo[]) => MediaDeviceInfo[])
      | MediaDeviceInfo[]
  ) => void
) => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((stream) => {
      setDevices(stream);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default BottomBar;

import { useQuery } from "react-query";
import {
  createFacility,
  deleteFacility,
  getAllDepartmentsDropdown,
  getCityFacilitiesByTypeDropdown,
  getFacilitiesByTypeDropdown,
  getFacilitiesDepartmentsDropdown,
  getFacilityDetail,
  getFacilityList,
  getFacilityStatistics,
  getFacilityTypesDropdown,
  updateFacility,
} from "./api";
import { convertToString } from "utils/form";
import { useDropdownsOptions } from "hooks/useOptions";
import useFormMutation from "hooks/useFormMutation";
import { IFacilityData, IFacilityListParams } from "./type";
import useTableQuery from "hooks/useTableQuery";
import { IPagination } from "hooks/usePagination";
import { useDependentMutation } from "hooks/useDependentMutation";
import {
  allDepartmentsKey,
  cityFacilityByTypeKey,
  facilitiesKey,
  facilityByTypeKey,
  facilityDepartmentsKey,
  facilityDetailKey,
  facilityStatisticsKey,
  facilityTypes,
} from "./keys";

export const useFacilityDetail = (id: string | undefined) => {
  const queryResult = useQuery(
    [facilityDetailKey, id],
    () => getFacilityDetail(Number(id)),
    { enabled: !!id }
  );
  return queryResult;
};

export const useCreateFacility = () => {
  const mutationResult = useFormMutation(createFacility, [
    [cityFacilityByTypeKey],
    [facilityByTypeKey],
  ]);
  return mutationResult;
};

export const useUpdateFacility = (id: string | undefined) => {
  const mutationResult = useFormMutation(
    (data: IFacilityData) => updateFacility(data, Number(id)),
    [
      [facilityDetailKey],
      [cityFacilityByTypeKey],
      [facilityByTypeKey],
      [facilityDepartmentsKey],
    ]
  );
  return mutationResult;
};

export const useAllDepartmentDropdown = () => {
  const queryResult = useQuery(allDepartmentsKey, getAllDepartmentsDropdown);
  return queryResult;
};

export const useFacilityTypesDropdown = () => {
  const queryResult = useQuery(facilityTypes, getFacilityTypesDropdown);
  return queryResult;
};

export function useFacilityTypesOptions() {
  const { data, ...rest } = useFacilityTypesDropdown();

  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
}

export const useFacilitiesDepartmentsDropdown = (
  hfNameId: string | undefined
) => {
  const queryResult = useQuery(
    [facilityDepartmentsKey, hfNameId],
    () =>
      getFacilitiesDepartmentsDropdown({ facilities_ids: [Number(hfNameId)] }),
    {
      enabled: !!hfNameId,
    }
  );
  return queryResult;
};

export const useFacilitiesByTypeDropdown = (hfType: string | undefined) => {
  const facilitiesKey = [facilityByTypeKey, hfType];
  const queryResult = useQuery(
    facilitiesKey,
    () => getFacilitiesByTypeDropdown({ hf_type: convertToString(hfType) }),
    {
      enabled: !!hfType,
    }
  );
  return queryResult;
};

export const useCityFacilitiesByTypeDropdown = (
  cityId: string | undefined,
  hfTypeKey: string | undefined
) => {
  const queryKey = [cityFacilityByTypeKey, cityId, hfTypeKey];

  const queryResult = useQuery(
    queryKey,
    () =>
      getCityFacilitiesByTypeDropdown(
        Number(cityId),
        convertToString(hfTypeKey)
      ),
    {
      enabled: !!cityId && !!hfTypeKey,
    }
  );
  return queryResult;
};

export const useGetFacilityStatistics = () => {
  const queryResult = useQuery(facilityStatisticsKey, getFacilityStatistics);
  return queryResult;
};

export function useGetFacilityList(
  params: IFacilityListParams,
  pagination: IPagination,
  maxPageUpdater: (maxPage: number) => void
) {
  const queryResult = useTableQuery(
    getFacilityList,
    facilitiesKey,
    params,
    pagination,
    maxPageUpdater
  );
  return queryResult;
}

export const useDeleteFacility = () => {
  const mutationResult = useDependentMutation(
    (data: number) => deleteFacility(Number(data)),
    [[cityFacilityByTypeKey], [facilityByTypeKey], [facilityDepartmentsKey]],
    [[facilityStatisticsKey], [facilitiesKey]]
  );
  return mutationResult;
};

import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";
import Button from "../button";
import UploadFilesList from "./uploadFilesList";
import {
  EXCEL_FORMATS,
  IMAGE_FORMATS,
  IMAGE_PDF_FORMATS,
} from "constants/uploader";
import { DragAndDropCloud, ErrorSquareLinear } from "components/icon";
import { useTranslation } from "react-i18next";

type TFileType = "image" | "excel" | "multiType" | "image_pdf";

export interface IUploadInputDefaultProps {
  text?: string;
  dropText?: string;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  type?: TFileType;
  fileUploadCount: number;
  onChange?: (files: File[]) => void;
  onBlur?: () => void;
  className?: string;
  error?: string;
  filesList?: File[];
  variant?: "normal" | "dense";
}

// custom input for uploading file like image
function UploadInput({
  inputProps,
  text,
  dropText,
  type,
  fileUploadCount,
  className,
  onChange,
  onBlur,
  filesList,
  error: formError,
  variant = "normal",
}: IUploadInputDefaultProps) {
  const { t } = useTranslation("form");
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState<File[]>(filesList || []);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    //#region onBlur raising handler
    if (filesList && filesList.length > 0) {
      // that means user has selected some files
      setIsDirty(true);
      onBlur?.();
    }
    if (isDirty) {
      onBlur?.();
    }
    //#endregion
    setFiles(filesList || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesList]);

  // get error from form
  useEffect(() => {
    if (formError && !error) setError(formError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);
  // function to iterate through files and return list of files
  const fileListIterator = (fileList: FileList) => {
    let temp: File[] = [];
    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];
      temp.push(element);
    }
    return temp;
  };
  // file type validation
  const validateFile = (fileList: FileList | null): boolean => {
    if (fileList === null) return false;
    if (fileList.length + files.length > fileUploadCount) {
      setError(
        t(`upload_file${fileUploadCount > 1 ? "s" : ""}_count`, {
          count: fileUploadCount,
        })
      );
      return false;
    }
    if (type === "excel") {
      for (let index = 0; index < fileList.length; index++) {
        if (!EXCEL_FORMATS.includes(fileList[index].type)) {
          setError(t("invalid_file_format"));
          return false;
        }
      }
    } else if (type === "image") {
      for (let index = 0; index < fileList.length; index++) {
        if (!IMAGE_FORMATS.includes(fileList[index].type)) {
          setError(t("invalid_file_format"));
          return false;
        }
      }
    } else if (type === "image_pdf") {
      for (let index = 0; index < fileList.length; index++) {
        if (!IMAGE_PDF_FORMATS.includes(fileList[index].type)) {
          setError(t("invalid_file_format"));
          return false;
        }
      }
    }
    return true;
  };
  // function to detect drag enter
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
    setError("");
  };
  // function to detect drag exit
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };
  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // get files when drag and dropped
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const inputFiles = e.dataTransfer.files;
    if (validateFile(inputFiles)) {
      let newFileList = [...fileListIterator(inputFiles), ...files];
      setFiles(newFileList);
      onChange?.(newFileList);
    }
  };
  // add files to list on input change event
  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;
    if (inputFiles && validateFile(inputFiles)) {
      let newFileList = [...files, ...fileListIterator(inputFiles)];
      setFiles(newFileList);
      onChange?.(newFileList);
    }
    e.target.value = "";
  };
  // function to handle removing item
  const onRemoveItem = (index: number) => {
    const tempFiles = [...files].filter((item, i) => i !== index);
    console.log(tempFiles);

    setFiles(tempFiles);
    onChange?.(tempFiles);
  };
  // render component
  return (
    <div
      className={clsx(
        classes.container,
        className ?? "",
        dragging && classes.dragging,
        variant === "dense" && classes.dense,
        variant === "dense" && "pb-2 pb-sm-0"
      )}
    >
      <div
        className={clsx(
          classes.container__dragBox,
          dragging && classes.dragging
        )}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        data-testid="hidden-div"
      />
      <DragAndDropCloud
        className={clsx(
          classes.container__image,
          dragging && classes.dragging,
          variant === "dense" && "m-0"
        )}
        data-variant={variant}
      />
      <h3
        className={clsx(classes.container__title, dragging && classes.dragging)}
        data-variant={variant}
      >
        {dragging
          ? dropText || t("upload_drop_text")
          : text || t("upload_text")}
      </h3>
      {variant !== "dense" && (
        <div
          className={clsx(
            classes.container__subtitle,
            dragging && classes.dragging
          )}
        >
          {t("or")}
        </div>
      )}

      <input
        type="file"
        accept={
          type === "excel"
            ? EXCEL_FORMATS.join(", ")
            : type === "image"
            ? IMAGE_FORMATS.join(", ")
            : type === "image_pdf"
            ? IMAGE_PDF_FORMATS.join(", ")
            : "*/*"
        }
        className={"d-none"}
        onChange={inputOnChange}
        ref={inputRef}
        data-testid="hidden-input"
        multiple={fileUploadCount > 1}
        {...inputProps}
      />
      <Button
        className={clsx(
          classes.container__button,
          dragging && classes.dragging,
          variant === "dense" && classes.denseButton
        )}
        disabled={files.length === fileUploadCount}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {t("select_file")}
      </Button>
      {error && (
        <div
          className={clsx(classes.container__error)}
          onClick={() => setError("")}
        >
          {error}
          <div className={clsx(classes.container__error__icon)}>
            <ErrorSquareLinear />
          </div>
        </div>
      )}
      {files.length > 0 && (
        <div className={classes.fileListContainer}>
          <UploadFilesList
            files={files}
            onRemoveItem={onRemoveItem}
            filePreview={variant === "normal"}
          />
        </div>
      )}
    </div>
  );
}

UploadInput.defaultProps = {
  type: "excel",
  fileUploadCount: 1,
};

export default UploadInput;

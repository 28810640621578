import apiCaller, { IResponse } from "./index";
import {
  IPaginationParams,
  IPaginationTableList,
} from "./types/paginationType";
import { IDoctorBiography } from "./auth";
import { ISelectedItem } from "../components/core/checkboxList";

export interface IDoctorPatients {
  id: number;
  patient_id: string;
  user_name: string;
  relatioinship: string;
  full_name: string;
  birth_date: string;
  phone: string;
  mobile: string;
  gender: "Male" | "Female";
  facility_name: string;
  facility_type: string;
  country: string;
  flag: string;
}

export interface IDoctorStaff {
  id: number;
  uid: string;
  full_name: string;
  facility: string;
  departments: { id: string; name: string }[];
  phone_number: string;
  country: string;
  flag: string;
}

export interface ISummaryNotes {
  id: number;
  appointment: {
    id: number;
    type: string;
    date: string;
  };
  robot: {
    id: number;
    pid: string;
    name: string;
  };
  local_doctor: {
    id: number;
    pid: string;
    name: string;
    speciality: string;
  };
  international_doctor: {
    id: number;
    pid: string;
    name: string;
    speciality: string;
  };
  patient: {
    id: number;
    pid: string;
    name: string;
    gender: string;
    birthday: string;
    avatar: string;
  };
  facility: {
    id: number;
    name: string;
  };
}

export interface IStoredResultDetails {
  reason: string;
  patient: {
    id: number;
    pid: string;
    name: string;
    gender: "Male" | "Female";
    date_of_birth: string;
    relationship: string;
  };
  robot: {
    id: number;
    pid: string;
    name: string;
    type: string;
    type_value: string;
    devices: string[];
  };
  blood_sugar: {
    date: string;
    value: {
      unit: string;
      glucose: number | string;
    };
  }[];
  blood_pressure: {
    date: string;
    value: {
      unit: string;
      systolic: number | string;
      diastolic: number | string;
      heart_rate: number | string;
      meanArterialPressure: number;
    };
  }[];
  temperature: {
    date: string;
    value: {
      unit: string;
      hasFever: number;
      temperature: number | string;
      temperatureType: string;
    };
  }[];
  pulse_oximeter: {
    date: string;
    value: {
      spO2: number | string;
      pulse_rate: number | string;
    };
  }[];
  weight: number;
  height: number;
}

export interface ISummaryNote {
  id: number;
  appointment: {
    id: number;
    type: string;
    date: string;
  };
  local_doctor: {
    id: number;
    pid: string;
    name: string;
    speciality: string;
    gender: string;
  };
  international_doctor: string;
  robot: {
    id: number;
    pid: string;
    name: string;
    devices: {
      id: number;
      created_at: string;
      updated_at: string;
      name: string;
      serial_number: string;
      model: string;
      manufacturer: string;
      type_id: number;
      icon: string;
      uuid: string;
      warranty_expiration: string;
      robot_id: number;
      device_id: string;
      type: {
        id: number;
        name: string;
        symbol: string;
      };
    }[];
  };
  followup_interval: string;
  referral_doctor: {
    id: number;
    pid: string;
    name: string;
    speciality: string;
    gender: string;
  };
  history_of_present_illness: string;
  review_of_systems: string;
  physical_exam: string;
  impressions: string;
  prescription: {
    dose: number;
    quality: number;
    refills: string[];
    frequency: string[];
    medication: string[];
  };
  past_medical_history: {
    type: string;
    value: {
      problem: string[];
      relationship: string;
    };
  };
  medications: {
    from: string[];
    taking: boolean;
    frequency: string[];
    medicline: string[];
  };
  allergies: {
    reaction: string[];
    medicline: string[];
  };
  orders: {
    medicline: string[];
  };
  signed_at: string;
}

export interface IShowSummaryNote {
  vital_sign: {
    blood_sugar: {
      date: string;
      value: {
        unit: string;
        glucose: number;
      };
    }[];
    blood_pressure: {
      date: string;
      value: {
        unit: string;
        systolic: number;
        diastolic: number;
        heart_rate: number;
        meanArterialPressure: number;
      };
    }[];
    temperature: {
      date: string;
      value: {
        unit: string;
        hasFever: number;
        temperature: number;
        temperatureType: string;
      };
    }[];
    pulse_oximeter: {
      date: string;
      value: {
        spO2: number;
        pulse_rate: number;
      };
    }[];
    weight: number;
    height: number;
    reason: string;
    lang: string;
  };
  summary_note: {
    constitution: ISelectedItem[];
    id: number;
    appointment: {
      id: number;
      type: "local" | "international";
      date: string;
      appointment_id: string;
    };
    local_doctor: {
      id: number;
      pid: string;
      name: string;
      speciality: string;
      gender: "female" | "male";
      signature: string;
      avatar: string;
    };
    international_doctor: {
      id: number;
      pid: string;
      name: string;
      speciality: string;
      gender: "female" | "male";
      signature: string;
      avatar: string;
    };
    creator_doctor: {
      id: number;
      pid: string;
      name: string;
      speciality: string;
      gender: "female" | "male";
      signature: string;
      avatar: string;
    };
    patient: {
      id: number;
      pid: string;
      birthday: string;
      gender: "female" | "male";
      name: string;
    };
    robot: {
      id: number;
      robot_id: string;
      name: string;
      type: string;
      devices: string[];
    };
    visit_for: string;
    addendum: {
      id: number;
      appointment_id: number;
      content: string;
      created_at: string;
      updated_at: string;
    }[];
    followup_interval: string;
    followup_doctor: string;
    referral?: {
      doctor_id: string;
      doctor_name: string;
      type: string;
      speciality: string;
      interval: string;
    };
    history_of_present_illness: string;
    review_of_systems: string;
    physical_exam: string;
    impressions: string;
    prescription: {
      want_prescription: boolean;
      medicines: {
        id: number;
        medicine_name: string;
        dosage: string;
        formulation: string;
        frequency: string;
        quantity: string;
        refills: string;
      }[];
      allow_substitution: boolean;
    };
    past_medical_history: [
      {
        type: "history_of_illnesses";
        value: {
          illnesses: string[];
          comment: string;
          hospitalized: boolean;
          hospitalized_reasons: { reason: string; date: string }[];
          past_surgery: boolean;
          past_surgery_types: { surgery_type: string; date: string }[];
        };
      },
      {
        type: "medications_and_allergies";
        value: {
          medicines: {
            id: number;
            medicine_name: string;
            dosage: string;
            formulation: string;
            frequency: string;
            taking: "Yes" | "No";
          }[];
          allergies: { id: number; allergy: string; reaction: string }[];
          allergic_reactions: string[];
          food_allergic: string[];
          other_allergic: string[];
        };
      }
    ];
    /*medications: {
      medicines: {
        id: number;
        medicine_name: string;
        dosage: string;
        formulation: string;
        frequency: string;
        taking: "Yes" | "No";
      }[];
    };*/
    /*allergies: {
      allergies: { id: number; allergy: string; reaction: string }[];
      allergic_reactions: string[];
      food_allergic: string[];
      other_allergic: string[];
    };*/
    orders: {
      labs: string[];
      imagings: string[];
      tests: string[];
      plans: string[];
      has_labs: boolean;
      has_imagings: boolean;
      has_tests: boolean;
      has_plans: boolean;
    };
    note: string;
    education: string;
    instructions: string;
    signed_at: string;
  };
}

export interface IAppointmentsResults {
  data_id: number;
  appointment_id: number;
  patient: {
    birthday: string;
    id: number;
    name: string;
    patient_id: string;
  };
  avatar_url: string;
  facility_name: string;
  device_name: string;
  device_id: string;
  device_type: string;
  relationship: string;
  relationship_value: string;
  robot: {
    id: number;
    robot_id: string;
    type: string;
    location: string;
  };
  measurement_datetime: string;
}

export interface IStoredResult {
  id: number;
  device_type: string | { id: string; name: string; symbol: string };
  robot_location: string;
  time_zone: {
    id: number;
    zone_name: string;
    gmt_offset: number;
    gmt_offset_name: string;
    country_id: number;
  };
  date: string;
  data: any;
  attachment: string;
}

export type IStoredResultChart = {
  date: string;
  value: any;
}[];

export interface IMedicalHistory {
  medical_history: { id: number; type: string; value: any };
  patient: {
    id: number;
    pid: string;
    full_name: string;
    gender: string;
    birthday: string;
  };
}

export function getDoctorPatientsList(
  page: number,
  resultsPerPage: number,
  keyword: string,
  relationship?: string[],
  gender?: string[],
  facility?: string[]
): Promise<IResponse<IPaginationTableList<IDoctorPatients>>> {
  return apiCaller.get(`/doctors/patients`, {
    params: {
      page,
      per_page: resultsPerPage,
      keyword,
      gender: gender || [],
      facility_type: facility || [],
      relationship: relationship || [],
    },
  });
}

export function getDoctorStaffList(
  page: number,
  resultsPerPage: number,
  genders: string[],
  hf_types: string[],
  filter: string
): Promise<IResponse<IPaginationTableList<IDoctorStaff>>> {
  return apiCaller.get(`/doctors/doctor-staffs-list`, {
    params: {
      page,
      per_page: resultsPerPage,
      genders,
      hf_types,
      filter,
    },
  });
}

export function getAppointmentsResults(
  page: number,
  resultsPerPage: number,
  filter?: string,
  device_types?: string[],
  relationship?: string[],
  hf_types?: string[],
  from?: string,
  to?: string
): Promise<IResponse<IPaginationTableList<IAppointmentsResults>>> {
  return apiCaller.get(`/appointments/results`, {
    params: {
      page,
      per_page: resultsPerPage,
      filter,
      device_types,
      relationship,
      hf_types,
      from,
      to,
    },
  });
}

export function getPatientStoredResult(
  page: number,
  resultsPerPage: number,
  patientId: string,
  deviceType:
    | "TM"
    | "WS"
    | "GM"
    | "BP"
    | "PO"
    | "US"
    | "SC"
    | "EC"
    | "ECG"
    | "DO"
    | "SPR",
  patientType: "patient" | "family_member",
  filter: string,
  from: string,
  to: string,
  appointment_id?: string | number
): Promise<IResponse<IPaginationTableList<IStoredResult>>> {
  return apiCaller.get(`/appointment-data/stored-results`, {
    params: {
      patient_id: patientId,
      patient_type: patientType,
      device_type: deviceType,
      filter,
      from,
      to,
      page,
      per_page: resultsPerPage,
      appointment_id,
    },
  });
}

export function createMedicalHistory(data: FormData): Promise<IResponse<any>> {
  return apiCaller.post("/medical-history/create", data);
}

export function updateMedicalHistory({
  id,
  data,
}: {
  id: number;
  data: FormData;
}): Promise<IResponse<any>> {
  return apiCaller.put(`/medical-history/${id}`, data);
}

export function showMedicalHistory(
  patientType: string,
  patientId: number,
  type: string
): Promise<IResponse<IMedicalHistory>> {
  return apiCaller.get(`/medical-history/${patientType}/${patientId}/${type}`);
}

export function writeAddendum(data: FormData): Promise<IResponse<any>> {
  return apiCaller.post("/addendum", data);
}

export function getStoredResultDetails(
  robotId: number,
  patientId: number,
  patient_type: "patient" | "family_member",
  appointment_id?: string | number
): Promise<IResponse<IStoredResultDetails>> {
  return apiCaller.get(
    `/appointment-data/stored-results-details?patient_id=${patientId}${
      robotId >= 0 ? `&robot_id=${robotId}` : ""
    }`,
    { params: { patient_type, appointment_id } }
  );
}

export function createSummaryNote({
  data,
  id,
}: {
  id: number;
  data: FormData;
}): Promise<IResponse<{ id: number }>> {
  return apiCaller.post(`/visit-summary-notes/` + id, data);
}

export function signSummaryNote({
  password,
  id,
}: {
  password: string;
  id: string;
}): Promise<IResponse<any>> {
  return apiCaller.put(`/visit-summary-notes/${id}/sign`, { password });
}

export function showSummaryNote(
  id: number | string
): Promise<IResponse<IShowSummaryNote>> {
  return apiCaller.get(`/visit-summary-notes/${id}`);
}

export function getStoredResultChart(
  deviceType: string,
  patientId: number,
  patientType: "patient" | "family_member",
  appointment_id?: string | number,
  from?: string,
  to?: string
): Promise<IResponse<IStoredResultChart>> {
  return apiCaller.get("/appointment-data/stored-results-chart", {
    params: {
      from,
      to,
      patient_type: patientType,
      patient_id: patientId,
      device_type: deviceType,
      appointment_id,
    },
  });
}

export interface IDoctorDailyScheduleParams {
  date: string;
  doctor_id: number;
}

export interface IDoctorSchedule {
  end_date: string;
  id: number;
  repeat_pattern: string;
  start_date: string;
  timezone: { id: number; name: string; gmt_offset_name: string };
  type: string;
}

export function getDoctorDailySchedule({
  doctor_id,
  date,
}: IDoctorDailyScheduleParams): Promise<IResponse<IDoctorSchedule[]>> {
  return apiCaller.get(`/doctors/${doctor_id}/daily-schedules`, {
    params: { date },
  });
}

export interface IStaffDoctorListParams extends IPaginationParams {
  genders: string[];
  doctor_types: string[];
  filter: string;
}
export interface IStaffDoctorList {
  id: number; // 35;
  uid: string; // "D-000000035";
  name: string; // "sample doctor 1";
  avatar: string; // "7efe655b-6197-4707-b62d-41cb775eea55";
  country: string; // "United States";
  flag: string; //"https://cdn.kcak11.com/CountryFlags/countries/us.svg";
  more_data: {
    id: number; // 22;
    created_at: string; // "2023-02-26T04:36:04.000000Z";
    updated_at: string; // "2023-03-12T10:19:36.000000Z";
    user_id: number; // 35;
    speciality: string; // "Surgery";
    sub_speciality: string; //"General Adult";
    title: string; //"MD";
    deleted_at: null | string;
    cmo_id: null | number; //? i don't know the response value or type
    biography: {
      medical_doctor_school?: string;
      practice_area?: string;
    } | null; //? i don't know the response value or type
    website: string; //"https://lyonsglobal.com";
    doctor_type: string; // "local";
    licenses: [
      {
        NPI_number: string; // "1234";
        country_of_license: number; // 231;
      }
    ];
  };
}
export function getStaffDoctorList(
  params: IStaffDoctorListParams
): Promise<IResponse<IPaginationTableList<IStaffDoctorList>>> {
  return apiCaller.get(`/doctors/staff-doctors-list`, {
    params: params,
  });
}

export interface IAssignedStaffToDoctors {
  staff_id: number; //23;
  staff_uid: string; // "no-role-000000023";
  staff_name: string; //  "Keshaun O'Conner";
  staff_facilities: string[];
  doctor_id: number; //52;
  doctor_uid: string; // "no-role-000000052";
  doctor_name: string; //  "Benton Conn";
}

export interface IAssignedStaffToDoctorParams extends IPaginationParams {
  filter: string;
}
export function getAssignedStaffToDoctorList(
  params: IAssignedStaffToDoctorParams
): Promise<IResponse<IPaginationTableList<IAssignedStaffToDoctors>>> {
  return apiCaller.get(`doctors/assigned-staffs-to-doctor-index`, {
    params: params,
  });
}

export interface IAssignedStaffToDoctorResponse {
  id: number; // 4;
  name: string; // "Sajjad Moghayyad";
  city: string; // "Ashkāsham";
  state: string; // "Badakhshan";
  country: string; // "Afghanistan";
  Speciality: string; // "speciality";
  staffs: {
    id: number; // 4;
    name: string; // "Sajjad Moghayyad";
    city: string; // "Ashkāsham";
    state: string; // "Badakhshan";
    country: string; // "Afghanistan";
    hf_name: string; //"Mr. Cory Ebert";
    hf_type: string; // "ambulance";
  }[];
}
export function getAssignedStaffsToDoctor(
  doctorId: number
): Promise<IResponse<IAssignedStaffToDoctorResponse>> {
  return apiCaller.get(`doctors/${doctorId}/doctor-staffs`);
}
export interface IAssignStaffToDoctorData {
  doctor_id: number;
  staffs: number[];
}
export function updateAssignStaffToDoctor(
  data: IAssignStaffToDoctorData
): Promise<IResponse<null>> {
  return apiCaller.put(`/doctors/assign-staff-to-doctor`, data);
}

export function deleteAssignedStaffFromDoctor(
  doctorId: number,
  staffId: number
) {
  return apiCaller.delete(`/doctors/${doctorId}/staff/${staffId}/deassign`);
}

type apptType = "scheduled" | "ondemand";

export interface IGetDoctorCalendarParams {
  types: apptType[];
  doctor_id: number;
  from?: string;
  to?: string;
}

export function getDoctorCalendar({
  doctor_id,
  to,
  types,
  from,
}: IGetDoctorCalendarParams): Promise<IResponse<string[]>> {
  return apiCaller.get(`/doctors/${doctor_id}/scheduled-calendar`, {
    params: {
      types,
      to,
      from,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
}

export interface IUpdateDoctorProfileParams {
  first_name?: string;
  last_name?: string;
  email?: string;
  mobile?: string;
  user_name?: string;
  city_id?: string;
  birthday?: string;
  gender?: string;
  employee_number?: string;
  timezone_id?: number;
  phone?: string;
  address?: string;
  signature?: string;
  languages?: { language: string; language_fluency: string }[];
  speciality?: string;
  sub_speciality?: string;
  title?: string;
  website?: string;
  licenses?: { NPI_number: string; country_of_license: number }[];
  biography?: IDoctorBiography;
  mrn_number?: string;
  national_id?: string;
  insurance?: string;
  emergency_phone_number?: string;
  departments?: number[];
  cmo_id?: number;
  avatar?: string;
  postal_code?: string;
}

export function updateDoctorProfile(
  data: IUpdateDoctorProfileParams
): Promise<IResponse<any>> {
  return apiCaller.put(`/doctors/profile`, data);
}

export interface IDoctorsTempList {
  id: number;
  is_accepted: boolean;
  doctor: {
    id: number;
    user_id: string;
    doctor_id: number;
    full_name: string;
    speciality: string;
    gender: string;
  };
}

export interface IGetDoctorTempsParams extends IPaginationParams {
  genders: string[];
  filter: string;
}

export function getDoctorsTempList(
  params: IGetDoctorTempsParams
): Promise<IResponse<IPaginationTableList<IDoctorsTempList>>> {
  return apiCaller.get(`/doctors/temp-data`, { params });
}

export interface ITempData {
  changes: {
    email: { old: string; new: string }; //pass
    title: { old: string; new: string }; //pass
    phone: { old: string; new: string }; //pass
    mobile: { old: string; new: string }; //pass
    status: { old: string; new: string };
    gender: { old: string; new: string }; //pass
    avatar: { old: string; new: string }; // +++
    cmo_id: { old: string; new: string }; //pass
    city_id: { old: string; new: string }; //pass
    website: { old: string; new: string }; //pass
    address: { old: string; new: string }; //pass
    birthday: { old: string; new: string }; //pass
    licenses: {
      old: [{ country_of_license: string; NPI_number: string }];
      new: [{ country_of_license: string; NPI_number: string }];
    };
    doctor_id: { old: string; new: string };
    biography: {
      old: {
        doctor_position: { id: string; value: string }[];
        honors_and_awards: { id: string; value: string }[];
        medical_experience: {
          id: string;
          practice_area: string;
          years_in_practice: string;
          medical_doctor_school: string;
        }[];
        membership: { id: string; value: string }[];
        short_biography: string;
      };
      new: {
        doctor_position: { id: string; value: string }[];
        honors_and_awards: { id: string; value: string }[];
        medical_experiences: {
          id: string;
          practice_area: string;
          years_in_practice: string;
          medical_doctor_school: string;
        }[];
        membership: { id: string; value: string }[];
        short_biography: string;
      };
    };
    signature: { old: string; new: string };
    insurance: { old: string; new: string };
    languages: {
      old: { language: string; language_fluency: string }[];
      new: { language: string; language_fluency: string }[];
    };
    user_name: { old: string; new: string };
    last_name: { old: string; new: string };
    first_name: { old: string; new: string };
    speciality: { old: string; new: string };
    mrn_number: { old: string; new: string };
    departments: { old: string; new: string };
    accepted_at: { old: string; new: string };
    doctor_type: { old: string; new: string };
    timezone_id: { old: string; new: string };
    national_id: { old: string; new: string };
    sub_speciality: { old: string; new: string };
    employee_number: { old: string; new: string };
    emergency_phone_number: { old: string; new: string };
  };
  profile: {
    id: number;
  };
}

export function getTempData(id: number): Promise<IResponse<ITempData>> {
  return apiCaller.get(`doctors/temp-data/${id}`);
}

export function acceptUpdateProfile(id: number): Promise<IResponse<any>> {
  return apiCaller.put(`/doctors/temp-data/${id}/accept`);
}

export function denyUpdateProfile(id: number): Promise<IResponse<any>> {
  return apiCaller.put(`/doctors/temp-data/${id}/deny`);
}

import clsx from "clsx";
import { ForwardedRef, ReactNode, useEffect, useRef, useState } from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  ScatterDataPoint,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import classes from "./styles.module.scss";
import { useThemeMode } from "hooks/useThemeMode";
import { humanizedDate } from "../../../utils/date";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useTranslation } from "react-i18next";

// VitalCard Component props type interface
export interface IVitalCardProps {
  icon: ReactNode;
  title: string;
  number: number | string;
  unit: string;
  conditionText?: string;
  condition?: "normal" | "warning" | "danger";
  pastData: number[];
  dates: string[];
}

// register what we want to use from chart js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

// get main color from css variable
const getMainColor = () => {
  const icon = document.getElementById("vital-icon");
  if (!!icon) {
    return getComputedStyle(icon).getPropertyValue("--color-primary-main");
  } else {
    return getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-main"
    );
  }
};

// component to show vital info detail and generate chart based on previous data
const VitalCard = ({
  icon,
  title,
  dates,
  number,
  unit,
  conditionText,
  condition,
  pastData,
}: IVitalCardProps) => {
  const { t } = useTranslation("global");
  // register reference to chart
  const ref = useRef();
  let [mainColor, setMainColor] = useState(getMainColor());
  const { theme } = useThemeMode();
  useEffect(() => {
    setTimeout(() => setMainColor(getMainColor()), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  // state of plotting data
  const [plotData, setPlotData] = useState({
    labels: [],
    datasets: [],
  });

  // get context from chart ref anf setting gradient color and handle changing data
  useEffect(() => {
    if (!ref) return;
    // @ts-ignore

    // updating chart data and background
    const newData = {
      labels: pastData.map((d, index) =>
        t("measurement_label", { date: humanizedDate(dates[index]) })
      ),
      datasets: [
        {
          fill: "start",
          data: pastData,
          borderColor: `rgb(${mainColor})`,
          // @ts-ignore
          backgroundColor: createGradient(ref.current.ctx, mainColor),
        },
      ],
    };
    // @ts-ignore
    setPlotData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastData, ref, mainColor, dates]);

  // render component
  return (
    <div className={clsx(classes.vitalCard)}>
      <div className={clsx(classes.vitalCard__head)}>
        <div id="vital-icon" className={clsx(classes.vitalCard__iconPlace)}>
          {icon}
        </div>
        <div className={clsx(classes.vitalCard__title)}>{title}</div>
      </div>
      <div className={clsx(classes.vitalCard__body)}>
        <div className={clsx(classes.vitalCard__numPlace)}>
          <div className={clsx(classes.vitalCard__num)}>
            {number || "- - -"}
          </div>
          {!!number && (
            <div className={clsx(classes.vitalCard__unit)}>{unit}</div>
          )}
        </div>
        {!!number && (
          <div
            className={clsx(
              classes.vitalCard__condition,
              condition === "normal" && classes.normal,
              condition === "warning" && classes.warning,
              condition === "danger" && classes.danger
            )}
          >
            {t(conditionText ? conditionText : condition)}
          </div>
        )}
      </div>
      <div className={clsx(classes.vitalCard__chartContainer)}>
        <div className={clsx(classes.vitalCard__chart)}>
          {renderPlot(
            t,
            pastData,
            dates,
            ref,
            plotData,
            setPlotData,
            mainColor
          )}
        </div>
      </div>
    </div>
  );
};

// function for first render chart and create reference from it
const renderPlot = (
  t: any,
  data: number[],
  dates: string[],
  ref:
    | ForwardedRef<
        ChartJSOrUndefined<
          "line",
          (number | ScatterDataPoint | null)[],
          unknown
        >
      >
    | undefined,
  plotData: { datasets: any[] },
  setPlotData: any,
  mainColor: string
) => {
  const min = Math.min(...data);

  // options of chart
  const options = {
    responsive: true,
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        display: false,
        min: min - 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    tension: 0.5,
  };
  //chart dataset
  const dataset = {
    labels: data.map((d, index) =>
      t("measurement_label", { date: humanizedDate(dates[index]) })
    ),
    datasets: [
      {
        fill: "start",
        data: data,
        borderColor: `rgb(${mainColor})`,
        backgroundColor: "#295DE100",
      },
    ],
  };
  if (plotData.datasets.length === 0) {
    setPlotData(dataset);
  }

  return <Line ref={ref} options={options} data={plotData} />;
};

// function for creating gradient color for background of area chart
function createGradient(ctx: any, mainColor: string) {
  const gradient = ctx.createLinearGradient(0, 0, 0, 300);
  gradient.addColorStop(0, `rgb(${mainColor})`);
  gradient.addColorStop(0.2, `rgba(${mainColor},.3)`);
  gradient.addColorStop(0.3, `rgba(${mainColor},.1`);
  gradient.addColorStop(0.6, `rgba(${mainColor},0)`);
  gradient.addColorStop(1, `rgba(${mainColor},0)`);

  return gradient;
}

// component default props
VitalCard.defaultProps = {
  condition: "normal",
};

export default VitalCard;

import { ReactComponent as MessageIcon } from "assets/icons/bold/message.svg";
import { ReactComponent as NotificationsIcon } from "assets/icons/bold/notification.svg";
import { useAuth } from "hooks/useAuth";
import { useHistory } from "react-router";
import classes from "../styles.module.scss";
import HoverToolbox from "../../../common/hoverToolbox";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getUnreadCounts } from "../../../../api/notifications";

function formatNumber(n: number): string {
  if (n < 100) {
    return n.toString();
  } else {
    return "+99";
  }
}
function MainGroup() {
  const history = useHistory();
  const { role } = useAuth();
  const { t } = useTranslation(["global"]);

  const { data } = useQuery("getUnreadCounts", () => getUnreadCounts(), {
    cacheTime: 0,
    staleTime: 0,
    keepPreviousData: true,
  });

  return (
    <>
      <HoverToolbox text={t("messages_text")}>
        <button
          className={classes.mainIconContainer}
          onClick={() => history.push(`/panel/${role}/messages`)}
        >
          <MessageIcon className={classes.mainIcon} />
          {data?.data?.data?.messages_count &&
          data?.data?.data?.messages_count > 0 ? (
            <div className={classes.numberBadge}>
              {formatNumber(data?.data?.data?.messages_count)}
            </div>
          ) : (
            <></>
          )}
        </button>
      </HoverToolbox>
      <HoverToolbox text={t("notifications_text")}>
        <button
          className={classes.mainIconContainer}
          onClick={() => history.push(`/panel/${role}/notifications`)}
        >
          <NotificationsIcon className={classes.mainIcon} />
          {data?.data?.data?.notifications_count &&
          data?.data?.data?.notifications_count > 0 ? (
            <div className={classes.numberBadge}>
              {formatNumber(data?.data?.data?.notifications_count)}
            </div>
          ) : (
            <></>
          )}
        </button>
      </HoverToolbox>
    </>
  );
}

export default MainGroup;

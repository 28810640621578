export const getTemperatureCondition = (t?: number) => {
  if (!t) {
    return "normal";
  }

  if (t >= 107) {
    return "danger";
  } else if (t <= 90) {
    return "danger";
  } else if (t <= 97) {
    return "warning";
  } else if (t >= 100) {
    return "warning";
  } else {
    return "normal";
  }
};

export const getBloodPressureCondition = (b?: number) => {
  if (b) {
    if (b >= 140) {
      return "danger";
    } else if (b > 120) {
      return "warning";
    } else if (b < 90) {
      return "warning";
    } else {
      return "normal";
    }
  }
  return "normal";
};

export const getBloodSugarCondition = (b?: number) => {
  if (b) {
    if (b > 180 || b < 80) {
      return "danger";
    }
    if (b > 150 || b < 70) {
      return "warning";
    }
    return "normal";
  }
  return "normal";
};

export const getHeartRateCondition = (h?: number) => {
  if (h) {
    if (h > 130 || h < 60) return "danger";
    if (h > 120 || h < 60) return "warning";
    return "normal";
  }
  return "normal";
};

export const getOximeterCondition = (o?: number) => {
  if (o) {
    if (o < 90) {
      return "danger";
    }
    if (o < 95) {
      return "warning";
    }
    return "normal";
  }
  return "normal";
};

import {
  getCityDoctorsByCityAndSpeciality,
  getLACityDoctorsByCityAndSpeciality,
} from "api/dropdown";
import FormAutoComplete from "components/form/formAutoComplete";
import { FrameBold } from "components/icon";
import { useQuery } from "react-query";
import { getFormikFieldProps } from "utils/form";
import {
  IDefaultProps as IAutoCompleteProps,
  IMenuOption,
} from "components/core/autoComplete";
import { FormikProps } from "formik";
import { useDropdownDefaultValue } from "hooks/useDropdownDefaultValue";
import { useDropdownsOptions } from "hooks/useOptions";
import { useTranslation } from "react-i18next";
import { cityDoctorBySpecialityKey } from "../../../utils/keyGenerator/keys";

export interface IDoctorNameProps<T> {
  formik: FormikProps<T>;
  cityFieldName: keyof T & string;
  doctorFieldName: keyof T & string;
  specialityFieldName: keyof T & string;
  rootProps?: Partial<IAutoCompleteProps>;
  doctorId?: string;
  doctorName?: string;
  disabled?: boolean;
  noPadding?: boolean;
  la?: boolean;
}

export function DoctorName<T extends { [key: string]: any }>({
  formik,
  cityFieldName,
  specialityFieldName,
  doctorFieldName,
  rootProps,
  doctorId,
  doctorName,
  disabled,
  noPadding,
  la,
}: IDoctorNameProps<T>) {
  const { t } = useTranslation("form");

  //? Get data from server
  const { data, isLoading, isError } = useQuery(
    la
      ? [
          cityDoctorBySpecialityKey,
          "LA",
          "city: ",
          formik.values[cityFieldName]?.key,
          " speciality: ",
          formik.values[specialityFieldName]?.key,
        ]
      : [
          cityDoctorBySpecialityKey,
          "city: ",
          formik.values[cityFieldName]?.key,
          " speciality: ",
          formik.values[specialityFieldName]?.key,
        ],
    () =>
      la
        ? getLACityDoctorsByCityAndSpeciality({
            city_id: Number(formik.values[cityFieldName].key),
            speciality: formik.values[specialityFieldName].key,
          })
        : getCityDoctorsByCityAndSpeciality({
            city_id: Number(formik.values[cityFieldName].key),
            speciality: formik.values[specialityFieldName].key,
          }),
    {
      enabled:
        !!formik.values[cityFieldName]?.key &&
        !!formik.values[specialityFieldName]?.key,
    }
  );

  //? sets default value to dropdowns
  const OPTIONS = useDropdownsOptions({ data: data });

  useDropdownDefaultValue({
    fieldName: doctorFieldName,
    formik: formik,
    id: doctorId,
    OPTIONS: OPTIONS,
    name: doctorName,
  });

  const changeHandler = (value: IMenuOption) => {
    formik.setFieldValue(doctorFieldName, value);
    if (value.key !== "") {
      formik.setFieldError(doctorFieldName, undefined);
    }
  };

  return (
    <FormAutoComplete
      noPadding={noPadding}
      rootProps={{
        icon: FrameBold,
        placeholder: t("doctor_name"),
        disabled:
          !formik.values[cityFieldName] ||
          !formik.values[cityFieldName].key ||
          !formik.values[specialityFieldName] ||
          !formik.values[specialityFieldName].key ||
          isError ||
          isLoading ||
          disabled,
        isError,
        isLoading,
        ...rootProps,
      }}
      options={OPTIONS ? OPTIONS : []}
      {...getFormikFieldProps(doctorFieldName, t("doctor_name"), formik)}
      onChange={changeHandler}
    />
  );
}

import { ITime, TRange } from "interfaces";
import { I24HTime } from "interfaces/time";
import moment from "moment";
import { convertToTwoDigits } from "utils/form";

// date tools will be place here
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// function to create string from number at given length and add 0 to start
function padWithZero(num: number, minLength: number) {
  const numberString = num?.toString();
  if (numberString?.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString?.length) + numberString;
}

// function that convert date to readable string
function humanizedDate(
  date: string | Date,
  showTime?: boolean,
  showDay?: boolean
): string {
  const dateInstance = typeof date === "string" ? new Date(date) : date;

  if (isNaN(dateInstance.getTime())) {
    return "- - -";
  }

  const time = `${padWithZero(dateInstance?.getHours(), 2)}:${padWithZero(
    dateInstance?.getMinutes(),
    2
  )}`;
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const day = dateInstance?.getDay();
  const humanizedDate = `${showDay ? weekday[day] : ""} ${
    monthNames[dateInstance?.getMonth()]
  } ${dateInstance?.getDate()}, ${dateInstance?.getFullYear()} ${
    showTime ? time : ""
  }`;

  return humanizedDate;
}

function addOneDay(date: Date) {
  return new Date(date.getTime() + 24 * 60 * 60 * 1000);
}

// function that convert data to readable string and return its time
function humanizedTime(date: any, utc?: boolean): string {
  const dateInstance = new Date(date);

  const output = `${padWithZero(dateInstance?.getHours(), 2)}:${padWithZero(
    dateInstance.getMinutes(),
    2
  )}`;
  const utcOutput = `${padWithZero(
    dateInstance?.getUTCHours(),
    2
  )}:${padWithZero(dateInstance?.getUTCMinutes(), 2)}`;

  if (utc) return convertTime(utcOutput);

  return convertTime(output);
}

function humanizedITime(time: ITime): string {
  return `${padWithZero(time.hour, 2)}:${padWithZero(time.minute, 2)} ${
    time.period
  }`;
}

// convert date to string
function convertToAPIDateFormat(date: string | Date | null): string {
  if (!!date) {
    const dateInstance = new Date(date);
    const year = dateInstance.getUTCFullYear();
    const month = convertToTwoDigits(dateInstance.getUTCMonth() + 1);
    const day = convertToTwoDigits(dateInstance.getUTCDate());

    return `${year}-${month}-${day}`;
  }
  return "";
}

function convertBirthday(date: string | Date): string {
  if (!!date) {
    const dateInstance = new Date(date);
    const year = dateInstance.getFullYear();
    const month = convertToTwoDigits(dateInstance.getMonth() + 1);
    const day = convertToTwoDigits(dateInstance.getDate());

    return `${year}-${month}-${day}`;
  }
  return "";
}

function convertToAPIDateFreeze(date: string | Date): string {
  if (!!date) {
    const dateInstance = new Date(date);
    const year = dateInstance.getFullYear();
    const month = convertToTwoDigits(dateInstance.getMonth() + 1);
    const day = convertToTwoDigits(dateInstance.getDate());

    return `${year}-${month}-${day}`;
  }
  return "";
}
// convert date to string
function convertToAPIFullDateFormat(
  date: string | Date,
  utc?: boolean
): string {
  if (!!date) {
    const dateInstance = new Date(date);
    const _date = convertToAPIDateFormat(date);
    const hour = convertToTwoDigits(
      utc ? dateInstance.getUTCHours() : dateInstance.getUTCHours()
    );
    const minute = convertToTwoDigits(
      utc ? dateInstance.getUTCMinutes() : dateInstance.getUTCMinutes()
    );
    // const seconds = convertToTwoDigits(
    //   utc ? dateInstance.getUTCSeconds() : dateInstance.getSeconds()
    // );

    return `${_date} ${hour}:${minute}`;
  }
  return "";
}

function convertToAPIFullDateFreeze(date: string | Date): string {
  if (!!date) {
    const dateInstance = new Date(date);
    const _date = convertToAPIDateFreeze(date);
    const hour = convertToTwoDigits(dateInstance.getHours());
    const minute = convertToTwoDigits(dateInstance.getMinutes());

    return `${_date} ${hour}:${minute}`;
  }
  return "";
}

// convert date string to full date
function convertToFullDateFormat(date: string | Date): string {
  const dateInstance = new Date(date);
  const year = dateInstance.getFullYear();
  const month = convertToTwoDigits(dateInstance.getMonth() + 1);
  const day = convertToTwoDigits(dateInstance.getDate());

  const time = `${dateInstance.getHours()}:${padWithZero(
    dateInstance.getMinutes(),
    2
  )}:${padWithZero(dateInstance.getSeconds(), 2)}`;
  const dateSlash = `${month}/${day}/${year}`;

  return `${dateSlash} - ${time}`;
}

function convertToFullDate(date: string | Date): string {
  const dateInstance = new Date(date);
  const year = dateInstance.getFullYear();
  const month = convertToTwoDigits(dateInstance.getMonth() + 1);
  const day = convertToTwoDigits(dateInstance.getDate());

  const time = `${padWithZero(dateInstance.getHours(), 2)}:${padWithZero(
    dateInstance.getMinutes(),
    2
  )}:${padWithZero(dateInstance.getSeconds(), 2)}`;
  const dateSlash = `${month}-${day}-${year}`;

  return `${dateSlash} ${time}`;
}

function convertToDate(date: string | Date): string {
  const dateInstance = new Date(date);
  const year = dateInstance.getFullYear();
  const month = convertToTwoDigits(dateInstance.getMonth() + 1);
  const day = convertToTwoDigits(dateInstance.getDate());
  const dateSlash = `${month}/${day}/${year}`;

  return `${dateSlash}`;
}

function convertToTime(date: string | Date): string {
  const dateInstance = new Date(date);

  const time = `${dateInstance.getHours()}:${padWithZero(
    dateInstance.getMinutes(),
    2
  )}:${padWithZero(dateInstance.getSeconds(), 2)}`;

  return `${time}`;
}

function getDaysBetween(date: [Date | null, Date | null]) {
  const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
  if (date[0] && date[1]) {
    const start: number = new Date(toUTCDate(date[0])).getTime();
    const end: number = new Date(toUTCDate(date[1])).getTime();
    const daysBetweenDates: number = Math.ceil((end - start) / MS_PER_DAY);
    return Array.from(
      new Array(daysBetweenDates),
      (v, i) => new Date(start + i * MS_PER_DAY)
    );
  }
  return [];
}

function get12HTime(date: Date | string, utc?: boolean): ITime {
  var dt = new Date(date);
  console.log(dt);
  var hours = utc ? dt.getUTCHours() : dt.getHours();
  var period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;

  var minutes = utc ? dt.getUTCMinutes() : dt.getMinutes();

  // const convertedTime = new Date(date).toLocaleString("en-US", {
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  //   timeZone: utc ? "UTC" : undefined,
  // });
  // const hourMinute = convertedTime
  //   .substring(0, convertedTime.length - 3)
  //   .split(":");
  // console.log(hourMinute, convertedTime);

  return {
    hour: Number(hours) as TRange<0, 12>,
    minute: Number(minutes) as TRange<0, 60>,
    period: period as "AM" | "PM",
  };
}

function get24HTime(time: ITime): I24HTime {
  const PM = time.period === "PM";
  const hours = (+time.hour % 12) + (PM ? 12 : 0);
  return {
    hour: hours as TRange<0, 24>,
    minute: time.minute,
  };
}

function convert24HTime(time: ITime) {
  const PM = time.period === "PM";
  const hours = (+time.hour % 12) + (PM ? 12 : 0);
  return `${padWithZero(hours as TRange<0, 24>, 2)}:${padWithZero(
    time.minute,
    2
  )}`;
}

function isTimeOverlap(aStart: Date, aEnd: Date, bStart: Date, bEnd: Date) {
  var e1start = aStart.getTime();
  var e1end = aEnd.getTime();
  var e2start = bStart.getTime();
  var e2end = bEnd.getTime();

  return (
    (e1start > e2start && e1start < e2end) ||
    (e2start > e1start && e2start < e1end)
  );
}

function isPastFrom24H(date: Date) {
  return new Date().getTime() - date.getTime() < 24 * 60 * 60 * 1000;
}

function isPast(date: Date) {
  return date.getTime() < Date.now();
}

function toUTCDate(date: Date | string) {
  const dateObj = new Date(date);
  return new Date(
    Date.UTC(
      dateObj?.getFullYear(),
      dateObj?.getMonth(),
      dateObj?.getDate(),
      dateObj?.getHours(),
      dateObj?.getMinutes(),
      dateObj?.getSeconds()
    )
  ).toISOString();
}

function convertTime(timeStr: string): string {
  if (timeStr.includes("AM") || timeStr.includes("PM")) {
    return timeStr;
  }
  // Split the string into hours and minutes
  const [hours, minutes] = timeStr.split(":");

  // Convert the hours to a number
  const hoursNum = parseInt(hours);

  // Determine if it's AM or PM
  const meridian = hoursNum < 12 ? "AM" : "PM";

  // Convert to 12-hour format
  let hours12 = hoursNum % 12;
  if (hours12 === 0) {
    hours12 = 12;
  }

  // Convert back to string format
  return `${padWithZero(hours12, 2)}:${minutes} ${meridian}`;
}

function getValidDate(date: Date | string | undefined) {
  let dateInstance: Date | "" = "";
  if (!!date) {
    const tempDate = new Date(date);
    const isValidDate = !isNaN(tempDate.getTime());
    dateInstance = isValidDate ? tempDate : "";
  }
  return dateInstance;
}

function setTimeForDate(
  date: Date | string | undefined,
  time: any,
  utc?: boolean
) {
  let dateInstance: Date | string = "";
  if (!!date) {
    const tempDate = moment(date);
    tempDate.set({
      hour: time.hour,
      minute: time.minute,
    });

    dateInstance = utc
      ? tempDate.utc().format("YYYY-MM-DD HH:mm")
      : tempDate.format("YYYY-MM-DD HH:mm");
  }
  return dateInstance;
}

export {
  isPastFrom24H,
  humanizedDate,
  convertToAPIDateFormat,
  convertToAPIFullDateFormat,
  humanizedTime,
  padWithZero,
  convertToFullDateFormat,
  getDaysBetween,
  get12HTime,
  get24HTime,
  isTimeOverlap,
  convertToDate,
  convertToTime,
  humanizedITime,
  convert24HTime,
  isPast,
  convertToFullDate,
  toUTCDate,
  addOneDay,
  convertToAPIDateFreeze,
  convertToAPIFullDateFreeze,
  convertTime,
  convertBirthday,
  getValidDate,
  setTimeForDate,
};

import { useQuery } from "react-query";
import { getCityDoctorsByCityAndSpeciality } from "api/dropdown";
import { HospitalBold } from "components/icon";
import { getDropdownKey } from "utils/dropdowns";
import { FastDropdown } from "../formikFields";
import IDropdown from "components/formikFields/IDropdown";
import { convertToString } from "utils/form";
import { useTranslation } from "react-i18next";
import { cityDoctorBySpecialityKey } from "../../utils/keyGenerator/keys";

interface IProps<T> extends IDropdown<T> {
  /**
   * The field name of the `city` that this `doctors` is dependent on it.
   *
   * without knowing the `city` we can not fetch the states of that `city`, so the `doctors` depends on the `city`.
   */
  cityFieldName: keyof T & string;

  /**
   * The field name of the `speciality` that this `doctors` is dependent on it.
   *
   * without knowing the `speciality` we can not fetch the states of that `speciality`, so the `doctors` depends on the `speciality`.
   */
  specialityFieldName: keyof T & string;
}

export function DoctorsByCityAndSpeciality<T>({
  formik,
  cityFieldName,
  specialityFieldName: hfTypeFieldName,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the values from formik values and extract its key
  const cityId = getDropdownKey(formik, cityFieldName);
  const specialityKey = getDropdownKey(formik, hfTypeFieldName);

  // fetch data
  const { data, isLoading, isError } = useQuery(
    [cityDoctorBySpecialityKey, cityId, specialityKey],
    () =>
      getCityDoctorsByCityAndSpeciality({
        city_id: Number(cityId),
        speciality: convertToString(specialityKey),
      }),
    {
      enabled: !!cityId && !!specialityKey,
    }
  );

  return (
    <FastDropdown
      label={t("doctor_name")}
      formik={formik}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!cityId || !specialityKey || disabled}
      icon={HospitalBold}
      {...props}
    />
  );
}

import { TRoute } from "config/router";
import { lazy } from "react";
import { lazyRetry } from "utils/route";

export const NEW_NOTIFICATION_ROUTE = {
  path: "new-notification",
  exact: true,
  component: lazy(() =>
    lazyRetry(
      () => import("pages/globalPages/notification/components/newNotification")
    )
  ),
};

export const DASHBOARD_GLOBAL_ROUTES: TRoute[] = [
  {
    path: "profile-management/view-profile",
    exact: true,
    component: lazy(() =>
      lazyRetry(() => import("pages/globalPages/profileManagement/index"))
    ),
  },
  {
    path: "profile-management/edit",
    exact: true,
    component: lazy(() =>
      lazyRetry(() => import("pages/globalPages/profileManagement/index"))
    ),
  },
  {
    path: "messages",
    exact: true,
    component: lazy(() =>
      lazyRetry(() => import("pages/globalPages/messaging/index"))
    ),
    inners: [
      {
        path: "new-message",
        exact: true,
        component: lazy(() =>
          lazyRetry(
            () => import("pages/globalPages/messaging/components/newMessage")
          )
        ),
      },
      {
        path: ":id",
        exact: true,
        component: lazy(() =>
          lazyRetry(
            () => import("pages/globalPages/messaging/components/viewMessage")
          )
        ),
      },
    ],
  },
  {
    path: "notifications",
    exact: true,
    component: lazy(() =>
      lazyRetry(() => import("pages/globalPages/notification/index"))
    ),
    inners: [
      // {
      //   path: ":id",
      //   exact: true,
      //   component: lazy(
      //     () =>
      //       import("pages/globalPages/notification/components/viewNotification")
      //   ),
      // },
    ],
  },
  {
    path: "change-password",
    exact: true,
    component: lazy(() =>
      lazyRetry(() => import("pages/globalPages/changePassword/index"))
    ),
  },
  {
    path: "notification-settings",
    exact: true,
    component: lazy(() =>
      lazyRetry(() => import("pages/globalPages/notificationSettings"))
    ),
  },
  {
    component: lazy(() => lazyRetry(() => import("pages/globalPages/privacy"))),
    exact: true,
    path: "privacy-policy",
  },
];

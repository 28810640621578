import apiCaller, { IResponse } from "api";
import {
  IDeviceData,
  IDeviceResponse,
  IDevicesListParams,
  IDevicesListResponse,
} from "./type";
import { IPaginationTableList } from "api/types/paginationType";
import { IStatistics } from "api/types/statisticsTypes";
import { IDropdownResponse } from "api/dropdown";

const baseURL = "devices";

export function createDevice(data: IDeviceData): Promise<IResponse<null>> {
  return apiCaller.post(`${baseURL}`, data);
}
export function createGroupDevices(data: FormData) {
  return apiCaller.post(`${baseURL}/import`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function updateDevice(
  data: IDeviceData,
  id: number
): Promise<IResponse<null>> {
  return apiCaller.put(`${baseURL}/${id}`, data);
}

export function getDevicesList(
  params: IDevicesListParams
): Promise<IResponse<IPaginationTableList<IDevicesListResponse>>> {
  return apiCaller.get(`${baseURL}`, { params: params });
}

export function getDevice(id: number): Promise<IResponse<IDeviceResponse>> {
  return apiCaller.get(`${baseURL}/${id}`);
}

export function deleteDevice(id: number): Promise<IResponse<null>> {
  return apiCaller.delete(`${baseURL}/${id}`);
}

export function getDevicesStatistics(): Promise<IResponse<IStatistics>> {
  return apiCaller.get(`${baseURL}/statistics`);
}

export function getDeviceTypesDropdown(): Promise<
  IResponse<IDropdownResponse[]>
> {
  return apiCaller.get(`dropdown/device-types`);
}

export function getDeviceTypeDevicesDropdown(
  deviceTypeId: number
): Promise<IResponse<IDropdownResponse[]>> {
  return apiCaller.get(`dropdown/device-types/${deviceTypeId}/devices`);
}

import {
  RedirectToDashboard,
  RedirectToDashboardAbs,
} from "components/common/redirectToDashboard";
import { lazy } from "react";
import { TRoute } from "config/router";
import { DASHBOARD_GLOBAL_ROUTES } from "./globalDashboard";
import ServerError from "../../pages/500";
import Forbidden from "../../pages/403";
import BadRequest from "../../pages/400";
import Unavailable from "../../pages/503";
import NoInternet from "../../pages/noInternet";
import { lazyRetry } from "utils/route";

const DOCTOR_ROUTES: TRoute = {
  path: "doctor",
  exact: true,
  component: RedirectToDashboard,
  inners: [
    {
      path: "500",
      exact: true,
      component: ServerError,
    },
    {
      path: "403",
      exact: true,
      component: Forbidden,
    },
    {
      path: "400",
      exact: true,
      component: BadRequest,
    },
    {
      path: "503",
      exact: true,
      component: Unavailable,
    },
    {
      path: "no-connection",
      exact: true,
      component: NoInternet,
    },
    {
      path: "dashboard",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/home"))
      ),
      exact: true,
    },
    {
      path: "registration",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/registration"))
      ),
      exact: true,
    },
    {
      path: "availability-time",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/availabilityTime"))
      ),
      exact: true,
      inners: [
        {
          path: "add",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/doctor/availabilityTime/components/addAvailabilityTime"
                )
            )
          ),
          exact: true,
        },
        {
          path: "view-profile/:id",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/doctor/availabilityTime/components/addAvailabilityTime"
                )
            )
          ),
          exact: true,
        },
        {
          path: ":schId",
          component: lazy(() =>
            lazyRetry(
              () =>
                import(
                  "pages/dashboard/doctor/availabilityTime/components/updateAvailabilityTime"
                )
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "assigned-robots",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/doctor/availabilityTime/assignedRobots")
        )
      ),
      exact: true,
    },
    {
      path: "appointments/scheduled",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/appointments"))
      ),
      exact: true,
    },
    {
      path: "appointments/on-demand",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/appointments"))
      ),
      exact: true,
    },
    {
      path: "edit-intake-form/:intake_id/edit",
      component: lazy(() =>
        lazyRetry(() =>
          lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
        )
      ),
      exact: true,
    },
    {
      path: "edit-intake-form/:intake_id",
      component: lazy(() =>
        lazyRetry(() =>
          lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
        )
      ),
      exact: true,
    },
    {
      path: "patients/list",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/patientList"))
      ),
      exact: true,
    },
    {
      path: "medical-history/:pid/:patient_type",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/medicalHistory"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "past-visit-summary-note/:id/:facility/download",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pastVisitSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "write-summary-note/:pid/:appt_id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/writeSummaryNote"))
      ),
      exact: true,
    },
    {
      path: "write-addendum/:pid",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/writeAddendum"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "stored-results/:id/:family_member/:appointment_id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/viewStoredResult"))
      ),
      exact: true,
    },
    {
      path: "patients/stored-results",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/storingResults"))
      ),
      exact: true,
    },
    {
      path: "patients/past-visit-summary/:patient_id/:patient_type",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/summaryNotes"))
      ),
      exact: true,
    },
    {
      path: "patients/past-visit-summary",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/summaryNotes"))
      ),
      exact: true,
    },
    {
      path: "patients/summary-note-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/summaryNoteManagement"))
      ),
      exact: true,
    },
    {
      path: "staffs",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/staffList"))
      ),
      exact: true,
    },
    // {
    //   path: "connect-to-robot",
    //   component: lazy(() => import("pages/dashboard/doctor/connectToRobot")),
    //   exact: true,
    // },
    {
      path: "video-visit/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/general/onlineVisit"))
      ),
    },
    {
      path: "appointments-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/appointmentReports")
        )
      ),
    },
    {
      path: "PACS",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/pacs/pacs"))
      ),
    },
    {
      path: "profile-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/profileManagement"))
      ),
    },
    {
      path: "staff-management/view-profile/:id",
      component: lazy(() =>
        lazyRetry(
          () =>
            import(
              "pages/dashboard/superAdmin/staffManagement/viewStaffProfile"
            )
        )
      ),
      exact: true,
    },
    {
      path: "intake-and-medical/:id/:self",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/intakeAndMedical"))
      ),
    },
    {
      path: "view-appointment-detail/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/appointmentDetail"))
      ),
      exact: true,
    },
    {
      path: "view-appointment-detail/:id/print",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/superAdmin/appointmentDetail"))
      ),
      exact: true,
    },
    // {
    //   path: "robot-drive",
    //   component: lazy(() =>
    //     lazyRetry(() => import("pages/dashboard/doctor/robot/robotMap"))
    //   ),
    //   exact: true,
    // },
    {
      path: "robot-drive/:id",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/doctor/robot/robotMap"))
      ),
      exact: true,
    },
    {
      path: "intake-form-management",
      component: lazy(() =>
        lazyRetry(() => import("pages/dashboard/staff/intakeForms"))
      ),
      exact: true,
      inners: [
        {
          path: ":intake_id",
          component: lazy(() =>
            lazyRetry(() =>
              lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
            )
          ),
          exact: true,
        },
        {
          path: ":intake_id/edit",
          component: lazy(() =>
            lazyRetry(() =>
              lazyRetry(() => import("pages/dashboard/staff/intakeFormEdit"))
            )
          ),
          exact: true,
        },
      ],
    },
    {
      path: "financial-report-generation",
      component: lazy(() =>
        lazyRetry(
          () => import("pages/dashboard/superAdmin/reports/appointmentReports")
        )
      ),
    },
    { component: RedirectToDashboardAbs, exact: true, path: "help-center" },
    ...DASHBOARD_GLOBAL_ROUTES,
  ],
};

export default DOCTOR_ROUTES;

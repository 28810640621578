import { ReactComponent as NoConnectionSVG } from "assets/images/noConnection.svg";
import classes from "./styles.module.scss";

function NoConnectionIcon(props: { [key: string]: any }) {
  return (
    <NoConnectionSVG
      data-variant="bulk"
      {...props}
      className={classes.unAvailable}
    />
  );
}

export { NoConnectionIcon };

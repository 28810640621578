export const E_NOTIFICATION = "notification";
export const E_MESSAGE = "message";
export const E_FACILITY = "facility";
export const E_DEPARTMENT = "department";
export const E_CITY = "city";
export const E_STATE = "state";
export const E_COUNTRY = "country";
export const E_ROBOT = "robot";
export const E_SUPER = "super";
export const E_DEVICE = "device";
export const E_PROFILE = "profile";
export const E_FILE = "file";
export const E_PHONE = "phone";
export const E_TIMEZONE = "timezone";
export const E_DOCTOR = "doctor";
export const E_USER = "user";
export const E_FINANCIAL = "financial";
export const E_APPOINTMENT = "appointment";
export const E_PAYMENT = "payment";
export const E_PATIENT = "patient";
export const E_SUMMARY = "summary_note";
export const E_INTAKE = "intake_form";
export const E_MEDICAL_HISTORY = "medical_history";
export const E_STAFF = "staff";
export const E_RESULT = "storing_result";

import {DepartmentBold} from "components/icon";
import {getDropdownKey} from "utils/dropdowns";
import {FastMultiSelect} from "../formikFields";
import IMultiSelect from "components/formikFields/IMultiSelect";
import {useFacilitiesDepartmentsDropdown} from "features/healthcareFacility";
import {useTranslation} from "react-i18next";

interface IProps<T> extends IMultiSelect<T> {
  /**
   * The field name of the HF that this department is dependent on it.
   *
   * without knowing the HF we can not fetch the departments of that HF, so the department depends on the HF.
   */
  HFNameFieldName: keyof T & string;
}

export function FacilityMSDepartment<T>({
  HFNameFieldName,
  formik,
  disabled,
  ...props
}: IProps<T>) {
  const { t } = useTranslation("form");

  // get the hf name id from formik values and extract its key
  const hfNameId = getDropdownKey(formik, HFNameFieldName);

  // fetch data
  const { data, isLoading, isError } =
    useFacilitiesDepartmentsDropdown(hfNameId);

  return (
    <FastMultiSelect
      formik={formik}
      label={`${t("departments")} (${t("optional")})`}
      data={data}
      isError={isError}
      isLoading={isLoading}
      disabled={!hfNameId || disabled}
      icon={DepartmentBold}
      {...props}
    />
  );
}

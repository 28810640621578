import { AxiosError, AxiosInstance } from "axios";
import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import apiCaller, { IServerResponse } from "api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useBaseUrl } from "../../utils/route";
import { useTranslation } from "react-i18next";

interface IAPIConfiguratorProps {
  axiosInstance?: AxiosInstance;
  onError?: (message: string) => void;
}

export function APIConfigurator({
  axiosInstance = apiCaller,
  onError = (error) => toast.error(error),
}: IAPIConfiguratorProps) {
  const { t } = useTranslation("form");
  const { logOut } = useAuth();
  const base = useBaseUrl();
  const history = useHistory();

  const errorHandler = async (error: AxiosError<IServerResponse<null>>) => {
    if (!error.response) {
      if (error.code !== "ERR_CANCELED") {
        // "ERR_CANCELED" happened if the file query or mutation has been canceled.
        // in this case we don't want to show an error message.
        onError(t("unable_server_response"));
      }
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 401:
        await logOut(false);
        onError(t("logged_out"));
        break;

      case 403:
        onError(t("access_denied"));
        history.push(`${base}403`);
        break;
      case 500:
        onError(t("server_error"));
        history.push(`${base}500`);
        break;
      case 400:
        onError(t("bad_request"));
        history.push(`${base}400`);
        break;
      case 503:
        onError(t("service_unavailable"));
        history.push(`${base}503`);
        break;

      case 406:
        onError(error.response.data.message!); // back-end guarantees this behavior.
        break;

      case 422:
        const errors = error.response.data.errors;
        if (!!errors) {
          for (const key in errors) {
            errors[key].forEach((item) => onError(item));
          }
        }
        break;

      default:
        onError(error.response.data.message ?? t("error"));
    }

    return Promise.reject(error);
  };

  useEffect(() => {
    axiosInstance.interceptors.response.use(undefined, errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

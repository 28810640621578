import clsx from "clsx";
import classes from "./styles.module.scss";

export interface IStepbarProps {
  items: string[];
  activePage: number;
  onClick: (index: number) => void;
}

const Stepbar = ({ items, activePage, onClick }: IStepbarProps) => {
  return (
    <div className={clsx(classes.stepbar)}>
      {items.map((item, index) => {
        return (
          <div
            className={clsx(
              classes.stepbar__itemBack,
              index <= activePage && classes.stepbar__itemBackS,
              index === 0 && classes.stepbar__firstItem,
              index === items.length - 1 && classes.stepbar__lastItem
            )}
            key={index}
          >
            <div
              onClick={() => {
                onClick(index);
              }}
              className={clsx(
                classes.stepbar__item,
                index === activePage && classes.stepbar__itemSelected,
                index < activePage && classes.stepbar__itemFinished,
                index === 0 && classes.stepbar__firstItem,
                index === items.length - 1 && classes.stepbar__lastItem
              )}
            >
              {item}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepbar;

import { IResponse } from "api";
import {
  MutateFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";

export function useDependentMutation<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  mutationFn: MutateFunction<IResponse<TData>, TError, TVariables, TContext>,
  queryKeysToRemove?: QueryKey[],
  queryKeysToRefetch?: QueryKey[],
  options?: UseMutationOptions<IResponse<TData>, TError, TVariables, TContext>
): UseMutationResult<IResponse<TData>, TError, TVariables, TContext>;

export function useDependentMutation<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  mutationFn: MutateFunction<IResponse<TData>, TError, TVariables, TContext>,
  queryKeysToRemove?: (data: TVariables) => QueryKey[],
  queryKeysToRefetch?: QueryKey[],
  options?: UseMutationOptions<IResponse<TData>, TError, TVariables, TContext>
): UseMutationResult<IResponse<TData>, TError, TVariables, TContext>;

/**
 * This is tradition use mutation function, just do some more actions on its `onSuccess` and `onMutate` option.
 * @param mutationFn MutateFunction
 * @param queryKeysToRemove give array of query keys if you want the `queryClient` to `removeQueries` of that query keys after the mutation is succeed.
 *
 * NOTE: ONLY USE THIS if this mutation effect on other queries in other pages. So if you see them you will find out they are all inactive
 * @param queryKeysToRefetch give array of query keys if you want the `queryClient` to
 * `resetQueries` of that query keys after the mutation is succeed.
 *
 * NOTE: ONLY USE THIS if this mutation effect on other queries in this pages. So if you see them you will find out they are all active or inactive(when the fetch fn is same but parameters or not)
 * @param options UseMutationOptions
 * @returns UseMutationResult
 */
export function useDependentMutation<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  mutationFn: MutateFunction<IResponse<TData>, TError, TVariables, TContext>,
  queryKeysToRemove?: ((data: TVariables) => QueryKey[]) | QueryKey[],
  queryKeysToRefetch?: QueryKey[],
  options?: UseMutationOptions<IResponse<TData>, TError, TVariables, TContext>
) {
  const queryClient = useQueryClient();
  return useMutation(mutationFn, {
    ...options,
    onSuccess(data, variables, context) {
      if (typeof queryKeysToRemove !== "function") {
        queryKeysToRemove?.forEach((element) => {
          queryClient.removeQueries(element);
        });
      }
      queryKeysToRefetch?.forEach((element) => {
        queryClient.resetQueries({ queryKey: element, active: true });
        queryClient.removeQueries({ queryKey: element, inactive: true });
      });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onSettled(data, error, variables, context) {
      if (options?.onSettled)
        options.onSettled(data, error, variables, context);
      if (typeof queryKeysToRemove === "function") {
        const queryKeys = queryKeysToRemove(variables);
        console.log("query keys to remove: ", queryKeys);

        queryKeys.forEach((element) => {
          queryClient.removeQueries(element);
        });
      }
    },
  });
}

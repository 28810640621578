import DropdownItem from "components/core/dropdownItem";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export interface IResetMenuItemProps {
  rowId: string | number;
}

//? reset items in dropdown
export function ResetMenuItem({ rowId }: IResetMenuItemProps) {
  const { t } = useTranslation("global");
  const history = useHistory();
  return (
    <DropdownItem
      onClick={() => {
        console.log("reset password action on row: " + rowId);
        history.push(`${history.location.pathname}/reset-password/${rowId}`);
      }}
    >
      <span>{t("reset_password")}</span>
    </DropdownItem>
  );
}

import { RegistrationProvider } from "../context";
import StaffRegistrationPage from "./components/registrationLayout";

const StaffRegistration = () => {
  return (
    <RegistrationProvider>
      <StaffRegistrationPage />
    </RegistrationProvider>
  );
};

export default StaffRegistration;

import { IShowPatientPrescription } from "../../../../api/healthRecord";
import { IShowAppointment } from "../../../../api/appointment";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import useDownloadPdf from "../../../../hooks/useDownloadPdf";
import { notify } from "../../../../components/core/toast";
import Page from "../../../../components/layout/page";
import PrintNotif from "../../../../components/common/printNotif";
import { humanizedDate, humanizedTime } from "../../../../utils/date";
import clsx from "clsx";
import classes from "../styles.module.scss";
import DataCard from "../../../../components/common/dataCard";
import InformationCard from "../../../../components/common/informationCard";
import { ListInfo } from "../../../../components/icon/listInfo";
import CardContainer from "../../../../components/common/cardContainer";
import BasicTable from "../../../../components/core/basicTable";
import { useParams } from "react-router-dom";
import { getLinkData } from "../../../../api/share";
import { useQuery } from "react-query";
import Loader from "../../../../components/common/loader";
import ImageCard from "../../../../components/common/videoVisit/components/images/ImageCard";
import { usePrescriptionColumns } from "../../../dashboard/patient/healthRecords/prescription";
import { useTranslation } from "react-i18next";

const ShareShowPrescription = () => {
  const { t } = useTranslation("global");
  const PRESCRIPTION_COLUMNS = usePrescriptionColumns();

  const history = useHistory();

  const id = useParams<{ id: string }>()?.id;
  if (!id) {
    history.push("/404");
  }
  const { data, isLoading, isError } = useQuery([getLinkData.name, id], () =>
    getLinkData(id)
  );
  const search = new URLSearchParams(data?.data?.data?.data);
  const encodedData = search.get("data");
  let d: undefined | IShowPatientPrescription = undefined;
  try {
    d = JSON.parse(encodedData || "");
  } catch (e) {}
  const encodedAppt = search.get("appt");

  let appt: IShowAppointment | undefined = undefined;

  try {
    appt = JSON.parse(encodedAppt || "");
  } catch (e) {}

  const [printOpen, setPrintOpen] = useState(false);

  const printWebpage = useDownloadPdf();

  useEffect(() => {
    if (d && !isLoading) {
      notify.info(t("pdf_wait"));
      printWebpage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, isLoading, isError]);
  return (
    <Page
      title={t("health_record.prescription.title")}
      type={"inner"}
      backTo={"pop"}
      noButtons={true}
    >
      <Loader isLoading={isLoading} isError={isError}>
        <div className={clsx(classes.modal)}>
          <DataCard
            variant={"patientPrescription"}
            avatar={appt?.local_doctor?.avatar}
            tableData={{
              appt_id: appt?.appt_id || "- - -",
              appt_type: appt?.appt_type || "- - -",
              visit_date: humanizedDate(appt?.start_date || "") || "- - -",
              visit_time: humanizedTime(appt?.start_date || "") || "- - -",
              timezone: appt?.local_doctor?.timezone?.name || "- - -",
              patient_name:
                appt?.patient?.first_name + " " + appt?.patient?.last_name ||
                "- - -",
              birth: humanizedDate(appt?.patient?.birthday || "") || "- - -",
              hf_name: appt?.facility?.name || "- - -",
            }}
            id={appt?.local_doctor?.user_id || ""}
            name={
              appt?.local_doctor?.first_name +
              " " +
              appt?.local_doctor?.last_name
            }
            specialty={appt?.local_doctor?.speciality || ""}
          />
          <InformationCard
            icon={<ListInfo />}
            title={t("health_record.prescription.title")}
            parentClassName={clsx(classes.modal__back)}
          >
            <div className={clsx(classes.healthRecord__column, "mt-2")}>
              <div>
                <CardContainer type={"basic"}>
                  <BasicTable
                    columns={PRESCRIPTION_COLUMNS}
                    data={d?.medicines?.map((p, i) => {
                      return { ...p, id: i + 1 };
                    })}
                  />
                </CardContainer>
              </div>
              <div>
                <span
                  className={clsx(classes.modal__bigText, classes.modal__bold)}
                >
                  {t("doctor")}:&nbsp;&nbsp;
                </span>
                <span className={clsx(classes.modal__bigText)}>{`${
                  appt?.local_doctor?.first_name +
                  " " +
                  appt?.local_doctor?.last_name
                } (${appt?.local_doctor?.speciality})`}</span>
              </div>
              <div className={clsx(classes.modal__smallPrimary)}>
                {humanizedDate(appt?.start_date || "") +
                  " " +
                  humanizedTime(appt?.start_date || "")}
              </div>
            </div>
          </InformationCard>
          <div className={classes.modal__sig}>
            <ImageCard
              imgUrl={appt?.local_doctor?.signature_base || ""}
              noZoom
            />
          </div>
        </div>
      </Loader>
      <PrintNotif
        open={printOpen}
        setOpen={setPrintOpen}
        name={"Prescription"}
      />
    </Page>
  );
};

export default ShareShowPrescription;

import { City, Country, State, Timezone } from "components/dropdown";
import classes from "../../styles.module.scss";
import Button from "components/core/button";
import { LocationBold, MailTrackingBold } from "components/icon";
import { formSubmission } from "utils/form";
import BeatLoader from "react-spinners/BeatLoader";
import { IRegistrationStepProps } from "../../doctor/components/registrationLayout";
import Grid from "components/core/Grid";
import { useTranslation } from "react-i18next";
import { FastInput } from "components/formikFields";

const RegistrationThirdPage = ({
  formik,
  isMutationLoading,
  onSetAlert,
  backButton,
}: IRegistrationStepProps) => {
  const { t } = useTranslation("form");

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        formSubmission(e, formik);
      }}
    >
      <Grid>
        <Country
          formik={formik}
          fieldName="country"
          dependencies={["state", "timezone"]}
          label={t("living_country")}
          id={formik.values.country.key}
        />
        <State
          formik={formik}
          fieldName="state"
          countryFieldName="country"
          dependencies={["city"]}
          label={t("living_state")}
          id={formik.values.state.key}
        />
        <City
          formik={formik}
          fieldName="city"
          stateFieldName="state"
          label={t("living_city")}
          id={formik.values.city.key}
        />
        <Timezone
          user_id={undefined}
          formik={formik}
          fieldName="timezone"
          countryFieldName="country"
          id={formik.values.timezone.key}
        />

        <FastInput
          formik={formik}
          fieldName={"zipCode"}
          icon={MailTrackingBold}
          label={t("zipcode")}
        />

        <FastInput
          formik={formik}
          fieldName={"address"}
          icon={LocationBold}
          label={t("address")}
        />
        <div className={classes.buttonContainer}>
          {backButton}
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => onSetAlert(true)}
          >
            {t("cancel")}
          </Button>
          <Button type="submit" disabled={isMutationLoading}>
            {isMutationLoading ? (
              <div className="d-flex mt-2">
                <BeatLoader color="#fff" size={10} margin={2} />
              </div>
            ) : (
              t("register")
            )}
          </Button>
        </div>
      </Grid>
    </form>
  );
};

export default RegistrationThirdPage;

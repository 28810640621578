import { ReactComponent as ForbiddenSVG } from "assets/images/403.svg";
import classes from "./styles.module.scss";

function ForbiddenIcon(props: { [key: string]: any }) {
  return (
    <ForbiddenSVG data-variant="bulk" {...props} className={classes.forbid} />
  );
}

export { ForbiddenIcon };

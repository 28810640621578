import clsx from "clsx";
import { IVonageMetaData } from "interfaces/vonageStream";
import classes from "./styles.module.scss";

export interface IMessage {
  from: string;
  text: string;
  timeText: string;
  isMyMessage: boolean;
}

// Message component for display in messages
const Message = ({ text, timeText, isMyMessage, from }: IMessage) => {
  const senderData: IVonageMetaData = JSON.parse(from);
  // render component
  return (
    <div
      className={clsx(
        classes.column,
        !isMyMessage && classes.message__other_back
      )}
    >
      {isMyMessage && (
        <div>
          <span className={clsx(classes.title)}>{senderData.name}</span>
          <span className={clsx(classes.role)}>
            {" (" + senderData.role + ")"}
          </span>
        </div>
      )}
      <div className={clsx(classes.message)}>
        <div
          className={clsx(classes.message__text)}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <div
          className={clsx(
            classes.message__time,
            !isMyMessage && classes.message__other_color
          )}
        >
          {timeText}
        </div>
      </div>
    </div>
  );
};

export default Message;

import classes from "./sideMenu.styles.module.scss";
import { ReactComponent as MenuIcon } from "assets/icons/bold/menu.svg";
import { useContext } from "react";
import { SidebarContext } from "..";
import { X_LARGE } from "constants/breakpoints";
import IconButton from "components/core/iconButton";

function raiseWindowResizeEvent() {
  window.dispatchEvent(new Event("resize"));
}
export function SideMenuButton() {
  const { sideIn, setSideIn, setAutoSideOut } = useContext(SidebarContext);

  return (
    <IconButton
      className={classes.sideMenuButton}
      variant={"contained"}
      icon={MenuIcon}
      onClick={() => {
        if (window.innerWidth >= X_LARGE) {
          setAutoSideOut(false);
          // if you want to not reset the user's decision about not to auto side out then set the value to sideIn value. like this: setAutoSideOut(sideIn);
        }
        setSideIn(!sideIn);
        setTimeout(raiseWindowResizeEvent, 200);
      }}
    />
  );
}

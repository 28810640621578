import clsx from "clsx";
import classes from "./styles.module.scss";
import { Step } from "./step";
import { StepNumber } from "pages/globalPages/registration/context";
import { useTranslation } from "react-i18next";
import { ReactComponent as SignSVG } from "assets/images/login/sign.svg";
import { LanguageDropdown } from "../../../components/common/languageDropdown";

export interface ILoginLayoutProps extends React.PropsWithChildren<any> {
  hasFooter?: boolean;
  form_title: string;
  form_description: string;
  stepsCount?: number;
  step?: 1 | 2 | 3 | 4 | 5;
  onSetStep?: (step: StepNumber) => void;
}

export default function LoginLayout({
  children,
  hasFooter,
  form_title,
  form_description,
  stepsCount,
  step,
  onSetStep,
}: ILoginLayoutProps) {
  const { t } = useTranslation(["global"]);

  return (
    <div className={clsx([classes.loginContainer])}>
      <div className={classes.introductionSection}>
        <div className={classes.introductionContainer}>
          <div className="d-flex">
            <div className={classes.brand}>{t("leo")}</div>

            {/* <div className={classes.symbolContainer}>
              <div className={classes.trademark_symbol}>
                <span>&trade;</span>
              </div>
            </div> */}
          </div>
          <div className={classes.introduction_text}>
            <div>
              <div>{t("login_layout.text")}</div>
              <div className="d-flex justify-content-end mt-1">
                <SignSVG className="ME-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.formSection}>
        {/* <LanguageDropdown /> */}
        <div className={classes.form_container}>
          {step && (
            <Step count={stepsCount || 3} step={step} onSetStep={onSetStep} />
          )}
          <div className={classes.language_container}>
            <div className={classes.form_title}>{form_title}</div>
            <div style={{ alignSelf: "end" }}>
              <LanguageDropdown />
            </div>
          </div>
          <div className={classes.form_description}>{form_description}</div>
          <div className={classes.form_formContainer}>{children}</div>
          <div>
            {hasFooter && (
              <div className={classes.desktop_footer}>Lyons Global™</div>
            )}
          </div>
        </div>
      </div>
      {hasFooter && <div className={classes.mobile_footer}>Lyons Global™</div>}
    </div>
  );
}

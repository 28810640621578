import { useRobotLevelDropdown } from "features/registeredRobots";
import { useDropdownsOptions } from "hooks/useOptions";
//TODO: remove this hook
const useRobotLevelOptions = () => {
  const { data, ...rest } = useRobotLevelDropdown();

  const OPTIONS = useDropdownsOptions({ data: data });
  return { OPTIONS: OPTIONS, ...rest };
};

export default useRobotLevelOptions;
